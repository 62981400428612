import { useTheme } from "@mui/material";
import { useState } from "react";
import { Autocomplete } from "../common/AutoComplete";
import Button from "../common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import Typography from "../common/Typography";

const ApplyTopicToAppsDialog = ({ open, onClose, apps, onSubmit }) => {
  const theme = useTheme();
  const [isEmptyApp, setIsEmptyApp] = useState(false);
  const [selectedApp, setSelectedApp] = useState("");

  const styles = {};

  const onCloseDialog = () => {
    setSelectedApp("");
    setIsEmptyApp(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle handleClose={onCloseDialog}>
        <Typography variant="subtitle1" color={theme.palette.surface80.main}>
          Select Application
        </Typography>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          Select application to apply the selected topic
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          label="Application"
          variant="standard"
          error={isEmptyApp}
          helperText={isEmptyApp && "Please select an Application."}
          options={apps}
          value={selectedApp}
          onChange={(e: Event, newValue) => {
            if (newValue) setIsEmptyApp(false);
            setSelectedApp(newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (selectedApp) {
              onSubmit(selectedApp);
              setSelectedApp("");
            } else setIsEmptyApp(true);
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyTopicToAppsDialog;
