import { useTheme } from "@mui/material";
import Box from "./Box";
import { SpectrumGraphProps } from "../dashboard-widgets/type";

const SpectrumGraph = ({ data, height, width }: SpectrumGraphProps) => {
  const total = data?.length
    ? data?.reduce((acc, label) => acc + label?.value, 0)
    : 0;
  const theme = useTheme();
  const styles = {
    bar: {
      display: "flex",
      width: width ? `${width}px` : "100%",
      height: `${height}px`,
    },
    segment: (label) => ({
      width: `${(label.value / total) * 100}%`,
      backgroundColor: label.color,
    }),
  };
  return (
    <Box sx={styles.bar}>
      {data && data?.length
        ? data.map((label, index) => (
            <Box key={label.label} sx={styles.segment(label)}></Box>
          ))
        : null}
    </Box>
  );
};

export default SpectrumGraph;
