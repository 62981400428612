import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteLoaderApp,
  fetchAppDetails,
  fetchLoaderGraph,
  fetchLoaderList,
  fetchLoaderOverview,
  fetchSnippets,
  setLoaderAppDescription,
} from "../apis/dataLoaderAPI";

const initialState = {
  dataLoaderApp: {},
  loaders: null,
};

export const fetchLoaderOverviewSlice = createAsyncThunk(
  "loader/overview",
  async (params: any) => {
    const response = await fetchLoaderOverview(params);
    return response.data;
  }
);

export const fetchLoaderAppDetailsSlice = createAsyncThunk(
  "loader/app",
  async (params: any) => {
    const response = await fetchAppDetails(params);
    return response.data;
  }
);

export const fetchLoaderGraphSlice = createAsyncThunk(
  "loader/graph",
  async (params: { customerId: string; dataLoaderId: string }) => {
    const response = await fetchLoaderGraph(params);
    return response.data;
  }
);

export const fetchSnippetsSlice = createAsyncThunk(
  "loader/snippet",
  async (params: {
    customerId: string;
    dataLoaderId: string;
    dataSourceId: string;
    documentId: string;
    label: string;
    labelName: string;
  }) => {
    const response = await fetchSnippets(params);
    return response.data;
  }
);

export const fetchLoaderListSlice = createAsyncThunk(
  "loader/list",
  async (params: { customerId: string; count?: number }) => {
    const response = await fetchLoaderList(params);
    return response.data;
  }
);

export const deleteLoaderAppSlice = createAsyncThunk(
  "loader/delete",
  async (params: { customerId: string; dataLoaderId: string }) => {
    const response = await deleteLoaderApp(params);
    return response.data;
  }
);

export const setLoaderAppDescriptionSlice = createAsyncThunk(
  "loader/description",
  async (params: { customerId: any; id: any; description: any }) => {
    const response = await setLoaderAppDescription(params);
    return response.data;
  }
);

export const dataLoaderSlice = createSlice({
  name: "dataLoader",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoaderAppDetailsSlice.fulfilled, (state, action) => {
      state.dataLoaderApp = action.payload;
    });
    builder.addCase(fetchLoaderListSlice.fulfilled, (state, action) => {
      state.loaders = action.payload?.map((app) => ({
        label: app?.name,
        value: app?.name,
      }));
    });
  },
});

export default dataLoaderSlice.reducer;
