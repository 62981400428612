import { CollapsibleGraph } from "../../common/CollapsibleGraph";
import { useEffect, useMemo, useState } from "react";
import Box from "../../common/Box";
import ChainIcon from "../../../assets/img/chainIcon.svg";
import { Popover } from "../../common/Popover";
import { LangchainPopover } from "./LangchainPopover";
import { LangchainGraphNode } from "./LangchainGraphNode";
import GPTIcon from "../../../assets/img/gptIcon.svg";
import HuggingFaceIcon from "../../../assets/img/hugging.png";
import { useTheme } from "@mui/material";

export const ChainGraph = ({
  isChild = false,
  className = "",
  chainData = {},
}: {
  isChild: boolean;
  className: string;
  chainData: any;
}) => {
  const [treeData, setTreeData] = useState<any>({});
  const [selectedNode, setSelectedNode] = useState<any>("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState({});
  const [popoverDataLabels, setPopoverDataLabels] = useState<
    Array<{
      label: string;
      field: string;
    }>
  >([]);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  useEffect(() => {
    if (Object.keys(chainData)?.length > 0) {
      setTreeData(chainData);
    }
  }, [chainData]);

  const childrenLength = treeData?.children?.filter(
    (val) => Object.keys(val)?.length > 0
  )?.length;

  const styles = {
    chainGraphWrapper: {
      position: "relative",
      height:
        childrenLength > 0
          ? 100 * (childrenLength === 1 ? childrenLength + 1 : childrenLength)
          : 200,
      width: childrenLength > 0 ? 600 : 228,
      borderRadius: "5px",
      border: "1px solid #3B3E52",
      paddingX: "12px",
    },
    chainGraph: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    chainIcon: {
      display: "flex",
      gap: "4px",
    },
    chainText: {
      fontSize: "11px",
      color: "#fff",
    },
    modelDetail: {
      position: "absolute",
      left: "12px",
      bottom: "12px",
    },
    popover: {
      ".MuiPaper-root": {
        background: theme?.palette?.surface80?.main || "#E6E7ED",
        padding: "20px",
        marginTop: "8px",
      },
    },
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const selectedElement = document.getElementsByClassName("selectedNode")[0];
    selectedElement?.classList?.remove("selectedNode");
    setAnchorEl(null);
  };

  const openPopover = (e, id) => {
    if (selectedNode !== id) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClick = (e, d) => {
    const elem: any = document.querySelectorAll(`#${d?.data?.name}`);
    if (selectedNode === d?.data?.name) {
      elem[elem?.length - 1].classList.remove("selectedNode");
      setSelectedNode("");
      setAnchorEl(null);
    } else {
      const allElements = document.querySelectorAll("*");
      allElements.forEach((element) => {
        element.classList.remove("selectedNode");
      });
      elem[elem?.length - 1].classList.add("selectedNode");
      setSelectedNode(d?.data?.name);
      setPopoverData(d?.data);
      openPopover(e, d?.data?.name);
      if (d?.data?.type === "loader") {
        setPopoverDataLabels(loaderPopoverLabels);
      } else {
        setPopoverDataLabels(vectorDBPopoverLabels);
      }
    }
  };

  const plotGraph = useMemo(() => {
    return (
      <CollapsibleGraph
        className={className}
        graphData={treeData}
        handleClick={handleClick}
        classNameForGraph="chain-graph-wrapper"
        isChild={isChild}
        nodeDepth={100}
        nodeLength={childrenLength > 0 ? childrenLength + 1 : 2}
      />
    );
  }, [treeData]);

  const vectorDBPopoverLabels = [
    {
      label: "Location",
      field: "location",
    },
    {
      label: "Installed Via Package Manager",
      field: "installedVia",
    },
    {
      label: "Github Link",
      field: "githubLink",
    },
    {
      label: "License Type",
      field: "licenceType",
    },
    {
      label: "Documentation Site Link",
      field: "documentationUrl",
    },
    {
      label: "PyPi Link",
      field: "pypiUrl",
    },
  ];

  const loaderPopoverLabels = [
    {
      label: "Source",
      field: "sourcePath",
    },
    {
      label: "Source Type",
      field: "sourceType",
    },
    {
      label: "Source Files",
      field: "sourceFiles",
    },
    {
      label: "Last Modified",
      field: "lastModified",
    },
  ];

  return (
    <Box sx={styles.chainGraph}>
      <Box sx={styles.chainIcon}>
        <img src={ChainIcon} alt="Chain Icon" height={16} width={16} />
        <div style={styles.chainText}>{className}</div>
      </Box>
      <Box className="chain-graph-wrapper" sx={styles.chainGraphWrapper}>
        {plotGraph}
        <Popover
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          sx={styles.popover}>
          <LangchainPopover
            handleClose={handleClose}
            popoverData={popoverData}
            dataLabels={popoverDataLabels}
          />
        </Popover>
        <Box sx={styles.modelDetail}>
          <LangchainGraphNode
            icon={
              chainData?.modelDetail?.vendor === "Huggingface"
                ? HuggingFaceIcon
                : GPTIcon
            }
            title={chainData?.modelDetail?.name}
            description={chainData?.modelDetail?.vendor}
            isNode={false}
          />
        </Box>
      </Box>
    </Box>
  );
};
