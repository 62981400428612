import { ButtonProps, SxProps, useMediaQuery, useTheme } from "@mui/material";
import Button from "./Button";
import { useHistory } from "react-router-dom";

interface TextButtonProps extends ButtonProps {
  hideText?: boolean;
  sx?: SxProps;
  link?: string;
  onClick?: (event?) => void;
}

const TextButton = ({
  hideText = false,
  sx = {},
  link = "",
  onClick = () => {},
  ...props
}: TextButtonProps) => {
  const theme = useTheme();
  const history = useHistory();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down("lg"));

  const navigateTo = () => {
    history.push(link);
  };

  const styles = {
    buttonText: {
      minWidth: "unset",
      color: theme?.palette?.primaryBlue?.main || "#0074E0",
      backgroundColor: "none",
      textTransform: "none",
      lineHeight: "1",
      padding: 0,
      position: "unset",
      ":hover": {
        backgroundColor: "transparent",
        color: theme?.palette?.secondaryBlue?.main || "#5CB1FF",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
      "& .MuiButton-endIcon": {
        marginLeft: "2px",
      },
      "& .MuiButton-startIcon": {
        marginRight: "4px",
      },
      "&.MuiButton-textError": {
        color: theme?.palette?.critical?.main || "#FF655B",
      },
    },
  };
  return (
    <Button
      variant="text"
      sx={{ ...styles.buttonText, ...sx } as SxProps}
      onClick={link ? navigateTo : onClick}
      {...props}>
      {hideText && isSmallWidth ? "" : props.children}
    </Button>
  );
};

export default TextButton;
