import React, { useEffect, useState } from "react";
import "./style.scss";
import { getDataStoresStatsApi } from "../../../apis/index.js";
import { getCustomerId } from "../../../utils/SessionHelper";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../common/Table";

const DataOverview = ({ selectedAccount, region }) => {
  const [data, setData] = useState([]);

  const shadowOverviewData = [
    {
      name: "gpt2",
      count: 2,
    },
    { name: "cerebras/Cerebras-GPT-590M", count: 1 },
    { name: "falcon-7b-bf16", count: 1 },
  ];

  const getDatastoreStats = async () => {
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      location: region ? region : null,
    };

    let res = await getDataStoresStatsApi(payload);
    if (!res.datastores) return;
    const types = res.datastores.types;
    setData(types);
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  return (
    <div className="llm-data-container">
      <div className="sensitive-table">
        <div className="sensitive-table">
          <div className="table-responsive">
            {/*<table className={`mb-0 table table-border-less table-hover`}>
              <thead>
                <tr className="vuls-row">
                  <th scope="col">Name</th>
                  <th scope="col" className="table-header">
                    Count
                  </th>
                </tr>
              </thead>*/}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell>Name</TableCell>
                  <TableCell>Count</TableCell>
                </TableHead>

                {shadowOverviewData && shadowOverviewData.length ? (
                  <TableBody>
                    {shadowOverviewData.map(function (item, idx) {
                      return (
                        <TableRow key={idx}>
                          <TableCell> {item.name}</TableCell>
                          <TableCell>{item.count}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  ""
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataOverview;
