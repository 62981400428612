import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDocumentBySources,
  getDocumentDetails,
  getDocumentInfo,
  getImpactedDocuments,
  getSnippetsByLabels,
} from "../apis/documentAPI";

const initialSlice = {
  documents: [],
};

export const fetchImpactedDocumentsSlice = createAsyncThunk(
  "document/impacted",
  async (params: { customerId: string; count?: number }) => {
    const response = await getImpactedDocuments(params);
    return response.data;
  }
);

export const fetchDocumentInfoSlice = createAsyncThunk(
  "document/info",
  async (params: { customerId: string; documentName: string }) => {
    const response = await getDocumentInfo(params);
    return response.data;
  }
);

export const fetchDocumentDetailsSlice = createAsyncThunk(
  "document/details",
  async (params: { customerId: string; documentName: string }) => {
    const response = await getDocumentDetails(params);
    return response.data;
  }
);

export const fetchDocumentBySourcesSlice = createAsyncThunk(
  "document/bySources",
  async (params: { customerId: string; count?: number }) => {
    const response = await getDocumentBySources(params);
    return response.data;
  }
);

export const fetchSnippetsByLabelsSlice = createAsyncThunk(
  "document/snippetsByLabels",
  async (params: { customerId: string; count?: number }) => {
    const response = await getSnippetsByLabels(params);
    return response.data;
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState: initialSlice,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchImpactedDocumentsSlice.fulfilled, (state, action) => {
      const docList = action?.payload?.data
        ? Object.keys(action?.payload?.data).map((key) => ({
            name: key,
            ...action?.payload?.data[key],
          }))
        : [];
      state.documents = docList as any;
    });
  },
});

export default documentSlice.reducer;
