import { useTheme } from "@mui/material";
import { useMemo } from "react";
import Box from "../../../common/Box";
import Divider from "../../../common/Divider";
import MetricBlock from "../../../common/MetricBlock";
import { AppOverview } from "../../../dashboard-widgets/APIResponseType";

interface ColumnType {
  id: number;
  value?: number;
  outOf?: number;
  label?: string;
  isCritical?: boolean;
  isDivider?: boolean;
}

const AppDetails = ({ overview }) => {
  const theme = useTheme();
  const styles = {
    panel: {
      display: "flex",
      gap: theme.spacing(6),
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      borderRadius: "4px",
    },
  };

  const overviewColumns: ColumnType[] = useMemo(
    () => [
      {
        id: 0,
        value: overview?.users_count,
        outOf: 0,
        label: "Users",
        isCritical: false,
      },
      {
        id: 1,
        isDivider: true,
        value: 0,
        outOf: 0,
        label: "",
        isCritical: false,
      },
      {
        id: 2,
        value: overview?.retrievals,
        outOf: 0,
        label: "Retrievals",
        isCritical: false,
      },
      {
        id: 3,
        isDivider: true,
      },
      {
        id: 4,
        value: overview?.topics_count,
        label: "Topics Accessed",
        isCritical: false,
      },
      {
        id: 5,
        value: overview?.entities_count,
        label: "Entities Accessed",
        isCritical: false,
      },
      {
        id: 6,
        isDivider: true,
      },
      {
        id: 7,
        value: overview?.documents_accessed,
        label: "Documents Accessed",
        isCritical: false,
      },
    ],
    [overview]
  );

  return (
    <Box sx={styles.panel}>
      {overviewColumns.map((col: ColumnType) => {
        return col?.isDivider ? (
          <Divider orientation="vertical" flexItem />
        ) : (
          <MetricBlock
            label={col?.label || ""}
            value={col?.value || 0}
            outOf={col.outOf || 0}
            isCritical={col?.isCritical || false}
          />
        );
      })}
    </Box>
  );
};

export default AppDetails;
