import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import "./AddAccountModal.scss";
import InfoModal from "../info-modal/InfoModal";
import infoFilled from "../../../assets/img/icons/ep-info-filled.svg";
import Button from "../../common/Button";
import { getUserId } from "../../../utils/SessionHelper";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import { ACCOUNT_TYPES } from "../../../utils/Constants";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { FormControl, InputLabel } from "../../common/FormControl";
import { TextField } from "../../common/TextField";
import { FormHelperText, styled, useTheme } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { createAccountAsync } from "../../../redux/slices/accountSlice";
import { MenuItem, Select } from "../../common/Select";
import { accountType } from "../../constants/constants";

const AccountDetailStep = ({
  accountDetails,
  selectedAccount,
  externalId,
  backStep,
  addAccount,
  handleStepOneModal,
  loading,
  refreshAccounts,
}) => {
  const dispatch = useDispatch();
  const isDashboardView = useSelector(
    (state) => state.dashboard.isDashboardView
  );
  const isDataView = isDashboardView === accountType.Data;

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const AI_SCANNING_FREQUENCY = [
    { label: "General AI Scan", value: "Gen-AI" },
    { label: "AI Langchain", value: "Langchain-AI" },
  ];

  const [enableBtn, setEnableBtn] = useState(false);
  const [lunchStackModal, setLunchStackModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("us-east-1");
  const [dataScanFrequency, setDataScanFrequency] = useState(
    DATA_SCANNING_FREQUENCY[2].value
  );
  const [gcpDetails, setGcpDetails] = useState({
    accountId: "",
    serviceAccountKey: "",
    scanScope: "",
  });
  const [emptyValue, setEmptyValue] = useState({
    accountIdMsg: "",
    apiKeyMsg: "",
    scanScopeMsg: "",
  });
  const [fileName, setFileName] = useState("");
  const theme = useTheme();

  const styles = {
    gcpForm: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    textField: {
      "& .MuiFormLabel-root": {
        fontSize: theme.typography.caption,
      },
      "& .MuiInputBase-input": {
        fontSize: theme.typography.body2,
      },
    },
    fileUpload: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(1),
      gap: theme.spacing(1),
      //  alignItems: "left",
    },
    fileName: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  };
  const [aiType, setAIType] = useState(AI_SCANNING_FREQUENCY[0].value);

  // Formik
  const formik = useFormik({
    initialValues: {
      accountId: accountDetails.accountId,
      accountDescription: accountDetails.accountDescription,
      externalId: externalId,
      awsRoleARN: accountDetails.awsRoleARN,
      dataScanFrequency: dataScanFrequency,
      aiType: aiType,
    },

    validationSchema: Yup.object().shape({
      accountId: Yup.string()
        .max(12, "Account Id should be 12 digit")
        .min(12, "Account Id should be 12 digit")
        .matches("^[0-9]", "Account Id should be numeric.")
        .required("Please enter valid Account Id"),
      externalId: Yup.string().required("Please enter External Id."),
      // awsRoleARN: Yup.string().required("Please enter Role Arn."),
    }),
    onSubmit: (values) => {
      if (isDataView) {
        delete values.aiType;
      } else {
        delete values.dataScanFrequency;
      }
      addAccount(values);
      // nextStep(false);
    },
  });

  useEffect(() => {
    setEnableBtn(accountDetails.accountId && accountDetails.externalId);
  }, []);

  const createGCPAccount = async () => {
    if (
      gcpDetails.accountId !== "" &&
      gcpDetails.serviceAccountKey !== "" &&
      gcpDetails.scanScope !== ""
    ) {
      const resp = await dispatch(
        createAccountAsync({
          id: gcpDetails.accountId,
          type: selectedAccount,
          accountDescription: "Account desc",
          serviceAccountKey: gcpDetails.serviceAccountKey,
          scanScope: gcpDetails.scanScope,
          userId: getUserId(),
        })
      );
      if (resp && resp.payload && !resp.payload.err) {
        handleStepOneModal();
        refreshAccounts();
        Toaster(TOASTER_TYPES.SUCCESS, "Account added successfully.");
      } else {
        Toaster(
          TOASTER_TYPES.ERROR,
          resp.payload?.err?.message ||
            "Please enter a valid Account Id and try again"
        );
      }
      setEmptyValue({
        accountIdMsg: "",
        apiKeyMsg: "",
        scanScopeMsg: "",
      });
    } else {
      setEmptyValue({
        accountIdMsg:
          gcpDetails.accountId === "" ? "Please enter Account ID" : "",
        apiKeyMsg:
          gcpDetails.serviceAccountKey === ""
            ? "Please select a JSON File"
            : "",
        scanScopeMsg:
          gcpDetails.scanScope === "" ? "Please select a value" : "",
      });
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: "100%",
    zIndex: 99,
  });

  const ScanScopeOptions = [
    {
      label: "AI-only",
      value: "ai-scan",
    },
    {
      label: "Full Cloud",
      value: "full-scan",
    },
  ];

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {selectedAccount === ACCOUNT_TYPES.AWS ? (
          <div className="modal-body">
            {loading && (
              <img
                alt="Modal loading"
                src="static/img/loader-circle.svg"
                className="modal-loading-img"
              ></img>
            )}
            <label className="label">Account Id</label>
            <span className="mandatory-icon">*</span>
            <div className="row">
              <div className="col col-lg-12">
                <input
                  type="number"
                  id="accountId"
                  placeholder="12-Digit AWS Account Id"
                  name="accountId"
                  maxLength={"12"}
                  minLength={"12"}
                  className="form-control"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  onInput={(event) => {
                    if (!event.target.value.length) {
                      document.getElementById("accountId").value = "";
                    }
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.keyCode === 38 ||
                      event.keyCode === 40 ||
                      event.keyCode === 69
                    ) {
                      event.preventDefault();
                      return;
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length === 13) {
                      return;
                    }
                    if (e.target.value === "e") {
                      return;
                    }
                    formik.setFieldValue("accountId", e.target.value?.trim());
                    setEnableBtn(true);
                  }}
                  value={formik.values.accountId}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.accountId && (
                  <div className="error-message">{formik.errors.accountId}</div>
                )}
              </div>
            </div>
            <label className="label">Account Description</label>
            {/* <span className="mandatory-icon">*</span> */}
            <div className="row mt-2">
              <div className="col col-lg-12">
                <textarea
                  className="form-control"
                  placeholder="Account Description"
                  name="accountDescription"
                  id="accountDescription"
                  onChange={(e) => {
                    formik.setFieldValue("accountDescription", e.target.value);
                  }}
                  value={formik.values.accountDescription}
                ></textarea>
              </div>
            </div>
            <label className="label">External ID</label>
            <span className="mandatory-icon">*</span>
            <div className="row">
              <div className="col col-lg-12">
                <input
                  type="text"
                  id="externalId"
                  placeholder="External Id"
                  name="externalId"
                  className="form-control"
                  onChange={(e) => {
                    formik.setFieldValue("externalId", e.target.value?.trim());
                    setEnableBtn(true);
                  }}
                  value={formik.values.externalId}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.externalId && formik.touched.externalId && (
                  <div className="error-message">
                    {formik.errors.externalId}
                  </div>
                )}
              </div>
              <div className="col-lg-12">
                <label className="label">
                  The External ID has been auto generated for you. You can
                  change the ID to a custom ID.
                </label>
              </div>
            </div>
            <label className="label">Region</label>
            <div className="row">
              <div className="col col-lg-12">
                <div className="row">
                  <div className="col col-lg-12">
                    <select
                      className="form-control"
                      value={selectedRegion}
                      onChange={(value) => {
                        setSelectedRegion(value.value);
                      }}
                    >
                      <option disabled>Select Region</option>
                      <option value={"us-east-1"}>us-east-1</option>
                      {/* {regionList.map((region, index) => (
                      <option key={index} value={region.value}>
                        {region.value}
                      </option>
                    ))} */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {isDataView ? (
              <>
                <label className="label mt-2">Data Scanning Frequency</label>
                <div className="row">
                  <div className="col col-lg-12">
                    <div className="row">
                      <div className="col col-lg-12">
                        <select
                          className="form-control"
                          value={formik.values.dataScanFrequency}
                          onBlur={formik.handleBlur}
                          onChange={(event) => {
                            setDataScanFrequency(event.target.value);
                            formik.setFieldValue(
                              "dataScanFrequency",
                              event.target.value
                            );
                          }}
                        >
                          <option disabled>
                            Select Data Scanning Frequency
                          </option>
                          {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                            <option key={index} value={frequency.value}>
                              {frequency.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <label className="label mt-2">AI Scan</label>
                <div className="row">
                  <div className="col col-lg-12">
                    <div className="row">
                      <div className="col col-lg-12">
                        <select
                          className="form-control"
                          value={formik.values.aiType}
                          onBlur={formik.handleBlur}
                          onChange={(event) => {
                            setAIType(event.target.value);
                            formik.setFieldValue("aiType", event.target.value);
                          }}
                        >
                          <option disabled>Select AI Scan</option>
                          {AI_SCANNING_FREQUENCY.map((frequency, index) => (
                            <option key={index} value={frequency.value}>
                              {frequency.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <hr></hr>
          </div>
        ) : selectedAccount === ACCOUNT_TYPES.GCP ? (
          <Box sx={styles.gcpForm}>
            <Typography variant="caption">Account Details</Typography>
            <FormControl variant="standard">
              <TextField
                error={emptyValue.accountIdMsg !== ""}
                helperText={emptyValue.accountIdMsg}
                label="Project ID"
                variant="standard"
                value={gcpDetails.accountId}
                sx={styles.textField}
                onChange={(event) => {
                  setGcpDetails({
                    ...gcpDetails,
                    accountId: event.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl variant="standard">
              {/*<TextField
              error={emptyValue.apiKeyMsg !== ""}
              helperText={emptyValue.apiKeyMsg}
              label="API Key"
              variant="standard"
              sx={styles.textField}
              onChange={(event) =>
                setGcpDetails({
                  ...gcpDetails,
                  apiKey: event.target.value,
                })
              }
            />*/}
              <Box sx={styles.fileUpload}>
                <Typography variant="caption">Choose JSON File</Typography>
                <Box sx={styles.fileName}>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    component="label"
                  >
                    Select File
                    <VisuallyHiddenInput
                      type="file"
                      accept=".json"
                      onChange={async ({ target }) => {
                        const toBase64 = async (file) =>
                          new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = (error) => reject(error);
                          });
                        setFileName(target.files[0].name);
                        const file = await toBase64(target.files[0]);
                        setGcpDetails({
                          ...gcpDetails,
                          serviceAccountKey: file.split(",")[1],
                        });
                      }}
                    />
                  </Button>
                  <Typography
                    variant="caption"
                    color={theme.palette.surface60.main}
                  >
                    {fileName || "No File Selected"}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color={theme.palette.critical.main}
                >
                  {emptyValue?.apiKeyMsg}
                </Typography>
              </Box>
            </FormControl>
            <Select
              label="Scan Scope"
              fullWidth={true}
              variant="standard"
              value={gcpDetails?.scanScope}
              options={ScanScopeOptions}
              onChange={(event) =>
                setGcpDetails({
                  ...gcpDetails,
                  scanScope: event.target.value,
                })
              }
              sx={{
                width: "100%",
              }}
            />
          </Box>
        ) : (
          <></>
        )}

        <div className="modal-footer">
          <div className="row">
            <div className="col p-0">
              <div className="float-left">
                <Button
                  onClick={() => {
                    backStep(false);
                  }}
                  variant="contained"
                  type="submit"
                  disabled={loading}
                >
                  Back: Select Account
                </Button>
              </div>
              <div className="float-right">
                {selectedAccount === ACCOUNT_TYPES.AWS ? (
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid || !enableBtn}
                  >
                    Onboard Account
                  </Button>
                ) : (
                  <Button
                    onClick={createGCPAccount}
                    variant="contained"
                    disabled={loading}
                  >
                    Onboard Account
                  </Button>
                )}

                <span className="separator mx-3"></span>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    handleStepOneModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {lunchStackModal ? (
        <InfoModal handleModal={() => setLunchStackModal(false)} />
      ) : (
        ""
      )}
    </div>
  );
};

export default AccountDetailStep;
