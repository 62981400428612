const SlackIcon = ({ disabled }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.37393 10.1064C3.37393 11.0364 2.62334 11.787 1.69331 11.787C0.763288 11.787 0.0126953 11.0339 0.0126953 10.1064C0.0126953 9.1789 0.763288 8.42578 1.69331 8.42578H3.37393V10.1064ZM4.21298 10.1064C4.21298 9.17637 4.96357 8.42578 5.8936 8.42578C6.82363 8.42578 7.57422 9.17637 7.57422 10.1064V14.3067C7.57422 15.2367 6.82363 15.9873 5.8936 15.9873C4.96357 15.9873 4.21298 15.2367 4.21298 14.3067V10.1064Z"
        fill={disabled ? "grey" : "#E01E5A"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.89354 3.36124C4.96351 3.36124 4.21292 2.61065 4.21292 1.68062C4.21292 0.750592 4.96351 0 5.89354 0C6.82357 0 7.57416 0.750592 7.57416 1.68062V3.36124H5.89354ZM5.89354 4.21292C6.82357 4.21292 7.57416 4.96351 7.57416 5.89354C7.57416 6.82357 6.82357 7.57416 5.89354 7.57416H1.68062C0.750592 7.57416 0 6.82104 0 5.89354C0 4.96604 0.750592 4.21292 1.68062 4.21292H5.89354Z"
        fill={disabled ? "grey" : "#36C5F0"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6261 5.89354C12.6261 4.96351 13.3767 4.21292 14.3067 4.21292C15.2367 4.21292 15.9873 4.96351 15.9873 5.89354C15.9873 6.82357 15.2367 7.57416 14.3067 7.57416H12.6261V5.89354ZM11.787 5.89354C11.787 6.82357 11.0364 7.57416 10.1064 7.57416C9.17637 7.57416 8.42578 6.82357 8.42578 5.89354V1.68062C8.42578 0.750592 9.17637 0 10.1064 0C11.0364 0 11.787 0.750592 11.787 1.68062V5.89354Z"
        fill={disabled ? "grey" : "#2EB67D"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1064 12.6261C11.0364 12.6261 11.787 13.3767 11.787 14.3067C11.787 15.2367 11.0364 15.9873 10.1064 15.9873C9.17637 15.9873 8.42578 15.2367 8.42578 14.3067V12.6261H10.1064ZM10.1064 11.787C9.17637 11.787 8.42578 11.0364 8.42578 10.1064C8.42578 9.17637 9.17637 8.42578 10.1064 8.42578H14.3193C15.2493 8.42578 15.9999 9.17637 15.9999 10.1064C15.9999 11.0364 15.2493 11.787 14.3193 11.787H10.1064Z"
        fill={disabled ? "grey" : "#ECB22E"}
      />
    </svg>
  );
};

export default SlackIcon;
