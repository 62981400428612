import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Divider from "../../common/Divider";
import TextButton from "../../common/TextButton";
import Typography from "../../common/Typography";
import ViewMoreBlock from "../../common/ViewMoreBlock";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import { convertUTCToLocal, getDateTime } from "../../../utils/commonUtils";
import JiraIcon from "../../icons/JiraIcon";

const NUM_OF_DEFAULT_CHARACTERS = 250;

const LoaderSnippetDetailsBlock = ({ data }) => {
  const theme = useTheme();
  const styles = {
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.25),
      backgroundColor: theme.palette.surface5.main,
      padding: theme.spacing(2),
      borderRadius: "4px",
      borderBottom: `1px solid ${theme.palette.surface20.main}`,
    },
    details: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    retrievedOnText: {
      display: "flex",
      justifyContent: "space-between",
    },
    rowActions: {
      display: "flex",
      gap: theme.spacing(2),
    },
    snippetTextBlock: {
      width: "80%",
    },
  };
  const retrievalDate =
    data?.metadata && data.metadata?.createdAt
      ? convertUTCToLocal(data.metadata.createdAt)
      : null;
  return (
    <Box sx={styles.messageContainer}>
      <Box sx={styles.details}>
        <Box sx={styles.retrievedOnText}>
          {retrievalDate && (
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Loaded On: {getDateTime(retrievalDate)}
            </Typography>
          )}
          <Box sx={styles.rowActions}>
            <TextButton
              disabled={true}
              startIcon={<JiraIcon disabled={true} />}
            >
              Open Ticket
            </TextButton>
            <Divider orientation="vertical" flexItem />
            <TextButton disabled={true} startIcon={<OutlinedFlagIcon />}>
              Report
            </TextButton>
          </Box>
        </Box>
        <Box sx={styles.keyValue}>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Snippet
          </Typography>
          <Box sx={styles.snippetTextBlock}>
            <ViewMoreBlock
              value={data?.doc}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoaderSnippetDetailsBlock;
