import { useTheme } from "@mui/material";
import { useState } from "react";
import Typography from "./Typography";
import { getTruncatedString } from "../../utils/commonUtils";
import TextButton from "./TextButton";

interface ViewMoreBlockProp {
  value: string;
  length: number;
}

const ViewMoreBlock = ({ value, length }: ViewMoreBlockProp) => {
  const [readMore, setReadMore] = useState(false);
  const theme = useTheme();
  const styles = {
    viewMoreBtn: {
      fontSize: theme.spacing(1.5),
      marginLeft: theme.spacing(0.5),
    },
    wordBreak: {
      wordBreak: "break-all",
    },
  };

  return (
    <Typography
      variant="caption"
      component="span"
      color={theme.palette.surface80.main}
      sx={styles.wordBreak}>
      {value ? getTruncatedString(value, length, readMore) : "-"}
      {value?.length > length ? (
        <TextButton
          onClick={() => setReadMore((prevState) => !prevState)}
          sx={styles.viewMoreBtn}>
          View {`${readMore ? "less" : "more"}`}
        </TextButton>
      ) : null}
    </Typography>
  );
};

export default ViewMoreBlock;
