import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import { TextField } from "../../common/TextField";
import Typography from "../../common/Typography";

const PolicyName = ({ policyName, setPolicyName }) => {
  const theme = useTheme();
  const styles = {
    card: {
      width: "60%",
      padding: theme.spacing(2),
    },
  };
  return (
    <Box sx={styles.card}>
      <TextField
        value={policyName}
        onChange={(event) => {
          setPolicyName(event.target.value);
        }}
        label="Policy Name"
        fullWidth={true}
      />
      <Typography variant="caption" color={theme.palette.surface50.main}>
        You can either keep the suggested name or click to customize and enter
        your own.
      </Typography>
    </Box>
  );
};

export default PolicyName;
