import React, { useEffect } from "react";
import FormatUrl from "../../utils/UrlFormatter";
import { setAuthToken } from "../../utils/SessionHelper";
import axios from "axios";

const LoginCallback = () => {
  const loginCallback = async () => {
    let url = FormatUrl(
      `/user/serviceProviderCallback${window.location.search}&login=true`
    );
    let res = await axios.get(url);
    res = res.data;

    const token = res.token;
    const userInfo = token.userInfo;

    setAuthToken(token.id_token);
    localStorage.setItem("refreshToken", token.refresh_token);
    localStorage.setItem("username", userInfo.username);
    localStorage.setItem("first_name", userInfo.first_name);
    localStorage.setItem("last_name", userInfo.last_name);
    localStorage.setItem("customerRef", userInfo.customerRef);
    localStorage.setItem("userId", userInfo._id ? userInfo._id : userInfo.id);
    localStorage.setItem("customerId", userInfo.metadata.customerId);
    localStorage.setItem("id", userInfo?.id);
    localStorage.setItem(
      "customer_name",
      token?.customer && token?.customer?.name ? token?.customer?.name : "CD"
    );
    window.location = "/";
  };
  useEffect(() => {
    loginCallback();
  }, []);

  return <></>;
};

export default LoginCallback;
