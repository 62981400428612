import { useTheme } from "@mui/material";
import Box from "./Box";

export const StatusIndicator = ({ severity = "low" }) => {
  const theme = useTheme();
  const styles = {
    indicator: (severity) => ({
      height: "15px",
      width: "15px",
      borderRadius: "50%",
      background:
        severity === "low"
          ? theme.palette.primaryBlue.main
          : severity === "high"
          ? theme.palette.high.main
          : severity === "moderate"
          ? theme.palette.warning.main
          : theme.palette.critical.main,
    }),
  };
  return <Box sx={styles.indicator(severity)}></Box>;
};
