import { useTheme } from "@mui/material";

import StarIcon from "../../../assets/img/starIcon.svg";
import { useEffect, useState } from "react";
import Box from "../../../components/common/Box";
import Typography from "../../../components/common/Typography";
import ViewMoreBlock from "../../../components/common/ViewMoreBlock";
import Chip from "../../../components/common/Chip";
import Divider from "../../../components/common/Divider";
import FileChip from "../../../components/common/FileChip";

const NUM_OF_DEFAULT_CHARACTERS = 500;

const ContextDetails = ({ initialContext, finalContext, response }) => {
  const noOfContexts = initialContext?.length;
  const [selectedContext, setSelectedContext] = useState(0);
  const theme = useTheme();
  const styles = {
    card: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    aiChip: {
      color: theme.palette.surface60.main,
      backgroundColor: "transparent",
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
    context: {},
    contextBtns: {
      display: "flex",
      gap: theme.spacing(2),
    },
    contextBtn: {
      cursor: "pointer",
    },
    divider: {
      backgroundColor: "#292D40",
    },
    contextFile: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "100%",
    },
  };
  const showContextSelector = typeof initialContext !== "string";

  return (
    <Box sx={styles.card}>
      <Box sx={styles.keyValue}>
        <Typography variant="caption" color={theme.palette.surface40.main}>
          Context
        </Typography>
        {showContextSelector ? (
          <Box sx={styles.keyValue}>
            <Box sx={styles.contextBtns}>
              {[...Array(noOfContexts)].map((_, index) => (
                <Typography
                  color={
                    selectedContext === index
                      ? theme.palette.surface100.main
                      : theme.palette.primaryBlue.main
                  }
                  onClick={() => setSelectedContext(index)}
                  sx={styles.contextBtn}
                >
                  {index + 1}
                </Typography>
              ))}
            </Box>
            <Box sx={styles.contextFile}>
              <ViewMoreBlock
                value={
                  initialContext && initialContext[selectedContext]
                    ? initialContext[selectedContext]?.data
                    : "-"
                }
                length={NUM_OF_DEFAULT_CHARACTERS}
              />
              <FileChip
                fileName={initialContext[selectedContext]?.retrievedFrom}
                link={initialContext[selectedContext]?.retrievedFrom}
              />
            </Box>
          </Box>
        ) : (
          <ViewMoreBlock
            value={initialContext}
            length={NUM_OF_DEFAULT_CHARACTERS}
          />
        )}
      </Box>
      <Divider orientation="horizontal" sx={styles.divider} />
      <Box sx={styles.keyValue}>
        <Typography variant="caption" color={theme.palette.surface40.main}>
          Response
        </Typography>
        <ViewMoreBlock value={response} length={NUM_OF_DEFAULT_CHARACTERS} />
      </Box>
    </Box>
  );
};

export default ContextDetails;
