import { useTheme } from "@mui/material";
import Box from "./Box";
import { Breadcrumbs, Link } from "./Breadcrumbs";
import Typography from "./Typography";

type Props = {
  breadcrumbs: {
    isLink: boolean;
    route?: string;
    page: string;
  }[];
};
const PageBreadcrumbs = ({ breadcrumbs = [] }: Props) => {
  const theme = useTheme();
  const styles = {
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
  };
  return (
    <Breadcrumbs sx={styles.breadCrumbLink}>
      {breadcrumbs.map((breadcrumb) =>
        breadcrumb.isLink ? (
          <Link
            underline="hover"
            color="inherit"
            href={breadcrumb.route}
            sx={styles.blueLink}
          >
            {breadcrumb.page}
          </Link>
        ) : (
          <Typography variant="caption" sx={styles.activeLink}>
            {breadcrumb.page}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
