import * as React from "react";
import Box from "../../common/Box";
import { CollapsibleGraph } from "../../common/CollapsibleGraph";
import { useEffect, useMemo, useState } from "react";
import { ChainGraph } from "./ChainGraph";
import { hydrate } from "react-dom";

export const LangchainGraph = ({
  treeData,
  nodeHeight,
}: {
  treeData: any;
  nodeHeight: number;
}) => {
  const [newNode, setNewNode] = useState(true);
  const [selectedNode, setSelectedNode] = useState<string>("");
  const [elem, setElem] = useState<any>([]);

  const handleClick = (e, d) => {
    if (selectedNode === d?.data?.name) {
      const elem: any = document.getElementById(d?.data?.name);
      elem.classList.remove("selectedNode");
      setSelectedNode("");
    } else {
      const elem: any = document.getElementById(d?.data?.name);
      const allElements = document.querySelectorAll("*");
      allElements.forEach((element) => {
        element.classList.remove("selectedNode");
      });
      elem.classList.add("selectedNode");
      setSelectedNode(d?.data?.name);
    }
  };

  const styles = {
    langchainGraphWrapper: {
      height:
        nodeHeight *
          treeData?.children?.filter((val) => Object.keys(val)?.length > 0)
            ?.length || "200px",
    },
  };

  const plotGraph = useMemo(() => {
    return (
      <CollapsibleGraph
        className="langchainGraph"
        graphData={treeData}
        handleClick={handleClick}
        classNameForGraph="langchain-graph-wrapper"
        setNewNode={() => setNewNode(true)}
        nodeDepth={nodeHeight}
        nodeLength={
          treeData?.children?.filter((val) => Object.keys(val)?.length > 0)
            ?.length
        }
      />
    );
  }, [treeData, elem, newNode]);

  useEffect(() => {
    const elementName = treeData?.children;
    if (newNode) {
      const interval = setInterval(() => {
        let element: any = elementName?.map((el) => {
          let count = 0;
          return {
            ...el,
            index: count + 1,
            childNode: document.getElementById(el?.name),
          };
        });
        setElem([...element]);
        if (element?.length > 0 && element.some((item) => item)) {
          clearInterval(interval);
        }
      }, 100);
      setNewNode(false);
    }
  }, [newNode]);

  useEffect(() => {
    if (elem?.length > 0) {
      let chainNumber = 0;
      elem?.map((element) => {
        if (element?.childNode) {
          chainNumber = chainNumber + 1;
          hydrate(
            <foreignObject
              width={
                treeData?.children[0]?.chainData?.children?.length > 0
                  ? 600
                  : 228
              }
              height={
                treeData?.children?.length > 0
                  ? 200 * (treeData?.children?.length + 1)
                  : 300
              }>
              <div>
                <ChainGraph
                  className={`Chain-${chainNumber}`}
                  isChild={true}
                  chainData={element?.chainData}
                />
              </div>
            </foreignObject>,
            element?.childNode
          );
        }
      });
    }
  }, [elem]);

  return (
    <Box className="langchain-graph-wrapper" sx={styles.langchainGraphWrapper}>
      {plotGraph}
    </Box>
  );
};
