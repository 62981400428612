const JiraIcon = ({ disabled }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5464 1.71484H7.66699C7.66699 2.53847 7.99418 3.32836 8.57657 3.91075C9.15896 4.49314 9.94885 4.82032 10.7725 4.82032H12.0397V6.0439C12.0409 7.75744 13.4297 9.1463 15.1432 9.14742V2.31163C15.1432 1.98213 14.8761 1.71484 14.5464 1.71484Z"
        fill={disabled ? "grey" : "#2684FF"}
      />
      <path
        d="M11.1421 5.14258H4.2627C4.26376 6.85613 5.65256 8.24498 7.36616 8.2461H8.63343V9.47359C8.63561 11.1871 10.0254 12.575 11.7389 12.575V5.73948C11.7389 5.40986 11.4717 5.14258 11.1421 5.14258Z"
        fill={disabled ? "grey" : "url(#paint0_linear_1_7462)"}
      />
      <path
        d="M7.73587 8.56641H0.856445C0.856445 10.2815 2.24687 11.6719 3.96193 11.6719H5.23317V12.8954C5.23429 14.6074 6.62068 15.9956 8.33272 15.9989V9.16325C8.33272 8.83364 8.06549 8.56641 7.73587 8.56641Z"
        fill={disabled ? "grey" : "url(#paint1_linear_1_7462)"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_7462"
          x1="1875.53"
          y1="8.56445"
          x2="1133.38"
          y2="875.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#0052CC" />
          <stop offset="1" stop-color="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_7462"
          x1="1922.42"
          y1="18.2368"
          x2="1063.95"
          y2="964.201"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#0052CC" />
          <stop offset="1" stop-color="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JiraIcon;
