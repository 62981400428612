import React, { useEffect, useState } from "react";
import "./style.scss";
import { ReactComponent as RDSIcon } from "./icons/rds.svg";
import { ReactComponent as SizeIcon } from "./icons/size.svg";
import { ReactComponent as RadioIcon } from "./icons/radio.svg";
import { ReactComponent as ArrowIcon } from "./icons/arrow.svg";
import { ReactComponent as ArrowDownIcon } from "./icons/arrow-down.svg";

import { ReactComponent as S3Icon } from "./icons/s3.svg";
import { ReactComponent as ElasticCacheIcon } from "./icons/elastic-cache.svg";

import { getDataStoresStatsApi } from "../../../apis/index.js";
import { getCustomerId } from "../../../utils/SessionHelper";
import { datastoreIcons } from "../../../utils/styles";
import { useTheme } from "@mui/material";
import Typography from "../../common/Typography";
import Box from "../../common/Box";
import Divider from "../../common/Divider";

const DataOverview = ({ selectedAccount, region }) => {
  const [data, setData] = useState([]);
  const [maxSize, setMaxSize] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const theme = useTheme();

  const getDatastoreStats = async () => {
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      location: region ? region : null,
    };

    let res = await getDataStoresStatsApi(payload);
    console.log(res);
    if (!res.datastores) return;
    const types = res.datastores.types;
    setData(types);

    let count = 0;
    let maxSize = 0;
    let totalSize = 0;
    let totalCount = 0;

    for (let i of types) {
      count += 1;
      maxSize =
        maxSize < parseInt(i.size.split(" ")[0])
          ? parseInt(i.size.split(" ")[0])
          : maxSize;

      totalSize += parseInt(i.size.split(" ")[0]);
      totalCount += parseInt(i.count);
    }
    setMaxSize(maxSize);
    setTotalSize(totalSize);
    setTotalCount(totalCount);
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  const styles = {
    card: {
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
    dataCount: { fontFamily: "Manrope", fontSize: "28px" },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    contentRow: {
      //display: "flex",
      //flexDirection: "column",
      //gap: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "50% 50%",
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
    },
    numberText: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  };

  return (
    <Box sx={styles.card}>
      <Typography variant="caption" color={theme.palette.surface60.main}>
        Data Overview
      </Typography>
      <Box sx={styles.cardContainer}>
        <Typography color={theme.palette.surface70.main} sx={styles.dataCount}>
          {totalCount}
        </Typography>
        <Divider />
        <Box sx={styles.contentRow}>
          {data &&
            data.map((d, index) => (
              <Box sx={styles.numberText}>
                <img
                  className="icon"
                  src={
                    datastoreIcons[d.datastoreType]
                      ? datastoreIcons[d.datastoreType]
                      : datastoreIcons["generic"]
                  }
                />
                <Typography variant="body2">{d?.count}</Typography>
                <Typography variant="caption">{d?.datastoreType}</Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DataOverview;
