import { useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import Box from "../common/Box";
import PageBreadcrumbs from "../common/PageBreadcrumbs";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { fetchFindingsSlice } from "../../redux/slices/dashboardSlice";
import { Tab, TabPanel, Tabs } from "../common/Tabs";
import PageTable from "./PageTable";
import { useHistory } from "react-router-dom";

const AllFindingsPage = ({ setPath }) => {
  const [findings, setFindings] = useState({
    findings: {},
    documents_with_findings: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    pageHeader: {
      display: "flex",
      alignItems: "center",
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
    },
  };

  const breadcrumbs = [
    {
      isLink: true,
      route: "/",
      page: "Home",
    },
    {
      isLink: false,
      page: "All Findings",
    },
  ];

  const tabs = [
    {
      label: "Findings",
      value: "findings",
    },
    {
      label: "Documents With Findings",
      value: "docs-with-findings",
    },
  ];

  const findingTableCols = [
    {
      id: "finding-label",
      value: "Finding",
    },
    {
      id: "snippets",
      value: "Snippets",
      color: theme.palette.lightMagenta.main,
    },
  ];

  const docsTableCols = [
    {
      id: "docs-label",
      value: "Documents",
    },
    {
      id: "snippets",
      value: "Snippets",
      color: theme.palette.lightMagenta.main,
    },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabName = urlParams.get("tab");
    if (tabName) setActiveTab(tabName);
    else setActiveTab(tabs[0].value);
    setPath(window.location.pathname);
    fetchAllFindings();
  }, []);

  const findingsKeys = findings?.findings
    ? Object.keys(findings?.findings)
    : [];
  const docsKeys = findings?.documents_with_findings
    ? Object.keys(findings?.documents_with_findings)
    : [];

  const handleTabChange = (event: Event | SyntheticEvent, newValue: string) => {
    history.push(`${window.location.pathname}?tab=${newValue}`);
    setActiveTab(newValue);
  };

  const fetchAllFindings = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchFindingsSlice(payload));

    if (response?.payload) {
      setFindings(response.payload);
    }
    setIsLoading(false);
  };

  return (
    <Box sx={styles.page}>
      <PageBreadcrumbs breadcrumbs={breadcrumbs} />
      <Tabs value={activeTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tab {...tab} />
        ))}
      </Tabs>
      <TabPanel index="docs-with-findings" value={activeTab}>
        <PageTable
          pageTitle="List of Documents with Findings"
          tableKeys={docsKeys}
          tableData={findings.documents_with_findings}
          tableCols={docsTableCols}
          isLoading={isLoading}
        />
      </TabPanel>
      <TabPanel index="findings" value={activeTab}>
        <PageTable
          pageTitle="List of Findings"
          tableKeys={findingsKeys}
          tableData={findings.findings}
          tableCols={findingTableCols}
          isLoading={isLoading}
        />
      </TabPanel>
    </Box>
  );
};

export default AllFindingsPage;
