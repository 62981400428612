import { IconButton, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "../../common/Box";
import { Select } from "../../common/Select";
import Typography from "../../common/Typography";
import Search from "../../common/Search";
import Checkbox from "../../common/Checkbox";
import NoData from "../../common/NoData";
import { useDispatch } from "react-redux";
import { fetchUserGroupsSlice } from "../../../redux/slices/userSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { AppDispatch } from "../../../redux/store";

const identityProviders = [
  {
    label: "Azure Active Directory",
    value: "azure_ad",
    path: "LDAP://OU=SecurityGroups,OU=Departments,OU=Company,DC=corp,DC=company,DC=com",
    groups: [
      "us_support_group@acmecorp.com",
      "underwriter_group@acmecorp.com",
      "us_intern_group@acmecorp.com",
      "sales_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
  },
  { label: "Google", value: "google", path: "", groups: [] },
  { label: "Okta", value: "okta", path: "", groups: [] },
];

const SelectGroups = ({
  selectedGroups,
  setSelectedGroups,
  setIdentityProvider,
  identityProvider,
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [IDgroups, setIDGroups] = useState<any>([]);
  const [userGroups, setUserGroups] = useState<any>([]);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const styles = {
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    width60: {
      width: "60%",
    },
    groupsBox: {
      width: "100%",
      gap: theme.spacing(3),
    },
    gap_4: {
      gap: theme.spacing(4),
    },
    availableGroup: {
      border: `1px solid ${theme.palette.surface20.main}`,
      width: "50%",
      borderRadius: "4px",
    },
    listOfGroups: {
      display: "flex",
      flexDirection: "column",
      minHeight: "250px",
      maxHeight: "250px",
      overflowY: "auto",
      gap: theme.spacing(1.5),
      padding: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.surface20.main}`,
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "20px", width: "fit-content" },
    },
    groupName: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    truncatedText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cancelIcon: {
      width: "16px",
      color: theme.palette.primaryBlue.main,
    },
    iconButton: {
      width: "20px",
      padding: "0 !important",
      height: "16px",
    },
  };

  const checkIfChecked = (group) => {
    return selectedGroups.includes(group);
  };

  const fetchUserGroups = async () => {
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchUserGroupsSlice(payload));
    if (response?.payload) {
      setUserGroups(response?.payload);
      setIDGroups(response?.payload);
    }
  };

  useEffect(() => {
    if (searchKeyword) {
      const filteredGroups = userGroups?.filter((group) =>
        group.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setIDGroups(filteredGroups);
    } else {
      setIDGroups(userGroups);
    }
  }, [searchKeyword]);

  useEffect(() => {
    fetchUserGroups();
  }, []);

  return (
    <Box sx={{ ...styles.flexCol, ...styles.gap_4, padding: theme.spacing(2) }}>
      <Box sx={styles.flex}>
        <Select
          options={identityProviders}
          value={identityProvider}
          onChange={(event: any) =>
            setIdentityProvider(
              identityProviders?.find(
                (item) => item.value === event.target.value
              )?.value
            )
          }
          label="Identity Provider"
          minWidth={300}
        />
        <Box sx={{ ...styles.flexCol, ...styles.width60 }}>
          <Typography variant="caption" color={theme.palette.surface40.main}>
            Path
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.surface60.main}
            sx={styles.truncatedText}
          >
            {identityProviders?.find(
              (item) => item.value === identityProvider?.value
            )?.path || "-"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...styles.flexCol, ...styles.groupsBox }}>
        <Search
          placeholder="Search Groups"
          value={searchKeyword}
          onChange={(event) => setSearchKeyword(event.target.value)}
          onClear={() => setSearchKeyword("")}
          width={"49%"}
        />
        <Box sx={{ ...styles.flex, gap: theme.spacing(2) }}>
          <Box sx={styles.availableGroup}>
            <Box
              sx={{
                padding: theme.spacing(2),
              }}
            >
              <Typography variant="caption">
                Available Groups ({IDgroups?.length || 0})
              </Typography>
            </Box>
            <Box sx={styles.listOfGroups}>
              {IDgroups?.length > 0 ? (
                IDgroups?.map((group) => (
                  <Box sx={styles.groupName}>
                    <Checkbox
                      label={group}
                      checked={checkIfChecked(group)}
                      sx={styles.checkbox}
                      onClick={() => {
                        if (selectedGroups.includes(group)) {
                          setSelectedGroups(
                            selectedGroups.filter((item) => item !== group)
                          );
                        } else {
                          setSelectedGroups([...selectedGroups, group]);
                        }
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                      color={theme.palette.surface80.main}
                    >
                      {group}
                    </Typography>
                  </Box>
                ))
              ) : (
                <NoData customText="No Groups Found" />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.availableGroup,
              backgroundColor: "rgba(59, 62, 82, 0.5)",
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                width: "100%",
              }}
            >
              <Typography variant="caption">
                Selected Groups ({selectedGroups?.length})
              </Typography>
            </Box>
            <Box
              sx={{
                ...styles.listOfGroups,
                width: "100%",
                borderTop: `1px solid ${theme.palette.surface30.main}`,
              }}
            >
              {selectedGroups.map((group) => (
                <Box
                  sx={{
                    ...styles.groupName,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                    color={theme.palette.surface80.main}
                  >
                    {group}
                  </Typography>
                  <IconButton
                    sx={styles.iconButton}
                    onClick={() =>
                      setSelectedGroups(
                        selectedGroups?.filter((item) => item !== group)
                      )
                    }
                  >
                    <CloseRoundedIcon sx={styles.cancelIcon} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectGroups;
