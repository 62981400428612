export const CLOUD_ENV = "/vulnerabilities/cloud-env/";
export const DATASTORE_DETAILS = "/datastore-details/";
export const POLICY_SETTINGS = "/settings/policy-settings/";
export const POLICY_DETAILS = "/policy-details/";
export const DATASTORE = "/datastore";
export const DATA_LOADER_DETAILS = "/data-loader/";
export const APPLICATION_DETAILS = "/application/";
export const ALL_DATA_LOADERS_PAGE = "/data-loaders/";
export const ALL_APPLICATIONS_PAGE = "/applications/";
export const ALL_FINDINGS_PAGE = "/findings";
export const ALL_USERS_PAGE = "/users";
