import { axiosDenaliPrivate } from "../../utils/ApiHandler";

export const fetchPolicyViolations = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/policyviolations", params);
};

export const fetchDataSourceSnippets = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/docs/details", params);
};

export const suggestClassificationLabelForPrompt = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/entity/feedback", params);
};

export const suggestClassificationLabelForDoc = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/topic/feedback", params);
};
