import { useTheme } from "@mui/material";
import Box from "../../../common/Box";
import Checkbox from "../../../common/Checkbox";
import Divider from "../../../common/Divider";
import TextButton from "../../../common/TextButton";
import Typography from "../../../common/Typography";
import JiraIcon from "../../../../assets/img/jira.svg";
import SlackIcon from "../../../../assets/img/slack.svg";
import { useEffect, useMemo, useState } from "react";
import DataSourceDetailsItem from "./EntityDetails/DataSourceDetailsItem";
import DocumentDetailsItem from "./EntityDetails/DocumentDetailsItem";
import FindingsDetails from "./EntityDetails/FindingDetails";
import DetailsBlock from "./DetailsBlock";
import NoData from "../../../common/NoData";
import UserDetailsItem from "./EntityDetails/UsersDetailsItem";
import JiraSlackActionButtons from "../JiraSlackActionButtons";
import { getSumOfValues } from "../../../../utils/commonUtils";
import AuthorizedIdentities from "./EntityDetails/AuthorizedIdentities";

interface IProps {
  data: any;
  isDataLoader: boolean;
}

const SafeRetrievalDetails = ({ data = {}, isDataLoader }: IProps) => {
  const [selectedFirstColValue, setSelectedFirstColVal] = useState("");
  const [selectedSecondColValue, setSelectedSecondColValue] = useState("");
  const [authorizedIdentities, setAuthorizedIdentities] = useState([]);

  const theme = useTheme();
  const styles = {
    safeRetrievalCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      paddingBottom: theme.spacing(2),
      borderRadius: "4px",
      width: "100%",
    },
    cardActions: {
      display: "flex",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  };

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      const firstItem = Object.keys(data)[0];
      setSelectedFirstColVal(firstItem || "");
      const documents = Object.keys(data[firstItem]?.documents);
      if (firstItem && documents?.length > 0) {
        setSelectedSecondColValue(documents[0]);
      }
    }
  }, [data]);

  const secondTableList = useMemo(() => {
    if (selectedFirstColValue) {
      const loaderDetails = data[selectedFirstColValue];
      if (
        loaderDetails &&
        loaderDetails?.documents &&
        Object.keys(loaderDetails?.documents)?.length > 0
      ) {
        const result = Object.keys(loaderDetails.documents)
          ?.map((doc) => ({
            id: loaderDetails.documents[doc].id,
            name: doc,
            entity: loaderDetails.documents[doc].entities,
            topic: loaderDetails.documents[doc].topics,
            identities: loaderDetails.documents[doc].user_identities,
            findings:
              getSumOfValues(loaderDetails.documents[doc].entities) +
              getSumOfValues(loaderDetails.documents[doc].topics),
          }))
          .sort((a, b) => -a.findings + b.findings);

        setSelectedSecondColValue(result[0]?.name);
        setAuthorizedIdentities(result[0].identities);
        return result;
      }
      return [];
    }
    return [];
  }, [selectedFirstColValue]);

  const findingsList = useMemo(() => {
    if (selectedFirstColValue && selectedSecondColValue) {
      setAuthorizedIdentities(
        data[selectedFirstColValue].documents[selectedSecondColValue]
          ?.user_identities || []
      );
      const findings =
        data[selectedFirstColValue].documents[selectedSecondColValue];
      return findings;
    }
    return [];
  }, [selectedSecondColValue]);

  const overviewColumns = [
    {
      id: "loader",
      title: `${isDataLoader ? "Datasource" : "Users"} (${
        Object.keys(data)?.length
      })`,
      data: Object.keys(data),
      children: [
        Object.keys(data)?.length ? (
          Object.keys(data).map((dataItem) =>
            isDataLoader ? (
              <DataSourceDetailsItem
                isSelected={dataItem === selectedFirstColValue}
                name={dataItem}
                total={data[dataItem]?.total_documents}
                count={data[dataItem]?.document_with_findings}
                onClick={() => setSelectedFirstColVal(dataItem)}
                key={dataItem}
              />
            ) : (
              <UserDetailsItem
                isSelected={dataItem === selectedFirstColValue}
                name={dataItem}
                total={Object.keys(data[dataItem]?.documents)?.length || 0}
                count={data[dataItem]?.document_with_findings}
                onClick={() => setSelectedFirstColVal(dataItem)}
                key={dataItem}
              />
            )
          )
        ) : (
          <NoData customText="No Datasources Found" />
        ),
      ],
    },
    {
      id: "document",
      title: `All Documents (${secondTableList.length})`,
      data: secondTableList,
      children: [
        secondTableList?.length ? (
          secondTableList.map((item) => (
            <DocumentDetailsItem
              {...item}
              isSelected={item.name === selectedSecondColValue}
              onClick={() => setSelectedSecondColValue(item.name)}
              key={item.name}
            />
          ))
        ) : (
          <NoData customText="No Documents Found" />
        ),
      ],
    },
    {
      id: "findings",
      title: ["Findings", "Authorized Users"],
      children: [
        <FindingsDetails
          selectedFirstValue={data[selectedFirstColValue]}
          selectedSecondValue={secondTableList.find(
            (doc) => doc.name === selectedSecondColValue
          )}
          findings={findingsList}
          isDataLoader={isDataLoader}
        />,
        <AuthorizedIdentities identities={authorizedIdentities} />,
      ],
    },
  ];

  return (
    <Box sx={styles.safeRetrievalCard}>
      <JiraSlackActionButtons />
      <Box sx={styles.container}>
        {overviewColumns.map((column) => (
          <DetailsBlock
            key={column.id}
            title={column.title}
            content={column.children}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SafeRetrievalDetails;
