import "./style.scss";
import Box from "../../common/Box";
import { CopyBlock, dracula } from "react-code-blocks";
import { ReactComponent as ArrowDownIcon } from "../../../assets/img/arrow-up.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/img/arrow-down.svg";
import { useState } from "react";
import ReactTooltip from 'react-tooltip';
import { ThemeProvider } from 'styled-components';
import documentLoadingStep from "./documentLoadingSteps.json"

const ORDERED_LIST = "OL";

const Accordions = ({ data, title }) => {
  const [accordions, setAccordions] = useState(data);
  const [tooltip, setTooltip] = useState('');

  const handleToggle = (index) => {
    let newArr = [...accordions];
    newArr[index].open = !newArr[index].open;
    setAccordions(newArr);
  };

  const handleCopy = () => {
  console.log("Copied !!");
    setTooltip('Code copied!');
    setTimeout(() => {
      setTooltip('');
    }, 5000);
  };
  const customTheme ={
    styles:[
    {
        types: ["token"],
            style:{
                color:"rgb(80, 161, 79)"
            }
    }]
  }
  return (
    <div>
      <h5 className="accordion-title">{title}</h5>
      <div className="accordions">
        {accordions.map((accordion, index) => (
          <div className="accordion" key={index}>
            <h6 onClick={() => handleToggle(index)}>
              {accordion.title}
              <p className="float-right">
                {accordion.open ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </p>
            </h6>
            <div className={accordion.open ? "show" : "hide"}>
              <div className="content">{accordion.description}</div>
              {accordion?.answer &&
                (accordion?.answer?.listType == ORDERED_LIST ? (
                  <ol>
                    {accordion?.answer?.list?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ol>
                ) : (
                  <ul style={{ listStyle: "inside" }}>
                    {accordion?.answer?.list?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                ))}
                {documentLoadingStep?.steps?.map((item, index) => (
                    <div>
                        {item?.step ? (
                            <div style= {{margin: "10px"}}>{item.step}</div>
                        ) : null }
                        {item?.code ? (
                            <div>
                                <div data-tip={tooltip}>
                                    <Box className="helpCodeBlock">
                                        <ThemeProvider theme={dracula}>
                                            <CopyBlock
                                                language={"python"}
                                                text={item?.code || ""}
                                                showLineNumbers={false}
                                                onCopy={handleCopy}
                                                theme={customTheme}
                                                codeBlock
                                            />
                                        </ThemeProvider>
                                    </Box>
                                </div>
                                <ReactTooltip place="top" type="light" effect="solid" />
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordions;
