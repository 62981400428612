import { useTheme } from "@mui/material";
import { getShortenedString } from "../../../../../utils/commonUtils";
import Box from "../../../../common/Box";
import Checkbox from "../../../../common/Checkbox";
import Tooltip from "../../../../common/Tooltip";
import Typography from "../../../../common/Typography";

const DataSourceDetailsItem = ({
  isSelected = false,
  name = "",
  total = "",
  count = "",
  onClick,
}) => {
  const theme = useTheme();
  const styles = {
    parent: {
      cursor: "pointer",
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "top",
      padding: theme.spacing(2),
      backgroundColor: isSelected ? theme.palette.surface0.main : "",
      borderLeft: isSelected
        ? `1px solid ${theme.palette.primaryBlue.main}`
        : "",
      ":hover": {
        backgroundColor: theme.palette.surface0.main,
      },
    },
    font13: {
      fontSize: "13px",
    },
    userNameRole: {
      display: "flex",
      gap: theme.spacing(1),
    },
    checkbox: {
      height: "fit-content",
    },
    accessDetails: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    labelBox: {
      backgroundColor: theme.palette.blueGrey.main,
      paddingY: theme.spacing(0.2),
      paddingX: "6px",
      borderRadius: "2px",
    },
  };

  return (
    <Box sx={styles.parent} onClick={onClick}>
      {/*<Checkbox label="" sx={styles.checkbox} />*/}
      <Box sx={styles.flexCol}>
        <Box sx={styles.userNameRole}>
          <Tooltip title={name}>
            <div>
              <Typography
                color={theme.palette.surface80.main}
                sx={styles.font13}
              >
                {getShortenedString(name)}
              </Typography>
            </div>
          </Tooltip>
          {/*<Typography color={theme.palette.surface50.main} variant="caption">
              Sales Executive
            </Typography>*/}
        </Box>
        <Box sx={styles.accessDetails}>
          {/*<Typography
              color={theme.palette.surface60.main}
              variant="caption"
              sx={styles.accessDetails}
            >
              Accessed{" "}
              <Box sx={styles.labelBox}>
                <Typography color={theme.palette.critical.main} variant="caption">
                  5
                </Typography>
                <Typography
                  color={theme.palette.surface60.main}
                  variant="caption"
                >
                  /12
                </Typography>{" "}
                Labels
              </Box>{" "}
              | 6 Documents
            </Typography>*/}
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {count}/{total} Documents with Findings
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DataSourceDetailsItem;
