import MUIDialog from "@mui/material/Dialog";
import MUIDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Box from "./Box";
import { IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Dialog = (props) => {
  const theme = useTheme();
  const styles = {
    dialog: {
      ".MuiPaper-root": {
        background: theme.palette.surface10.main,
      },
    },
  };
  return <MUIDialog {...props} sx={{ ...styles.dialog, ...props.sx }} />;
};

export const DialogTitle = ({ handleClose, children }, props) => {
  const theme = useTheme();
  const styles = {
    dialogHeader: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      paddingX: theme.spacing(2),
      paddingY: theme.spacing(2),
    },
    dialogTitle: {
      color: theme.palette.surface80.main,
      fontSize: theme.spacing(2.25),
      padding: 0,
      textTransform: "none",
    },
    closeIcon: {
      fontSize: theme.spacing(2),
      width: theme.spacing(2),
    },
  };
  return (
    <Box sx={styles.dialogHeader}>
      <MUIDialogTitle
        children={children}
        {...props}
        sx={{ ...styles.dialogTitle, ...props.sx }}
      />
      <IconButton aria-label="close" onClick={handleClose}>
        <CloseIcon sx={styles.closeIcon} />
      </IconButton>
    </Box>
  );
};

export const DialogContent = (props) => {
  return <MuiDialogContent {...props} />;
};

export const DialogActions = (props) => {
  const theme = useTheme();
  const styles = {
    dialogAction: {
      padding: theme.spacing(2),
      ">:not(:first-of-type)": {
        marginLeft: theme.spacing(2),
      },
    },
  };
  return (
    <MuiDialogActions {...props} sx={{ ...styles.dialogAction, ...props.sx }} />
  );
};
