import BlockUi from "react-block-ui";
import CloudTable from "./table";
import Cards from "./cards";
import { LOADER_CONFIG, REACT_APP_ASSETS_API } from "../../../utils/Constants";
import { Loader } from "react-loaders";
import { useEffect, useState } from "react";
import NoDataContainer from "../../cards/no-data";
import {
  getCustomerId,
  getSelectedDatastoreAccount,
} from "../../../utils/SessionHelper";
import {
  listAssetsAsync,
  listAssetsSearchAsync,
} from "../../../redux/slices/assetsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";
import Box from "../../common/Box";
import { Pagination } from "../../common/Pagination";
import { CardWrapper } from "../../common/CardWrapper";
import Search from "../../common/Search";
import NoData from "../../common/NoData";

const Assets = ({ path, selectedAccount }) => {
  const defaultAccount = getSelectedDatastoreAccount() || {
    value: "all",
    label: "All",
  };
  const limit = 10;
  const page_ = sessionStorage.getItem("page");
  const dispatch = useDispatch();

  const [page, setPage] = useState(page_ ? parseInt(page_) : 1);
  const [inputText, setInputText] = useState("");
  const [severity, setSeverity] = useState(null);
  const [sortField, setSortField] = useState("cdAssetRiskScore");
  const [search, setSearch] = useState(null);
  const [sortType, setSortType] = useState("desc");
  const assets = useSelector((state) =>
    REACT_APP_ASSETS_API === "MONGODB"
      ? state.asset.assets
      : state.asset.assetsSearch
  );

  const styles = {
    cardWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 3,
    },
  };

  const loading = useSelector((state) => state.globalOperation.loading);

  const sortData = (field) => {
    if (field === sortField && sortType === "asc") {
      setSortType("desc");
    } else if (field === sortField && sortType === "desc") {
      setSortType("asc");
    } else {
      setSortType("desc");
    }

    setSortField(field);
    setPage(1);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedAccount]);

  const fetchData = async () => {
    dispatch(showLoading());
    await dispatch(
      REACT_APP_ASSETS_API === "MONGODB"
        ? listAssetsAsync({
            customerId: getCustomerId(),
            cloudAccountId:
              selectedAccount.value === "all" ? null : selectedAccount.value,
            ordering: sortField,
            search: search,
            page: page,
            limit: limit,
            exact: false,
          })
        : listAssetsSearchAsync({
            customerId: getCustomerId(),
            cloudAccountId:
              selectedAccount.value === "all" ? null : selectedAccount.value,
            q: search,
            page: page,
            limit: limit,
            exact: false,
            sortType: sortType,
            ordering: sortField,
            severity:
              !severity || severity.includes("TOTAL ASSETS")
                ? null
                : severity.replaceAll(" ", "_"),
          })
    );
    dispatch(hideLoading());
  };

  useEffect(() => {
    fetchData();
  }, [page, selectedAccount, sortField, severity, sortType]);

  const makeSearch = (ev) => {
    ev.preventDefault();
    fetchData();
  };

  const onClear = () => setSearch("");

  return (
    <div id="datastores">
      <Cards
        severity={severity}
        filterIssues={(type) => setSeverity(type)}
        selectedAccount={selectedAccount}
        inputText={inputText}
        setInputText={setInputText}
        assets={true}
      />
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <div id="datastores">
          <CardWrapper sx={styles.cardWrapper}>
            <div class="row border-">
              <div class="col col-xl-4 align-self-start">
                <Search
                  placeholder="Search.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && makeSearch(e)}
                  onClear={onClear}
                />
              </div>
            </div>

            {assets && assets.data && assets.data.length ? (
              <Box>
                <CloudTable
                  severity={severity}
                  currentPosts={assets.data}
                  sortData={sortData}
                  sortType={sortType}
                  sortField={sortField}
                />
                <Pagination
                  page={page}
                  rowsPerPageOptions={10}
                  disabled={assets && assets.total_pages <= 1}
                  count={assets ? assets.total_pages : 0}
                  siblingCount={0}
                  onChange={(e, value) => setPage(value)}
                />
              </Box>
            ) : (
              !loading && <NoData customText="No Assets Available." />
            )}
          </CardWrapper>
        </div>
      </BlockUi>
    </div>
  );
};

export default Assets;
