import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPIKeys, addAPIKey, deleteAPIKey } from "../apis/apiKeyAPI";

const initialState = {};

// Async calls.
export const fetchAllAPIKeysSlice = createAsyncThunk("apiKey/all", async () => {
  const response = await getAPIKeys();
  return response.data;
});

export const addNewAPIKeySlice = createAsyncThunk(
  "apiKey/add",
  async (params: any) => {
    const response = await addAPIKey(params);
    return response.data;
  }
);

export const deleteAPIKeySlice = createAsyncThunk(
  "apiKey/delete",
  async (params: string) => {
    const response = await deleteAPIKey(params);
    console.log({ response });
    return response.data;
  }
);

const apiKeySlice = createSlice({
  name: "apiKey",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: () => {},
});

export default apiKeySlice.reducer;
