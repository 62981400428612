import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useParams } from "react-router-dom";
import { LOADER_CONFIG } from "../../utils/Constants";
import "./ShadowLLMComponent.scss";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import loaderWhite from "../../assets/img/half-circle-white.svg";
import "reactjs-popup/dist/index.css";
import tagIcon from "../../assets/img/icons/tagImage.svg";
import Moment from "react-moment";
import CPU from "../../assets/img/v1-icons/cpu.svg";
import SharedCard from "../home/v1/sharedCard/SharedCard";
import RiskScore from "../../assets/img/llm/risk-score.svg";
import BiasScore from "../../assets/img/llm/bias-score.svg";
// import StarScore from "../../assets/img/llm/star.svg";
import { ReactComponent as StarScore } from "../../assets/img/llm/star.svg";
import CreateTicket from "../../assets/img/v1-icons/create-ticket.svg";
import ViewTicket from "../../assets/img/v1-icons/view-ticket.svg";
import { getCustomerId } from "../../utils/SessionHelper";
import { axiosDenaliPrivate } from "../../utils/ApiHandler";
import { getPolicyStatsAsync } from "../../redux/slices/policySlice";
import ManageTagsModal from "../../components/modal/manage-tags-modal/ManageTagsModal";
import SensitiveDataModal from "../../components/modal/sensitive-data/SensitiveDataModal";
import { trainingDataList } from "./training_data";
import ViolationIcon from "../../assets/img/icons/compliance-violation.svg";
import {
  listDataStoreTagsAsync,
  updateDataStoreAsync,
} from "../../redux/slices/dataStoreSlice";
import EditIcon from "../../assets/img/edit.svg";
import DatastoreTicketModal from "../../components/modal/datastore-ticket-modal/DatastoreTicketModal";
import HuggingFace from "../../assets/img/hugging.png";
import IssueInstanceModal from "../modal/issue-instance-modal/IssueInstanceModal";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
import { fetchDatastoreDescriptionAsync } from "../../redux/slices/dataStoreSlice";
import TrainingDataGraphView from "../charts/llm-charts/training-data-graph/TrainingDataGraphView";
import TrainingDataGraphLineageView from "../charts/llm-charts/training-data-linear-graph/TrainingDataGraphLineageView";
import TranningDataOrchestratorGraph from "../charts/llm-charts/training-data-orchestrator/TrainingDataOrchestratorGraph";

const ShadowLLMComponent = ({ selectedAccount }) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketModalInfo, setTicketModalInfo] = useState(false);
  const [isIssueInstanceModal, setIsIssueInstanceModal] = useState();
  const history = useHistory();
  const [trainingData, setTrainingData] = useState([]);
  const datastoreId = params.id?.replaceAll("_", "/");
  const assetId = params.assetId?.replaceAll("_", "/");
  const policyViolationId = params?.policyViolationId;
  const [tags, setTags] = useState([]);
  const [datastoreDescriptions, setDatastoreDescriptions] = useState([]);
  const [launchButtonTitle, setLaunchButtonTitle] = useState("Launch AIScan");
  const [trainingDataGraphView, setTrainingDataGraphView] = useState(false);
  const [graphLineageView, setGraphLineageView] = useState(false);
  const [moreTags, setMoreTags] = useState([]);
  const [isManageTagsModal, setIsManageTagsModal] = useState(false);
  const [isSensitiveDataModal, setIsSensitiveDataModal] = useState(false);
  const [datastore, setDatastore] = useState({});
  const [datastorePolicy, setDatastorePolicy] = useState();
  const [datastoreType, setDatastoreType] = useState(null);
  const [trainingDataFilter, setTrainningDataFilter] = useState("all");
  const [width, setWidth] = useState(0);
  const ref = useRef();
  const [
    policyViolationsMaliciousActivity,
    setpolicyViolationsMaliciousActivity,
  ] = useState(false);
  const [
    policyViolationsSensitiveData,
    setPolicyViolationsSensitiveData,
  ] = useState(false);

  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    // return () => {
    //   removeSelectedDataFlow();
    // };
  }, []);

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth);
  };
  const getDatastore = async () => {
    setLoading(true);
    let url = `/datastore/instance/detail`;
    const payload = {
      id: datastoreId,
      assetId: assetId,
      customerId: getCustomerId(),
    };
    let res;
    res = await axiosDenaliPrivate.post(url, payload);

    if (res && res.data) {
      setDatastore(res.data);
      setDatastoreType(res.data.datastoreType);

      setTrainingData([
        ...res?.data?.llmInfo?.TrainingData,
        ...trainingDataList,
      ]);
      // setTrainingData(trainingDataList);
      // setTrainingData(res?.data?.llmInfo?.TrainingData);
    } else {
      setDatastore([]);
    }
    setLoading(false);
  };

  const getSensitiveData = async () => {
    const payload = {
      id: datastoreId,
      customerId: getCustomerId(),
    };
    let resp = await dispatch(fetchDatastoreDescriptionAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setDatastoreDescriptions(
        resp?.payload?.filter((element) => element?.Automated !== "Manual")
      );
    }
  };

  const updateDataStore = async (type_) => {
    if (!type_) return;

    const resp = await dispatch(
      updateDataStoreAsync({
        datastoreType: type_,
        customerId: getCustomerId(),
        datastoreId: datastoreId,
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, "Datastore Type updated successfully.");
      getDatastore();
    } else {
      Toaster(TOASTER_TYPES.SUCCESS, "Datastore Type updated successfully.");
      getDatastore();
      // Toaster(
      //   TOASTER_TYPES.ERROR,
      //   resp.payload?.err?.message || "Failed to add account."
      // );
      // handleModal(false);
    }
  };

  const getPolicyStats = async () => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      datastoreId: datastoreId,
      policyViolationId: policyViolationId ? policyViolationId : null,
    };
    let resp = await dispatch(getPolicyStatsAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setDatastorePolicy(resp.payload);
    } else {
      setDatastorePolicy([]);
    }
  };
  const refreshPolicies = () => {
    getPolicyStats();
  };

  const refreshDataStoreTags = async () => {
    const res = await dispatch(
      listDataStoreTagsAsync({
        customerId: getCustomerId(),
        cloudAccountId: datastore?.cloudAccountId || "",
        datastoreId: datastoreId,
      })
    );
    let tagList = [];
    if (res && res.payload && !res.payload.err) {
      // Adding sensitive tag
      if (res.payload?.customerSensTag) {
        tagList.push({
          value: res.payload.customerSensTag,
          label: res.payload.customerSensTag,
          type: "sensitive",
        });
      }
      if (res.payload?.customerTags) {
        // Adding custom tag
        res.payload.customerTags.map((tag) => {
          if (
            tag.toLowerCase() === "us_access_only" ||
            tag.includes("us access only") ||
            tag.includes("us-access-only")
          ) {
            // props.setIsUSOnlyAccess(true);
          }

          tagList.push({
            value: tag,
            label: tag,
            type: "custom",
          });
        });
      }
    }
    setTags(tagList);
  };

  useEffect(() => {
    getSensitiveData();
  }, []);

  useEffect(() => {
    if (datastore && datastore.cloudAccountId) {
      refreshDataStoreTags();
    }
  }, [datastore]);

  useEffect(() => {
    getPolicyStats();
    getDatastore();
    getSensitiveData();
  }, []);

  const viewPolicy = (policy) => {
    history.push("/datastore/policy-violation/" + policy.policyViolationId);
    sessionStorage.setItem(
      "policy",
      JSON.stringify({
        name: policy.policyViolationName,
        id: policy.policyViolationId,
        description: policy.description ? policy.description : null,
        remediation: policy.remediation ? policy.remediation : null,
      })
    );
  };

  const editPolicy = (id) => {
    history.push("/datastore/policy-violation/" + id);
  };

  const launchAIScan = () => {
    setLaunchButtonTitle("Loading Model");

    setTimeout(() => {
      setLaunchButtonTitle("Running AIScan");

      setTimeout(() => {
        setLaunchButtonTitle("Launch AIScan");
        let launchObj = {
          FileName: "",
          source: "AIScan",
        };
        launchObj["sensitive labels"] = "PII, PHI, PCI";
        if (trainingData.length > 0) {
          setTrainingData([...trainingData, launchObj]);
        } else {
          setTrainingData([launchObj]);
        }
      }, "60000");
    }, "60000");
  };

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <div className="llm-container">
        <SharedCard>
          <div className="row mb-2">
            <div class="col-lg-6 col-md-6">
              {datastore &&
                datastore.llmInfo &&
                datastore.llmInfo.llmInformation && (
                  <div className="left-container">
                    <h6 className="title">
                      LLM Information
                      <a
                        target="_blank"
                        href={"https://huggingface.co/"}
                        title="huggingface.co"
                      >
                        <img src={HuggingFace} />
                      </a>
                    </h6>
                    <div className="">
                      <div className="row ">
                        <div className="col-md-12 ">
                          <table>
                            <tbody>
                              <tr>
                                <td className="key">Name:</td>
                                <td className="value pl-4">
                                  {" "}
                                  {datastore.llmInfo.llmInformation.Name}
                                </td>
                              </tr>
                              <tr>
                                <td className="key">Base Family:</td>
                                <td className="value pl-4">
                                  {" "}
                                  {
                                    datastore.llmInfo.llmInformation[
                                      "Base Family"
                                    ]
                                  }
                                </td>
                              </tr>{" "}
                              <tr>
                                <td className="key">Framework:</td>
                                <td className="value pl-4">
                                  {" "}
                                  {
                                    datastore.llmInfo.llmInformation[
                                      "Framework"
                                    ]
                                  }
                                </td>
                              </tr>{" "}
                              <tr>
                                <td className="key">Owner:</td>
                                <td className="value pl-4">
                                  {" "}
                                  {datastore.llmInfo.llmInformation["Owner"]}
                                </td>
                              </tr>{" "}
                              <tr>
                                <td className="key">Tags:</td>
                                <td className="value pl-4">
                                  {" "}
                                  <div class="row ">
                                    {tags.length === 0 ? (
                                      <div class="col-md-auto pl-3 p-0">
                                        {" "}
                                        N/A{" "}
                                      </div>
                                    ) : (
                                      tags.map((tag, index) => (
                                        <div key={index}>
                                          {index < 3 ? (
                                            <div class="col-md-auto pl-3 p-0">
                                              <span className="subvalue-bold data-store-tag">
                                                <img
                                                  className="mr-1"
                                                  src={tagIcon}
                                                ></img>
                                                <span
                                                  title={
                                                    tag?.label.length > 12
                                                      ? tag?.label
                                                      : ""
                                                  }
                                                >
                                                  {tag?.label.length > 12
                                                    ? tag?.label.slice(0, 12) +
                                                      "..."
                                                    : tag.label || ""}
                                                </span>
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {/* Adding more tags link */}
                                          {index == 3 && (
                                            <Popup
                                              trigger={(open) => (
                                                <div class="col-md-auto pl-3 p-0">
                                                  <span className="subvalue-bold more-tags">
                                                    <b>
                                                      <u>more...</u>
                                                    </b>
                                                  </span>
                                                </div>
                                              )}
                                              position="right top"
                                              closeOnDocumentClick
                                              className="top-panel-tags"
                                            >
                                              <div className="header">Tags</div>
                                              <div class="row">
                                                {moreTags.map((tag, index) => (
                                                  <div
                                                    key={index}
                                                    class="col-md-auto"
                                                  >
                                                    <div className="subvalue-bold data-store-tag m-2">
                                                      <img
                                                        className="mr-2"
                                                        src={tagIcon}
                                                      ></img>
                                                      <span
                                                        className="subvalue-bold"
                                                        title={tag.label}
                                                      >
                                                        {tag?.label.length > 25
                                                          ? tag?.label.slice(
                                                              0,
                                                              25
                                                            ) + "..."
                                                          : tag.label || ""}
                                                      </span>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </Popup>
                                          )}
                                        </div>
                                      ))
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div className="row ">
                        <div className="col-sm-3 key ">Name:</div>
                        <div className="col-sm-9 value ">
                          {datastore.llmInfo.llmInformation.Name}
                        </div>
                      </div>
                      <div className="row pl-3">
                        <div className="col-sm-3 key">Base Family:</div>
                        <div className="col-sm-9 value">
                          {datastore.llmInfo.llmInformation["Base Family"]}
                        </div>
                      </div>
                      <div className="row pl-3">
                        <div className="col-sm-3 key">Framework:</div>
                        <div className="col-sm-9 value">
                          {datastore.llmInfo.llmInformation["Framework"]}
                        </div>
                      </div>
                      <div className="row pl-3">
                        <div className="col-sm-3 key">Owner:</div>
                        <div className="col-sm-9 value">
                          {datastore.llmInfo.llmInformation["Owner"]}
                        </div>
                      </div> */}
                      {/* <div className="row pl-3">
                        <div className="col col-sm-3 key workday-subheading">
                          Tags:
                        </div>
                        <div className="col col-sm-8 d-flex value workday-subheading-value">
                          <div class="row ">
                            {tags.length === 0 ? (
                              <div class="col-md-auto pl-3 p-0"> N/A </div>
                            ) : (
                              tags.map((tag, index) => (
                                <div key={index}>
                                  {index < 3 ? (
                                    <div class="col-md-auto pl-3 p-0">
                                      <span className="subvalue-bold data-store-tag">
                                        <img
                                          className="mr-1"
                                          src={tagIcon}
                                        ></img>
                                        <span
                                          title={
                                            tag?.label.length > 12
                                              ? tag?.label
                                              : ""
                                          }
                                        >
                                          {tag?.label.length > 12
                                            ? tag?.label.slice(0, 12) + "..."
                                            : tag.label || ""}
                                        </span>
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {index == 3 && (
                                    <Popup
                                      trigger={(open) => (
                                        <div class="col-md-auto pl-3 p-0">
                                          <span className="subvalue-bold more-tags">
                                            <b>
                                              <u>more...</u>
                                            </b>
                                          </span>
                                        </div>
                                      )}
                                      position="right top"
                                      closeOnDocumentClick
                                      className="top-panel-tags"
                                    >
                                      <div className="header">Tags</div>
                                      <div class="row">
                                        {moreTags.map((tag, index) => (
                                          <div key={index} class="col-md-auto">
                                            <div className="subvalue-bold data-store-tag m-2">
                                              <img
                                                className="mr-2"
                                                src={tagIcon}
                                              ></img>
                                              <span
                                                className="subvalue-bold"
                                                title={tag.label}
                                              >
                                                {tag?.label.length > 25
                                                  ? tag?.label.slice(0, 25) +
                                                    "..."
                                                  : tag.label || ""}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </Popup>
                                  )}
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                )}
            </div>
            <div class="col-lg-6 col-md-6">
              <div className="right-container">
                {datastore &&
                  datastore.llmInfo &&
                  datastore.llmInfo.TrainingDataSets && (
                    <div>
                      <div className="row">
                        <div className="col-sm-6 p-0">
                          <img src={BiasScore} />
                          <span className="score-title">Bias Score</span>
                          <h5 className="score-value">
                            {datastore.llmInfo.TrainingDataSets["Bias Score"]}
                          </h5>
                        </div>
                        <div className="col-sm-6 p-0">
                          <img src={RiskScore} />

                          <span className="score-title">Toxicity Score</span>
                          <h5 className="score-value">
                            {datastore.llmInfo.TrainingDataSets["Risk Score"]}
                          </h5>
                        </div>
                        <div className="col-lg-12 col-md-12 p-0">
                          {" "}
                          <table>
                            <tbody>
                              <tr>
                                <td className="info">
                                  <span className="model-hash">
                                    Model Hash:
                                  </span>
                                </td>
                                <td>
                                  <span className="hash">
                                    {
                                      datastore.llmInfo.TrainingDataSets[
                                        "Model Hash"
                                      ]
                                    }
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className="info">
                                  {" "}
                                  <span className="model-hash">
                                    Training Data Sets:
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span className="hash">
                                    {datastore?.llmInfo?.TrainingData?.length}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <h6 className="info">
                          <span className="model-hash">Model Hash:</span>
                          <span className="hash">
                            {datastore.llmInfo.TrainingDataSets["Model Hash"]}
                          </span>
                        </h6> */}
                      </div>
                      {/* <h6 className="mt-1 info">
                        <span className="model-hash">Training Data Sets:</span>
                        <span className="hash">
                          {datastore?.llmInfo?.TrainingData?.length}
                        </span>
                      </h6> */}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="row  action-buttons">
            <div className="col col-sm-12 workday-subheading">
              <button
                type="button"
                onClick={() => setIsManageTagsModal(true)}
                className="btn float-left mr-3 btn-primary btn-sm my-1"
                disabled={!datastore?.cloudAccountId}
              >
                Manage Tags
              </button>
              <button
                type="button"
                onClick={() => setIsSensitiveDataModal(true)}
                className="btn   btn-primary btn-sm my-1"
                disabled={
                  datastoreDescriptions && datastoreDescriptions.length === 0
                }
              >
                Sensitive Data
              </button>

              {datastoreType !== "managed" ? (
                <button
                  type="button"
                  onClick={() =>
                    updateDataStore(
                      datastoreType === "shadow"
                        ? "self-managed"
                        : datastoreType === "self-managed"
                        ? "shadow"
                        : null
                    )
                  }
                  className="btn   btn-primary btn-sm my-1 ml-3"
                >
                  {datastoreType === "shadow" ? "Sanctioned" : "Shadow"}
                </button>
              ) : (
                ""
              )}
            </div>
            {isManageTagsModal ? (
              <ManageTagsModal
                tags={tags}
                refreshDataStoreTags={refreshDataStoreTags}
                handleModal={() => setIsManageTagsModal(false)}
                datastoreId={datastoreId}
                cloudAccountId={datastore?.cloudAccountId}
              />
            ) : (
              ""
            )}
            {isSensitiveDataModal && (
              <SensitiveDataModal
                handleModal={() => setIsSensitiveDataModal(false)}
                datastoreDescriptions={datastoreDescriptions}
                sensitiveScore={datastore.sensitiveScore}
              />
            )}
          </div>
        </SharedCard>
        <SharedCard>
          <div className="row mb-2">
            <div class="col-lg-6 col-md-6">
              {datastore &&
                datastore.llmInfo &&
                datastore.llmInfo.InstallInformation && (
                  <div className="left-container">
                    <h6 className="title">Instance information</h6>
                    <div className="row">
                      <div className="col pr-4">
                        <table className="instance-table">
                          <tbody>
                            <tr>
                              <td className="key">Account: </td>
                              <td className="value">
                                {datastore.llmInfo.InstallInformation.Account}
                              </td>
                            </tr>
                            {datastore?.host && datastore?.host !== null && (
                              <tr>
                                <td className="key">Host:</td>
                                <td className="value">{datastore.host}</td>
                              </tr>
                            )}
                            {datastore?.llmInfo?.InstallInformation[
                              "Host Path"
                            ] &&
                              datastore?.llmInfo?.InstallInformation[
                                "Host Path"
                              ] !== null && (
                                <tr>
                                  <td className="key">Host Path:</td>
                                  <td className="value">
                                    {
                                      datastore.llmInfo.InstallInformation[
                                        "Host Path"
                                      ]
                                    }
                                  </td>
                                </tr>
                              )}

                            {datastore?.llmInfo?.InstallInformation[
                              "Runtime"
                            ] &&
                              datastore?.llmInfo?.InstallInformation[
                                "Runtime"
                              ] !== null && (
                                <tr>
                                  <td className="key">Runtime:</td>
                                  <td className="value">
                                    {
                                      datastore.llmInfo.InstallInformation[
                                        "Runtime"
                                      ]
                                    }
                                  </td>
                                </tr>
                              )}

                            {datastore?.llmInfo?.InstallInformation[
                              "SagemakerDomain"
                            ] &&
                              datastore?.llmInfo?.InstallInformation[
                                "SagemakerDomain"
                              ] !== null && (
                                <tr>
                                  <td className="key">Domain:</td>
                                  <td className="value">
                                    {
                                      datastore.llmInfo.InstallInformation[
                                        "SagemakerDomain"
                                      ]
                                    }
                                  </td>
                                </tr>
                              )}

                            <tr>
                              <td className="key">Size:</td>
                              <td className="value">
                                {datastore.llmInfo.InstallInformation["Size"]}
                              </td>
                            </tr>
                            <tr>
                              <td className="key">Last Used:</td>
                              <td className="value">
                                {" "}
                                {
                                  datastore.llmInfo.InstallInformation[
                                    "Last Used"
                                  ]
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-3 key">Account:</div>
                      <div className="col-sm-9 value">
                        {datastore.llmInfo.InstallInformation.Account}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 key">Host:</div>
                      <div className="col-sm-9 value">{datastore.host}</div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 key">Host Path:</div>
                      <div className="col-sm-9 value">
                        {datastore.llmInfo.InstallInformation["Host Path"]}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 key">Size:</div>
                      <div className="col-sm-9 value">
                        {datastore.llmInfo.InstallInformation["Size"]}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 key">Last Used:</div>
                      <div className="col-sm-9 value">
                        {datastore.llmInfo.InstallInformation["Last Used"]}
                      </div>
                    </div> */}
                  </div>
                )}
            </div>
            <div class="col-lg-6 col-md-6 ">
              <div className="right-container">
                {datastore &&
                  datastore?.llmInfo &&
                  datastore?.llmInfo?.PostTrained && (
                    <div className="row">
                      <div className="col-sm-12 p-0">
                        {datastore?.llmInfo?.PostTrained?.is_different_hash ? (
                          <StarScore
                            className={`active-star-svg`}
                            fill="#bb86fc"
                            width="40"
                          />
                        ) : (
                          <StarScore className={`active-star-svg`} />
                        )}
                        <span className="score-title">
                          Post - Trained after downloading
                        </span>
                      </div>
                      <table className="mt-1">
                        <tbody>
                          <tr className="info">
                            <td className="model-hash">Model Hash:</td>
                            <td className="hash">
                              {
                                datastore.llmInfo.PostTrained[
                                  "Installed Model Hash"
                                ]
                              }
                            </td>
                          </tr>
                          {datastore?.llmInfo?.PostTrained?.duplicate_volume &&
                            datastore?.llmInfo?.PostTrained?.duplicate_volume
                              ?.length > 0 && (
                              <tr className="info">
                                <td className="model-hash">Clone:</td>
                                <td className="hash">
                                  <div
                                    className={"card-circle disable-button"}
                                    onClick={() =>
                                      setIsIssueInstanceModal(true)
                                    }
                                  >
                                    <img
                                      src={CPU}
                                      className="mb-1 pointer"
                                      title="Other Instances of same Issue"
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>

                      {/* <h6 className="info">
                        <span className="model-hash">Model Hash:</span>
                        <span className="hash">
                          {
                            datastore.llmInfo.PostTrained[
                              "Installed Model Hash"
                            ]
                          }
                        </span>
                      </h6> */}

                      {/* <br />
                      <br />

                      {datastore.llmInfo.PostTrained.duplicate_volume &&
                      datastore.llmInfo.PostTrained.duplicate_volume.length ? (
                        <div className="col-sm-6">
                          <span>Clone: </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {datastore.llmInfo.PostTrained.duplicate_volume &&
                      datastore.llmInfo.PostTrained.duplicate_volume.length ? (
                        <div className="col-sm-6">
                          <div
                            className={"card-circle disable-button"}
                            onClick={() => setIsIssueInstanceModal(true)}
                          >
                            <img
                              src={EC2Image}
                              className="mb-1 pointer"
                              title="Other Instances of same Issue"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </SharedCard>

        <SharedCard>
          <div className="row mb-2">
            <div class="col-lg-12 col-md-12">
              <div className="left-container" ref={ref}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <h6 className="title mt-1">Training Data</h6>
                    {(datastore?.llmInfo?.llmInformation?.Name ===
                      "falcon-7b-bf16" ||
                      datastore?.llmInfo?.llmInformation?.Name === "gpt2") && (
                      <span>
                        <span className="ml-4">
                          <span className="mr-2">Table View</span>
                          <Toggle
                            defaultChecked={trainingDataGraphView}
                            onChange={() =>
                              setTrainingDataGraphView(!trainingDataGraphView)
                            }
                            className="risk-accept-toggle mr-2"
                            icons={false}
                            id="risk-accept-toggle"
                          />
                          <span className="mr-2">Graph View</span>
                        </span>
                        {trainingDataGraphView &&
                          datastore?.llmInfo?.llmInformation?.Name ===
                            "falcon-7b-bf16" && (
                            <span className="ml-4">
                              <span className="mr-2">Lineage view</span>
                              <Toggle
                                defaultChecked={graphLineageView}
                                onChange={() =>
                                  setGraphLineageView(!graphLineageView)
                                }
                                className="risk-accept-toggle mr-2"
                                icons={false}
                                id="risk-accept-toggle"
                              />
                            </span>
                          )}
                      </span>
                    )}
                  </div>
                  <div className="d-flex">
                    <button
                      type="button"
                      onClick={() => {
                        launchButtonTitle === "Launch AIScan" &&
                          launchAIScan(true);
                      }}
                      className={
                        launchButtonTitle === "Launch AIScan"
                          ? "btn float-left mr-3 btn-primary btn-sm my-1 "
                          : "btn float-left mr-3 btn-primary btn-disable btn-sm my-1 cursor-default"
                      }
                      disabled={!launchButtonTitle === "Launch AIScan"}
                    >
                      <span className="mr-2">{launchButtonTitle}</span>
                      {launchButtonTitle !== "Launch AIScan" && (
                        <img src={loaderWhite} className="launch-loader" />
                      )}
                    </button>
                  </div>
                </div>
                {trainingDataGraphView &&
                  !graphLineageView &&
                  datastore?.llmInfo?.llmInformation?.Name ===
                    "falcon-7b-bf16" && (
                    <ul
                      className="nav nav-pills mb-1 d-flex"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item nav-item-all">
                        <a
                          className={`nav-link px-3 ${
                            trainingDataFilter === "all" ? "active" : ""
                          }`}
                          id="all"
                          data-toggle="pill"
                          href="#filter-types"
                          role="tab"
                          aria-selected="false"
                          onClick={() => {
                            setTrainningDataFilter("all");
                          }}
                        >
                          All
                        </a>
                      </li>
                      <li className="nav-item nav-filters">
                        <a
                          className={`nav-link   px-3 ${
                            trainingDataFilter === "Configured" ? "active" : ""
                          }`}
                          id="Configured"
                          data-toggle="pill"
                          href="#filter-types-tab"
                          role="tab"
                          aria-controls="filter-types"
                          aria-selected="false"
                          onClick={() => {
                            setTrainningDataFilter("Configured");
                          }}
                        >
                          <span className="filter-color filter-color-configured">
                            {" "}
                          </span>{" "}
                          Configured
                        </a>
                      </li>
                      <li
                        className={
                          trainingDataGraphView
                            ? "nav-item nav-filters nav-filter-disable "
                            : "nav-item nav-filters"
                        }
                      >
                        <a
                          className={`nav-link disabled  px-3 ${
                            trainingDataGraphView && "cursor-default"
                          } ${trainingDataFilter === "Live Access" ? "" : ""}`}
                          id="Live Access"
                          data-toggle="pill"
                          href="#filter-types-tab"
                          role="tab"
                          aria-controls="filter-types"
                          aria-selected="false"
                          onClick={() => {
                            if (!trainingDataGraphView) {
                              setTrainningDataFilter("Live Access");
                            }
                          }}
                        >
                          <span className="filter-color filter-color-live-access">
                            {" "}
                          </span>{" "}
                          LiveAccess
                        </a>
                      </li>
                      <li className="nav-item nav-filters">
                        <a
                          className={`nav-link px-3 ${
                            trainingDataFilter === "Access Path" ? "active" : ""
                          }`}
                          id="Access Path"
                          data-toggle="pill"
                          href="#filter-types-tab"
                          role="tab"
                          aria-controls="filter-types"
                          aria-selected="false"
                          onClick={() => {
                            setTrainningDataFilter("Access Path");
                          }}
                        >
                          {" "}
                          <span className="filter-color filter-color-access-path">
                            {" "}
                          </span>
                          AccessPath
                        </a>
                      </li>
                    </ul>
                  )}

                {!trainingDataGraphView ? (
                  <div className="mt-2 table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th>Location</th>
                          <th>Size</th>
                          <th>Type</th>
                          <th>Source</th>
                          <th>Sensitive Labels</th>
                        </tr>
                      </thead>
                      {trainingData && (
                        <tbody>
                          {trainingData?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.FileName}</td>
                              <td>{item.Location}</td>
                              <td>{item.size}</td>
                              <td>{item.type}</td>
                              <td>{item?.source || "Configured"}</td>
                              <td>
                                {item?.["sensitive labels"] ||
                                  item?.sensitive_labels}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                ) : trainingDataGraphView && graphLineageView ? (
                  <div className="w-100">
                    <TrainingDataGraphLineageView
                      trainingData={trainingDataList?.filter(
                        (data) => data.Source === "Configured"
                      )}
                      llmInfo={datastore?.llmInfo?.llmInformation}
                      trainingDataFilter={trainingDataFilter}
                      datastoreId={datastoreId}
                      width={width}
                    />{" "}
                  </div>
                ) : (
                  <div className="w-100">
                    {datastore?.llmInfo?.llmInformation?.Name ===
                    "falcon-7b-bf16" ? (
                      <TrainingDataGraphView
                        policyViolationsMaliciousActivity={
                          policyViolationsMaliciousActivity
                        }
                        policyViolationsSensitiveData={
                          policyViolationsSensitiveData
                        }
                        trainingData={trainingDataList}
                        llmInfo={datastore?.llmInfo?.llmInformation}
                        trainingDataFilter={trainingDataFilter}
                        datastoreId={datastoreId}
                        width={width}
                      />
                    ) : (
                      <TranningDataOrchestratorGraph
                        trainingData={trainingDataList?.filter(
                          (data) => data.Source === "Configured"
                        )}
                        llmInfo={datastore?.llmInfo?.llmInformation}
                        trainingDataFilter={trainingDataFilter}
                        datastoreId={datastoreId}
                        width={width}
                      />
                    )}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </SharedCard>

        <SharedCard>
          <div className="row mb-2">
            <div class="col-lg-12 col-md-12">
              <div className="right-container">
                <h6 className="title">Policy Violations</h6>

                <div className="mt-4 table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Policy</th>
                        <th>Last Violated</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datastorePolicy &&
                        datastorePolicy?.policyViolations?.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    viewPolicy(item);
                                  }}
                                >
                                  {item.policyViolationName}
                                </span>{" "}
                                {index === 1 &&
                                  graphLineageView === false &&
                                  trainingDataGraphView === true && (
                                    <img
                                      alt="icon"
                                      className={
                                        policyViolationsMaliciousActivity
                                          ? "ml-2 cursor-pointer policy-violation-selected"
                                          : "ml-2 cursor-pointer"
                                      }
                                      src={ViolationIcon}
                                      onClick={() => {
                                        setpolicyViolationsMaliciousActivity(
                                          !policyViolationsMaliciousActivity
                                        );
                                        setPolicyViolationsSensitiveData(false);
                                      }}
                                    />
                                  )}
                                {index === 3 &&
                                  graphLineageView === false &&
                                  trainingDataGraphView === true && (
                                    <img
                                      alt="icon"
                                      className={
                                        policyViolationsSensitiveData
                                          ? "ml-2 cursor-pointer policy-violation-selected"
                                          : "ml-2 cursor-pointer"
                                      }
                                      src={ViolationIcon}
                                      onClick={() => {
                                        setPolicyViolationsSensitiveData(
                                          !policyViolationsSensitiveData
                                        );
                                        setpolicyViolationsMaliciousActivity(
                                          false
                                        );
                                      }}
                                    />
                                  )}
                              </td>
                              <td>
                                <Moment format="YYYY-MM-DD HH:MM:SS">
                                  {item.lastViolatedAt}
                                </Moment>
                              </td>
                              <td>
                                <img
                                  onClick={() => {
                                    editPolicy(item.policyViolationId);
                                  }}
                                  title="Edit Policy"
                                  alt="icon"
                                  className="ml-1 cursor-pointer"
                                  src={EditIcon}
                                />
                                {item && item.tickets > 0 && (
                                  <img
                                    alt="icon"
                                    title="Ticket Details"
                                    src={ViewTicket}
                                    className="ticket-icon ml-1 ml-3  cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(item?.url ? item?.url : "#");
                                    }}
                                  />
                                )}
                                {item && item.tickets === 0 && (
                                  <img
                                    alt="icon"
                                    title="Create Ticket"
                                    src={CreateTicket}
                                    className={
                                      datastorePolicy.ticketIntegration
                                        ? "ticket-icon ml-3  cursor-pointer"
                                        : "ticket-icon-disable ml-3 "
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (datastorePolicy.ticketIntegration) {
                                        setTicketModal(true);
                                        setTicketModalInfo({
                                          policyViolationId:
                                            item.policyViolationId,
                                          datastoreId: item.datastoreId,
                                        });
                                      }
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          )
                        )}{" "}
                      {ticketModal && (
                        <DatastoreTicketModal
                          type="policy"
                          refreshDatastores={refreshPolicies}
                          datastoreId={ticketModalInfo.datastoreId}
                          policyViolationId={ticketModalInfo.policyViolationId}
                          handleModal={() => {
                            setTicketModal(false);
                          }}
                        />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </SharedCard>

        {isIssueInstanceModal ? (
          <IssueInstanceModal
            instances={datastore.llmInfo.PostTrained.duplicate_volume}
            handleModal={() => setIsIssueInstanceModal(false)}
          />
        ) : (
          ""
        )}
      </div>
    </BlockUi>
  );
};

export default ShadowLLMComponent;
