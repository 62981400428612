import axios from "axios";
import React, { useState, useEffect } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import LoginPageLogo from "../../assets/img/login-page-logo-dark.svg";
import LoginPageLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import LoginPageTitle from "../../assets/img/daxa-assets/daxa-title.svg";
import { listAccountsAsync } from "../../redux/slices/accountSlice";
import { LOADER_CONFIG } from "../../utils/Constants";
import { setContext } from "../../utils/SessionHelper";
import { Toaster } from "../../utils/toaster";
import FormatUrl from "../../utils/UrlFormatter";
import {
  expandSidebar,
  isAIView,
  isDataView,
  // issueViewDisable,
  // issueViewVisible,
} from "../../redux/slices/dashboardSlice";
import { accountType } from "../../components/constants/constants";
const Login = ({ setLoggendIn }) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    document.title = "Daxa - Login";
  }, []);

  const redirectToHome = async () => {
    setLoggendIn(true);
    history.push("/");
  };

  const emailVerification = (ev) => {
    ev.preventDefault();
    if (!emailRegexMatch(email)) {
      Toaster(4, "Invalid email format!");
      return;
    }

    if (!email.includes(".")) {
      Toaster(4, "Invalid email format!");
      return;
    }
    setLoading(true);
    let url = FormatUrl(`/user/emailVerification`);
    axios
      .post(url, {
        email: email,
      })
      .then((res) => {
        const resp = res.data;
        setLoading(false);
        if (resp.message === "Okta true") {
          window.location = FormatUrl(`/user/loginToOkta`);
          window.close();
        } else if (resp.message === "Login with google") {
          window.location = FormatUrl(`/user/loginToGoogle`);
          console.log(FormatUrl(`/user/loginToGoogle`));
          // window.close();
        } else {
          setStep(2);
        }
      })
      .catch((error) => {
        Toaster(2, error?.response?.data?.err?.message);
        setLoading(false);
      });
  };

  const emailRegexMatch = (mail) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(mailformat)) {
      return true;
    }
    return false;
  };

  const handleLogin = (ev) => {
    ev.preventDefault();
    if (!email) {
      Toaster(4, "email required !");
      return;
    }
    if (!emailRegexMatch(email)) {
      Toaster(4, "Invalid email format!");
      return;
    }

    if (!email.includes(".")) {
      Toaster(4, "Invalid email format!");
      return;
    }
    if (!password) {
      Toaster(4, "password required !");
      return;
    }

    setLoading(true);
    let url = FormatUrl(`/user/login`);

    axios
      .post(url, {
        password: window.btoa(password),
        email: email,
      })
      .then((res) => {
        dispatch(expandSidebar());

        setContext(res.data);
        res.data.token.customer.securityType === accountType.Data
          ? dispatch(isDataView())
          : dispatch(isAIView());

        redirectToHome();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Toaster(2, err.response?.data?.err?.message || "Invalid credentials");
      });
  };

  return (
    <>
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <section className="sign-in-page">
          <div className="container p-0">
            <div className="logo-container">
              <div>
                <img
                  src={LoginPageLogo}
                  alt=""
                  className="login-from-logo"
                  height={48}
                />
              </div>
              <div>
                <img
                  src={LoginPageTitle}
                  alt=""
                  className="login-from-logo"
                  width={88}
                />
              </div>
            </div>

            <div className="row no-gutters">
              <div className="col-sm-12 align-self-center">
                <div className="sign-in-from">
                  <form
                    className="mt-4"
                    onSubmit={step === 1 ? emailVerification : handleLogin}
                  >
                    <div className="form-group">
                      {/* <label className="form-label">Email ID</label> */}
                      <input
                        type="text"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Email ID"
                        onChange={(e) => setEmail(e.target.value)}
                        // onBlur={emailVerification}
                      />
                    </div>

                    {step === 2 ? (
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control mb-0"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="d-inline-block w-100">
                      <button type="submit" className="btn btn-primary">
                        {step === 1 ? "Next" : "Login"}
                      </button>
                    </div>

                    {step === 2 ? (
                      <p className="text-center">
                        <Link to="/forgot-password">Forgot password ?</Link>
                      </p>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>

            <p className="text-center">
              Don't have an account yet ? <Link to="/register">SIGN UP</Link>
            </p>
          </div>
        </section>
      </BlockUi>
    </>
  );
};

export default Login;
