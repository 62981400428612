import { ReactNode, useEffect, useState } from "react";
import Box from "./Box";
import { useTheme } from "@mui/material";
import * as d3 from "d3";
import NoData from "./NoData";

interface CustomTreeMapProps {
  id: string;
  colorScale: any;
  data: any;
  noDataText?: string;
  graphHeight?: number;
  graphWidth?: number;
  tooltipContent?: (node: any) => JSX.Element;
}

const CustomTreeMap = ({
  id,
  colorScale,
  data,
  noDataText = "",
  graphHeight = 400,
  graphWidth,
  tooltipContent,
}: CustomTreeMapProps) => {
  const [coords, setCoords] = useState<{
    x: number;
    y: number;
    data: {};
  } | null>(null);
  const theme = useTheme();
  const styles = {
    container: {
      height: "60%",
      width: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    tooltip: {
      position: "absolute",
      zIndex: 1,
      padding: theme.spacing(1),
      borderRadius: "50px",
      top: coords?.y ? coords.y + 20 + "px" : 0,
      left: coords?.x + "px" || 0,
    },
  };

  var root: any =
    data?.length > 0
      ? d3
          .stratify()
          .id(function (d: any) {
            return d.id;
          })
          .parentId(function (d: any) {
            return d.parent;
          })(data)
      : null;

  if (root)
    root.sum(function (d: any) {
      return +d.value;
    });

  const values = data?.length
    ? data?.map((d) => (d?.value ? Number(d?.value) : NaN))
    : [];
  const minValue = d3.min(values);
  const [min, max] = d3.extent(values);

  const drawTreeMap = () => {
    const element = d3.selectAll(`.${id}`);
    element.selectAll("svg").remove();
    var margin = { top: 0, right: 0, bottom: 0, left: 0 },
      height = graphHeight - margin.top - margin.bottom;
    const parentWidth =
      document.getElementsByClassName(`${id}-parent`)[0]?.clientWidth || 300;
    const width = parentWidth - margin.left - margin.right;

    var svg = d3
      .select(`#${id}`)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    d3.treemap().size([width, height]).padding(1)(root);

    const adjustTooltipX = (x) => {
      const tooltipMargin = 200;
      const boundingCoords = document
        .querySelector(`.${id}-parent`)
        ?.getBoundingClientRect();

      const tooltipSpacing = tooltipMargin ? tooltipMargin - 100 : 0;
      const xCoord = boundingCoords?.right
        ? boundingCoords?.right - x < 250
          ? x - (!tooltipMargin ? 180 : 90 + tooltipSpacing)
          : x
        : x;

      return xCoord;
    };

    var showTooltip = function (event, d, applyStyle = true) {
      if (applyStyle) {
        d3.select(`#rect-${d.id}`).attr("rx", 6).attr("ry", 6);
        d3.select(`#rect-${d.id}`).style("stroke", "white");
      }
      const xPos = adjustTooltipX(event.pageX);
      const yPos = event.pageY;

      setCoords({ x: xPos, y: yPos, data: d?.data });
    };

    var hideTooltip = function (event, d) {
      setCoords(null);
      d3.select(`#rect-${d.id}`).attr("rx", 0).attr("ry", 0);
      d3.select(`#rect-${d.id}`).style("stroke", "none");
    };

    var moveTooltip = function (event, d) {
      const xPos = adjustTooltipX(event.pageX);
      const yPos = event.pageY;
      setCoords({ x: xPos, y: yPos, data: d?.data });
    };

    const groups = svg
      .selectAll("g")
      .data(root.leaves())
      .enter()
      .append("g")
      .on("mouseenter", showTooltip)
      .on("mouseleave", hideTooltip)
      .on("mousemove", moveTooltip)
      .on("click", (event, d) => {});

    groups
      .append("rect")
      .attr("id", (d: any) => `rect-${d.data.id}`)
      .attr("class", (d: any) =>
        Number(d.data.value) >= Number(10) ? "rect-text" : "rect-no-text"
      )
      .attr("x", function (d: any) {
        return d.x0;
      })
      .attr("y", function (d: any) {
        return d.y0;
      })
      .attr("width", function (d: any) {
        return d.x1 - d.x0;
      })
      .attr("height", function (d: any) {
        return d.y1 - d.y0;
      })
      .style("stroke", "black")
      .style("fill", (d: any) => colorScale(d.data.tag));

    // and to add the text labels
    groups
      .append("foreignObject")
      .attr("x", function (d: any) {
        return d.x0 + 5;
      }) // +10 to adjust position (more right)
      .attr("y", function (d: any) {
        return d.y0 + 5;
      }) // +20 to adjust position (lower)
      .attr("width", (d: any) => d.x1 - d.x0 - 5)
      .attr("height", (d: any) => d.y1 - d.y0 - 5)
      .append("xhtml:p")
      .style("fill", "black")
      .html((d: any) => {
        const width = d.x1 - d.x0 - 5;
        const height = d.y1 - d.y0;
        return /*html*/ `<div class="treemap-rect">
            <div class="treemap-rect-header">
              ${
                d.data.violation
                  ? '<div class="treemap-error-badge"></div>'
                  : ""
              }
              ${
                width > 60
                  ? `<div class="treemap-rect-label">${d.data.label}</div>`
                  : ""
              }
            </div>
            ${
              height > 30 && width > 60
                ? `<div class="treemap-rect-value">${`${
                    d.data.value <= 9 ? "0" : ""
                  }${d.data.value}`}</div>`
                : ""
            }
          </div>`;
      });
  };

  useEffect(() => {
    if (data?.length > 1) drawTreeMap();
  }, [data]);

  return (
    <Box sx={styles.container} className={`${id}-parent`}>
      {data?.length > 1 ? (
        <>
          <div id={id} className={id}></div>
          {coords && tooltipContent ? (
            <Box sx={styles.tooltip}>
              <div className="treemap-tooltip">
                {tooltipContent(coords.data)}
              </div>
            </Box>
          ) : null}
        </>
      ) : (
        <NoData customText={noDataText} />
      )}
    </Box>
  );
};

export default CustomTreeMap;
