import { useTheme } from "@mui/material";
import Box from "../common/Box";
import CustomTreeMap from "../common/CustomTreeMap";
import LegendWithColor from "../common/LegendWithColor";
import Typography from "../common/Typography";
import CardWithAction from "./CardWithAction";
import { CardDataObj } from "./type";
import { TagColorMapping } from "../constants/constants";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchDocumentBySourcesSlice,
  fetchSnippetsByLabelsSlice,
} from "../../redux/slices/documentSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { capitalizeFirstLetter } from "../../utils/utils";
import { AppDispatch } from "../../redux/store";
import SnippetByLabelsTooltip from "./treemap-tooltips/SnippetsByLabelsTooltip";
import DatasourcesTooltip from "./treemap-tooltips/DatasourcesTooltip";

export interface TreeMapNode {
  label: string;
  id: string;
  value: number;
  parent: string;
  tag?: string;
}

const SnippetsByLabels = () => {
  const [docBySources, setDocBySources] = useState<Array<TreeMapNode> | null>(
    null
  );
  const [snippetsByLabels, setSnippetsByLabels] =
    useState<Array<TreeMapNode> | null>();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    graphContainer: {
      paddingX: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.25),
    },
    legends: {
      display: "flex",
      gap: theme.spacing(2),
    },
    cardTitle: {
      fontSize: "13px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  };

  const fetchDocsBySourcese = async (payload) => {
    const response = await dispatch(fetchDocumentBySourcesSlice(payload));
    if (response?.payload?.data) {
      const formattedList = Object.keys(response.payload.data)?.map(
        (item, index) => ({
          id: `docs-by-sources-${index}`,
          value: response.payload.data[item],
          parent: "P1",
          label: item,
        })
      );

      setDocBySources([
        { id: "P1", value: 0, parent: "", label: "P1" },
        ...formattedList,
      ]);
    }
  };

  const fetchSnippetsByLabels = async (payload) => {
    const response = await dispatch(fetchSnippetsByLabelsSlice(payload));
    if (response?.payload?.data) {
      const formattedList = Object.keys(response.payload.data)?.map(
        (item, index) => ({
          id: `snippets-by-labels-${index}`,
          value: response.payload.data[item]?.count,
          tag: response.payload.data[item]?.tag,
          parent: "P1",
          label: item,
        })
      );
      setSnippetsByLabels([
        { id: "P1", value: 0, parent: "", label: "P1" },
        ...formattedList,
      ]);
    }
  };

  useEffect(() => {
    const payload = {
      customerId: getCustomerId(),
    };
    fetchDocsBySourcese(payload);
    fetchSnippetsByLabels(payload);
  }, []);

  const cardData: CardDataObj = {
    snippetsByLabels: {
      title: "Snippets by Labels",
      children: (
        <Box sx={styles.card}>
          <Box sx={styles.graphContainer}>
            <CustomTreeMap
              id="snippetByLabels"
              colorScale={(label) => TagColorMapping[label]}
              data={snippetsByLabels}
              noDataText="No Snippets Found"
              graphHeight={200}
              tooltipContent={(node) => <SnippetByLabelsTooltip node={node} />}
            />
            <Box sx={styles.legends}>
              {Object.keys(TagColorMapping)?.map((tag) => (
                <LegendWithColor
                  color={TagColorMapping[tag]}
                  label={capitalizeFirstLetter(tag)}
                />
              ))}
            </Box>
          </Box>
          <Box sx={styles.graphContainer}>
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.cardTitle}
            >
              Documents by Sources
            </Typography>
            <Box
              sx={{
                paddingBottom: theme.spacing(2),
              }}
            >
              <CustomTreeMap
                id="docBySources"
                colorScale={() => "#3F466D"}
                data={docBySources}
                noDataText="No Documents Found"
                graphHeight={200}
                tooltipContent={(node) => <DatasourcesTooltip node={node} />}
              />
            </Box>
          </Box>
        </Box>
      ),
    },
  };

  return <CardWithAction cardData={cardData} isLoading={false} />;
};

export default SnippetsByLabels;
