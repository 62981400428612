import { useTheme } from "@mui/material";
import Box from "../../../../common/Box";
import NoData from "../../../../common/NoData";
import Typography from "../../../../common/Typography";

const AuthorizedIdentities = ({ identities }) => {
  const theme = useTheme();
  const styles = {
    container: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    font13: {
      fontSize: "13px",
    },
  };
  return (
    <Box sx={styles.container}>
      {identities?.length > 0 ? (
        identities.map((identity) => (
          <Typography color={theme.palette.surface80.main} sx={styles.font13}>
            {identity}
          </Typography>
        ))
      ) : (
        <NoData customText="No Identities Found" />
      )}
    </Box>
  );
};

export default AuthorizedIdentities;
