import { useTheme } from "@mui/material";
import Box from "./Box";
import Tooltip from "./Tooltip";
import Typography from "./Typography";

interface KeyValueProps {
  label: string;
  valueIcon?: JSX.Element | null;
  value: string;
}

const KeyValueBlock = ({ label, valueIcon = null, value }: KeyValueProps) => {
  const theme = useTheme();
  const styles = {
    block: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      width: "90%",
    },
    labelText: { fontSize: "11px" },
    value: {
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    tooltipChildren: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "100%",
    },
  };

  return (
    <Box sx={styles.block}>
      <Typography sx={styles.labelText} color={theme.palette.surface30.main}>
        {label}
      </Typography>
      <Box sx={styles.tooltipChildren}>
        {valueIcon ? valueIcon : <></>}
        <Tooltip title={value || ""}>
          <div style={styles.tooltipChildren}>
            <Typography
              variant="caption"
              color={theme.palette.surface80.main}
              sx={styles.value}
            >
              {value || "-"}
            </Typography>
          </div>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default KeyValueBlock;
