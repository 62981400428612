import { useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import Box from "../../../common/Box";
import Checkbox from "../../../common/Checkbox";
import { Tab, Tabs } from "../../../common/Tabs";
import Typography from "../../../common/Typography";

const DetailsBlock = ({ title, content }) => {
  const [selectedTab, setSelectedTab] = useState<number | null>(null);
  const theme = useTheme();

  const styles = {
    font13: {
      fontSize: "13px",
    },
    users: {
      height: "100%",
    },
    userHeader: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      paddingX: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.surface20.main}`,
      minHeight: "50px",
    },
    userBox: {
      height: "100%",
      borderRight: `1px solid ${theme.palette.surface20.main}`,
      paddingBottom: theme.spacing(2),
    },
    checkbox: {
      height: "fit-content",
    },
    children: {
      height: "300px",
      overflowY: "auto",
    },
  };

  const isTab = typeof title !== "string";

  useEffect(() => {
    if (typeof title !== "string" && !selectedTab) {
      setSelectedTab(0);
    }
  }, [title]);

  const handleTabChange = (event: Event | SyntheticEvent, newValue: number) =>
    setSelectedTab(newValue);

  return (
    <Box sx={styles.users}>
      <Box sx={styles.userBox}>
        <Box sx={styles.userHeader}>
          {/*<Checkbox label="" sx={styles.checkbox} />*/}
          {isTab ? (
            <Tabs value={selectedTab} onChange={handleTabChange}>
              {title.map((titleStr, index) => (
                <Tab label={titleStr} value={index} key={titleStr} />
              ))}
            </Tabs>
          ) : (
            <Typography color={theme.palette.surface70.main} sx={styles.font13}>
              {title}
            </Typography>
          )}
        </Box>
        <Box sx={styles.children}>
          {selectedTab ? content[selectedTab] : content[0]}
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsBlock;
