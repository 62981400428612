import { useEffect, useMemo, useState } from "react";
import Box from "../../../components/common/Box";
import { Tab, Tabs } from "../../../components/common/Tabs";
import { useTheme } from "@mui/material";
import CustomTab from "../CustomTab";
import Typography from "../../../components/common/Typography";
import { Select } from "../../../components/common/Select";
import Snippet from "./Snippet";
import NoData from "../../../components/common/NoData";
import LabelWithSnippetCount from "./LabelWithSnippetCount";
import { useDispatch } from "react-redux";
import { fetchDocumentDetailsSlice } from "../../../redux/slices/documentSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../redux/store";

interface DocumentSnippets {
  total_snippets: number;
  snippets_with_harmful_data: any;
  snippets_with_sensitive_content: any;
}

const ListOfSnippets = ({ snippets }) => {
  return snippets?.length ? (
    snippets.map((snippet: any) => (
      <Snippet retrievedOn={snippet?.retrieved_on} snippet={snippet?.doc} />
    ))
  ) : (
    <NoData customText="No Snippets" />
  );
};

const DocumentSnippets = ({ name }) => {
  const [selectedTab, setSelectedTab] = useState("snippets_with_harmful_data");
  const [documentSnippets, setDocumentSnippets] =
    useState<DocumentSnippets | null>(null);
  const [filteredSnippets, setFilteredSnippets] = useState({});
  const [selectedLabel, setSelectedLabel] = useState("");
  const [snippets, setSnippets] = useState([]);
  const [applications, setApplications] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [filterByApp, setFilterByApp] = useState("all");
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const styles = {
    pageBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    snippetsCard: {},
    noBottomBorder: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 0,
      },
    },
    promptSelector: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #292D40",
      padding: theme.spacing(2),
    },
    filters: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
      marginLeft: "auto",
    },
    font13: {
      fontSize: "13px",
    },
    panel: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
      height: "500px",
      overflow: "hidden",
    },
    cardContent: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      flexGrow: "1",
      overflow: "hidden",
    },
    gridTitle: {
      padding: theme.spacing(2),
      borderBottom: "1px solid #292D40",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      flexGrow: "1",
      overflow: "hidden",
    },
    column1: {
      borderRight: "1px solid #292D40",
      //  overflow: "hidden",
    },
    column2: {
      //  overflow: "hidden",
    },
    snippetList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      overflowY: "auto",
      height: "100%",
    },
  };

  const fetchSnippetLabels = (label) => {
    return documentSnippets
      ? Object.keys(documentSnippets[label])?.map((snippet: any) => ({
          label: snippet,
          value: documentSnippets[label][snippet],
        }))
      : [];
  };

  const getKeyItemsFromObject = (obj: { key: [] }) => {
    //using reducer get length of items in object
    return obj
      ? Object.values(obj).reduce((acc, item: []) => {
          return acc + item?.length;
        }, 0)
      : 0;
  };

  const tabs = useMemo(() => {
    return {
      snippets_with_harmful_data: {
        label: "Snippets with Harmful Data",
        color: "#B9605B",
        value: "snippets_with_harmful_data",
        data: fetchSnippetLabels("snippets_with_harmful_data"),
        snippets: getKeyItemsFromObject(
          documentSnippets?.snippets_with_harmful_data
        ),
        outOf: documentSnippets?.total_snippets || 0,
      },
      snippets_with_sensitive_content: {
        label: "Snippets with Sensitive Content",
        value: "snippets_with_sensitive_content",
        data: fetchSnippetLabels("snippets_with_sensitive_content"),
        snippets: getKeyItemsFromObject(
          documentSnippets?.snippets_with_sensitive_content
        ),
        outOf: documentSnippets?.total_snippets || 0,
      },
    };
  }, [documentSnippets]);

  const fetchDocumentSnippets = async () => {
    const payload = {
      customerId: getCustomerId(),
      documentName: name,
    };
    const response = await dispatch(fetchDocumentDetailsSlice(payload));
    if (response?.payload?.data) {
      const data = response?.payload?.data;
      setApplications([
        {
          label: `All Data Loaders (${data?.apps?.length || 0})`,
          value: "all",
        },
        ...data.apps?.map((app) => ({
          value: app,
          label: app,
        })),
      ]);
      setDocumentSnippets(data);
    }
  };

  const filterTabsWithSnippets = (allLabels: DocumentSnippets) => {
    let filteredObj = {} as { [key: string]: {} };

    for (const key in allLabels[selectedTab]) {
      const filteredArray = allLabels[selectedTab][key]?.filter((value) =>
        filterByApp === "all" ? true : value?.app_name === filterByApp
      );
      if (filteredArray.length > 0) {
        filteredObj[key] = {
          label: key,
          value: filteredArray.length,
          data: filteredArray,
        };
      }
    }

    return filteredObj;
  };

  useEffect(() => {
    if (name) {
      fetchDocumentSnippets();
    }
  }, [name]);

  useEffect(() => {
    if (selectedTab && documentSnippets) {
      const filteredData = filterTabsWithSnippets(documentSnippets);
      if (filteredData && Object.keys(filteredData).length) {
        if (!Object.keys(filteredData).includes(selectedLabel)) {
          setSelectedLabel(Object.keys(filteredData)[0]);
        }
      }
      setFilteredSnippets(filteredData || {});
    }
  }, [selectedTab, filterByApp, documentSnippets]);

  return (
    <Box sx={styles.pageBody}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          "&.MuiTab-root": {
            paddingBottom: "0",
          },
        }}
      >
        {Object.values(tabs)?.map((tab) => (
          <Tab
            sx={{
              "&.MuiTab-root": {
                paddingBottom: 0,
              },
            }}
            label={
              <CustomTab
                {...tab}
                count={tab.snippets}
                selectedValue={selectedTab}
              />
            }
            value={tab.value}
          />
        ))}
      </Tabs>
      <Box sx={styles.snippetsCard}>
        <Box sx={styles.panel}>
          <Box sx={styles.promptSelector}>
            <Box sx={styles.filters}>
              <Typography
                color={theme.palette.surface30.main}
                sx={styles.font13}
              >
                Show Snippets for:
              </Typography>
              <Select
                placeholder="Filter by Data Loaders"
                options={applications}
                sx={styles.noBottomBorder}
                value={filterByApp}
                onChange={(event: any) => setFilterByApp(event.target.value)}
              />
            </Box>
          </Box>
          <Box sx={styles.cardContent}>
            <Box sx={{ ...styles.column, ...styles.column1 }}>
              <Box sx={styles.gridTitle}>
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.font13}
                >
                  Labels
                </Typography>
              </Box>
              <LabelWithSnippetCount
                onChange={(label) => setSelectedLabel(label)}
                selected={selectedLabel}
                labels={Object.values(filteredSnippets)}
              />
            </Box>
            <Box sx={{ ...styles.column, ...styles.column2 }}>
              <Box sx={styles.gridTitle}>
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.font13}
                >
                  Snippets
                </Typography>
              </Box>
              <Box sx={styles.snippetList}>
                <ListOfSnippets
                  snippets={filteredSnippets[selectedLabel]?.data}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentSnippets;
