import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Adds a topic
 * @param {*} params
 * @returns
 */
export const addTopic = (params) => {
  return axiosDenaliPrivate.post("api/v1/ai/app/byot/add_topic", params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const fetchTopics = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/byot/get_byot_topic", params);
};

export const deleteTopic = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/byot/delete_topic", params);
};
