import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { PolicyViolationDetailsCard } from "../LLMModel/PolicyViolationDetailsCard";
import data from "../../../mock-data/llmFrameworkPageData.json";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { listDataStoreTagsAsync } from "../../../redux/slices/dataStoreSlice";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import { Breadcrumbs, Link } from "../../common/Breadcrumbs";
import LLMTabs from "./LLMTabs";
import LLMFrameworkDetails from "./LLMFrameworkDetails";
import { TabPanel } from "../../common/Tabs";
import { LangchainGraph } from "../graph/LangchainGraph";
import { ChainGraph } from "../graph/ChainGraph";
import { LangchainGraphNode } from "../graph/LangchainGraphNode";
import { CollapseIconComponent } from "../../common/CollapseIcon";
import LangchainIcon from "../../../assets/img/langchain.svg";
import ChromaIcon from "../../../assets/img/chromaIcon.svg";
import PostgresIcon from "../../../assets/img/postgres.svg";
import { frameworkPromptSummaryData } from "../../../mock-data/frameworkPromptMessagesData";
import { getLoaderIcon } from "../../../utils/commonUtils";
import {
  fetchEntitiesSlice,
  fetchTopicsSlice,
} from "../../../redux/slices/policySlice";
import LoaderOverviewPanel from "./Overview/LoaderOverviewPanel";
import {
  deleteAppSlice,
  fetchAppDetailsSlice,
  fetchAppOverviewSlice,
  fetchAppRetreivalsSlice,
  setAppDescriptionSlice,
} from "../../../redux/slices/appSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import { useHistory } from "react-router-dom";
import AddAppPolicy from "./AddAppPolicy";
import PromptSummaryPanel from "./PromptSummaryPanel";
import { DataSourceTab } from "./DataSourceTab";
import { AppDispatch } from "../../../redux/store";

const APPLICATION_STR = "application";

export const LLMFrameworkPage = ({ setPath }) => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const ref = useRef<any>(null);
  const [frameworkData, setFrameworkData] = useState<any>({});
  const objectId = params.id;
  const [width, setWidth] = useState<any>(0);
  const [tags, setTags] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("overview");
  const [nodeHeight, setNodeHeight] = useState(0);
  const [collapsibleTreeData, setCollapsibleTreeData] = useState<any>({});
  const [tabsData, setTabsData] = useState(null);

  const styles = {
    loader: {
      height: "100vh",
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
    },
    pageLayout: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    langchainFramework: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      height: "100%",
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    langchainHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    langchainTitle: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "center",
    },
    langchainDetailsCard: {
      display: "flex",
      gap: theme.spacing(4),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      height: "auto",
      transition: "all 0.5s",
    },
    tabLabel: {
      paddingX: theme.spacing(1),
    },
    promptSummaryPanel: {
      background: theme.palette.surface10.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
    tab: {
      marginTop: theme.spacing(1.5),
    },
  };

  const getFrameworkDetails = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      appId: objectId,
    };
    const resp = await dispatch(fetchAppDetailsSlice(payload));
    if (resp?.payload) {
      setFrameworkData(resp?.payload);
      if (resp?.payload?.chains?.length > 0) {
        const structuredTreeData = buildCollapsibleGraphData(
          resp?.payload?.chains,
          resp?.payload
        );
        setCollapsibleTreeData(structuredTreeData);
      } else {
        setCollapsibleTreeData({});
      }
    } else {
      setFrameworkData({});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPath(window.location.pathname);
    window.addEventListener("resize", getWidth);
    getEntitiesTopicsList();
    getFrameworkDetails();
    const widthValue: any = ref?.current?.offsetWidth;
    setWidth(widthValue);
  }, []);

  useEffect(() => {
    if (objectId) {
      const filteredData = data?.frameworkPageData.find(
        (item) => item?.id === objectId
      );
      setFrameworkData(filteredData?.data);
    }
  }, [objectId]);

  const getEntitiesTopicsList = async () => {
    let payload = {
      customerId: getCustomerId(),
    };
    await dispatch(fetchEntitiesSlice(payload));
    await dispatch(fetchTopicsSlice(payload));
  };

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth);
  };

  let frameworkTabs = [
    {
      value: "overview",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Overview
        </Typography>
      ),
    },
    {
      value: "datasource",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Safe Loader
        </Typography>
      ),
    },
    {
      value: "promptSummary",
      label: (
        <Typography variant="body2" sx={styles.tabLabel}>
          Safe Retrieval
        </Typography>
      ),
    },
  ];

  const updateDataStructure = (data) => {
    let result = {};
    if (data && data?.length) {
      data.map((user: any) => {
        result = {
          ...result,
          [user.userName]: {
            user_id: user.userId,
            documents: user.documents,
          },
        };
      });
    }
    return result;
  };

  const getAppData = async () => {
    const appId = params.id;
    const payload = {
      appId: appId,
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchAppOverviewSlice(payload));
    const retirevalRes = await dispatch(fetchAppRetreivalsSlice(payload));
    if (response?.payload || retirevalRes?.payload) {
      return {
        overview: response?.payload || {},
        data: updateDataStructure(retirevalRes?.payload || []),
      };
    }
    return { overview: {}, data: [] };
  };

  const buildCollapsibleGraphData = (unstructuredData, frameworkDetails) => {
    if (unstructuredData?.length > 0) {
      let graphData = {};
      unstructuredData?.map((item) => {
        graphData = {
          name: item.name,
          height: 70,
          width: 200,
          isNode: false,
          nodeDepth: 600,
          icon: <CollapseIconComponent id={item.name} />,
          node: (
            <LangchainGraphNode
              icon={LangchainIcon}
              title={item.name}
              variant="main"
              isNode={false}
            />
          ),
          children:
            item?.vectorDbs?.length > 0
              ? getLangchainChildren(item?.vectorDbs, item, frameworkDetails)
              : [],
        };
      });
      return graphData;
    }
    return {};
  };

  const constructChainData = (unstructuredData, index, frameworkDetails) => {
    if (Object.keys(unstructuredData)?.length > 0) {
      let graphData = {
        name: unstructuredData?.name,
        version: unstructuredData?.version,
        height: 70,
        width: 200,
        isNode: true,
        type: "vectorDB",
        nodeDepth: 200,
        packageInfo: unstructuredData?.packageInfo,
        node: (
          <LangchainGraphNode
            id="lineage-graph-node"
            icon={getLoaderIcon(unstructuredData?.name)}
            title={unstructuredData?.name}
            description={unstructuredData?.version}
            isNode={true}
          />
        ),
        icon:
          unstructuredData?.bucket?.length > 0 ||
          frameworkDetails?.loaders?.length > 0 ? (
            <CollapseIconComponent id={unstructuredData?.name} />
          ) : (
            ""
          ),
        children:
          unstructuredData?.bucket?.length > 0
            ? getVectorDbChildren(unstructuredData?.bucket)
            : frameworkDetails?.loaders?.length > 0
            ? getVectorDbChildren([frameworkDetails?.loaders[index]])
            : [],
      };
      return graphData;
    }
    return {};
  };

  const addNullObject = (array) => {
    let newArray: any = [];
    for (let i = 0; i < array?.length - 1; i++) {
      newArray.push({});
    }
    return [...newArray, ...array];
  };

  const getLangchainChildren = (array, item, frameworkDetails) => {
    let collapsibleNodeHeight = 0;
    let chainNumber = 0;
    let newArray = array?.map((db, index) => {
      let height =
        db?.bucket?.length > 0
          ? 200 *
            (db?.bucket?.length === 1
              ? db?.bucket?.length + 1
              : db?.bucket?.length)
          : 300;
      if (collapsibleNodeHeight > height) {
        collapsibleNodeHeight = nodeHeight;
      } else {
        collapsibleNodeHeight = height;
      }
      chainNumber = chainNumber + 1;
      return {
        name: db?.name,
        // width: db?.bucket?.length > 0 ? 600 : 228,
        width: frameworkDetails?.loaders?.length > 0 ? 600 : 228,
        height:
          db?.bucket?.length > 0
            ? 200 *
              (db?.bucket?.length === 1
                ? db?.bucket?.length + 1
                : db?.bucket?.length)
            : 300,
        isNode: false,
        node: (
          <ChainGraph
            className={`Chain-${chainNumber}`}
            isChild={true}
            chainData={constructChainData(db, index, frameworkDetails)}
          />
        ),
        chainData: {
          ...constructChainData(db, index, frameworkDetails),
          modelDetail: item?.model,
        },
      };
    });
    setNodeHeight(collapsibleNodeHeight);
    return addNullObject(newArray);
  };

  const getVectorDbChildren = (array) => {
    let newArray = array?.map((item) => {
      return {
        name: item?.name,
        height: 70,
        width: 200,
        isNode: true,
        type: "loader",
        node: (
          <LangchainGraphNode
            icon={getLoaderIcon(item?.name)}
            title={item?.name}
            description={item?.sensitiveData || ""}
            isNode={true}
          />
        ),
        packageInfo: item,
      };
    });

    return addNullObject(newArray);
  };

  const getInstanceDetails = () => {
    if (
      frameworkData &&
      frameworkData.chains &&
      frameworkData.chains?.length > 0
    ) {
      const chainObj = frameworkData.chains[0];

      return {
        frameworkSummary: {
          name: frameworkData.name,
          family: frameworkData.framework.name,
          version: frameworkData.framework.version,
          description: frameworkData.description,
        },
        vectorDB: chainObj.vectorDbs[0]?.name,
        vectorDBLocation: chainObj.vectorDbs[0]?.location,
        vectorDBVersion: chainObj.vectorDbs[0]?.version,
        embeddingModel: chainObj?.vectorDbs[0]?.embeddingModel,
        lastused: frameworkData.lastUsed,
        models: {
          vendor: chainObj.model.vendor,
          name: chainObj.model.name,
        },
      };
    }
    return {};
  };

  const handleDelete = async (appName) => {
    const payload = {
      customerId: getCustomerId(),
      appId: params.id,
    };
    const resp = await dispatch(deleteAppSlice(payload));
    if (resp?.payload?.success) {
      Toaster(
        TOASTER_TYPES.SUCCESS,
        `Application ${appName} successfully deleted!`
      );
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp?.payload?.error?.message);
    }
    history.goBack();
  };

  const setDescription = async (description) => {
    const payload = {
      customerId: getCustomerId(),
      id: params.id,
      description,
    };
    return await dispatch(setAppDescriptionSlice(payload));
  };

  return (
    <>
      {isLoading ? (
        <Box sx={styles.loader}>
          <Loader />
        </Box>
      ) : Object.keys(frameworkData)?.length > 0 ? (
        <Box sx={styles.langchainFramework}>
          <Breadcrumbs sx={styles.breadCrumbLink}>
            <Link
              underline="hover"
              color="inherit"
              href="/"
              sx={styles.blueLink}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/applications"
              sx={styles.blueLink}
            >
              Applications
            </Link>
            <Typography variant="caption" sx={styles.activeLink}>
              {frameworkData?.name}
            </Typography>
          </Breadcrumbs>
          <LLMFrameworkDetails
            item={APPLICATION_STR}
            frameworkData={{ ...frameworkData, ...getInstanceDetails() }}
            onDeleteApp={handleDelete}
            setDescription={setDescription}
          />
          <Box sx={styles.tab}>
            <LLMTabs
              tabs={frameworkTabs}
              activeTab={selectedTab}
              onTabChange={(event, newValue) => setSelectedTab(newValue)}
            />
          </Box>
          <TabPanel
            value={selectedTab}
            index="policyViolations"
            key="policyViolations"
          >
            <PolicyViolationDetailsCard ticketIntegration={false} />
          </TabPanel>
          <TabPanel value={selectedTab} index="overview" key="overview">
            {Object.keys(collapsibleTreeData)?.length > 0 ? (
              <LangchainGraph
                treeData={collapsibleTreeData}
                nodeHeight={nodeHeight}
              />
            ) : (
              <NoData customText="No data found" />
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index="datasource" key="datasource">
            <DataSourceTab frameworkData={frameworkData} />
          </TabPanel>
          <TabPanel
            value={selectedTab}
            index="promptSummary"
            key="promptSummary"
          >
            <PromptSummaryPanel />
          </TabPanel>
          <TabPanel value={selectedTab} index="addPolicy" key="addPolicy">
            <AddAppPolicy appId={params.id} />
          </TabPanel>
        </Box>
      ) : (
        <NoData customText="No data found." />
      )}
    </>
  );
};
