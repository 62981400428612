import Box from "./Box";

export const CollapseIconComponent = ({ id = "" }: { id: string }) => {
  const styles = {
    collapseWrapper: {
      position: "absolute",
      right: 0,
      top: "0",
      bottom: "0",
      marginY: "auto",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      flexShrink: 0,
      border: "1px solid #080B1E",
      transform: "rotate(-45deg)",
    },
    iconWrapper: {
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      flexShrink: 0,
      border: "2px solid #080B1E",
      height: "100%",
      width: "100%",
    },
    collapseIcon: {
      transform: "rotate(45deg)",
    },
  };
  return (
    <Box id="icon-wrapper" sx={styles.collapseWrapper}>
      <Box sx={styles.iconWrapper}>
        <Box sx={styles.collapseIcon} id={`icon-${id}`}>
          -
        </Box>
      </Box>
    </Box>
  );
};
