import { useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "../../common/Box";
import Divider from "../../common/Divider";
import Typography from "../../common/Typography";
import { Tab, Tabs } from "../../common/Tabs";
import * as d3 from "d3";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useParams } from "react-router-dom";
import AppTreeMap from "./AppTreeMap";
import {
  fetchAppOverviewSlice,
  fetchAppRetreivalsSlice,
  fetchAppRetrievalOverviewSlice,
} from "../../../redux/slices/appSlice";
import SensitiveMessagesDetails from "./SensitiveMessagesDetails";
import { AppDispatch } from "../../../redux/store";
import SelectedNodeDetails from "./SelectedNodeDetails";
import { useSelector } from "react-redux";
import { fetchImpactedDocumentsSlice } from "../../../redux/slices/documentSlice";
import { fetchActiveUsersSlice } from "../../../redux/slices/userSlice";

const PromptSummaryPanel = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState<any>("user");

  const [retrievalOverview, setRetrievalOverview] = useState<any>({});
  const [retrievalData, setRetrievalData] = useState<any>([]);
  const [treeMapView, setTreeMapView] = useState(0);
  const [isLoadingRetrievalData, setIsLoadingRetrievalData] = useState(false);
  const documentsList = useSelector((state: any) => state.document.documents);
  const usersList = useSelector((state: any) => state.user.users);

  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    treeMapContainer: {
      width: "100%",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    messageBlock: {
      display: "flex",
      flexDirection: "column",
    },
    sensitiveMessages: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    messageCount: {
      fontSize: "24px",
    },
    formControls: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    checkboxLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "20px" },
    },
    promptCheckbox: {
      color: theme.palette.peach.main,
    },
    responseCheckbox: {
      color: theme.palette.paleBlue.main,
    },
    contextCheckbox: {
      color: theme.palette.laurelGreen.main,
    },
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    graphContainer: {},
    promptSummary: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    summaryHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    sortByText: {
      fontSize: "13px",
    },
    messageDetailsHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    sortByBox: {
      display: "flex",
      alignItems: "end",
      gap: theme.spacing(1.5),
    },
    treeMapLense: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },
  };

  const getRetrievalCount = (data, field) => {
    return data?.reduce((acc, item) => acc + item[field], 0);
  };

  const treeMapViews = useMemo(
    () => [
      {
        value: 0,
        label: "Users",
        key: "Users",
        retrievalCount: retrievalOverview.retrievals,
        treeMapData: retrievalOverview?.users
          ? [
              { name: "P1", value: "", parent: "", user: "P1", id: "P1" },
              ...retrievalOverview.users?.map((item, index) => ({
                id: index.toString(),
                name: item?.name,
                value: item?.retrieval_count,
                parent: "P1",
              })),
            ]
          : [{ name: "P1", value: "", parent: "", user: "P1", id: "P1" }],
      },
      {
        value: 1,
        label: "Document",
        key: "Document",
        retrievalCount: retrievalOverview.retrievals,
        treeMapData: retrievalOverview?.documents_accessed
          ? [
              { name: "P1", value: "", parent: "", user: "P1", id: "P1" },
              ...retrievalOverview.documents_accessed?.map((item, index) => ({
                id: index.toString(),
                name: item?.source_path,
                value: item?.retrieval_count,
                parent: "P1",
              })),
            ]
          : [{ name: "P1", value: "", parent: "", user: "P1", id: "P1" }],
      },
    ],
    [retrievalOverview]
  );

  const fetchTreeMapData = async (payload) => {
    const response = await dispatch(fetchAppRetrievalOverviewSlice(payload));

    if (response?.payload) {
      setRetrievalOverview(response?.payload);
    }
  };

  const fetchRetrievalsData = async (payload) => {
    setIsLoadingRetrievalData(true);
    const response = await dispatch(fetchAppRetreivalsSlice(payload));
    if (response?.payload) {
      setRetrievalData(response?.payload);
    }
    setIsLoadingRetrievalData(false);
  };

  const getSelectedNodeDetails = () => {
    let details = null;
    const data = treeMapViews.find(
      (item) => item.value === treeMapView
    )?.treeMapData;

    if (data) {
      const node = data.find((item) => item.id === selectedNode);

      if (selectedFilter === "file" && documentsList?.length) {
        details = {
          ...node,
          ...documentsList?.find((item) => item.name === node?.name),
        };
      } else if (usersList?.length) {
        details = {
          ...node,
          ...usersList?.find((item) => item.name === node?.name),
        };
      } else {
        details = { ...node };
      }
    }
    return details;
  };

  const fetchDocsList = async () => {
    if (!documentsList) {
      await dispatch(
        fetchImpactedDocumentsSlice({ customerId: getCustomerId() })
      );
    }
  };

  const fetchUsersList = async () => {
    if (!usersList) {
      await dispatch(fetchActiveUsersSlice({ customerId: getCustomerId() }));
    }
  };

  useEffect(() => {
    const payload = {
      customerId: getCustomerId(),
      appId: params.id,
    };
    fetchTreeMapData(payload);
    fetchRetrievalsData(payload);
    fetchDocsList();
    fetchUsersList();
  }, []);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Box sx={styles.messageBlock}>
          <Box sx={styles.sensitiveMessages}>
            <Typography color={theme.palette.surface70.main} variant="body2">
              Retrieval Count
            </Typography>
            <Typography
              color={theme.palette.critical.main}
              sx={styles.messageCount}
            >
              {treeMapViews[treeMapView]?.retrievalCount}
            </Typography>
          </Box>
          {/*<Typography
            variant="caption"
            color={theme.palette.surface50.main}
          >{`Total Messages ${totalPrompts}`}</Typography>*/}
        </Box>
      </Box>
      <Box sx={styles.graphContainer}>
        <Box sx={styles.treeMapContainer}>
          <Box sx={styles.treeMapLense}>
            <Typography variant="caption" color={theme.palette.surface50.main}>
              View By
            </Typography>
            <Tabs
              value={treeMapView}
              onChange={(event, newValue) => {
                setTreeMapView(newValue);
                setSelectedNode(null);
                setSelectedFilter(newValue === 0 ? "user" : "file");
              }}
            >
              {treeMapViews?.map((item) => (
                <Tab value={item.value} label={item.label} key={item.value} />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <AppTreeMap
              setSelectedNode={setSelectedNode}
              selectedNode={selectedNode}
              data={
                treeMapViews[treeMapView]?.treeMapData || [
                  { name: "P1", value: "", parent: "", user: "P1" },
                ]
              }
              maxTwoValue={2}
            />
            {selectedNode && (
              <SelectedNodeDetails
                nodeDetails={getSelectedNodeDetails()}
                selectedFilter={selectedFilter}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Divider orientation="horizontal" />
      <Box sx={styles.promptSummary}>
        <Box sx={styles.summaryHeader}>
          <Typography variant="body2">Retrievals by Labels</Typography>
        </Box>
        <SensitiveMessagesDetails
          isLoading={isLoadingRetrievalData}
          messageData={retrievalData}
        />
      </Box>
    </Box>
  );
};

export default PromptSummaryPanel;
