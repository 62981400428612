import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useHistory, useParams } from "react-router-dom";
import { getIssuesApi, getIssuesApiV2 } from "../../apis";
import closeIcon from "../../assets/img/icons/close-icon.svg";
import NoDataContainer from "../../components/cards/no-data";
import Cards from "../../components/vulnerabilities/cards";
import VulnerabilityTable from "../../components/vulnerabilities/table";
import {
  LOADER_CONFIG,
  REACT_APP_SECURITYISSUES_API,
} from "../../utils/Constants";
import { addIndex } from "../../utils/dataHandler";
import {
  getCustomerId,
  getSelectedIssuesAccount,
  setSelectedIssuesAccount,
} from "../../utils/SessionHelper";
import Search from "../../components/common/Search";
import { Select } from "../../components/common/Select";
import Box from "../../components/common/Box";
import { Pagination } from "../../components/common/Pagination";
import "../Datastore/datastoreView.scss";
import { useTheme } from "@mui/material";
import { CardWrapper } from "../../components/common/CardWrapper";
import NoData from "../../components/common/NoData";

const Datastore = ({ setPath, filterIssues, selectedAccount }) => {
  const limit = 10;
  const defaultAccount = getSelectedIssuesAccount() || {
    value: "all",
    label: "All",
  };
  const [subFilter, setSubFilter] = useState("Active");
  const [subFiltersList, setSubFiltersList] = useState([
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Risk Accepted",
      label: "Risk Accepted",
    },
    {
      value: "Resolved",
      label: "Resolved",
    },
  ]);
  const page_ = sessionStorage.getItem("page");
  const params = useParams();
  const [inputText, setInputText] = useState("");
  const [currentPosts, setCurrentPosts] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("cdIssueRiskScore");
  const [sortType, setSortType] = useState("desc");
  const routeAssetId = params.assetId?.replaceAll("_", "/");
  const cloudEnvClass = params.cloudEnvClass;

  const policyViolationId = params.policyViolationId;
  const [page, setPage] = useState(
    !routeAssetId && page_ ? parseInt(page_) : 1
  );

  const [search, setSearch] = useState(null);

  const [totalIssuesCount, setTotalIssuesCount] = useState(0);
  const [isRiskAccepted, setIsRiskAccepted] = useState(false);
  const [assetId, setAssetId] = useState();
  const history = useHistory();
  const theme = useTheme();

  const styles = {
    select: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 3,
    },
  };

  useEffect(() => {
    document.title = "Cloud Defense - Vulnerabilities";
    setPath(window.location.pathname);
  }, []);

  const availableKeywords = [
    "cdissueRiskScore",
    "vulnerabilityid",
    "type",
    "name",
    "issuetype",
    "displayseverityscore",
    "severityscore",
    "instancelocation",
    "location",
    "impactedDatastoresCount",
    "impacteddatastores",
  ];

  const getSearchField = (keyword, field, search) => {
    if (availableKeywords.includes(keyword.toLowerCase())) {
      return field;
    } else {
      return search;
    }
  };
  const getKeywordField = (keyword) => {
    if (availableKeywords.includes(keyword.toLowerCase())) {
      return keyword;
    } else {
      return null;
    }
  };

  const getIssues = async (page_ = 0) => {
    setLoading(true);
    const cloudAccountId =
      selectedAccount.value === "all" ? null : selectedAccount.value;
    let payload = {
      customerId: getCustomerId(),
      cloudAccountId: cloudAccountId,
      page: page_ ? page_ : page,
      limit: limit,
      ordering: sortField,
      severity:
        !severity || severity.includes("TOTAL ISSUES")
          ? null
          : severity.replaceAll(" ", "_"),
      policyViolationId: policyViolationId ? policyViolationId : null,
      // risk_accepted: isRiskAccepted,
    };

    const search_ =
      search && search.includes(":")
        ? getSearchField(search.split(":")[0], search.split(":")[1], search)
        : search;
    const keyword_ =
      search && search.includes(":")
        ? getKeywordField(search.split(":")[0])
        : null;

    if (REACT_APP_SECURITYISSUES_API === "OPENSEARCH") {
      payload = {
        customerId: getCustomerId(),
        q: search_,
        dataset: "issues",
        exact: false,
        page: page_ ? page_ : page,
        limit: limit,
        ordering: sortField,
        sortType: sortType,
        cloudAccountId: cloudAccountId,
        severity:
          !severity || severity.includes("TOTAL ISSUES")
            ? null
            : severity.replaceAll(" ", "_"),
        risk_accepted: isRiskAccepted,
        policyViolationId: policyViolationId ? policyViolationId : null,
        cloudEnvClass: cloudEnvClass ? cloudEnvClass : null,
      };

      if (keyword_) {
        payload.keyword = keyword_;
      }
    }
    if (routeAssetId) {
      setAssetId(routeAssetId.replaceAll("_", "/"));
      payload.assetId = routeAssetId.replaceAll("_", "/");
    } else {
      setAssetId("undefine");
    }
    if (subFilter === "Active") {
      payload.risk_accepted = false;
    }
    if (subFilter === "Risk Accepted") {
      payload.risk_accepted = true;
    }
    if (subFilter === "Resolved") {
      payload.status = "resolved";
    }
    let res =
      REACT_APP_SECURITYISSUES_API === "OPENSEARCH"
        ? await getIssuesApiV2(payload)
        : await getIssuesApi(payload);

    if (res.err || !res.data) {
      setLoading(false);
      return;
    }

    setTotalIssuesCount(res.total_pages);
    res = addIndex(res.data);
    setCurrentPosts(res);
    setLoading(false);
    if (subFilter === "Risk Accepted") {
      setIsRiskAccepted(true);
    }
  };

  const handleRiskToggle = () => {
    setIsRiskAccepted(!isRiskAccepted);
  };

  const sortData = (field) => {
    if (field === sortField && sortType === "asc") {
      setSortType("desc");
    } else if (field === sortField && sortType === "desc") {
      setSortType("asc");
    } else {
      setSortType("desc");
    }
    setSortField(field);
    setPage(1);
  };

  useEffect(() => {
    setSelectedIssuesAccount(selectedAccount);
  }, [selectedAccount]);

  useEffect(() => {
    getIssues();
  }, [
    page,
    isRiskAccepted,
    subFilter,
    selectedAccount,
    sortField,
    severity,
    sortType,
  ]);

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  const issueList = () => {
    history.push("/vulnerabilities");
  };

  const makeSearch = (ev) => {
    ev.preventDefault();
    setPage(1);
    getIssues(1);
  };

  const setFilterValue = (value) => {
    setSubFilter(value);
    if (value === "Risk Accepted") {
      // setIsRiskAccepted(true);
    } else {
      setIsRiskAccepted(false);
    }
  };

  const onClear = () => setSearch("");

  return (
    <div id="issues">
      <Cards
        severity={severity}
        filterIssues={(type) => {
          setPage(1);
          setSeverity(type);
        }}
        cloudEnvClass={cloudEnvClass}
        selectedAccount={selectedAccount}
        inputText={inputText}
        setInputText={setInputText}
      />
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }
      >
        <div id="issues">
          {/* <div className="table-main"> */}
          <CardWrapper sx={styles.wrapper}>
            <div class="row">
              <div className="col col-xl-4 align-self-end">
                <Search
                  placeholder="Search.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && makeSearch(e)}
                  onClear={onClear}
                />
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-end">
                <div className="d-flex">
                  {assetId === "undefine" ? (
                    ""
                  ) : (
                    <span className="asset-id-tag">
                      {assetId}
                      <img
                        className="close-icon"
                        alt=""
                        src={closeIcon}
                        title="Close"
                        onClick={() => issueList()}
                      />
                    </span>
                  )}
                </div>
                <div className="d-flex datastore-details-issues">
                  <Select
                    value={subFilter}
                    variant="standard"
                    label="Filter"
                    options={subFiltersList}
                    sx={styles?.select}
                    onChange={(event) => {
                      setFilterValue(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <>
              {currentPosts.length ? (
                <Box>
                  <VulnerabilityTable
                    severity={severity}
                    currentPosts={currentPosts}
                    sortData={sortData}
                    isRiskAccepted={isRiskAccepted}
                    refreshIssues={getIssues}
                    subFilter={subFilter}
                    setIsRiskAccepted={setIsRiskAccepted}
                    sortField={sortField}
                    sortType={sortType}
                  />
                  <Pagination
                    page={page}
                    rowsPerPageOptions={10}
                    disabled={totalIssuesCount <= 1}
                    count={totalIssuesCount}
                    siblingCount={0}
                    onChange={(e, value) => setPage(value)}
                  />
                </Box>
              ) : (
                !loading && <NoData customText="No Issues Available." />
              )}
            </>
            {/* </div> */}
          </CardWrapper>
        </div>
      </BlockUi>
    </div>
  );
};

export default Datastore;
