import React, { useEffect } from "react";
import FormatUrl from "../../utils/UrlFormatter";
import axios from "axios";
import { Toaster, TOASTER_TYPES } from "../../utils/toaster";
import { useHistory } from "react-router-dom";

const SignUpCallback = () => {
  let history = useHistory();

  const signUpCallback = async () => {
    let url = FormatUrl(
      `/user/serviceProviderCallback${window.location.search}&login=false`
    );
    let res = await axios.get(url);
    res = res.data;

    if (res.message) {
      Toaster(TOASTER_TYPES.ERROR, res.message);
      history.push("/verify-email");
    } else {
      Toaster(0, res.err.message);
    }
    return;
  };

  useEffect(() => {
    signUpCallback();
  }, []);

  return <></>;
};

export default SignUpCallback;
