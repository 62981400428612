import { useEffect, useMemo, useState } from "react";
import Box from "../../components/common/Box";
import { useTheme } from "@mui/material";
import { Breadcrumbs, Link } from "../../components/common/Breadcrumbs";
import Typography from "../../components/common/Typography";
import Search from "../../components/common/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/common/Table";
import { useHistory } from "react-router-dom";
import NoData from "../../components/common/NoData";
import { capitalizeFirstLetter } from "../../utils/utils";
import { getFileName, getLoaderIcon } from "../../utils/commonUtils";
import ColorBadge from "../../components/common/ColorBadge";
import CustomPage from "../CustomPage";
import CustomTable from "../../components/common/CustomTable";
import { useDispatch } from "react-redux";
import { fetchImpactedDocumentsSlice } from "../../redux/slices/documentSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import Tooltip from "../../components/common/Tooltip";
import { AppDispatch } from "../../redux/store";

const DocumentsListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
    users: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    tableTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
    },
    font13: {
      fontSize: "13px",
    },
    userApps: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      paddingY: theme.spacing(1),
    },
    datastore: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
    },
    alignBaseline: {
      alignItems: "baseline",
    },
    truncateText: {
      whiteSpace: "nowrap",
      maxWidth: "500px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
    },
    tableBg: {
      backgroundColor: theme.palette.surface10.main,
    },
  };

  const fetchAllDocuments = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchImpactedDocumentsSlice(payload));
    if (response?.payload) {
      const docList: any = Object.keys(response?.payload?.data).map((key) => ({
        name: key,
        ...response?.payload?.data[key],
      }));
      setDocuments(docList);
      setDocumentList(docList);
    } else {
      setDocuments([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllDocuments();
  }, []);

  useEffect(() => {
    const filteredList = documents.filter((doc: { name: string }) =>
      doc?.name?.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setDocumentList(filteredList);
  }, [searchKeyword]);

  const breadcrumbs = [
    {
      isLink: true,
      name: "Home",
      link: "/",
    },
    {
      isLink: false,
      name: "Documents",
    },
  ];

  const tableCols = useMemo(
    () => [
      {
        id: 1,
        title: "Document",
        render: (data) => (
          <Tooltip title={data?.name}>
            <div>
              <Typography
                color={theme.palette.surface70.main}
                sx={{ ...styles.truncateText, ...styles.font13 }}
              >
                {getFileName(data?.name)}
              </Typography>
            </div>
          </Tooltip>
        ),
      },
      {
        id: 2,
        title: "Document Source",
        render: (data) => (
          <Box sx={styles.datastore}>
            <img
              src={getLoaderIcon(data?.loader_type)}
              alt="source"
              height={18}
            />
            <Typography sx={styles.font13} color={theme.palette.surface80.main}>
              {capitalizeFirstLetter(data?.loader_type)}
            </Typography>
          </Box>
        ),
      },
      {
        id: 3,
        title: "Snippets with Sensitive Content",
        render: (data) => (
          <Box sx={{ ...styles.datastore, ...styles.alignBaseline }}>
            <Typography variant="body2" color={theme.palette.surface80.main}>
              {data?.snippet_count?.sensitive?.count}
            </Typography>
            <Typography sx={styles.font13} color={theme.palette.surface50.main}>
              {`/ ${data?.snippet_count?.total}`}
            </Typography>
          </Box>
        ),
      },
      {
        id: 4,
        title: "Retrievals with Sensitive Data",
        render: (data) => {
          const count = data?.retrievals?.sensitive_count || 0;
          return (
            <Box sx={{ ...styles.datastore, ...styles.alignBaseline }}>
              {count ? <ColorBadge color="#DE2D31" /> : null}
              <Typography variant="body2" color={theme.palette.surface80.main}>
                {count}
              </Typography>
              <Typography
                sx={styles.font13}
                color={theme.palette.surface50.main}
              >
                {`/ ${data?.retrievals?.total}`}
              </Typography>
            </Box>
          );
        },
      },
    ],
    [documentList]
  );

  const gotoDocumentDetails = (doc) => {
    const docId = doc?.id?.length ? doc?.id[0] : "";
    if (docId) history.push(`/documents/${docId}`);
  };

  return (
    <CustomPage breadcrumbs={breadcrumbs}>
      <Box sx={styles.users}>
        <Box sx={styles.tableTitle}>
          <Typography>All Documents ({documentList?.length})</Typography>
          <Search
            placeholder="Search By Name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onClear={() => setSearchKeyword("")}
            onKeyDown={(e) => {}}
          />
        </Box>
        <Box sx={styles.tableBg}>
          <CustomTable
            tableCols={tableCols}
            tableData={documentList}
            onRowClick={gotoDocumentDetails}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </CustomPage>
  );
};

export default DocumentsListing;
