import React, { useState, useEffect } from "react";
import FormatUrl from "../../utils/UrlFormatter";
import { Toaster } from "../../utils/toaster";
import axios from "axios";
import LoginPageLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import LoginPageTitle from "../../assets/img/daxa-assets/daxa-title.svg";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { LOADER_CONFIG } from "../../utils/Constants";

const ResetPassword = () => {
  const [conformPasssword, setConformPasssword] = useState(null);
  const [password, setPassword] = useState(null);
  const [otp, setOtp] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    setOtp(query.get("confirmationCode"));
    setUserId(query.get("userId"));
  }, [query]);

  useEffect(() => {
    document.title = "Cloud Defense - Reset Password";
  }, []);

  const handleResetPassword = (ev) => {
    ev.preventDefault();

    if (!otp) {
      Toaster(4, "Otp required !");
      return;
    }
    if (!password) {
      Toaster(4, "Password required !");
      return;
    }
    if (!conformPasssword) {
      Toaster(4, "ConformPasssword required !");
      return;
    }
    if (password !== conformPasssword) {
      Toaster(4, "Password should match !");
      return;
    }

    setLoading(true);
    let url = FormatUrl(
      `/user/resetPassword?confirmationCode=${otp}&userId=${userId}`
    );

    axios
      .post(url, {
        password: password,
        confirmPassword: conformPasssword,
      })
      .then((res) => {
        setLoading(false);
        Toaster(1, "Password reset successfully!");
        window.location = "/";
      })
      .catch((err) => {
        setLoading(false);
        Toaster(
          2,
          err.response?.data?.err?.message || "Failed to reset password!"
        );
      });
  };

  return (
    <>
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }>
        <section className="sign-in-page">
          <div className="container p-0">
            <div className="logo-container">
              <div>
                <img
                  src={LoginPageLogo}
                  alt=""
                  className="login-from-logo"
                  height={48}
                />
              </div>
              <div>
                <img
                  src={LoginPageTitle}
                  alt=""
                  className="login-from-logo"
                  width={88}
                />
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-sm-12 align-self-center">
                <div className="sign-in-from">
                  <form className="mt-4" onSubmit={handleResetPassword}>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control mb-0"
                        id="exampleInputEmail1"
                        placeholder="Confirm password"
                        onChange={(e) => setConformPasssword(e.target.value)}
                      />
                    </div>
                    <div className="d-inline-block w-100">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BlockUi>
    </>
  );
};

export default ResetPassword;
