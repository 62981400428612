import { useEffect, useState } from "react";
import Button from "../common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import { TextField } from "../common/TextField";
import { useTheme } from "@mui/material";
import Typography from "../common/Typography";
import Box from "../common/Box";

interface AddDescriptionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  description: string;
}

const AddDescriptionDialog = (
  { isOpen, onClose, handleSubmit, description },
  props: AddDescriptionDialogProps
) => {
  const [isDescription, setIsDescription] = useState("");
  const [error, setError] = useState(false);
  const theme = useTheme();

  const styles = {
    textField: {
      ".MuiFormHelperText-root": {
        marginX: 0,
        textAlign: error ? "start" : "end",
      },
    },
    helperText: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  const handleDialogClose = () => {
    setError(false);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setIsDescription(description);
    }
  }, [isOpen, description]);

  const handleChange = (e) => {
    setIsDescription(e.target.value);
    if (e.target.value?.length > 350) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const getHelperText = () =>
    !error ? (
      <Typography variant="caption" color={theme.palette.surface50.main}>
        {isDescription?.length || 0}/350 Characters
      </Typography>
    ) : (
      <Box sx={styles.helperText}>
        <Typography variant="caption" color={theme.palette.critical.main}>
          Limit reached, please keep it within350 characters
        </Typography>
        <Typography variant="caption" color={theme.palette.critical.main}>
          {isDescription?.length}
          <Typography variant="caption" color={theme.palette.surface50.main}>
            /350 Characters
          </Typography>
        </Typography>
      </Box>
    );

  return (
    <Dialog open={isOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
      <DialogTitle handleClose={handleDialogClose}>
        {description ? "Update" : "Add"} Description
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          error={error}
          variant="outlined"
          placeholder="Briefly describe about the application "
          value={isDescription}
          multiline
          rows={4}
          helperText={getHelperText()}
          onChange={handleChange}
          sx={styles.textField}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button
          disabled={error}
          variant="contained"
          onClick={() => handleSubmit(isDescription)}
        >
          {description ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDescriptionDialog;
