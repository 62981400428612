import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DatastoreTicketModal from "../../modal/datastore-ticket-modal/DatastoreTicketModal";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import { deleteTicketAsync } from "../../../redux/slices/ticketSlice";
import { policyNotifyAsync } from "../../../redux/slices/policySlice";

import {
  LOADER_CONFIG,
  REACT_APP_DATASTORE_API,
} from "../../../utils/Constants";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
const PolicyDetails = ({ currentPosts, ticketDetail, getBulkTicketDetail }) => {
  const policy = JSON.parse(sessionStorage.getItem("policy"));
  const [ticketModal, setTicketModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const updatePolicy = (policy) => {
    sessionStorage.setItem("policy", JSON.stringify(policy));
    history.push("/settings/policy-settings/" + policy.id);
  };

  const deleteTicket = async (selectedAccount) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policy?.id ? policy?.id : null,
    };
    let resp = await dispatch(deleteTicketAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      getBulkTicketDetail();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const bulkNotify = async () => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policy?.id ? policy?.id : null,
    };
    let resp = await dispatch(policyNotifyAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      // getBulkTicketDetail();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
    >
      <div className="policy-details mb-4 p-3">
        <div className="row">
          <div className="col-4">
            <div className="row policy-details-section align-items-center">
              <div className="col col-md-12 col-lg-12  mb-2">
                <span className="policy-details-title">Policy Name</span>
                <br></br>
                <span className="policy-details-description">
                  {policy?.title}
                </span>
              </div>
              <div className="col col-md-12 col-lg-12 mt-2">
                <button
                  type="button"
                  className="btn modal-button-simple btn-primary m-1 mr-3"
                  onClick={() => {
                    updatePolicy(policy);
                  }}
                >
                  Edit Policy
                </button>
                {ticketDetail && ticketDetail?.ticketExist && (
                  <button
                    type="button"
                    onClick={() => {
                      window.open(ticketDetail.url);
                    }}
                    className="btn modal-button-simple btn-primary "
                  >
                    Ticket Details
                  </button>
                )}
                {ticketDetail && !ticketDetail?.ticketExist && (
                  <>
                    {policy?.actionType === "notify/archive" ? (
                      <button
                        type="button"
                        onClick={() => {
                          bulkNotify();
                        }}
                        disabled={
                          currentPosts.length > 0 &&
                          ticketDetail?.ticketIntegration !== false
                            ? false
                            : true
                        }
                        className="btn modal-button-simple btn-primary"
                      >
                        Bulk Notify
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setTicketModal(true);
                        }}
                        disabled={
                          currentPosts.length > 0 &&
                          ticketDetail?.ticketIntegration !== false
                            ? false
                            : true
                        }
                        className="btn modal-button-simple btn-primary"
                      >
                        Create Bulk Ticket
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="row">
              {policy?.description && policy?.description !== "null" && (
                <div className="col col-md-12 col-lg-12  mb-2">
                  <span className="policy-details-title">Description</span>
                  <br></br>
                  <span className="policy-details-description">
                    {policy?.description}
                  </span>
                </div>
              )}
              {policy?.remediation && policy?.remediation !== "null" && (
                <div className="col col-md-12 col-lg-12  mb-2">
                  <span className="policy-details-title">Remediation</span>
                  <br></br>
                  <span className="policy-details-description">
                    {policy?.remediation}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        {ticketModal && (
          <DatastoreTicketModal
            type="bulk"
            ticketDetail={ticketDetail}
            getBulkTicketDetail={getBulkTicketDetail}
            policyViolationId={policy.id}
            handleModal={() => setTicketModal(false)}
          />
        )}
      </div>
    </BlockUi>
  );
};

export default PolicyDetails;
