import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import { useEffect, useState } from "react";
import DatastoreTicketModal from "../../modal/datastore-ticket-modal/DatastoreTicketModal";
import { useHistory } from "react-router-dom";
import NoData from "../../common/NoData";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPolicyViolationsSlice } from "../../../redux/slices/frameworkPageSlice";
import Bar from "../../common/Bar";
import {
  convertUTCToLocal,
  getDateTime,
  getMaxValue,
} from "../../../utils/commonUtils";

export interface Props {
  barColor: string;
  dataDistribution: Array<{
    dataType: string;
    numberOfRecords: number;
    totalValue: number;
    impacted: number;
  }>;
  isFetchingData: boolean;
}

export const PolicyViolationDetailsCard = ({
  ticketIntegration,
  refreshPolicies,
}: {
  ticketIntegration: any;
  refreshPolicies?: any;
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketModalInfo, setTicketModalInfo] = useState<any>({});
  const [policyViolationDetails, setPolicyViolationDetails] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const objectId = params.id;
  const styles = {
    card: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
    },
    policyViolationDetailsCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    tableCellLast: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    tableCellAction: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
    },
    tableActionDivider: {
      height: "18px",
      margin: theme.spacing(0.26, 0),
      width: "1px",
      background: theme.palette.surface20.main,
    },
    moreIcon: {
      color: theme.palette.primaryBlue.main,
      width: "18px",
      cursor: "pointer",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface30.main,
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightLight,
      },
    },
    tableRow: {
      "&:last-child td, &:last-child th": { border: 0 },
    },
    tableBody: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      verticalAlign: "middle",
    },
    barCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      width: "80%",
      height: "100%",
    },
    cardHeader: {
      padding: theme.spacing(2),
    },
  };

  const redirectTo = (policy) => {
    sessionStorage.setItem(
      "policy",
      JSON.stringify({
        id: policy?.policyViolationId,
        title: policy?.policyViolationName,
        ...policy,
      })
    );
    history.push(`/datastore/policy-violation/${policy?.policyId}`);
  };

  const getPolicyViolationDetails = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      id: objectId,
    };
    const resp = await dispatch(fetchPolicyViolationsSlice(payload));
    if (resp?.payload?.length > 0) {
      setPolicyViolationDetails(resp?.payload);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPolicyViolationDetails();
  }, []);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.policyViolationDetailsCard}>
        <Box sx={styles.cardHeader}>
          <Typography variant="body2">Policy Violations</Typography>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow noHover={true}>
                <TableCell colSpan={2} sx={styles.tableHeader}>
                  Policy Type
                </TableCell>
                <TableCell sx={styles.tableHeader}>
                  Restricted Messages Count
                </TableCell>
                <TableCell sx={styles.tableHeader}>Last Violated</TableCell>
                <TableCell sx={styles.tableHeader}>Ticket Status</TableCell>
                {/*<TableCell sx={styles.tableHeader}>Actions</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {policyViolationDetails?.length > 0 ? (
                policyViolationDetails?.map((item) => (
                  <TableRow
                    //onClick={() => redirectTo(item)}
                    noHover={true}
                    //sx={styles.tableRow}
                  >
                    <TableCell colSpan={2} sx={styles.tableBody}>
                      {item?.title}
                    </TableCell>
                    <TableCell sx={styles.tableBody}>
                      <Box sx={styles.barCell}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface80.main}
                        >
                          {item.sensitiveMessageCount}
                        </Typography>
                        <Bar
                          isLastBar={false}
                          xLabel={""}
                          barBackColor={theme.palette.surface20.main}
                          barFaceColor={theme.palette.lightMagenta.main}
                          rectWidth={
                            (item?.sensitiveMessageCount /
                              getMaxValue(
                                "sensitiveMessageCount",
                                policyViolationDetails
                              )) *
                            100
                          }
                          index={3}
                          totalValue={4}
                          barLabel={""}
                          barValue={item?.sensitiveMessageCount}
                          type={""}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={styles.tableBody}>
                      {item?.lastViolated
                        ? getDateTime(convertUTCToLocal(item?.lastViolated))
                        : "-"}
                    </TableCell>
                    <TableCell sx={styles.tableBody}>
                      {item?.ticket_exist ? "Created" : "Not Created"}
                    </TableCell>
                    {/*<TableCell sx={styles.tableBody} width="15%">
                      <Box sx={styles.tableCellLast}>
                        <Box>
                          {item && item.tickets > 0 ? (
                            <TextButton
                              startIcon={<img src={JiraIcon} alt="jira" />}
                              sx={styles.tableCellAction}
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(item?.url ? item?.url : "#");
                              }}
                            >
                              View Ticket
                            </TextButton>
                          ) : (
                            <TextButton
                              startIcon={<img src={JiraIcon} alt="jira" />}
                              sx={styles.tableCellAction}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (ticketIntegration) {
                                  setTicketModal(true);
                                  setTicketModalInfo({
                                    policyViolationId: item.policyViolationId,
                                    datastoreId: item.datastoreId,
                                  });
                                }
                              }}
                            >
                              Create Ticket
                            </TextButton>
                          )}
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={styles.tableActionDivider}
                        />
                        <MoreVertIcon sx={styles.moreIcon} />
                      </Box>
                    </TableCell>*/}
                  </TableRow>
                ))
              ) : (
                <TableCell colspan="6" borderBottom={false}>
                  <NoData customText="No Data Found." />
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {ticketModal && (
        <DatastoreTicketModal
          type="policy"
          refreshDatastores={refreshPolicies}
          datastoreId={ticketModalInfo.datastoreId}
          policyViolationId={ticketModalInfo.policyViolationId}
          handleModal={() => {
            setTicketModal(false);
          }}
        />
      )}
    </Box>
  );
};
