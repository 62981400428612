import { useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "../../../common/Box";
import Checkbox from "../../../common/Checkbox";
import { Dialog, DialogContent, DialogTitle } from "../../../common/Dialog";
import NoData from "../../../common/NoData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../../common/Table";
import Typography from "../../../common/Typography";
import JiraSlackActionButtons from "../JiraSlackActionButtons";
import LoaderSnippetDetailsBlock from "../LoaderSnippetDetailsBlock";
import MessageDetailsBlock from "../MessageDetailsBlock";

const SnippetDetailsDialog = ({
  snippets,
  open,
  label,
  file,
  onClose,
  isDataLoader,
}) => {
  const theme = useTheme();
  const dataLoaderApp = useSelector((state: any) => state.dataLoader);

  const styles = {
    checkBox: {
      height: "min-content",
      alignSelf: "top",
    },
    tableBody: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: "transparent !important",
      },
    },
    dialogTitle: {
      width: "100%",
      fontSize: "20px",
    },
    dialogHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    tableCell: {
      "&.MuiTableCell-root": {
        borderBottom: "transparent",
        padding: "0px",
        paddingTop: theme.spacing(2),
      },
    },
    checkboxCell: {
      "&.MuiTableCell-root": {
        borderBottom: "transparent",
        padding: "0px",
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    colActionsCell: {
      "&.MuiTableCell-root": {
        padding: "0px",
      },
    },
    truncateFileName: {
      width: "100%",
      wordBreak: "break-all",
    },
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle handleClose={handleClose}>
        <Box sx={styles.dialogHeader}>
          <Typography sx={styles.dialogTitle}>
            Retrievals for '{label}' ({snippets?.length || 0})
          </Typography>
          {/*<Typography
            color={theme.palette.surface50.main}
            variant="body2"
            sx={styles.truncateFileName}
          >
            From {getFileName(file)}
          </Typography>*/}
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.colActionsCell}>
                  <JiraSlackActionButtons />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={styles.tableBody}>
              {snippets?.length > 0 ? (
                snippets.map((snippetItem) => (
                  <TableRow>
                    <TableCell sx={styles.tableCell}>
                      {isDataLoader ? (
                        <LoaderSnippetDetailsBlock data={snippetItem} />
                      ) : (
                        <MessageDetailsBlock data={snippetItem} />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    <NoData customText="No Data Found" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default SnippetDetailsDialog;
