import { useEffect, useMemo, useState } from "react";
import CardWithAction from "./CardWithAction";
import CustomTreeMap from "../common/CustomTreeMap";
import NoData from "../common/NoData";
import { useDispatch } from "react-redux";
import { getActiveUsersByRetrievalsSlice } from "../../redux/slices/userSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { TreeMapNode } from "./SnippetsByLabels";
import Box from "../common/Box";
import { useTheme } from "@mui/material";
import { AppDispatch } from "../../redux/store";
import RetrievalsByUserGroupsTooltip from "./treemap-tooltips/RetrievalsByUserGroupsTooltip";

const ActiveUsersByRetrievals = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const styles = {
    content: {
      paddingX: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  const [isLoading, setIsLoading] = useState(true);
  const [userGroups, setUserGroups] = useState<Array<TreeMapNode> | null>(null);

  const fetchUserByRetreivals = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(getActiveUsersByRetrievalsSlice(payload));
    if (response?.payload) {
      const userGroupsList = Object.keys(response.payload).map(
        (key, index) => ({
          id: `active-users-by-retreivals-${index}`,
          label: key,
          parent: "P1",
          value: response.payload[key]?.retrievals,
        })
      );
      setUserGroups([
        { id: "P1", value: 0, parent: "", label: "P1" },
        ...userGroupsList,
      ]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserByRetreivals();
  }, []);

  const cardData = useMemo(() => {
    return {
      activeUsers: {
        title: "Most Active User Groups by Retrievals",
        children: (
          <Box sx={styles.content}>
            {userGroups && userGroups?.length > 1 ? (
              <CustomTreeMap
                id="users-groups-by-retrievals"
                colorScale={() => "#3F466D"}
                data={userGroups}
                graphHeight={300}
                tooltipContent={(node) => (
                  <RetrievalsByUserGroupsTooltip node={node} />
                )}
              />
            ) : (
              <NoData customText="No Retrievals Data" />
            )}
          </Box>
        ),
      },
    };
  }, [userGroups]);

  return <CardWithAction isLoading={isLoading} cardData={cardData} />;
};

export default ActiveUsersByRetrievals;
