import { useEffect } from "react";
import LoginPageLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import LoginPageTitle from "../../assets/img/daxa-assets/daxa-title.svg";

const VerifyEmail = () => {
  useEffect(() => {
    document.title = "Cloud Defense - Registration Complete";
  }, []);
  return (
    <>
      <section className="sign-in-page">
        <div className="container p-0">
          <div className="logo-container">
            <div>
              <img
                src={LoginPageLogo}
                alt=""
                className="login-from-logo"
                height={48}
              />
            </div>
            <div>
              <img
                src={LoginPageTitle}
                alt=""
                className="login-from-logo"
                width={88}
              />
            </div>
          </div>
        </div>
        <h1 className="text-center text-primary mb-5">
          Thanks for your request. <br />
          We will notify you over email once your account is ready to use.
        </h1>
      </section>
    </>
  );
};

export default VerifyEmail;
