import MUIAccordion, { AccordionProps } from "@mui/material/Accordion";
import MUIAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MUIAccordionDetails, {
  AccordionDetailsProps,
} from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";

interface CustomAccordionProps extends AccordionProps {
  children: JSX.Element[] | JSX.Element;
  sx?: any;
}

interface CustomAccordionSummaryProps extends AccordionSummaryProps {
  children: JSX.Element;
  expandIcon?: JSX.Element;
  sx?: any;
}

interface CustomAccordionDetailsProps extends AccordionDetailsProps {
  children: JSX.Element;
  sx?: any;
}

export function Accordion({ children, sx, ...props }: CustomAccordionProps) {
  const styles = {
    accordion: {
      background: "none !important",
      borderBottom: "none",
      boxShadow: "none",
      width: "100%",
      "& .MuiAccordionDetails-root": {
        padding: "16px 0px 0px",
      },
    },
  };
  return (
    <MUIAccordion {...props} sx={{ ...styles.accordion, ...sx }}>
      {children}
    </MUIAccordion>
  );
}

export function AccordionSummary(
  {
    children,
    expandIcon = <ExpandMoreIcon />,
    sx,
  }: CustomAccordionSummaryProps,
  props
) {
  const theme = useTheme();
  const styles = {
    accordionSummary: {
      padding: 0,
      minHeight: "auto",
      justifyContent: "start",
      "&.Mui-expanded": {
        minHeight: "auto",
        margin: 0,
      },
      "& .MuiAccordionSummary-content": {
        margin: 0,
        flex: "none",
        width: "fit-content",
        "&.Mui-expanded": {
          margin: 0,
        },
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        "& .Mui-expanded": {
          minHeight: "auto",
        },
        svg: {
          color: theme.palette.surface50.main,
        },
      },
    },
  };
  return (
    <MUIAccordionSummary
      expandIcon={expandIcon}
      {...props}
      sx={{ ...styles.accordionSummary, ...sx }}
    >
      {children}
    </MUIAccordionSummary>
  );
}

export function AccordionDetails({
  children,
  sx,
  ...props
}: CustomAccordionDetailsProps) {
  return (
    <MUIAccordionDetails {...props} sx={sx}>
      {children}
    </MUIAccordionDetails>
  );
}
