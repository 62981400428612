import React from "react";
import deleteAccountIcon from "../../../assets/img/icons/delete-account-icon.svg";
import "./DeleteConfirmationModal.scss";
import loaderImage from "../../../assets/img/loader.svg";
import AcceptRisk from "../../../assets/img/icons/risk-accept.svg";
import Button from "../../common/Button";
import Box from "../../common/Box";
import { useTheme } from "@mui/material";
import Typography from "../../common/Typography";
// Functional component for delete integration.
const DeleteConfirmationModal = ({
  handleModal,
  deleteHandle,
  showLoader,
  modalData,
  image,
}) => {
  const theme = useTheme();
  const styles = {
    modalHeader: {
      // padding: theme.spacing(2),
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    modalFooter: {
      display: "flex",
      width: "100%",
      marginLeft: "auto",
      justifyContent: "end",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
  };

  const handleCancel = () => {
    handleModal(false);
  };

  return (
    <div id="delete-integration">
      <div
        className="modal fade show"
        id="deleteIntegration"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteIntegrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className={
                modalData.type === "accept"
                  ? "modal-header-accept d-flex justify-content-start align-items-center"
                  : "modal-header d-flex justify-content-start align-items-center"
              }
            >
              <Box sx={styles.modalHeader}>
                {/*<img
                src={
                  modalData?.type === "accept" ? AcceptRisk : deleteAccountIcon
                }
                width="40px"
                className="mr-3"
              />*/}
                <Typography
                  color={theme.palette.surface80.main}
                  variant="body2"
                >
                  {modalData?.title || "Delete "}
                </Typography>
              </Box>
            </div>
            <div className="modal-body">
              {showLoader && (
                <img src={loaderImage} className="modal-loading-img" />
              )}
              <div cassName="row">
                <Typography variant="body2">
                  {modalData?.message || "Are you sure, you want to delete?"}
                </Typography>
              </div>
            </div>
            <Box sx={styles.modalFooter}>
              <Button variant="contained" onClick={deleteHandle}>
                {modalData.type === "accept"
                  ? "Accept"
                  : modalData.type === "archive"
                  ? "Archive"
                  : "Delete"}
              </Button>
              <Button
                variant="outlined"
                disabled={showLoader}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
