import { useTheme } from "@mui/material";
import Box from "../../../components/common/Box";
import UserInfo from "./UserInfo";
import CustomPage from "../../CustomPage";
import UserRetrievals from "./UserRetrievals";

const UserDetails = () => {
  const theme = useTheme();
  const styles = {};

  const breadcrumbs = [
    {
      name: "Home",
      isLink: true,
      link: "/",
    },
    {
      name: "Active Users",
      isLink: true,
      link: "/users",
    },
    {
      name: "User Details",
      isLink: false,
    },
  ];

  return (
    <CustomPage breadcrumbs={breadcrumbs}>
      <UserInfo />
      <UserRetrievals />
    </CustomPage>
  );
};

export default UserDetails;
