import { Collapse, IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "../../components/common/Button";
import { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Dialog, DialogActions } from "../common/Dialog";
import { useDispatch } from "react-redux";
import {
  getAllPolicies,
  addPolicyAsync,
  fetchEntitiesSlice,
  fetchTopicsSlice,
  updatePolicyAsync,
} from "../../redux/slices/policySlice";
import { getCustomerId } from "../../utils/SessionHelper";
import {
  fetchLLMModelsListingSlice,
  fetchFrameworksSlice,
} from "../../redux/slices/dashboardSlice";
import Loader from "../common/Loader";
import DiscardChanges from "../common/DiscardChanges";
import SelectGroups from "./add-policy/SelectGroups";
import SemanticGuardrailConfig from "./add-policy/SemanticGuardrailConfig";
import ExceptionConfig from "./add-policy/ExceptionConfig";
import PolicyName from "./add-policy/PolicyName";
import { Step, StepContent, StepLabel, Stepper } from "../common/Stepper";
import Chip from "../common/Chip";
import TextButton from "../common/TextButton";
import StarIcon from "../../assets/img/starIcon.svg";

const frameworkType = "framework";
const llmType = "llm";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  isEdit: boolean;
  isOpen: boolean;
  handleClose: () => void;
  selectedAccount: any;
  fetchPolicies: () => {};
  buttonText?: string;
  dialogHeader?: string;
  policyDetails: any;
}

const allTopics = "All Topics";
const allFrameworks = "All Frameworks";
const allModels = "All Models";
const allEntities = "All Entities";

export const AddPolicy = ({
  isEdit,
  isOpen,
  handleClose,
  selectedAccount,
  fetchPolicies,
  buttonText,
  dialogHeader,
  policyDetails,
}: Props) => {
  const [policyCategories, setPolicyCategories] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [frameworks, setFrameworks] = useState<Array<string>>([]);
  const [models, setModels] = useState<Array<string>>([]);
  const [entities, setEntities] = useState<Array<string>>([]);
  const [topics, setTopics] = useState<Array<string>>([]);
  const [showTopicEntity, setShowTopicEntity] = useState("");
  const [onEditShowDiscard, setOnEditShowDiscard] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [addedAIAssist, setAddedAIAssist] = useState(false);

  const [isEmptyField, setIsEmptyField] = useState({
    topic: false,
    entity: false,
    framework: false,
    model: false,
    policy: false,
    name: false,
  });
  const [policyData, setPolicyData] = useState<{
    provider: string;
    name: string;
    isException: boolean;
    identities: Array<string>;
    semanticGuardrail: {
      entities: Array<string>;
      topics: Array<string>;
    };
  }>({
    provider: "",
    identities: [],
    name: "",
    isException: false,
    semanticGuardrail: {
      entities: [],
      topics: [],
    },
  });

  const theme = useTheme();
  const styles = {
    dialog: {
      display: "flex",
      flexDirection: "column",
      ".MuiPaper-root": {
        background: theme.palette.surface0.main,
      },
    },
    dialogHeader: {
      display: "flex",
      padding: 3,
      alignItems: "center",
    },
    backIcon: {
      color: theme.palette.surface50.main,
      fontSize: theme.spacing(3),
      width: theme.spacing(3),
    },
    dialogBody: {
      display: "flex",
      flex: 1,
      borderBottom: `0.5px solid ${theme.palette.surface20.main}`,
      paddingX: 7.5,
      paddingY: 2,
      flexDirection: "column",
      gap: theme.spacing(4.5),
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      width: "40%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    formHeading: {
      fontSize: "14px",
      opacity: 0.6,
    },
    aiChip: {
      backgroundColor: "transparent",
      color: theme.palette.surface60.main,
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
    stepLabel: {
      padding: 0,
      display: "flex",
      "& .MuiStepLabel-labelContainer": {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.surface10.main,
      },
    },
    stepContent: {
      paddingRight: 0,
      "& .MuiCollapse-root": {
        backgroundColor: theme.palette.surface10.main,
      },
    },
  };

  const getPolicyCategories = async (payload) => {
    setIsLoading(true);
    const res = await dispatch(getAllPolicies(payload));
    if (res.payload) {
      setPolicyCategories(
        res.payload.map((policy) => ({ ...policy, label: policy?.description }))
      );
    }
    setIsLoading(false);
  };

  const getAllEntities = async (payload) => {
    const res = await dispatch(fetchEntitiesSlice(payload));
    if (res.payload) {
      const entitiesList = res.payload?.map((entity) => entity?.name);
      setEntities([allEntities, ...entitiesList]);
    }
  };

  const getAllTopics = async (payload) => {
    const res = await dispatch(fetchTopicsSlice(payload));
    if (res.payload) {
      const topicList = res.payload?.map((topic) => topic?.name);
      setTopics([allTopics, ...topicList]);
    }
  };

  const steps = [
    {
      label: "Select Groups",
      component: (
        <SelectGroups
          selectedGroups={policyData.identities}
          setSelectedGroups={(newIdentities) =>
            setPolicyData((prevState) => ({
              ...prevState,
              identities: newIdentities,
            }))
          }
          identityProvider={policyData.provider}
          setIdentityProvider={(newProvider) =>
            setPolicyData((prevState) => ({
              ...prevState,
              provider: newProvider,
            }))
          }
        />
      ),
    },
    {
      label: "Semantic Guardrail Configuration",
      component: (
        <SemanticGuardrailConfig
          selectedLabels={policyData.semanticGuardrail}
          setPolicyData={setPolicyData}
          isAIAssist={true}
        />
      ),
      activeSubTitle: (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1),
              }}
            >
              Semantic Guardrail Configuration
              <Typography variant="body2" color={theme.palette.surface50.main}>
                Choose Topics and Entities to restrict for selected users
              </Typography>
            </Box>
            {/*<Button
              //disabled={addedAIAssist}
              variant="outlined"
              sx={{ marginLeft: "auto" }}
              onClick={() => {}}
            >
              <Chip
                sx={styles.aiChip}
                label="AI Assist"
                avatar={<img src={StarIcon} />}
              />
            </Button>*/}
          </Box>
          {addedAIAssist ? (
            <Box
              sx={{
                backgroundColor: "rgba(33, 144, 247, 0.2)",
                borderRadius: "4px",
                padding: theme.spacing(2),
                display: "flex",
                gap: theme.spacing(4),
                alignItems: "center",
              }}
            >
              <Chip
                sx={styles.aiChip}
                label="AI Assist"
                avatar={<img src={StarIcon} />}
              />
              <Typography
                variant="caption"
                color={theme.palette.surface100.main}
              >
                Based on the groups you selected, here are the recommended
                restricted entites and topics.
              </Typography>
              <TextButton onClick={() => {}}>Undo</TextButton>
            </Box>
          ) : null}
        </Box>
      ),
    },
    {
      label: "Exception Configuration",
      component: (
        <ExceptionConfig
          setPolicyExceptionConfig={() =>
            setPolicyData((prevState) => ({
              ...prevState,
              isException: !prevState.isException,
            }))
          }
          policyExceptionConfig={policyData.isException}
        />
      ),
    },
    {
      label: "Policy Name",
      component: (
        <PolicyName
          policyName={policyData?.name}
          setPolicyName={(newPolicyName) =>
            setPolicyData({ ...policyData, name: newPolicyName })
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (isOpen) {
      let payload = {
        customerId: getCustomerId(),
        cloudAccountId:
          selectedAccount.value === "all" ? null : selectedAccount.value,
      };
      getPolicyCategories(payload);
      //getAllModels(payload);
      //getAllFrameworks(payload);
      getAllEntities(payload);
      getAllTopics(payload);
    }
  }, [isOpen]);

  const validateInputFields = () => {
    const validationFlags = {
      name: !policyData?.name && policyData?.name === "",
    };
    //setIsEmptyField(false);
    return validationFlags;
  };

  const clearStateAndCloseDialog = () => {
    setPolicyData({
      name: "",
      provider: "",
      identities: [],
      isException: false,
      semanticGuardrail: {
        entities: [],
        topics: [],
      },
    });
    setShowDiscardDialog(false);
    setOnEditShowDiscard(false);
    setSelectedType("");
    setShowTopicEntity("");
    setIsEmptyField({
      topic: false,
      entity: false,
      framework: false,
      model: false,
      policy: false,
      name: false,
    });
    setActiveStep(0);
    handleClose();
  };

  const openDiscardChangesDialog = () => {
    if (
      !isEdit &&
      (policyData?.name ||
        policyData?.identities?.length ||
        policyData?.provider ||
        policyData?.semanticGuardrail?.entities?.length ||
        policyData?.semanticGuardrail?.topics?.length)
    ) {
      setShowDiscardDialog(true);
    } else if (isEdit && onEditShowDiscard) {
      setShowDiscardDialog(true);
    } else {
      clearStateAndCloseDialog();
    }
  };

  const handleStep = (index) => {
    setActiveStep(index);
  };

  const handleSubmit = async () => {
    const validationFlags = validateInputFields();
    if (Object.values(validationFlags).includes(true)) {
      return;
    } else {
      const firstName = localStorage.getItem("first_name");
      const lastName = localStorage.getItem("last_name");
      const payload = {
        customerId: getCustomerId(),
        name: policyData.name,
        type: "identity",
        userName: `${firstName} ${lastName}`,
        config: {
          userAndGroups: {
            provider: policyData.provider,
            identities: policyData.identities,
          },
          semanticGuardrail: policyData.semanticGuardrail,
          exception: {
            grantFullAccess: policyData.isException,
          },
        },
      };

      const res = await dispatch(addPolicyAsync(payload));
      if (res?.payload) {
        fetchPolicies();
      }
      clearStateAndCloseDialog();
    }
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      sx={styles.dialog}
      onClose={openDiscardChangesDialog}
    >
      <Box sx={styles.dialogHeader}>
        <IconButton onClick={openDiscardChangesDialog}>
          <ChevronLeftIcon sx={styles.backIcon} />
        </IconButton>
        <Typography variant="subtitle1" color={theme.palette.surface50.main}>
          {dialogHeader}
        </Typography>
      </Box>
      <Box sx={styles.dialogBody}>
        {isLoading ? (
          <Loader height="200px" />
        ) : (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} onClick={() => handleStep(index)}>
                <StepLabel sx={styles.stepLabel}>
                  {activeStep === index && step?.activeSubTitle
                    ? step.activeSubTitle
                    : step.label}
                </StepLabel>
                <StepContent
                  TransitionComponent={Collapse}
                  transitionDuration={2}
                  sx={styles.stepContent}
                >
                  {step.component}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={openDiscardChangesDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </DialogActions>
      <DiscardChanges
        open={showDiscardDialog}
        onClose={() => setShowDiscardDialog(false)}
        onSubmit={clearStateAndCloseDialog}
      />
    </Dialog>
  );
};
