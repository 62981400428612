import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Button from "../common/Button";
import { Dialog, DialogActions, DialogTitle } from "../common/Dialog";
import { TextField } from "../common/TextField";
import Typography from "../common/Typography";

const RevokeAPI = ({ open, onClose, onDelete }) => {
  const theme = useTheme();
  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      paddingRight: theme.spacing(15),
    },
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle handleClose={onClose}>Revoke API Key</DialogTitle>
      <Box sx={styles.dialogContent}>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          This API key will be instantly disabled, leading to rejection of API
          <br />
          requests made with it. Once revoked, you'll lose access to view <br />
          or modify this key.
        </Typography>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={() => onDelete()}>
          Revoke
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RevokeAPI;
