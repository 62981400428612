import { useState } from "react";
import Box from "../../common/Box";
import { TextField } from "../../common/TextField";
import Button from "../../common/Button";
import { useDispatch } from "react-redux";
import { addTopicSlice } from "../../../redux/slices/topicSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useTheme } from "@mui/material";
import { setAppPolicySlice } from "../../../redux/slices/appSlice";
import { AppDispatch } from "../../../redux/store";

const AddAppPolicy = ({ appId }) => {
  const [policyJson, setPolicyJson] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const styles = {
    container: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  };
  const addPolicy = () => {
    if (policyJson !== "") {
      const resp = dispatch(
        setAppPolicySlice({
          id: appId,
          customerId: getCustomerId(),
          policy: JSON.parse(policyJson),
        })
      );
      console.log(resp);
    }
  };
  return (
    <Box sx={styles.container}>
      <TextField
        label="Policy JSON"
        value={policyJson}
        onChange={(e) => setPolicyJson(e.target.value)}
      />
      <Button variant="contained" onClick={addPolicy}>
        Add Policy
      </Button>
    </Box>
  );
};

export default AddAppPolicy;
