import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormatUrl from "../../utils/UrlFormatter";
import axios from "axios";
import { Toaster } from "../../utils/toaster";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { EULA_URL, LOADER_CONFIG } from "../../utils/Constants";
import LoginPageLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import LoginPageTitle from "../../assets/img/daxa-assets/daxa-title.svg";
import MailIcon from "../../assets/img/email.svg";
import GoogleIcon from "../../assets/img/google.svg";
import { useHistory } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [company, setCompany] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleOrOkta, setGoogleOrOkta] = useState(null);
  const [disableRegister, setDisableRegister] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [step, setStep] = useState(1);
  const [securityType, setSecurityType] = useState("Gen-AI Security");
  const securityTypes = ["Data Security", "Gen-AI Security"];

  const history = useHistory();

  useEffect(() => {
    document.title = "Daxa - Register";
  }, []);

  const createTeam = (name) => {
    let url = FormatUrl(`/team/teams`);

    return axios.post(url, {
      name: name,
    });
  };

  const createTenant = (name, customerRef) => {
    let url = FormatUrl(`/tenant/tenants`);

    return axios.post(url, {
      name: "default",
      customerRef: customerRef,
    });
  };

  const validateGoogleLoginEmail = (email) => {
    let url = FormatUrl(`/user/getUserfromDbAndCognito`);
    return axios
      .post(url, {
        email: email,
      })
      .catch(function (error) {
        Toaster(2, "Account already exist!");
      });
  };

  const createCustomer = (name, plan, billing) => {
    let url = FormatUrl(`/customer/customer`);

    return axios.post(url, {
      securityType: securityType,
      name: company,
      plan: plan,
      billing: billing,
    });
  };

  const createContactUs = (name, email, company, message) => {
    let url = FormatUrl(`/email/contactUse`);

    return axios.post(url, {
      name: name,
      email: email,
      company: company,
      message: message,
    });
  };

  const createUser = async (
    firstname,
    lastname,
    email,
    customerRef,
    tenantRef,
    teamRef,
    googleOrOkta
  ) => {
    let url = FormatUrl(`/user/register`);
    return axios
      .post(url, {
        first_name: firstname,
        last_name: lastname,
        email: email,
        customerRef: customerRef,
        tenantRef: tenantRef,
        teamRef: teamRef,
        googleOrOkta: googleOrOkta,
      })
      .catch(function (error) {
        if (error.response) {
          setLoading(false);
          Toaster(
            2,
            error.response?.data?.err?.message || "Failed to register user"
          );
        } else {
          Toaster(2, "Failed to register user");
        }
      });
  };

  const emailRegexMatch = (mail) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(mailformat)) {
      return true;
    }
    return false;
  };

  const alphaNumericRegexMatch = (text) => {
    let textFormat = "^[a-zA-Z0-9 ]+$";
    if (text.match(textFormat)) {
      return true;
    } else {
      return false;
    }
  };

  const validateCompanyName = (text) => {
    let textFormat = "^[a-zA-Z0-9 _-]+$";
    if (text.match(textFormat)) {
      return true;
    } else {
      return false;
    }
  };

  const handleRegister = async (ev) => {
    ev.preventDefault();
    if (!firstName) {
      Toaster(4, "First name required!");
      return;
    }
    if (firstName && !alphaNumericRegexMatch(firstName)) {
      Toaster(4, "Invalid first name!");
      return;
    }

    if (lastName && !alphaNumericRegexMatch(lastName)) {
      Toaster(4, "Invalid last name!");
      return;
    }

    if (company && !validateCompanyName(company)) {
      Toaster(4, "Invalid company name!");
      return;
    }

    if (!lastName) {
      Toaster(4, "Last name required!");
      return;
    }
    if (!email) {
      Toaster(4, "email required!");
      return;
    }
    if (!emailRegexMatch(email)) {
      Toaster(4, "Invalid email format!");
      return;
    }
    if (!email.includes(".")) {
      Toaster(4, "Invalid email format!");
      return;
    }

    if (!company) {
      Toaster(4, "company required!");
      return;
    }

    const teamRes = await createTeam(company);
    const teamRef = teamRes.data.insertedId;

    const customerRes = await createCustomer(firstName, "TRIAL", "TRIAL");
    const customerRef = customerRes.data.insertedId;

    const tenantRes = await createTenant(company, customerRef);
    const tenantRef = tenantRes.data.insertedId;

    setLoading(true);
    const userRes = await createUser(
      firstName,
      lastName,
      email,
      customerRef,
      tenantRef,
      teamRef,
      googleOrOkta
    );

    const userRef = userRes.data;

    setLoading(false);

    if (userRef.status) {
      await createContactUs(name, email, company, message);
      Toaster(1, "Profile created. We will notify over email !");
      history.push("/verify-email");
      return;
    } else {
      Toaster(1, "Profile created. We will notify over email !");
      return;
    }
  };

  const validateEmail = async () => {
    if (!email) return;
    let url = FormatUrl(`/user/getUserfromDbAndCognito`);

    await axios
      .post(url, {
        email: email,
      })
      .catch(function (error) {
        setDisableRegister(true);
        Toaster(2, "Account already exist!");
      });
  };

  const googleLogin = () => {
    window.location = FormatUrl(`/user/signupToGoogle`);
  };

  return (
    <>
      <BlockUi
        tag="div"
        blocking={loading}
        loader={
          <Loader
            active
            type={LOADER_CONFIG.type}
            color={LOADER_CONFIG.color}
          />
        }>
        <section className="sign-in-page">
          <div className="container p-0">
            <div className="logo-container">
              <div>
                <img
                  src={LoginPageLogo}
                  alt=""
                  className="login-from-logo"
                  height={48}
                />
              </div>
              <div>
                <img
                  src={LoginPageTitle}
                  alt=""
                  className="login-from-logo"
                  width={88}
                />
              </div>
            </div>

            <div className="row no-gutters">
              <div className="col-sm-12 align-self-center">
                <div className="sign-in-from">
                  <form onSubmit={handleRegister} autocomplete="off">
                    {step === 2 && (
                      <div className="flex">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control mb-0"
                            id="exampleInputEmail1"
                            placeholder="* First name"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="form-group ml-3">
                          <input
                            type="text"
                            className="form-control mb-0"
                            id="exampleInputEmail1"
                            placeholder="* Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="* Email"
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={validateEmail}
                        />
                      </div>
                    )}

                    {step === 2 && (
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="* Company"
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </div>
                    )}

                    {step === 2 && (
                      <div className="form-group">
                        <select
                          className="form-control mb-0"
                          value={securityType}
                          onChange={(e) => setSecurityType(e.target.value)}>
                          {securityTypes.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="form-group">
                        <textarea
                          className="form-control mb-0"
                          placeholder="Message"
                          onChange={(e) =>
                            setMessage(e.target.value)
                          }></textarea>
                      </div>
                    )}

                    {step === 1 && (
                      <div
                        onClick={() => setStep(2)}
                        className="d-inline-block w-100 login-button">
                        <img src={MailIcon} className="mail-icon" />
                        Sign up with Email
                      </div>
                    )}

                    {step === 1 && (
                      <div
                        onClick={googleLogin}
                        className="d-inline-block w-100 mt-3 mb-3 login-button">
                        <img src={GoogleIcon} className="google-icon" />
                        Sign up with Google
                      </div>
                    )}

                    {step === 1 && (
                      <div className="form-group mt-3 mb-5">
                        <h6>
                          <label
                            htmlFor="terms"
                            className="mb-0 mt-0 position-absolute terms">
                            By Clicking signing up, you acknowledge that you
                            have <br />
                            read and agree to our{" "}
                            <a
                              href={EULA_URL}
                              target="_blank"
                              className="eula-url">
                              EULA
                            </a>
                          </label>
                        </h6>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="d-inline-block w-100">
                        <button type="submit" className="btn btn-primary">
                          FREE TRIAL
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <p className="text-center">
              Already a customer ? <Link to="/">LOGIN</Link>
            </p>
          </div>

          <br />
        </section>
      </BlockUi>
    </>
  );
};

export default Register;
