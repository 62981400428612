import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { InstanceDetailsCard } from "./InstanceDetailsCard";
import { PolicyViolationDetailsCard } from "./PolicyViolationDetailsCard";
import { SensitiveDataDetailsCard } from "./SensitiveDataDetailsCard";
import { LLMDetailsCard } from "./LLMDetailsCard";
import { useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { getCustomerId } from "../../../utils/SessionHelper";
import { axiosDenaliPrivate } from "../../../utils/ApiHandler";
import { useParams } from "react-router-dom";
import { trainingDataList } from "../../shadow-llm/training_data";
import { useDispatch } from "react-redux";
import {
  listDataStoreTagsAsync,
  updateDataStoreAsync,
  fetchLineageGraphDataSlice,
} from "../../../redux/slices/dataStoreSlice";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import ManageTagsModal from "../../../components/modal/manage-tags-modal/ManageTagsModal";
import { getPolicyStatsAsync } from "../../../redux/slices/policySlice";
import { fetchLLMDetailsSlice } from "../../../redux/slices/dataStoreSlice";
import { Tab, TabPanel, Tabs } from "../../common/Tabs";
import Button from "../../common/Button";
import { ToggleButtonGroup } from "../../common/ToggleButton";
import TrainingDataGraphView from "../../charts/llm-charts/training-data-graph/TrainingDataGraphView";
import TrainingDataGraphLineageView from "../../charts/llm-charts/training-data-linear-graph/TrainingDataGraphLineageView";
import Checkbox from "../../common/Checkbox";
import TranningDataOrchestratorGraph from "../../charts/llm-charts/training-data-orchestrator/TrainingDataOrchestratorGraph";
import { AppContext } from "../../../context/appContext";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  LineageGraphProps,
  ModelDetailsProps,
  TrainingDataProps,
} from "../../../types/llmModelPage/llmModelPage.types";
import { SelectedAccountType } from "../../../types/type";
import NoData from "../../common/NoData";

export const LLMModelPage = ({
  selectedAccount = {
    value: "all",
  },
  setPath,
}: {
  selectedAccount: SelectedAccountType;
  setPath: (e) => void;
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [datastore, setDatastore] = useState<ModelDetailsProps>(
    {} as ModelDetailsProps
  );
  const [datastoreType, setDatastoreType] = useState<any>(null);
  const [trainingData, setTrainingData] = useState<any>([]);
  const [launchButtonTitle, setLaunchButtonTitle] = useState("Launch AIScan");
  const [datastorePolicy, setDatastorePolicy] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [isManaged, setIsManaged] = useState(false);
  const [moreTags, setMoreTags] = useState<any>([]);
  const [isManageTagsModal, setIsManageTagsModal] = useState(false);
  const datastoreId = params.id?.replaceAll("_", "/");
  const assetId = params.assetId?.replaceAll("_", "/");
  const ref = useRef<any>(null);
  const [width, setWidth] = useState<number>(0);
  const [lineageGraphData, setLineageGraphData] = useState<
    Array<LineageGraphProps>
  >([]);
  const [
    policyViolationsMaliciousActivity,
    setpolicyViolationsMaliciousActivity,
  ] = useState(false);
  const [policyViolationsSensitiveData, setPolicyViolationsSensitiveData] =
    useState(false);
  const [trainingDataGraphView, setTrainingDataGraphView] = useState(false);
  const [graphLineageView, setGraphLineageView] = useState(true);
  const [trainingDataFilter, setTrainningDataFilter] = useState("all");
  const policyViolationId = params?.policyViolationId;
  const theme = useTheme();
  const { dataStoreViolation } = useContext(AppContext);

  const llmData = dataStoreViolation?.find(
    (item) => item?.type === "Under Finetuning"
  );
  const isFineTuned = llmData?.data?.find(
    (item) => item?.datastoreId === datastoreId
  );

  const styles = {
    pageLayout: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    headerSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    headerSectionTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerSectionTitleValue: {
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightRegular,
    },
    headerSectionDescription: {
      display: "flex",
      gap: theme.spacing(1),
    },
    headerSectionDescriptionBody: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
    },
    pageDetailsSection: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    llmDetailsSection: {
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      gap: theme.spacing(2),
    },
    cardContainer: {
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      marginY: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
    cardTitle: {
      fontSize: "13px",
    },
    cardHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    cardContent: {},
    cardControls: {
      display: "flex",
      justifyContent: "space-between",
    },
    graphFooter: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    graphCheckbox: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    greenCheckbox: {
      color: theme.palette.success.main,
      "&.Mui-checked": {
        color: theme.palette.success.main,
      },
    },
    purpleCheckbox: {
      color: theme.palette.lightPurple.main,
      "&.Mui-checked": {
        color: theme.palette.lightPurple.main,
      },
    },
    blueCheckbox: {},
    launchAIScanBtn: {
      marginRight: 3,
    },
  };

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  const getDatastore = async () => {
    setLoading(true);
    const payload = {
      id: datastoreId,
      // assetId: assetId,
      // customerId: getCustomerId(),
    };
    let res;
    res = await dispatch(fetchLLMDetailsSlice(payload));
    if (Object.keys(res.payload)?.length > 0) {
      setDatastore(res.payload);
      // setDatastoreType(res.data.datastoreType);

      // setTrainingData([
      //   ...res?.data?.llmInfo?.TrainingData,
      //   ...trainingDataList,
      // ]);
      // setTrainingData(trainingDataList);
      // setTrainingData(res?.data?.llmInfo?.TrainingData);
    } else {
      setDatastore({} as ModelDetailsProps);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (datastore?.datastoreType === "managed") {
  //     setIsManaged(true);
  //     setTrainingDataGraphView(true);
  //     setGraphLineageView(true);
  //   } else {
  //     setIsManaged(false);
  //   }
  // }, [datastore?.datastoreType]);

  const getLineageGraphData = async () => {
    const res = await dispatch(
      fetchLineageGraphDataSlice({
        customerId: getCustomerId(),
        modelId: assetId,
      })
    );
    if (res.payload) {
      setLineageGraphData(res.payload[0]?.lineageGraph);
    } else {
      setLineageGraphData([]);
    }
  };

  const getPolicyStats = async () => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
      datastoreId: datastoreId,
      policyViolationId: policyViolationId ? policyViolationId : null,
    };
    let resp = await dispatch(getPolicyStatsAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setDatastorePolicy(resp.payload);
    } else {
      setDatastorePolicy([]);
    }
  };

  const launchAIScan = () => {
    setLaunchButtonTitle("Loading Model");
    setTimeout(() => {
      setLaunchButtonTitle("Running AIScan");

      setTimeout(() => {
        setLaunchButtonTitle("Launch AIScan");
        let launchObj = {
          FileName: "",
          source: "AIScan",
        };
        launchObj["sensitive labels"] = "PII, PHI, PCI";
        if (trainingData.length > 0) {
          setTrainingData([...trainingData, launchObj]);
        } else {
          setTrainingData([launchObj]);
        }
      }, 60000);
    }, 60000);
  };

  // const getSensitiveData = async () => {
  //   const payload = {
  //     id: datastoreId,
  //     customerId: getCustomerId(),
  //   };
  //   let resp = await dispatch(fetchDatastoreDescriptionAsync(payload));
  //   if (resp && resp.payload && !resp.payload.err) {
  //     setDatastoreDescriptions(
  //       resp?.payload?.filter((element) => element?.Automated !== "Manual")
  //     );
  //   }
  // };

  const refreshDataStoreTags = async () => {
    const res = await dispatch(
      listDataStoreTagsAsync({
        customerId: getCustomerId(),
        cloudAccountId: datastore?.cloudAccountId || "",
        datastoreId: datastoreId,
      })
    );
    let tagList: any = [];
    if (res && res.payload && !res.payload.err) {
      // Adding sensitive tag
      if (res.payload?.customerSensTag) {
        tagList.push({
          value: res?.payload?.customerSensTag,
          label: res?.payload?.customerSensTag,
          type: "sensitive",
        });
      }
      if (res.payload?.customerTags) {
        // Adding custom tag
        res.payload.customerTags.map((tag) => {
          if (
            tag.toLowerCase() === "us_access_only" ||
            tag.includes("us access only") ||
            tag.includes("us-access-only")
          ) {
            // props.setIsUSOnlyAccess(true);
          }

          tagList.push({
            value: tag,
            label: tag,
            type: "custom",
          });
        });
      }
    }
    setTags(tagList);
  };

  const refreshPolicies = () => {
    getPolicyStats();
  };

  const toggleButtons = [
    {
      id: "graph",
      label: "Graph View",
      icon: <InsertChartOutlinedIcon fontSize="inherit" />,
    },
    {
      id: "table",
      label: "Table View",
      icon: <TableRowsOutlinedIcon fontSize="inherit" />,
    },
  ];

  const headerToggleButtons = [
    {
      id: "shadow",
      label: "Shadow",
      icon: "",
    },
    {
      id: "sanctioned",
      label: "Sanctioned",
      icon: "",
    },
  ];

  useEffect(() => {
    getDatastore();
    setTrainingDataGraphView(true);
    setGraphLineageView(true);
    // getSensitiveData();
    // getPolicyStats();
  }, []);

  useEffect(() => {
    const widthValue: any = ref?.current?.offsetWidth - 200;
    setWidth(widthValue);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getWidth);
    // return () => {
    //   removeSelectedDataFlow();
    // };
  }, []);

  const getWidth = () => {
    setWidth(ref?.current?.clientWidth - 200);
  };

  useEffect(() => {
    if (datastore && datastore.cloudAccountId) {
      refreshDataStoreTags();
    }
    getLineageGraphData();
  }, [datastore]);

  const updateDataStore = async (type_) => {
    if (!type_) return;

    const resp = await dispatch(
      updateDataStoreAsync({
        datastoreType: type_,
        customerId: getCustomerId(),
        datastoreId: datastoreId,
      })
    );
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, "Datastore Type updated successfully.");
      getDatastore();
    } else {
      Toaster(TOASTER_TYPES.SUCCESS, "Datastore Type updated successfully.");
      getDatastore();
      // Toaster(
      //   TOASTER_TYPES.ERROR,
      //   resp.payload?.err?.message || "Failed to add account."
      // );
      // handleModal(false);
    }
  };

  const constructLineageGraphData = () => {
    let trainingData: Array<TrainingDataProps> = [];
    lineageGraphData?.map((item: LineageGraphProps) => {
      trainingData.push.apply(trainingData, item?.trainingDataDetails);
    });
    let mapObj: any = new Map();

    trainingData.forEach((v: TrainingDataProps) => {
      let prevValue = mapObj.get(v.sourceName);
      if (!prevValue) {
        mapObj.set(v.sourceName, v);
      }
    });
    return [...mapObj.values()];
  };

  return (
    <Box sx={styles.pageLayout}>
      <Box sx={styles.headerSection}>
        <Box sx={styles.headerSectionTitle}>
          {/*<img src={GlobeIcon} height="19" width="19" alt="globe" />*/}
          <Box>
            <Typography sx={styles.headerSectionTitleValue}>
              {datastore?.name}
            </Typography>
            {datastore?.model_hash && (
              <Box sx={styles.headerSectionDescription}>
                <Typography sx={styles.headerSectionDescriptionBody}>
                  Model Hash
                </Typography>
                <Typography sx={styles.headerSectionDescriptionBody}>
                  {datastore?.model_hash}
                </Typography>
              </Box>
            )}
          </Box>
          {/* {datastoreType !== "managed" && (
            <ToggleButtonGroup
              exclusive
              options={headerToggleButtons}
              value={
                datastoreType === "shadow"
                  ? headerToggleButtons[1]?.label
                  : headerToggleButtons[0]?.label
              }
              onChange={() => {
                updateDataStore(
                  datastoreType === "shadow"
                    ? "self-managed"
                    : datastoreType === "self-managed"
                    ? "shadow"
                    : null
                );
              }}
            />
          )} */}
        </Box>
      </Box>
      <Box sx={styles.pageDetailsSection}>
        {!loading && Object.keys(datastore).length > 0 ? (
          <Box sx={styles.llmDetailsSection}>
            <LLMDetailsCard
              llmDetails={datastore}
              tags={tags}
              moreTags={[]}
              onClick={() => setIsManageTagsModal(true)}
            />
            <InstanceDetailsCard
              isManaged={isManaged}
              instanceDetails={datastore}
              isFineTuned={isFineTuned}
            />
            <SensitiveDataDetailsCard
              sensitiveDetails={datastore?.sensitive_data}
            />
          </Box>
        ) : (
          <NoData customText="No data found." />
        )}
        <Box sx={styles.cardContainer}>
          <Box sx={styles.cardContent}>
            <div ref={ref}>
              <Box sx={styles.cardControls}>
                <Box sx={styles.cardHeader}>
                  <Typography
                    color={theme.palette.surface80.main}
                    sx={styles.cardTitle}>
                    Training Data
                  </Typography>
                  {(datastore?.name === "falcon-7b-bf16" ||
                    datastore?.name === "gpt2") && (
                    <>
                      <ToggleButtonGroup
                        exclusive
                        options={toggleButtons}
                        value={
                          trainingDataGraphView ? "Graph View" : "Table View"
                        }
                        onChange={(event, newValue) => {
                          if (newValue === "Graph View") {
                            setTrainingDataGraphView(true);
                            if (!isManaged) {
                              setGraphLineageView(false);
                            }
                          } else setTrainingDataGraphView(false);
                        }}
                      />
                    </>
                  )}
                </Box>
                <Box sx={styles.launchAIScanBtn}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      launchButtonTitle === "Launch AIScan" && launchAIScan();
                    }}
                    disabled={launchButtonTitle !== "Launch AIScan"}>
                    {launchButtonTitle}
                  </Button>
                </Box>
              </Box>
              <Box>
                {trainingDataGraphView && (
                  <Tabs
                    value={graphLineageView ? "lineageView" : "graphView"}
                    onChange={(event, newValue) => {
                      setGraphLineageView(newValue !== "graphView");
                    }}>
                    <Tab
                      value="lineageView"
                      label="Lineage View"
                      key="lineageView"
                      // disabled={!isManaged}
                    />
                    <Tab value="graphView" label="Graph View" key="graphView" />
                  </Tabs>
                )}
              </Box>
              {!trainingDataGraphView ? (
                <div className="mt-2 table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Location</th>
                        <th>Size</th>
                        <th>Type</th>
                        <th>Source</th>
                        <th>Sensitive Labels</th>
                      </tr>
                    </thead>
                    {trainingData && (
                      <tbody>
                        {trainingData?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}>
                                {item.FileName}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}>
                                {item.Location}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}>
                                {item.size}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}>
                                {item.type}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}>
                                {item?.Source || "Configured"}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                variant="caption"
                                color={theme.palette.surface60.main}>
                                {item?.["sensitive labels"] ||
                                  item?.sensitive_labels}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              ) : trainingDataGraphView ? (
                <Box sx={{ height: "328px" }}>
                  <TabPanel
                    value={graphLineageView ? "lineageView" : "graphView"}
                    index="lineageView"
                    key="lineageView">
                    {lineageGraphData?.length > 0 ? (
                      <TrainingDataGraphLineageView
                        trainingData={constructLineageGraphData()}
                        llmInfo={lineageGraphData}
                        trainingDataFilter={trainingDataFilter}
                        datastoreId={datastoreId}
                        width={width}
                        modelName={datastore?.name}
                      />
                    ) : (
                      <NoData customText="No graph data found." />
                    )}
                  </TabPanel>
                  <TabPanel
                    value={graphLineageView ? "lineageView" : "graphView"}
                    index="graphView"
                    key="graphView">
                    <TrainingDataGraphView
                      policyViolationsMaliciousActivity={
                        policyViolationsMaliciousActivity
                      }
                      policyViolationsSensitiveData={
                        policyViolationsSensitiveData
                      }
                      trainingData={trainingDataList}
                      llmInfo={datastore?.llmInfo?.llmInformation}
                      trainingDataFilter={trainingDataFilter}
                      datastoreId={datastoreId}
                      width={width}
                    />
                  </TabPanel>
                </Box>
              ) : (
                <TranningDataOrchestratorGraph
                  trainingData={trainingDataList?.filter(
                    (data) => data.Source === "Configured"
                  )}
                  llmInfo={datastore?.llmInfo?.llmInformation}
                  trainingDataFilter={trainingDataFilter}
                  datastoreId={datastoreId}
                  width={width}
                />
              )}
              {trainingDataGraphView &&
                !graphLineageView &&
                datastore?.llmInfo?.llmInformation?.Name ===
                  "falcon-7b-bf16" && (
                  <Box sx={styles.graphFooter}>
                    <Box sx={styles.graphCheckbox}>
                      <Checkbox
                        label="all"
                        sx={styles.blueCheckbox}
                        checked={trainingDataFilter === "all"}
                        onClick={() => setTrainningDataFilter("all")}
                      />
                      <Typography variant="caption">All</Typography>
                    </Box>
                    <Box sx={styles.graphCheckbox}>
                      <Checkbox
                        label="configured"
                        sx={styles.blueCheckbox}
                        checked={trainingDataFilter === "Configured"}
                        onClick={() => setTrainningDataFilter("Configured")}
                      />
                      <Typography variant="caption">Configured</Typography>
                    </Box>
                    <Box sx={styles.graphCheckbox}>
                      <Checkbox
                        label="accessPath"
                        sx={styles.purpleCheckbox}
                        checked={trainingDataFilter === "Access Path"}
                        onClick={() => setTrainningDataFilter("Access Path")}
                      />
                      <Typography variant="caption">Access Path</Typography>
                    </Box>
                    <Box sx={styles.graphCheckbox}>
                      <Checkbox
                        label="liveAccess"
                        sx={styles.greenCheckbox}
                        checked={trainingDataFilter === "Live Access"}
                        onClick={() => setTrainningDataFilter("Live Access")}
                      />
                      <Typography variant="caption">Live Access</Typography>
                    </Box>
                  </Box>
                )}
            </div>
          </Box>
        </Box>
        {datastorePolicy?.policyViolations?.length > 0 && (
          <PolicyViolationDetailsCard
            ticketIntegration={datastorePolicy?.ticketIntegration}
            refreshPolicies={refreshPolicies}
          />
        )}
      </Box>
      {isManageTagsModal && (
        <ManageTagsModal
          tags={tags}
          handleModal={() => setIsManageTagsModal(false)}
          datastoreId={datastoreId}
          cloudAccountId={datastore?.cloudAccountId}
        />
      )}
    </Box>
  );
};
