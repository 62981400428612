import { useTheme } from "@mui/material";
import { useMemo } from "react";
import Box from "../../../common/Box";
import Divider from "../../../common/Divider";
import MetricBlock from "../../../common/MetricBlock";
import Typography from "../../../common/Typography";

const DataLoaderOverview = ({ overview }) => {
  const theme = useTheme();
  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      backgroundColor: theme.palette.surface10.main,
      padding: theme.spacing(2),
      borderRadius: "4px",
    },
    cardContent: {
      display: "flex",
      gap: theme.spacing(6),
    },
    fontWeight300: {
      fontWeight: 300,
    },
  };

  const metricsList = useMemo(() => {
    return [
      {
        id: 0,
        value: overview?.findings || 0,
        label: "Findings",
        isCritical: false,
        outOf: 0,
      },
      {
        id: 1,
        value: overview?.topic_count || 0,
        label: "Topics Blocked",
        isCritical: overview?.topic_count,
        outOf: 0,
      },
      {
        id: 2,
        value: overview?.entity_count || 0,
        label: "Entities Blocked",
        isCritical: overview?.entity_count,
        outOf: 0,
      },
    ];
  }, [overview]);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardContent}>
        <MetricBlock
          value={overview?.documents_with_findings || 0}
          outOf={overview?.total_documents || 0}
          isCritical={false}
          label="Documents With Findings"
          subText={`Across ${overview?.total_datasource || 0} Datasource`}
        />
        <Divider orientation="vertical" flexItem />
        {metricsList.map((metric) => (
          <MetricBlock {...metric} key={metric.id} />
        ))}
      </Box>
    </Box>
  );
};

export default DataLoaderOverview;
