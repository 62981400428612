import { SyntheticEvent } from "react";
import Box from "../../common/Box";
import { Tab, Tabs } from "../../common/Tabs";

interface Props {
  tabs: { value: string; label: JSX.Element | string; filter?: string }[];
  activeTab: string;
  onTabChange: (event: Event | SyntheticEvent, newValue: string) => void;
}

const LLMTabs = ({ tabs, activeTab, onTabChange }: Props) => {
  return (
    <Tabs value={activeTab} onChange={onTabChange}>
      {tabs.map((tab) => (
        <Tab {...tab} key={tab.value} />
      ))}
    </Tabs>
  );
};

export default LLMTabs;
