import { axiosPrivate } from "../../utils/ApiHandler";
import { getCustomerId } from "../../utils/SessionHelper";

/**
 * get all API Keys
 * @returns
 */
export const getAPIKeys = () => {
  return axiosPrivate.get(`/api/v1/apiKey/${getCustomerId()}`);
};

/**
 * add a new API key
 * @param params
 * @returns
 */
export const addAPIKey = (params) => {
  return axiosPrivate.post("/api/v1/apiKey", params);
};

/**
 * delete API key
 * @param params
 * @returns
 */
export const deleteAPIKey = (params) => {
  return axiosPrivate.delete(`/api/v1/apiKey/${params}`);
};
