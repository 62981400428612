import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getActiveUsers,
  getActiveUsersByRetrievals,
  getUserDetails,
  getUserGroups,
  getUserInfo,
} from "../apis/userAPI";

const initialState = {
  users: null,
  selectedUserRetrievals: null,
};

export const fetchActiveUsersSlice = createAsyncThunk(
  "user/fetch",
  async (params: { customerId: string; count?: number }) => {
    const response = await getActiveUsers(params);
    return response.data;
  }
);

export const fetchUserGroupsSlice = createAsyncThunk(
  "user/getUserGroups",
  async (params: { customerId: string }) => {
    const response = await getUserGroups(params);
    return response.data;
  }
);

export const getActiveUsersByRetrievalsSlice = createAsyncThunk(
  "user/getActiveUsersByRetrievals",
  async (params: { customerId: string }) => {
    const response = await getActiveUsersByRetrievals(params);
    return response.data;
  }
);

export const getUserInfoSlice = createAsyncThunk(
  "user/info",
  async (params: { customerId: string; name: string }) => {
    const response = await getUserInfo(params);
    return response.data;
  }
);

export const getUserRetrievals = createAsyncThunk(
  "user/details",
  async (params: { customerId: string; name: string }) => {
    const response = await getUserDetails(params);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActiveUsersSlice.fulfilled, (state, action) => {
      const userList = action?.payload
        ? Object.keys(action?.payload).map((key) => ({
            name: key,
            ...action?.payload[key],
          }))
        : [];
      state.users = userList as any;
    });
    builder.addCase(getUserRetrievals.fulfilled, (state, action) => {
      state.selectedUserRetrievals = action.payload;
    });
  },
});

export default userSlice.reducer;
