import { MenuItemProps, useTheme } from "@mui/material";
import MUIMenuItem from "@mui/material/MenuItem";
import MUISelect, { SelectProps } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MUIFormControl from "@mui/material/FormControl";
import Typography from "./Typography";
import { useState } from "react";

interface CustomSelectProps extends SelectProps {
  options?: Array<{
    value: string;
    label: string;
  }>;
  minWidth?: number;
}

export const Select = ({
  variant = "standard",
  disabled = false,
  size = "medium",
  error = false,
  options = [
    {
      value: "",
      label: "",
    },
  ],
  fullWidth,
  children,
  minWidth,
  ...props
}: CustomSelectProps) => {
  const theme = useTheme();
  const styles = {
    formControl: {
      paddingLeft: 0,
      //marginLeft: "auto",
      minWidth: minWidth || 120,
    },
    inputLabel: {
      "&.MuiFormLabel-colorPrimary": {
        color: theme.palette.surface50.main,
        "&.Mui-focused": {
          color: theme.palette.primaryBlue.main,
        },
      },
      "&.Mui-error, .Mui-error .Mui-focused": {
        color: theme.palette.critical.main,
      },
      "&.Mui-disabled, .Mui-disabled .Mui-focused": {
        color: theme.palette.surface30.main,
      },
    },
    select: {
      "&:before": {
        borderBottom: `2px solid ${theme.palette.surface50.main}`,
      },
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primaryBlue.main}`,
      },
      "&.Mui-error:after, &.Mui-error:before": {
        borderBottom: `2px solid ${theme.palette.critical.main}`,
      },
      "&.Mui-disabled:after, &.Mui-disabled:before": {
        borderBottomStyle: "solid",
        borderBottom: `2px solid ${theme.palette.surface30.main}`,
      },
      "&.Mui-disabled .MuiSelect-select": {
        "-webkit-text-fill-color": theme.palette.surface30.main,
      },
      ".MuiMenu-list": {
        background: theme.palette.surface10.main,
      },
    },
    icon: {
      color: theme.palette.surface40.main,
      height: "20px",
    },
  };

  return (
    <MUIFormControl
      variant={variant}
      disabled={disabled}
      size={size}
      error={error}
      fullWidth={fullWidth}
      sx={styles.formControl}
    >
      {props?.label && props?.label !== "" && (
        <InputLabel
          id="demo-simple-select-standard-label"
          sx={styles.inputLabel}
        >
          {props?.label}
        </InputLabel>
      )}
      <MUISelect {...props} sx={{ ...styles.select, ...props?.sx }}>
        {options
          ? options.map((filter) => (
              <MenuItem value={filter?.value} key={filter?.value}>
                <Typography variant="body2">{filter?.label}</Typography>
              </MenuItem>
            ))
          : children}
      </MUISelect>
    </MUIFormControl>
  );
};

export const MenuItem = (props: MenuItemProps) => {
  return <MUIMenuItem {...props} />;
};
