import "./UpdateAccountModal.scss";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import loaderImage from "../../../assets/img/loader.svg";
import { getAccountStatus } from "../../../redux/slices/accountSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { capitalizeFirstLetter } from "../../../utils/utils";
import TextButton from "../../common/TextButton";
import { aiTypeLangchain, scanTypeAI } from "../../constants/constants";
import Loader from "../../common/Loader";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Box from "../../common/Box";
import Button from "../../common/Button";
import { useTheme } from "@mui/material";
import Snackbar from "../../common/Snackbar";
import { ACCOUNT_TYPES } from "../../../utils/Constants";

// Functional component for Add Account.
const ViewAccountModal = ({ handleModal, account }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isGCPAccount = ACCOUNT_TYPES.GCP;
  const [defaultStat, setDefaultStat] = useState("monthly");
  const [accountData, setAccountData] = useState({});
  const [filters, setFilters] = useState([]);
  const [isAIAccount, setIsAIAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [copiedAPIKey, setCopiedAPIKey] = useState(false);

  const viewAccount = async (account) => {
    setIsLoading(true);
    const resp = await dispatch(
      getAccountStatus({
        customerId: getCustomerId(),
        cloudAccountId: account.id,
      })
    );
    setAccountData(resp.payload ? resp.payload : {});
    setIsLoading(false);
  };

  useEffect(() => {
    const filters = [];
    if (accountData.stats) {
      if (accountData.stats.monthly) {
        filters.push("monthly");
      }
      if (accountData.stats.weekly) {
        filters.push("weekly");
      }
      if (accountData.stats.daily) {
        filters.push("daily");
      }
      if (accountData.stats.hourly) {
        filters.push("hourly");
      }
    }

    setFilters(filters);
  }, [accountData]);

  const handleCancel = () => {
    handleModal(false);
  };

  useEffect(() => {
    if (account) {
      viewAccount(account);
      setIsAIAccount(
        account?.aiType?.toLowerCase() === aiTypeLangchain.toLowerCase()
      );
    }
  }, [account]);

  const MAPPINGS = {
    "rate(1 day)": "Daily",
    "rate(7 days)": "Weekly",
    "rate(30 days)": "Monthly",
  };

  const styles = {
    apiKey: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  };

  const apiKey = MAPPINGS[accountData.apiKey]
    ? MAPPINGS[accountData.apiKey]
    : accountData.apiKey;

  return (
    <div id="update-account">
      <div
        className="modal fade show"
        id="updateModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
            <div className="modal-header pl-0 d-flex justify-content-start align-items-center">
              <span> Account Status</span>
            </div>
            {isLoading ? (
              <Loader height="150px" />
            ) : (
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4 pl-0"> Account ID:</div>
                  <div className="col-md-7 modal-text">
                    {accountData.cloudAccountId}
                  </div>
                </div>
                {!isGCPAccount ? (
                  <div className="row mt-1">
                    <div className="col-md-4 pl-0"> CrossAccount Role ARN:</div>
                    <div className="col-md-7 modal-text">
                      {accountData.awsRoleARN}
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <div className="row mt-1">
                      <div className="col-md-4 pl-0"> Scan Scope:</div>
                      <div className="col-md-7 modal-text">
                        {accountData.awsRoleARN}
                      </div>
                    </div> */}
                    <div className="row mt-1">
                      <div className="col-md-4 pl-0"> Scan Type:</div>
                      <div className="col-md-7 modal-text">
                        {accountData.scanType}
                      </div>
                    </div>
                  </>
                )}
                <div className="row mt-1">
                  <div className="col-md-4 pl-0"> Onboarded On:</div>
                  <div className="col-md-7 modal-text">
                    {accountData.onboardedOn}
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-4 pl-0"> Onboarded By:</div>
                  <div className="col-md-7 modal-text">
                    {account?.userId?.email}
                  </div>
                </div>

                {isAIAccount ? (
                  <div className="row mt-1">
                    <div className="col-md-4 pl-0">API Key:</div>
                    <div className="col-md-7 modal-text">
                      <Box sx={styles.apiKey}>
                        {showAPIKey && apiKey
                          ? apiKey
                          : `${apiKey.slice(0, 1)}************${apiKey.slice(
                              -4
                            )}`}
                        <TextButton onClick={() => setShowAPIKey(!showAPIKey)}>
                          {showAPIKey ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </TextButton>
                        <TextButton
                          onClick={() => {
                            navigator.clipboard.writeText(apiKey);
                            setCopiedAPIKey(true);
                          }}
                        >
                          <ContentCopyRoundedIcon />
                        </TextButton>
                      </Box>
                    </div>
                  </div>
                ) : !isGCPAccount ? (
                  <>
                    <div className="row mt-1">
                      <div className="col-md-4 pl-0">
                        Data Scanning Frequency:
                      </div>
                      <div className="col-md-7 modal-text">
                        {MAPPINGS[accountData.dataScanFrequency]
                          ? MAPPINGS[accountData.dataScanFrequency]
                          : accountData.dataScanFrequency}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-4 pl-0"> Last Heartbeat:</div>
                      <div className="col-md-7 modal-text">
                        {accountData.lastHeartbeat}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-6">
                        <div className="mt-1 ml-15">
                          <span>
                            Cloud Defense Lambda (
                            {capitalizeFirstLetter(defaultStat)}
                            ):
                          </span>

                          {accountData.stats ? (
                            <div className="ml-4">
                              <span>
                                Total Execution Runtime:
                                {filters.length ? (
                                  <span className="modal-text ml-2">
                                    {accountData.stats[
                                      defaultStat
                                    ]?.aggregate?.totalExecutionTime?.toFixed(
                                      2
                                    )}{" "}
                                    ms
                                  </span>
                                ) : (
                                  <span className="modal-text ml-2">
                                    {accountData.stats?.aggregate?.totalExecutionTime?.toFixed(
                                      2
                                    )}{" "}
                                    ms
                                  </span>
                                )}
                              </span>
                              <br />
                              <span>
                                Total Estimated Cost:
                                {filters.length ? (
                                  <span className="modal-text ml-2">
                                    ${" "}
                                    {accountData.stats[
                                      defaultStat
                                    ]?.aggregate?.estimatedCost?.toFixed(6)}
                                  </span>
                                ) : (
                                  <span className="modal-text ml-2">
                                    ${" "}
                                    {accountData.stats?.aggregate?.estimatedCost?.toFixed(
                                      6
                                    )}
                                  </span>
                                )}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {filters.length ? (
                        <div className="col-sm-6">
                          <select
                            onChange={(e) => setDefaultStat(e.target.value)}
                            className="form-control"
                            value={defaultStat}
                          >
                            {filters.map((filter, key) => (
                              <option key={key} value={filter}>
                                {filter}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row modal-message mt-2 pt-4"></div>
                    <div className="row">
                      <div className="col col-xl-12">
                        <div className="table-responsive view-account-table">
                          {filters.length ? (
                            <table
                              className={`mb-0 pb-3 table table-border-less table-hover`}
                            >
                              <thead>
                                <tr className="vuls-row">
                                  <th scope="col">Function</th>
                                  <th scope="col">Execution time</th>
                                  <th scope="col">Estimated cost</th>
                                </tr>
                              </thead>

                              {accountData.stats &&
                              accountData.stats.detailed ? (
                                <tbody>
                                  {accountData.stats[defaultStat].detailed.map(
                                    function (item, idx) {
                                      return (
                                        <tr key={idx} className="vuls-row">
                                          <td className="data-store-name">
                                            {item.funcName}
                                          </td>

                                          <td className="data-store-name">
                                            {item?.totalExecutionTime?.toFixed(
                                              2
                                            ) || 0}{" "}
                                            ms
                                          </td>

                                          <td className="data-store-name">
                                            $ {item?.estimatedCost?.toFixed(6)}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              ) : (
                                <tbody></tbody>
                              )}
                            </table>
                          ) : (
                            <table
                              className={`mb-0 pb-3 table table-border-less table-hover`}
                            >
                              <thead>
                                <tr className="vuls-row">
                                  <th scope="col">Function</th>
                                  <th scope="col">Execution time</th>
                                  <th scope="col">Estimated cost</th>
                                </tr>
                              </thead>

                              {accountData.stats &&
                              accountData.stats.detailed ? (
                                <tbody>
                                  {accountData.stats.detailed.map(function (
                                    item,
                                    idx
                                  ) {
                                    return (
                                      <tr key={idx} className="vuls-row">
                                        <td className="data-store-name">
                                          {item.funcName}
                                        </td>

                                        <td className="data-store-name">
                                          {item?.totalExecutionTime?.toFixed(
                                            2
                                          ) || 0}{" "}
                                          ms
                                        </td>

                                        <td className="data-store-name">
                                          $ {item?.estimatedCost?.toFixed(6)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              ) : (
                                <tbody></tbody>
                              )}
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="modal-footer pb-4 pt-0">
              <Button
                variant="outlined"
                type="button"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        open={copiedAPIKey}
        onClose={() => setCopiedAPIKey(false)}
        message="API Key copied to clipboard!"
      />
    </div>
  );
};

export default ViewAccountModal;
