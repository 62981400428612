import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";

interface Props {
  value: number;
  outOf: number;
  isCritical: boolean;
  label: string;
  subText?: string;
  textColor?: string;
}

const MetricBlock = ({
  value,
  outOf,
  isCritical = false,
  label,
  subText = "",
  textColor,
}: Props) => {
  const theme = useTheme();
  const styles = {
    flexCol: { display: "flex", flexDirection: "column" },
    container: {
      gap: theme.spacing(1),
    },
    count: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "end",
    },
    text: {
      gap: theme.spacing(0.25),
    },
    valueText: {
      fontSize: "32px",
      lineHeight: "1.75rem",
      fontWeight: "300",
    },
    manropeFont: {
      fontFamily: "Manrope",
    },
  };
  return (
    <Box sx={{ ...styles.flexCol, ...styles.container }}>
      <Box sx={styles.count}>
        <Typography
          component="span"
          color={
            isCritical
              ? theme.palette.critical.main
              : theme.palette.surface80.main
          }
          sx={{ ...styles.valueText, ...styles.manropeFont }}
        >
          {value || 0}
        </Typography>
        {outOf > 0 && (
          <Typography
            component="span"
            color={theme.palette.surface60.main}
            variant="body1"
            sx={styles.manropeFont}
          >
            /{outOf || 0}
          </Typography>
        )}
      </Box>
      <Box sx={{ ...styles.flexCol, ...styles.text }}>
        <Typography
          color={textColor || theme.palette.surface80.main}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography color={theme.palette.surface50.main} variant="caption">
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default MetricBlock;
