import "./style.scss";
import { ReactComponent as DeleteIcon } from "../../../assets/img/close-icon.svg";
import Accordions from "./accordions";
import { useState } from "react";

const EmbededUiModal = ({ handleModal }) => {
  const [accordions, setAccordions] = useState([
    {
      title: "Dashboard",
      data: [
        {
          open: false,
          title: "What is a Framework?",
          description:
            "Framework is nothing but the Rag samples deployed by client ",
        },
        {
          open: false,
          title: "What is Policy and Policy Category?",
          description:
            "A policy is a set of principles, rules, or guidelines adopted. A policy category is a classification that groups similar types of policies together based on their purpose, subject matter, or the area they govern.",
        },
        {
          open: false,
          title: "What is a Geo Location Tag?",
          description:
            "Geo Location Tag is assigned to a data store to indicate the data is restricted to a certain geography. For example, the tag can be used when the data must remain within certain geography for regulatory compliance. Multiple custom tags can be assigned to a data store.",
        },
        {
          open: false,
          title: "How does prompt session summary works?",
          description: "TBD",
        },
      ],
    },
    {
      title: "Application Details",
      data: [
        {
          open: false,
          title: "What does overview page contain?",
          description:
            "Overview page described the framework details, Instance details, Database details and Loader Types with respective details",
        },
      ],
    },
    {
      title: "Data Source",
      data: [
        {
          open: false,
          title: "What is data source?",
          description:
            "By simple,data source refers data is obtained, collected, or retrieved for analysis, processing, and use in various applications. Similarly Daxa collects the data and process the data with different files loaded, where those data are classifiied into different restricted datas(topics and entites).",
        },
        {
          open: false,
          title: "What does data sources do?",
          description:
            "Data source contains the file with restricted data, source file and its paths. Also the datas are separated into different small snippets which goes through different policy validations(Findings) defined under policy sections.",
        },
        {
          open: false,
          title:
            "What kind of policy validation(Findings, Topics and Entities) data source pass through?",
          description: "TBD",
        },
      ],
    },
    {
      title: "Prompt Session",
      data: [
        {
          open: false,
          title: "What is Prompt session?",
          description:
            "A 'prompt session' in the context of using large language models (LLMs) involves a series of interactions where a user inputs prompts (questions, commands, or statements) and the model generates responses based on those inputs",
        },
        {
          open: false,
          title: "How does Prompt session works?",
          description:
            "Prompt session works by defined policies, the prompts, the context and the response are returned with violations or restricted messages. Also these are classified with respective violations and are plotted for better user visibility.",
        },
      ],
    },
    {
      title: "Policy Violation",
      data: [
        {
          open: false,
          title: "What is Policy Violations?",
          description:
            "A policy violation occurs when a user or an application attempts to bypass a pre-defined guardrail. For example, a malicious user may try to exfiltrate sensitive data from a data store to an external entity.",
        },
      ],
    },
    {
      title: "Policy",
      data: [
        {
          open: false,
          title: "What is a Policy?",
          description:
            "A policy is a guardrail defined by the security team to restrict cloud assets and data in one or more ways. For example, a policy can be defined to enforce encryption enabled on every data store or that data cannot travel outside of a certain region for regulatory compliance.",
        },
        {
          open: false,
          title: "What are my default Policies?",
          description: "TBD.",
        },
        {
          open: false,
          title: "What is a Policy Violation?",
          description:
            "A policy violation occurs when a user or an application attempts to bypass a pre-defined guardrail. For example, a malicious user may try to exfiltrate sensitive data from a data store to an external entity.",
        },
      ],
    },
    {
      title: "Integration",
      data: [
        {
          open: false,
          title: "How do I integrate Daxa with?",
          description: "TBD",
        },
      ],
    },
    {
      title: "SafeLoader",
      data: [
        {
          open: false,
          title: "What is SafeLoader?",
          description:
            "This describes how to augment your existing Langchain DocumentLoader with Daxa Safe DataLoader to get deep data visibility on the types of Topics and Entities ingested into the Gen-AI Langchain application.",
        },
        {
          open: false,
          title: "What LangChain Loaders are supported by SafeLoader?",
          description: "Below Loaders are supported",
          answer: {
            listType: "UL",
            list: [
              "DirectoryLoader",
              "JSONLoader",
              "CSVLoader",
              "DataFrameLoader",
              "S3FileLoader",
              "S3DirLoader",
              "UnstructuredMarkdownLoader",
              "UnstructuredPDFLoader",
              "UnstructuredFileLoader",
              "UnstructuredAPIFileLoader",
              "UnstructuredExcelLoader",
              "AmazonTextractPDFLoader",
              "GCSFileLoader",
              "GoogleDriveLoader",
              "PyPDFDirectoryLoader",
              "PyPDFLoader",
            ],
          },
        },
        {
          open: false,
          title: "How SafeLoader works?",
          description:"",
            answer: {
            listType: "UL",
            list: [
              "Safeloader enables safe data ingestion for Langchain document loader. This is done by wrapping the document loader call with Safe Loader",
              "A data report with all the findings, both Topics and Entities, will be generated and available for inspection",
            ],
          },
        },
        {
          open: false,
          title: "How to enable document loading?",
        },
      ],
    },
    {
      title: "SafeRetriever",
      data: [
        {
          open: false,
          title: "What is SafeRetriever?",
          description: "",
          answer: {
            listType: "UL",
            list: [
              "In LangChain, the SafeRetriever is a component designed to enhance the safety and reliability of document retrieval in language model applications. It functions as a wrapper around other retrievers, adding an extra layer of checks to ensure that the retrieved content adheres to specific safety or policy guidelines. This can be particularly useful in applications where it's critical to filter out potentially harmful, inappropriate, or irrelevant content before it reaches the user.",
              "The SafeRetriever operates by incorporating custom filtering logic that screens retrieved documents for content that might be deemed unsafe or non-compliant with predefined standards. This filtering can be based on various criteria, such as keywords, metadata, or more sophisticated content analysis methods using machine learning models.",
              "This approach helps ensure that only suitable and relevant documents are passed on to the language model for further processing or to the end user directly, thereby improving the overall trustworthiness and user experience of applications built with LangChain.",
            ],
          },
        },
        {
          open: false,
          title: "How SafeRetriever works?",
          description: "Testing",
        },
        {
          open: false,
          title: "What kind of vector databases are supported by Safe Retrieval Chain?",
          description: "",
          answer: {
            listType: "UL",
            list: [
              "Qdrant",
              "Pinecone",
            ],
          },
        },
        {
          open: false,
          title: "How to retrieve documents with Identity and Semantic enforcement using Safe Retrieval?",
          description:
            "Retrieval chain uses a SafeRetrieval to enforce that the snippets used for in-context are retrieved only from the documents authorized for the user. To achieve this, the Gen-AI application needs to provide an authorization context for this retrieval chain. This 'auth_context' should be filled with the identity and authorization groups of the user accessing the Gen-AI app.",
          answer: {
            listType: "OL",
            list: [
              "Loading Documents with Authorization metadata - See supported loader specific documentation for exact input field (typically load_auth=True)",
              "Using supported Vector database - Pick one from the supported Vector database",
              "Initializing Retrieval Chain - After loading the documents, the Retrieval chain is initialized. This chain uses the retriever ( created from the vector database) and an LLM.",
              "The 'ask' Function - This function accepts a question and an auth_context as input and returns the answer using the Retrieval chain where the auth_context contains the identity and authorization groups of the user accessing the application",
              "Posing Questions - The system retrieves answers based on the authorization metadata in the documents and the auth_context provided in the 'ask' function",
            ],
          },
        },
        ],
     },
    {
      title: "Accounts",
      data: [
        {
          open: false,
          title: "How do I add my Accounts?",
          description:
            "In order to ensure secure onboarding, each AWS cloud account in your organization is added individually as follows:",
          answer: {
            listType: "OL",
            list: [
              "Select the “Accounts” tab.",
              "Click on “Add New Account”.",
              "Select the “Type of account” e.g. AWS, Azure, GCP.",
              "Select “Next: Account Info” and gather your root account information.",
              "Enter your 12-digit root “Account Id”.",
              "Next, enter a brief “Account Description”.",
              "Skip the “External Id”.",
              "Select the “Region” where the account resides e.g. us-east-1.",
              "Click on “Launch Stack” to generate Role ARN in your AWS account.",
              "A separate window will appear to launch the CFN stack in your account.",
              "After around 10-15min, the CFN process will complete and generate a Role ARN.",
              "Copy the Role ARN link from the AWS account and paste in the “Role ARN” field here.",
              "Once a valid Role ARN is entered, click on “Add Account”.",
              "The account will now show up on the Accounts page and start gathering cloud resources.",
            ],
          },
        },
      ],
    },
  ]);

  return (
    <div
      className="new-modal modal embeded-modal fade show"
      id="exampleModalLong"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      style={{
        display: "block",
        paddingRight: "8px",
        background: "rgba(0, 0, 0, 0.5)",
      }}
      aria-modal="true"
    >
      <div className="modal-dialog modal-lg embeded-ui-modal" role="document">
        <div className="modal-content">
          <div className="modal-body mt-2">
            <h5 className="float-left">Help</h5>
            <DeleteIcon
              className="cursor-pointer float-right"
              onClick={() => handleModal(false)}
            />

            <input
              type="text"
              placeholder="Search"
              className="form-control accordion-search-form"
            />

            {accordions.map((accordion, index) => (
              <span key={index}>
                <Accordions
                  title={accordion.title}
                  setAccordions={setAccordions}
                  data={accordion.data}
                />
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbededUiModal;
