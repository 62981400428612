import { useState } from "react";
import { Select } from "../../components/common/Select";
import Box from "../../components/common/Box";
import { TextField } from "../../components/common/TextField";
import TextButton from "../../components/common/TextButton";
import { useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Chip from "../../components/common/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../components/common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";

const sensitiveTag = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
  { label: "Critical", value: "critical" },
];

export const AddEntity = ({ isOpen, handleClose }) => {
  const theme = useTheme();
  const [customEntity, setCustomEntity] = useState("");
  const [entityData, setEntityData] = useState<{
    selectedSensitiveTag: string;
    customEntity: Array<string>;
  }>({
    selectedSensitiveTag: "low",
    customEntity: [],
  });
  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    customEntity: {
      position: "relative",
    },
    addButton: {
      position: "absolute",
      right: "10px",
      bottom: "10px",
    },
    chipContainer: {
      display: "flex",
      gap: theme.spacing(2),
    },
    closeIcon: {
      fontSize: "10px",
      width: "10px",
    },
  };

  const handleAddCustomEntity = () => {
    if (customEntity) {
      const customEntities = [...entityData.customEntity, customEntity];
      setEntityData({ ...entityData, customEntity: customEntities });
      setCustomEntity("");
    }
  };

  const handleDelete = (value) => {
    if (value) {
      const customEntities = entityData.customEntity.filter(
        (item) => item !== value
      );
      setEntityData({ ...entityData, customEntity: customEntities });
    }
  };

  const handleSubmit = () => {};

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle handleClose={handleClose}>Add Entity</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Select
          label="Sensitive Tag"
          variant="standard"
          fullWidth
          defaultValue={sensitiveTag.length > 0 && sensitiveTag[0]?.value}
          value={entityData?.selectedSensitiveTag}
          options={sensitiveTag}
          onChange={(event) => {
            setEntityData({
              ...entityData,
              selectedSensitiveTag:
                sensitiveTag?.find((tag) => tag?.value === event.target.value)
                  ?.value || "",
            });
          }}
        />
        <Box sx={styles.customEntity}>
          <TextField
            fullWidth
            label="Custom Entity"
            placeholder="E.g. Phone Number"
            variant="standard"
            value={customEntity}
            onChange={(e) => setCustomEntity(e.target.value)}
          />
          <TextButton
            startIcon={<AddIcon />}
            onClick={handleAddCustomEntity}
            sx={styles.addButton}
          >
            Add
          </TextButton>
        </Box>
        <Box sx={styles.chipContainer}>
          {entityData?.customEntity?.map((item) => (
            <Chip
              label={item}
              variant="outlined"
              color="primary"
              deleteIcon={<CloseIcon sx={styles.closeIcon} />}
              onDelete={() => handleDelete(item)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
