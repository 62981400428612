import React, { useState } from "react";
import "../vulnerabilities/vulnerabilities.scss";
import { useHistory } from "react-router-dom";
import { getScoreColor } from "../../utils/styles";
import riskAccept from "../../assets/img/icons/risk-accept.svg";
import restoreRisk from "../../assets/img/icons/risk-accepted.svg";
import RiskAcceptModal from "../modal/risk-accept-modal/RiskAcceptModal";
import AddTicketsModal from "./AddTicketModal";
import { useDispatch } from "react-redux";
import {
  hideLoading,
  showLoading,
} from "../../redux/slices/globalOperationSlice";
import { listTicketsAsync } from "../../redux/slices/ticketSlice";
import TicketIcon from "../../assets/img/icons/small/task-yellow.svg";
import TicketUnavailableIcon from "../../assets/img/v1-icons/create-ticket.svg";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { useTheme } from "@mui/material";
import TextButton from "../common/TextButton";
import { StatusIndicator } from "../common/StatusIndicator";

const VulnerabilityTable = ({
  currentPosts,
  severity,
  sortData,
  pageName,
  isRiskAccepted,
  refreshIssues,
  isTicketColEnable = true,
  isActionColEnable = true,
  impactedDatastoreEnable = true,
  instanceLocationEnable = true,
  subFilter,
  selectedFilter,
  setIsRiskAccepted,
  sortType,
  sortField,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const [ticketModal, setTicketModal] = useState(false);
  const [isRiskAcceptModal, setIsRiskAcceptModal] = useState(null);
  const [allRiskAccepted, setAllRiskAccepted] = useState(null);
  const [sortingField, setSortingField] = useState([]);

  const [selectedSecurityInstance, setSelectedSecurityInstance] = useState({
    instanceId: "",
    issueId: "",
    instanceLocation: "",
    displayId: "",
  });

  const getPolicyNames = (items) => {
    if (!items) return;

    const names = [];
    for (let i of items) {
      names.push(i.name);
    }

    return names.join(", ");
  };

  const viewDetails = (item) => {
    history.push(
      `/vulnerabilities-details/${item.vulnerabilityID.replaceAll(
        "/",
        "_"
      )}/${item.instanceLocation.replaceAll("/", "_")}/${
        item.securityIssueInstanceId
      }/`
    );
  };

  const [addTicketsModal, setAddTicketsModal] = useState(false);
  const [ticketModalData, setTicketModalData] = useState({
    issue_id: "",
    instance_id: "",
  });

  const refreshIntegrations = async () => {
    dispatch(showLoading());
    dispatch(listTicketsAsync());
    setAddTicketsModal(false);
    dispatch(hideLoading());
  };

  const styles = {
    table: {
      minWidth: "max-content",
    },
    sortIcon: {
      fontSize: theme.typography.body2,
      color: theme.palette.surface50.main,
    },
    link: {
      fontSize: theme.typography.caption,
    },
  };

  return (
    <TableContainer>
      <Table sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell
              scope="col"
              style={{ minWidth: "100px" }}
              onClick={() => sortData("cdIssueRiskScore")}
            >
              Data Risk
              {sortType === "asc" && sortField === "cdIssueRiskScore" ? (
                <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
              ) : (
                <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
              )}
            </TableCell>
            <TableCell
              scope="col"
              style={
                pageName === "datastore-info"
                  ? { minWidth: "100px" }
                  : { minWidth: "100px" }
              }
              onClick={() => sortData("vulnerabilityId.keyword")}
            >
              Issue ID
              {sortType === "asc" && sortField === "vulnerabilityId.keyword" ? (
                <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
              ) : (
                <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
              )}
            </TableCell>
            <TableCell
              scope="col"
              style={
                pageName === "datastore-info"
                  ? { minWidth: "170px" }
                  : { minWidth: "200px" }
              }
              // style={{ minWidth: "220px" }}
              onClick={() => sortData("name.keyword")}
            >
              Name
              {sortType === "asc" && sortField === "name.keyword" ? (
                <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
              ) : (
                <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
              )}
            </TableCell>

            {selectedFilter === "policy-violation" ? (
              <TableCell
                scope="col"
                style={
                  pageName === "datastore-info"
                    ? { minWidth: "170px" }
                    : { minWidth: "200px" }
                }
                onClick={() => sortData("name.keyword")}
              >
                Policy Names
              </TableCell>
            ) : (
              ""
            )}

            <TableCell
              scope="col"
              style={{ minWidth: "160px" }}
              onClick={() => sortData("issueType.keyword")}
            >
              Type
              {sortType === "asc" && sortField === "issueType.keyword" ? (
                <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
              ) : (
                <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
              )}
            </TableCell>
            <TableCell
              scope="col"
              style={{ minWidth: "100px" }}
              onClick={() => sortData("displaySeverityScore.keyword")}
            >
              Severity
              {sortType === "asc" &&
              sortField === "displaySeverityScore.keyword" ? (
                <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
              ) : (
                <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
              )}
            </TableCell>
            {instanceLocationEnable && (
              <TableCell
                scope="col"
                style={{ minWidth: "220px" }}
                onClick={() => sortData("instanceLocation.keyword")}
              >
                Instance Location
                {sortType === "asc" &&
                sortField === "instanceLocation.keyword" ? (
                  <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                ) : (
                  <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                )}
              </TableCell>
            )}
            {impactedDatastoreEnable && (
              <TableCell
                scope="col"
                style={{ minWidth: "100px" }}
                onClick={() => sortData("impactedDatastoresCount")}
              >
                Impacted Datastores
                {sortType === "asc" &&
                sortField === "impactedDatastoresCount" ? (
                  <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                ) : (
                  <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                )}
              </TableCell>
            )}

            <TableCell
              scope="col"
              style={{ minWidth: "150px" }}
              onClick={() => sortData("totalInstanceCount")}
            >
              Total Instances
              {sortType === "asc" && sortField === "totalInstanceCount" ? (
                <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
              ) : (
                <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
              )}
            </TableCell>

            {isActionColEnable &&
            isTicketColEnable &&
            subFilter?.value !== "Resolved" ? (
              <TableCell scope="col" style={{ minWidth: "100px" }}>
                Actions
              </TableCell>
            ) : (
              ""
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map(function (item, idx) {
            const riskScore = getScoreColor(item.cdIssueRiskScore);
            const riskValue =
              riskScore === "score-elevated"
                ? "crtitical"
                : riskScore === "score-low"
                ? "low"
                : riskScore === "score-moderate"
                ? "moderate"
                : "high";
            return (
              <TableRow key={idx} onClick={() => viewDetails(item)}>
                <TableCell>
                  {/* {pageName === "datastore" &&
                    item?.issueType === "MaliciousActivity" && (
                      <img src={AttackIcon} width="20px" />
                    )}
                  <div
                    className={`${getScoreColor(item.cdIssueRiskScore)} hello`}
                  ></div> */}
                  <StatusIndicator severity={riskValue} />
                </TableCell>
                <TableCell>
                  <div
                    className={
                      pageName === "datastore-info"
                        ? "wrap-title-datastore-info"
                        : "wrap-title"
                    }
                    title={item?.vulnerabilityID}
                  >
                    {pageName === "datastore-info"
                      ? item?.displayId.length > 17
                        ? item?.displayId.slice(0, 17) + " ..."
                        : item?.displayId
                      : item?.displayId}
                  </div>
                </TableCell>
                <TableCell>
                  <div title={item?.name}>
                    <TextButton sx={styles.link}>
                      {pageName === "datastore-info"
                        ? item.name.length > 16
                          ? item.name.slice(0, 16) + " ..."
                          : item?.name
                        : item.name.length > 40
                        ? item.name.slice(0, 40) + " ..."
                        : item?.name}
                    </TextButton>
                  </div>
                </TableCell>

                {selectedFilter === "policy-violation" ? (
                  <TableCell>
                    <div>{getPolicyNames(item.policyViolations)}</div>
                  </TableCell>
                ) : (
                  ""
                )}

                <TableCell title={item?.issueType}>
                  {item?.issueType.length > 22
                    ? item.issueType.slice(0, 22) + "..."
                    : item.issueType}
                </TableCell>
                <TableCell>
                  <i class="icon fa fa-circle text-danger red-dot"></i>{" "}
                  <span>
                    {item?.displaySeverityScore
                      ? item?.displaySeverityScore.split(" ")[0]
                      : ""}
                  </span>
                </TableCell>
                {instanceLocationEnable && (
                  <TableCell title={item?.instanceLocation}>
                    <div className="wrap-with-ellipsis">
                      {item.instanceLocation
                        ? item.instanceLocation.substring(
                            item.instanceLocation.lastIndexOf(":") + 1
                          )
                        : null}
                    </div>
                  </TableCell>
                )}
                {impactedDatastoreEnable && (
                  <TableCell>{item?.impactedDatastoresCount}</TableCell>
                )}
                <TableCell>{item?.totalInstanceCount}</TableCell>
                {/* {isTicketColEnable ? <TableCell title="Ticket"></TableCell> : ""} */}
                {isActionColEnable && subFilter?.value !== "Resolved" ? (
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <div className="row">
                      {/* <div className="col pr-2">
                            {item?.tickets === 0 || item?.tickets === 1 ? (
                              <img
                                src={ticketUnavailable}
                                className="ticket-image"
                                title="Create Ticket"
                              />
                            ) : (
                              <img src={ticketAvailable} />
                            )}
                            
                          </div> */}
                      <div className="col justify-content-center">
                        <img
                          src={isRiskAccepted ? restoreRisk : riskAccept}
                          alt=""
                          className="mb-1 "
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedSecurityInstance({
                              instanceId: item.securityIssueInstanceId,
                              displayId: item?.displayId,
                              issueId: item.vulnerabilityID,
                              instanceLocation: item?.instanceLocation
                                ? item?.instanceLocation.substring(
                                    item?.instanceLocation.lastIndexOf(":") + 1
                                  )
                                : null,
                            });
                            setAllRiskAccepted(item.accepted_all_risks);
                            setIsRiskAcceptModal(true);
                          }}
                          width="25px"
                          title={
                            isRiskAccepted ? "Restore Risk" : "Accept Risk"
                          }
                        />
                        <img
                          src={item.url ? TicketIcon : TicketUnavailableIcon}
                          alt=""
                          className="ticket-icon ml-3 mb-1"
                          onClick={item.url ? () => window.open(item.url) : ""}
                        />
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            );
          })}
        </TableBody>
        {/* </table> */}
      </Table>
      {/* </div>
      </div> */}


      {isRiskAcceptModal ? (
        <RiskAcceptModal
          securityIssueInstanceId={selectedSecurityInstance.instanceId}
          securityIssueId={selectedSecurityInstance.issueId}
          instanceLocation={selectedSecurityInstance.instanceLocation}
          displayId={selectedSecurityInstance.displayId}
          riskAccepted={isRiskAccepted}
          allRiskAccepted={allRiskAccepted}
          handleModal={() => setIsRiskAcceptModal(false)}
          refreshIssues={refreshIssues}
        />
      ) : (
        ""
      )}

      
      {addTicketsModal && (
        <AddTicketsModal
          data={ticketModalData}
          handleModal={() => setAddTicketsModal(false)}
          refreshIntegrations={refreshIntegrations}
        />
      )}
    </TableContainer>
  );
};

export default VulnerabilityTable;
