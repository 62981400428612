import { useEffect, useState } from "react";
import PolicySetting from "../../components/settings/PolicySetting";
import PolicyPage from "../../components/policy/PolicyPage";

const PolicySettings = ({ setPath, selectedAccount }) => {
  useEffect(() => {
    document.title = "Daxa - Policy";
    setPath(window.location.pathname);
  }, []);

  const [showPolicySettings, setShowPolicySettings] = useState(false);

  return (
    <>
      {showPolicySettings && <PolicySetting />}
      <PolicyPage selectedAccount={selectedAccount} />
    </>
  );
};

export default PolicySettings;
