import "./HomeV1.scss";
import { fetchDashboardStat } from "../../redux/slices/dashboardSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerId } from "../../utils/SessionHelper";
import { addIndex, colorRiskData } from "../../utils/dataHandler";
import { useHistory } from "react-router-dom";
import { isJsonEmpty, numberFormatter } from "../../utils/utils";
import { useTheme } from "@mui/material";
import Box from "../common/Box";
import ImpactedDataStoresPanel from "../dashboard-widgets/ImpactedDataStoresPanel";
import GeoDistributionCard from "../dashboard-widgets/GeoDistributionCard";
import PolicyBarChart from "../dashboard-widgets/PolicyBarChart";
import PolicyStoreDetailsDrawer from "../policy-store-details-drawer/PolicyStoreDetailsDrawer";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import ModelAppListCard from "../dashboard-widgets/ModelAppListCard";
import FindingsCard from "../dashboard-widgets/FindingsCard";
import ApplicationsCard from "../dashboard-widgets/ApplicationsCard";
import UserAccessCard from "../dashboard-widgets/UserAccessCard";
import DocumentCard from "../dashboard-widgets/DocumentCard";
import SnippetsByLabels from "../dashboard-widgets/SnippetsByLabels";
import LLMCard from "../dashboard-widgets/LLMCard";
import UsersCard from "../dashboard-widgets/UsersCard";
import ActiveUsersByRetrievals from "../dashboard-widgets/ActiveUsersByRetrievals";
import DataLoaderWidget from "../dashboard-widgets/DataLoaderWidget";

const NewHome = ({ setPath, tab, selectedAccount, isIssueViewVisible }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const expandedSidebar = useSelector((state) => state.dashboard.sidebar);

  const limit = 10;
  const [risktype, setRisktype] = useState("high");
  const [severity, setSeverity] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [page, setPage] = useState(1);
  const [bars, setBars] = useState([]);
  const [selectedBar, setSelectedBar] = useState({});
  const [isBarClicked, setIsBarClicked] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState(false);
  const [dataStores, setDatastores] = useState([]);
  const [issues, setIssues] = useState([]);
  const [dataRisks, setDataRisks] = useState([]);
  const [totalDatastoresCount, setTotalDatastoresCount] = useState(0);
  const [issuesCount, setIssuesCount] = useState(0);
  const [datastoreIds, setDatastoreIds] = useState([]);
  const [hoveredDatastoreIds, setHoveredDatastoreIds] = useState([]);
  const [datastore, setDatastore] = useState({});
  const [loading, setLoading] = useState(false);
  const [datastoresLoading, setDatastoresLoading] = useState(false);
  const [riskplotLoading, setRiskplotLoading] = useState(false);
  const [filteredDatastores, setFiltersDatastores] = useState([]);
  const [filteredPolicyDatastores, setPolicyFiltersDatastores] = useState([]);
  const [stats, updateStats] = useState([]);
  const showShadowAI = localStorage.getItem("showShadowAI");
  const [dashboardCards, setDashboardCards] = useState([
    {
      id: "sensitive",
      title: "Sensitive Data",
      count: 0,
      clickable: true,
    },
    {
      id: "SHADOW",
      title: "Shadow Data Store",
      count: 0,
      clickable: true,
    },
    {
      id: "SHADOW_AI",
      title: "Shadow AI",
      count: 0,
      clickable: true,
    },
    {
      id: "violatingPolicies",
      title: "Violating Policies",
      count: 0,
    },
    {
      id: "cloudEnvIssues",
      title: "Cloud Risk Exposures",
      count: 0,
    },
  ]);

  const riskPlot = useSelector((state) => state.dashboard.riskPlot);
  const [breachCost, setBreachCost] = useState(0);

  const firstAccountStatus = useSelector(
    (state) => state.dashboard.firstAccountStatus
  );
  const accountAvailable = useSelector(
    (state) => state.account.accountAvailable
  );

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const getDashboardStats = async (selectedAccount) => {
    const payload = {
      customerId: getCustomerId(),
      cloudAccountId:
        selectedAccount.value === "all" ? null : selectedAccount.value,
    };
    let resp = await dispatch(fetchDashboardStat(payload));
    if (resp && resp.payload && !resp.payload.err) {
      updateStats(resp.payload);
    }
  };

  useEffect(() => {
    updateCards();
  }, [stats]);

  const updateCards = () => {
    let updatedstats = [...dashboardCards];

    const data = stats?.inventory?.data;
    const violatingPolicyCount = stats?.inventory?.policy?.stores;
    if (data) {
      updatedstats.map((card) => {
        switch (card.id) {
          case "sensitive":
            card.count = data[card.id] ? data[card.id] : 0;
            break;
          case "SHADOW":
            card.count = data["shadow"] ? data["shadow"] : 0;
            break;
          case "SHADOW_AI":
            card.count = data["shadowAI"] ? data["shadowAI"] : 0;
            break;
          case "cloudEnvIssues":
            card.count = data[card.id] ? data[card.id] : 0;
            break;
          case "violatingPolicies":
            card.count = violatingPolicyCount;

            break;
          default:
        }
      });
    }
    if (showShadowAI == "false") {
      updatedstats = updatedstats.filter((card) => card.title !== "Shadow AI");
    }
    setDashboardCards([...updatedstats]);
  };

  const theme = useTheme();

  const styles = {
    pageParent: {
      display: "flex",
      width: "100%",
      height: "auto",
    },
    sideBar: {
      display: "flex",
      justifyContent: "center",
      minWidth: "52px",
      borderRight: `1px solid ${theme.palette.surfaceMain.main}`,
    },
    homeContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    contentPanel: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    contentRow1: {
      display: "flex",
      width: "100%",
      gap: theme.spacing(2),
    },
    donutCardContainer: {
      overflow: "visible",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      width: "70%",
      display: "flex",
    },
    usageRiskContainer: {
      minWidth: "30%",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    barContainer: {
      display: "flex",
      gap: theme.spacing(2),
    },
    barCard: {
      width: "100%",
      paddingY: theme.spacing(1),
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
    cardBgColor: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    insightsBar: {
      width: "24px",
      display: "flex",
      justifyContent: "end",
    },
    logo: {
      marginTop: "22px",
      cursor: "pointer",
    },
    geoCard: {
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
    geoDetailsCard: {
      width: "40%",
      marginLeft: "auto",
    },
    frameworkList: {
      width: "70%",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
    },
    dashboardRow2: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "calc(70% - 16px) 30%",
      columnGap: theme.spacing(2),
      minHeight: "300px",
    },
    dashboardRow30_70: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "calc(30% - 16px) 70%",
      columnGap: theme.spacing(2),
      minHeight: "300px",
    },
    cardBackground: {
      minWidth: "100%",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      //padding: theme.spacing(2),
      //paddingTop: theme.spacing(1.5),
    },
    stickyPanel: {
      padding: `16px 0 16px 0`,
      position: "sticky",
      top: "76px",
      mixBlendMode: "inherit",
      background: theme.palette.surface0.main,
      zIndex: 100,
      marginTop: "-16px",
    },
    loaderCard: {
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
    },
  };

  const { openPolicyStoreDetailDrawer, setOpenPolicyStoreDetailDrawer } =
    useContext(AppContext);

  useEffect(() => {
    if (riskPlot) {
      let data = riskPlot;
      if (!data || isJsonEmpty(data)) return;
      data = colorRiskData(addIndex(data));
      setDataRisks(data);
    }
  }, [riskPlot]);

  const vulDatastores = () => {
    let vunerabilityDataStores_ = issues;
    if (severity) {
      vunerabilityDataStores_ = issues.filter((i) => severity === i.severity);
    }
    const data = vunerabilityDataStores_.slice(start - 1, end);
    return data;
  };

  const datastores = () => {
    let data_ = dataStores;
    if (severity) {
      data_ = dataStores.filter((i) => severity === i.severity);
    }
    const data = data_.slice(start - 1, end);
    return data;
  };

  const handleBarData = () => {
    const data = tab === 2 ? vulDatastores() : datastores();
    setBars(data);
    sessionStorage.setItem("selected_bar", JSON.stringify(data[0]));
  };

  const handleInfoModal = (visible, data) => {
    setInfoModalData(data);
    setShowInfoModal(visible);
  };

  useEffect(() => {
    setPath(window.location.pathname);
    document.title = "Daxa - Dashboard";
  }, []);

  return (
    <Box sx={styles.pageParent}>
      <Box sx={styles.homeContainer}>
        <Box sx={styles.contentPanel}>
          <Box sx={{ ...styles.contentRow1, gap: 0, ...styles.stickyPanel }}>
            <ImpactedDataStoresPanel />
          </Box>
          <Box sx={{ ...styles.dashboardRow2, marginTop: "-16px" }}>
            <Box sx={styles.cardBackground}>
              <DocumentCard />
            </Box>
            <Box sx={styles.cardBackground}>
              <SnippetsByLabels />
            </Box>
          </Box>
          <Box sx={styles.geoCard}>
            <DataLoaderWidget />
          </Box>
          <Box sx={styles.dashboardRow2}>
            <Box sx={styles.cardBackground}>
              <ApplicationsCard />
            </Box>
            <Box sx={styles.cardBackground}>
              <LLMCard />
            </Box>
          </Box>
          <Box sx={styles.dashboardRow2}>
            <Box sx={styles.cardBackground}>
              <UsersCard />
            </Box>
            <Box sx={styles.cardBackground}>
              <ActiveUsersByRetrievals />
            </Box>
          </Box>
          {/*<Box sx={styles.contentRow1}>
            <Box sx={styles.geoCard}>
              <GeoDistributionCard />
            </Box>
          </Box>*/}
        </Box>
      </Box>
    </Box>
  );
};

export default NewHome;
