import { useTheme } from "@mui/material";
import {
  getAllValuesSum,
  getShortenedString,
} from "../../../../../utils/commonUtils";
import Box from "../../../../common/Box";
import Checkbox from "../../../../common/Checkbox";
import Tooltip from "../../../../common/Tooltip";
import Typography from "../../../../common/Typography";
import { SNIPPET_COUNT_KEY } from "../../../../constants/constants";

const DocumentDetailsItem = ({ name, topic, entity, isSelected, onClick }) => {
  const theme = useTheme();
  const styles = {
    parent: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "top",
      padding: theme.spacing(2),
      backgroundColor: isSelected ? theme.palette.surface0.main : "",
      cursor: "pointer",
      ":hover": {
        backgroundColor: theme.palette.surface0.main,
      },
    },
    font13: {
      fontSize: "13px",
    },
    checkbox: {
      height: "fit-content",
    },
    accessDetails: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const topicSum = topic ? Object.keys(topic)?.length : 0;
  const entitySum = entity ? Object.keys(entity)?.length : 0;

  return (
    <Box sx={styles.parent} onClick={onClick}>
      {/*<Checkbox label="" sx={styles.checkbox} />*/}
      <Box sx={styles.flexCol}>
        <Tooltip title={name}>
          <div>
            <Typography color={theme.palette.surface80.main} sx={styles.font13}>
              {getShortenedString(name)}
            </Typography>
          </div>
        </Tooltip>
        <Box sx={styles.accessDetails}>
          <Typography variant="caption" color={theme.palette.surface60.main}>
            Topic{" "}
            <Typography
              variant="caption"
              color={
                topicSum
                  ? theme.palette.critical.main
                  : theme.palette.surface60.main
              }
            >
              {topicSum}
            </Typography>{" "}
            |{" "}
          </Typography>
          <Typography variant="caption" color={theme.palette.surface60.main}>
            Entity{" "}
            <Typography
              variant="caption"
              color={
                entitySum
                  ? theme.palette.critical.main
                  : theme.palette.surface60.main
              }
            >
              {entitySum}
            </Typography>{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentDetailsItem;
