import "./report.scss";
import LoginPageLogo from "../../assets/img/daxa-assets/daxa-icon.svg";
import LoginPageTitle from "../../assets/img/daxa-assets/daxa-title.svg";

const ReportDoc = () => {
  return (
    <div className="report-page">
      <div>
        <center>
          <div className="logo-container mb-5">
            <div>
              <img
                src={LoginPageLogo}
                alt=""
                className="login-from-logo"
                height={48}
              />
            </div>
            <div>
              <img
                src={LoginPageTitle}
                alt=""
                className="login-from-logo"
                width={88}
              />
            </div>
          </div>
        </center>
        <h1 className="font-weight-bold text-white text-center">
          Cloud Data Risk Report
        </h1>
        <h5 className="font-weight-bold text-center">Date : Jan 31,2023</h5>
      </div>
    </div>
  );
};

export default ReportDoc;
