import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Fetches loader app overview tab data
 * @param params app name, customer ID, cloud account ID
 * @returns
 */
export const fetchLoaderOverview = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/loader/app", params);
};

/**
 * Fetches app details
 * @param params
 * @returns
 */
export const fetchAppDetails = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/loader/app/detail", params);
};

/**
 * Fetches snippet details
 * @param params ref IDs
 * @returns
 */
export const fetchSnippets = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/snippet", params);
};

/**
 * Fetch list of data loaders
 * @param params customerId, count of items to fetch
 * @returns
 */
export const fetchLoaderList = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/loader/", params);
};

export const fetchLoaderGraph = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/graph", params);
};

export const deleteLoaderApp = async (params) => {
  return axiosDenaliPrivate.delete("/api/v1/ai/loader/app", { data: params });
};

export const setLoaderAppDescription = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/dataloader/description", params);
};
