import { IconButton, useTheme } from "@mui/material";
import Box from "../../components/common/Box";

import { TextField } from "../../components/common/TextField";
import TextButton from "../../components/common/TextButton";
import AddIcon from "@mui/icons-material/Add";
import Divider from "../../components/common/Divider";
import Typography from "../../components/common/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import DescriptionIcon from "@mui/icons-material/Description";
import Button from "../../components/common/Button";
import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { fetchTopics } from "../../redux/apis/topicAPI";
import { addTopicSlice } from "../../redux/slices/topicSlice";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import { AppDispatch } from "../../redux/store";

const TOPIC_ERROR_CODES = {
  successful: 200,
  topic_name_error: 409,
  file_error: 400,
};

const TOPIC_FILE_UPLOAD_PROMPTS = [
  {
    id: 0,
    message: "Checking if sufficient samples are provided ... ",
    maxDelay: 2000,
    minDelay: 2000,
  },
  {
    id: 1,
    message: "Checking if samples have enough variance ... ",
    maxDelay: 3000,
    minDelay: 1000,
  },
  {
    id: 2,
    message:
      "Checking for minimum centroidal distance from existing topics ... ",
    maxDelay: 4000,
    minDelay: 1000,
  },
];

const MessageString = ({
  uploadStatus,
  successCloseDialog,
  failCloseDialog,
}) => {
  const firstMessage = TOPIC_FILE_UPLOAD_PROMPTS[0];
  const theme = useTheme();
  const [messages, setMessages] = useState<
    Array<{ id: number; message: string; status: string; delay: number }>
  >([
    {
      id: firstMessage.id,
      message: firstMessage.message,
      status: "",
      delay: firstMessage.maxDelay,
    },
  ]);
  useEffect(() => {
    const timeoutIds: any = [];
    let messageList = messages;
    TOPIC_FILE_UPLOAD_PROMPTS.forEach((message, i) => {
      const isError = uploadStatus === TOPIC_ERROR_CODES.file_error;
      const delay = isError
        ? message.minDelay * (i + 1)
        : message.maxDelay * (i + 1);
      const timeoutId = setTimeout(() => {
        const text = isError
          ? message.id === 0
            ? "failed"
            : "skipped"
          : "success";
        // update message status and next message string
        const newMessages = messageList.map((msg) =>
          msg.id === i ? { ...msg, status: text } : msg
        );
        console.log("id message", i, newMessages);
        if (i <= 2) {
          const nextMessage = TOPIC_FILE_UPLOAD_PROMPTS[i + 1];
          messageList = [
            ...newMessages,
            {
              id: nextMessage?.id,
              message: nextMessage?.message,
              status: "",
              delay: nextMessage?.maxDelay,
            },
          ];
        } else {
          messageList = newMessages;
        }
        setMessages(messageList);
      }, delay);
      console.log({ messageList });
      timeoutIds.push(timeoutId);
    });

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, []);

  useEffect(() => {
    const isError = uploadStatus === TOPIC_ERROR_CODES.file_error;
    if (messages.length === 3 && messages[3]?.status !== "") {
      let t = setTimeout(
        () => {
          isError ? failCloseDialog() : successCloseDialog();
        },
        isError ? 2000 : 6000
      );
    }
  }, [messages]);

  const styles = {
    messages: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
    },
    message: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    statusCode: (str) => ({
      color:
        str === "failed"
          ? theme.palette.critical.main
          : str === "skipped"
          ? theme.palette.warning.main
          : theme.palette.success.main,
    }),
  };

  return (
    <Box sx={styles.messages}>
      {messages.map((str, i) => (
        <Box sx={styles.message}>
          <>
            <Typography variant="caption" color={theme.palette.surface60.main}>
              {str.message}
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.surface60.main}
              sx={styles.statusCode(str.status)}
            >
              {str.status}
            </Typography>
          </>
        </Box>
      ))}
    </Box>
  );
};

const AlertBox = ({ type, title, createdAt, onDelete }) => {
  const theme = useTheme();
  const styles = {
    alert: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#2A2D40",
      padding: theme.spacing(1),
      borderRadius: "2px",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      width: "85%",
    },
    deleteIcon: {
      fontSize: theme.spacing(2.5),
      width: theme.spacing(2.5),
      color: theme.palette.primaryBlue.main,
    },
    detailHeader: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    icon: {
      color: theme.palette.surface40.main,
      fontSize: theme.spacing(2),
      width: theme.spacing(2),
    },
    createdAtBox: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    createdAt: {
      fontSize: theme.spacing(1.25),
    },
  };
  return (
    <Box sx={styles.alert}>
      <Box sx={styles.details}>
        <Box sx={styles.detailHeader}>
          {type === "text" ? (
            <WysiwygIcon sx={styles.icon} />
          ) : (
            <DescriptionIcon sx={styles.icon} />
          )}
          <Typography color={theme.palette.surface80.main} variant="subtitle2">
            {title}
          </Typography>
        </Box>
        <Box style={styles.createdAtBox}>
          <Typography
            color={theme.palette.surface50.main}
            sx={styles.createdAt}
          >
            Added on:
          </Typography>
          <Typography
            color={theme.palette.surface50.main}
            sx={styles.createdAt}
          >
            {createdAt}
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={onDelete}>
        <DeleteIcon sx={styles.deleteIcon} />
      </IconButton>
    </Box>
  );
};

export const AddTopic = ({ isOpen, handleClose, fetchTopics }) => {
  const [topicData, setTopicData] = useState<{
    topic: string;
    description: string;
    examples: Array<{ id: string | number; createdOn: Date; value: string }>;
    file: Blob | null;
  }>({
    topic: "",
    description: "",
    examples: [],
    file: null,
  });
  const [emptyFields, setEmptyFields] = useState({
    topic: "",
    description: "",
    file: "",
  });
  const [exampleValue, setExampleValue] = useState("");
  const [fileStatus, setFileStatus] = useState(null);
  const [addedTopic, setAddedTopic] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const theme = useTheme();
  const fileInputRef = useRef<any>();
  const dispatch = useDispatch<AppDispatch>();

  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    textArea: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    addButton: {
      marginLeft: "auto",
    },
    icon: {
      fontSize: theme.spacing(2),
      width: theme.spacing(2),
    },
    dividerBody: {
      display: "flex",
      gap: theme.spacing(2),
    },
    divider: {
      width: "100%",
      height: "1px",
      background: theme.palette.surface20.main,
      border: "none",
      margin: "auto",
      flexShrink: 1,
    },
    orText: {
      fontSize: theme.spacing(1.5),
    },
    uploadFileBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    exampleText: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1.25),
    },
    alertHeading: {
      fontSize: theme.spacing(1.25),
    },
    uploadFileText: {
      display: "flex",
      gap: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
    messageBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    messages: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      setEmptyFields({ ...emptyFields, file: "" });
      setFileStatus(null);
    }
    setTopicData({
      ...topicData,
      file: event.target.files[0],
      examples: [
        {
          id: topicData.examples.length + 1,
          value: event.target.files[0]?.name,
          createdOn: new Date(),
        },
      ],
    });
  };

  const onAddExample = () => {
    if (exampleValue) {
      setTopicData({
        ...topicData,
        examples: [
          {
            id: topicData.examples.length + 1,
            value: exampleValue,
            createdOn: new Date(),
          },
        ],
      });
      setExampleValue("");
    }
  };

  const handleSubmit = async () => {
    const emptyFields = validateInputFields();
    if (Object.values(emptyFields).some((error) => error !== "")) return;
    setIsAdding(true);
    setFileStatus(null);
    const formData = new FormData();
    const customerId = getCustomerId();
    formData.append("customerId", customerId);
    formData.append("topic_name", topicData?.topic);
    formData.append("topic_description", topicData?.description);
    if (topicData?.file) {
      formData.append("upload_file", topicData?.file, topicData?.file?.name);
    }

    const response = await dispatch(addTopicSlice(formData));
    const payload = response.payload;

    if (payload.status_code) {
      if (
        payload.status_code === TOPIC_ERROR_CODES.successful ||
        payload.status_code === TOPIC_ERROR_CODES.file_error
      )
        setFileStatus(payload?.status_code);

      if (payload.status_code === TOPIC_ERROR_CODES.topic_name_error) {
        setIsAdding(false);
        setEmptyFields({ ...emptyFields, topic: payload?.message });
      }
    }
  };

  const successFunc = () => {
    setIsAdding(false);
    setAddedTopic(true);
  };

  const failFunc = () => {
    setIsAdding(false);
  };

  const closeAfterAdding = () => {
    setFileStatus(null);
    Toaster(TOASTER_TYPES.SUCCESS, "Topic successfully added");
    const customerId = getCustomerId();
    fetchTopics({ customerId });
    onClose();
  };

  const validateInputFields = () => {
    const fieldValidation = {
      topic: !topicData?.topic ? "Please provide a topic name" : "",
      description: !topicData.description
        ? "Please provide topic description"
        : "",
      file: !topicData?.file
        ? "Please upload examples to help the AI model learn and identify this topic"
        : "",
    };
    setEmptyFields(fieldValidation);
    return fieldValidation;
  };

  const onDeleteExample = (id) => {
    if (id) {
      setFileStatus(null);

      const filteredData = topicData.examples.filter((item) => item.id !== id);
      setTopicData({ ...topicData, file: null, examples: filteredData });
    }
  };

  const onClose = () => {
    setTopicData({ topic: "", description: "", examples: [], file: null });
    setEmptyFields({ topic: "", description: "", file: "" });
    setIsAdding(false);
    setAddedTopic(false);
    setFileStatus(null);
    handleClose();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle handleClose={onClose}>Add Topic</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <TextField
          label="Name"
          variant="standard"
          value={topicData?.topic}
          onChange={(e) => {
            if (e.target.value && emptyFields.topic) {
              setEmptyFields({ ...emptyFields, topic: "" });
            }
            setTopicData({ ...topicData, topic: e.target.value });
          }}
          error={emptyFields.topic ? true : false}
          helperText={emptyFields.topic}
        />
        <TextField
          label="Description"
          variant="standard"
          value={topicData?.description}
          onChange={(e) => {
            if (e.target.value && emptyFields.description) {
              setEmptyFields({ ...emptyFields, description: "" });
            }
            setTopicData({ ...topicData, description: e.target.value });
          }}
          error={emptyFields.description ? true : false}
          helperText={
            emptyFields.description && "Please provide topic description"
          }
        />
        {/*<Box sx={styles.textArea}>
          <TextField
            label="Examples"
            multiline
            maxRows={4}
            minRows={4}
            variant="outlined"
            value={exampleValue}
            onChange={(e) => setExampleValue(e.target.value)}
          />
          <TextButton
            startIcon={<AddIcon />}
            sx={styles.addButton}
            onClick={onAddExample}
          >
            Add
          </TextButton>
        </Box>
        <Box sx={styles.dividerBody}>
          <Divider variant="middle" sx={styles.divider} />
          <Typography color={theme.palette.surface40.main} sx={styles.orText}>
            OR
          </Typography>
          <Divider variant="middle" sx={styles.divider} />
        </Box>*/}
        <Box sx={styles.uploadFileBox}>
          <Box sx={styles.uploadFileText}>
            <Typography color={theme.palette.surface60.main} variant="body2">
              Provide examples relevant to the topic
            </Typography>
            <TextButton onClick={() => fileInputRef?.current?.click()}>
              Upload File
            </TextButton>
            <input
              onChange={handleChange}
              onClick={(event: any) => (event.target.value = null)}
              multiple={false}
              ref={fileInputRef}
              type="file"
              accept=".xlsx"
              hidden
            />
          </Box>
          {emptyFields.file && (
            <Box>
              <Typography variant="caption" color={theme.palette.critical.main}>
                {emptyFields.file}
              </Typography>
            </Box>
          )}
          <Box sx={styles.exampleText}>
            <Typography color={theme.palette.surface40.main} variant="body2">
              Examples Uploaded ({topicData?.examples?.length})
            </Typography>

            {topicData?.examples?.length > 0 &&
              topicData?.examples.map((item) => {
                let date = new Date(item?.createdOn);
                let dateString = [
                  date.getFullYear(),
                  ("0" + (date.getMonth() + 1)).slice(-2),
                  ("0" + date.getDate()).slice(-2),
                ].join("-");

                const hoursAndMinutes =
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  ":" +
                  date.getSeconds();
                return (
                  <AlertBox
                    type="text"
                    title={item.value}
                    createdAt={`${dateString} | ${hoursAndMinutes}`}
                    onDelete={() => onDeleteExample(item.id)}
                  />
                );
              })}
          </Box>
          <Box sx={styles.messageBox}>
            {fileStatus && (
              <Box sx={styles.messages}>
                <MessageString
                  uploadStatus={fileStatus}
                  successCloseDialog={successFunc}
                  failCloseDialog={failFunc}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={addedTopic ? closeAfterAdding : handleSubmit}
          disabled={isAdding}
        >
          {addedTopic ? "Done" : isAdding ? "Adding" : "Add Topic"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
