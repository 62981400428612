import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { convertUTCToLocal, getDateTime } from "../../../utils/commonUtils";
import Box from "../../common/Box";
import TextButton from "../../common/TextButton";
import Typography from "../../common/Typography";
import ViewMoreBlock from "../../common/ViewMoreBlock";
import Divider from "../../common/Divider";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import JiraIcon from "../../icons/JiraIcon";

const NUM_OF_DEFAULT_CHARACTERS = 250;

const FinalContext = ({ context }: { context: any[] }) => {
  const theme = useTheme();
  const styles = {
    btnContainer: {
      display: "flex",
      gap: theme.spacing(2),
      paddingY: theme.spacing(1),
    },
    btnText: (index) => ({
      fontSize: "16px",
      color:
        index === displayBtnId
          ? theme.palette.surface100.main
          : theme.palette.primaryBlue.main,
    }),
  };

  const [displayBtnId, setDisplayBtnId] = useState(0);

  useEffect(() => {
    setDisplayBtnId(0);
  }, [context]);

  return (
    <Box>
      <Box sx={styles.btnContainer}>
        {context?.map((contextButton, index) => (
          <TextButton
            onClick={() => setDisplayBtnId(index)}
            sx={styles.btnText(index)}
          >
            {index + 1}
          </TextButton>
        ))}
      </Box>
      <ViewMoreBlock
        value={context[displayBtnId]?.data}
        length={NUM_OF_DEFAULT_CHARACTERS}
      />
    </Box>
  );
};

const MessageDetailsBlock = ({ data }) => {
  const theme = useTheme();
  const styles = {
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.25),
      backgroundColor: theme.palette.surface5.main,
      padding: theme.spacing(2),
      borderRadius: "4px",
    },
    messageCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    messageFontSize: {
      fontSize: "13px",
    },
    dateText: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    messageData: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    policyChip: {
      display: "flex",
      width: "min-content",
      backgroundColor: "rgba(255, 101, 91, 0.20)",
      borderRadius: theme.spacing(0.5),
    },
    aiAssistedChip: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    finalContext: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    retrievedOnText: {
      display: "flex",
      justifyContent: "space-between",
    },
    rowActions: {
      display: "flex",
      gap: theme.spacing(2),
    },
  };
  const convertedDate =
    data?.metadata && data?.metadata?.createdAt
      ? convertUTCToLocal(data?.metadata?.createdAt)
      : null;

  return (
    <Box sx={styles.messageContainer}>
      <Box sx={styles.messageCard}>
        <Box sx={styles.messageData}>
          <Box sx={styles.keyValue}>
            <Box sx={styles.retrievedOnText}>
              {convertedDate && (
                <Typography
                  variant="caption"
                  color={theme.palette.surface40.main}
                >
                  Retrieved On: {getDateTime(convertedDate)}
                </Typography>
              )}
              <Box sx={styles.rowActions}>
                <TextButton
                  disabled={true}
                  startIcon={<JiraIcon disabled={true} />}
                >
                  Open Ticket
                </TextButton>
                <Divider orientation="vertical" flexItem />
                <TextButton disabled={true} startIcon={<OutlinedFlagIcon />}>
                  Report
                </TextButton>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Prompt
            </Typography>
            <ViewMoreBlock
              value={data?.prompt?.data || "-"}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          {/*<Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Initial Context
            </Typography>
            <ViewMoreBlock value={"-"} length={NUM_OF_DEFAULT_CHARACTERS} />
          </Box>*/}
          <Box sx={styles.keyValue}>
            <Box sx={styles.finalContext}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Context
              </Typography>
              {/*<Box sx={styles.aiAssistedChip}>
                <img src={StarIcon} alt="star" />
                <Typography
                  variant="caption"
                  color={theme.palette.surface60.main}
                >
                  AI Assisted
                </Typography>
              </Box>*/}
            </Box>
            <FinalContext context={data?.context} />
          </Box>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Response
            </Typography>
            <ViewMoreBlock
              value={data?.response?.data}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageDetailsBlock;
