import React, { useEffect, useRef, useState } from "react";

import "../../datastore/datastore.scss";
import { getScoreColor } from "../../../utils/styles";
import "./assets.scss";
import AffectedDatastoresAndIssuesModal from "../../modal/affected-datastore-and-issue-modal/AffectedDatastoresAndIssuesModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import { useTheme } from "@mui/material";
import { StatusIndicator } from "../../common/StatusIndicator";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

const CloudTable = ({ currentPosts = [], sortData, sortType, sortField }) => {
  const theme = useTheme();
  const [isDatastoreIssuesModal, setIsDatastoreIssuesModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});

  const closeModal = () => {
    setSelectedAsset({});
    setIsDatastoreIssuesModal(false);
  };

  const styles = {
    table: {
      minWidth: "max-content",
    },
    sortIcon: {
      fontSize: theme.typography.body2,
      color: theme.palette.surface50.main,
    },
  };

  return (
    <div className="row">
      <div className="col col-xl-12">
        <div className="border-0 card table-card">
          <TableContainer>
            <Table sx={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => sortData("cdAssetRiskScore")}>
                    Risk
                    {sortType === "asc" && sortField === "cdIssueRiskScore" ? (
                      <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                    ) : (
                      <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                    )}
                  </TableCell>
                  <TableCell onClick={() => sortData("name.keyword")}>
                    Name
                    {sortType === "asc" && sortField === "name.keyword" ? (
                      <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                    ) : (
                      <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                    )}
                  </TableCell>
                  <TableCell scope="col" onClick={() => sortData("id.keyword")}>
                    Resource Id
                    {sortType === "asc" && sortField === "id.keyword" ? (
                      <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                    ) : (
                      <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => sortData("metadata.cloudAccountId.keyword")}
                  >
                    Account Id
                    {sortType === "asc" &&
                    sortField === "metadata.cloudAccountId.keyword" ? (
                      <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                    ) : (
                      <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                    )}
                  </TableCell>
                  <TableCell onClick={() => sortData("location.keyword")}>
                    Region
                    {sortType === "asc" && sortField === "location.keyword" ? (
                      <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                    ) : (
                      <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                    )}
                  </TableCell>
                  <TableCell onClick={() => sortData("assetType.keyword")}>
                    Resource Type
                    {sortType === "asc" && sortField === "assetType.keyword" ? (
                      <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                    ) : (
                      <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                    )}
                  </TableCell>
                  <TableCell>Provider</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isDatastoreIssuesModal ? (
                  <AffectedDatastoresAndIssuesModal
                    handleModal={() => {
                      closeModal();
                    }}
                    assetIdValue={
                      selectedAsset.id
                        ? selectedAsset.id
                        : selectedAsset.asset_id
                    }
                    isSecurityIssueVisisble={true}
                    node={selectedAsset}
                    isAttackPath={true}
                    page="asset"
                  />
                ) : (
                  ""
                )}
                {currentPosts.map(function (item, idx) {
                  const riskScore = getScoreColor(item.cdAssetRiskScore);
                  const riskValue =
                    riskScore === "score-high"
                      ? "high"
                      : riskScore === "score-elevated"
                      ? "critical"
                      : "low";
                  return (
                    <TableRow
                      key={idx}
                      onClick={(e) => {
                        setIsDatastoreIssuesModal(true);
                        setSelectedAsset({
                          name: item.name,
                          is_primary: true,
                          asset_id: item.id ? item.id : item.asset_id,
                          tooltip_content: {
                            asset_type: item.assetType,
                            cloud_account_id: item.cloudAccountId,
                            location: item.location,
                            name: item.name
                              ? item.name.substring(
                                  item.name.lastIndexOf(":") + 1
                                )
                              : null,
                            asset_id: item.asset_id,
                          },
                        });
                      }}
                    >
                      <TableCell>
                        <StatusIndicator severity={riskValue} />
                      </TableCell>
                      <TableCell>
                        <div
                          className="wrap-with-ellipsis"
                          title={item.name ? item.name : item.assetType}
                        >
                          {item.name ? item.name : item.assetType}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="wrap-with-ellipsis"
                          title={item.id ? item.id : item.asset_id}
                        >
                          {item.id ? item.id : item.asset_id}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.cloudAccountId}
                        >
                          {item?.cloudAccountId}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.location}
                        >
                          {item?.location}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div
                          className="wrap-with-ellipsis"
                          title={item?.assetType}
                        >
                          {item?.assetType}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="wrap-with-ellipsis" title={"AWS"}>
                          AWS
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default CloudTable;
