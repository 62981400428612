import { useEffect, useState } from "react";
import Box from "./Box";
import * as d3 from "d3";
import ReactDOMServer from "react-dom/server";

interface CollapsibleGraphProp {
  className: string;
  graphData: any;
  handleClick?: any;
  classNameForGraph?: string;
  setNewNode?: any;
  isChild?: boolean;
  nodeDepth: number;
  nodeLength?: number;
  openPopover?: any;
}

export const CollapsibleGraph = ({
  className = "",
  graphData = {},
  handleClick,
  classNameForGraph = "",
  setNewNode,
  isChild = false,
  nodeDepth = 0,
  nodeLength = 1,
  openPopover = () => {},
}: CollapsibleGraphProp) => {
  const [treeData, setTreeData] = useState<any>({});
  let noOfClick = 0;

  // Collapse the node and all it's children
  function collapse(d) {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(collapse);
      d.children = null;
    }
  }

  const createGraph = () => {
    var margin = { top: 20, right: 0, bottom: 20, left: 50 };

    const width = parseFloat(d3.select(`.${classNameForGraph}`).style("width"));
    const height = nodeDepth * nodeLength || 200;

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    d3.select(`.${className}`).selectAll("svg").remove();
    var svg = d3
      .select(`.${className}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var i = 0,
      duration = 750,
      root;

    // declares a tree layout and assigns the size
    var treemap = d3.tree().size([height, width]);

    // Assigns parent, children, height, depth
    root = d3.hierarchy(treeData, function (d: any) {
      return d.children;
    });

    root.x0 = height / 2;
    root.y0 = 0;
    // Collapse after the second level
    root?.children?.forEach(collapse);

    update(root);

    function update(source) {
      // Assigns the x and y position for the nodes
      var treeData = treemap(root);

      // Compute the new tree layout.
      var nodes = treeData.descendants(),
        links = treeData.descendants().slice(1);

      // Normalize for fixed-depth.
      nodes.forEach(function (d) {
        d.y = d.depth * 180;
      });

      // ****************** Nodes section ***************************

      // Update the nodes...
      var node: any = svg.selectAll("g.node").data(nodes, function (d: any) {
        return d.id || (d.id = ++i);
      });

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node
        .enter()
        .append("g")
        .attr("class", function (d) {
          if (Object.keys(d?.data)?.length > 0) {
            return "node";
          }
          return "emptyNode";
        })
        .attr("id", function (d) {
          return d?.data?.name;
        })
        .attr("transform", function (d) {
          return d?.data?.width === "100%"
            ? `translate(${source?.y0 + 165}, -20)`
            : `translate(${source?.y0 + (isChild ? 145 : 165)}, ${source?.x0})`;
        });

      // Add labels for the nodes

      // Append HTML elements
      const nodeHTML = nodeEnter
        .append("foreignObject")
        .attr("width", function (d) {
          return d?.data?.width === "100%"
            ? d?.data?.width
            : d?.data?.icon
            ? d?.data?.width - 10
            : d?.data?.width;
        })
        .attr("height", function (d) {
          return d?.data?.height;
        })
        .style("border-radius", "4px")
        .on("click", function (e, d) {
          e.preventDefault();
          e.stopPropagation();
          if (d?.data?.isNode) {
            handleClick(e, d);
          }
        });

      nodeHTML.append("xhtml").html((d) => {
        return ReactDOMServer.renderToStaticMarkup(d?.data?.node);
      });

      const nodeIcon = nodeEnter
        .append("foreignObject")
        .attr("id", "icon-container")
        .attr("width", 20)
        .attr("height", function (d) {
          return d?.data?.height;
        })
        .attr("cursor", "pointer")
        .attr("x", 180);

      nodeIcon
        .append("xhtml")
        .html((d) => {
          return ReactDOMServer.renderToStaticMarkup(d?.data?.icon);
        })
        .on("click", function (e, d) {
          noOfClick += 1;
          setTimeout(() => {
            if (noOfClick === 1) {
              click(e, d);
            } else if (noOfClick === 2) {
              click(e, d);
            }
          }, 200);
        });

      // UPDATE
      var nodeUpdate = nodeEnter.merge(node);

      // Transition to the proper position for the node
      nodeUpdate
        .transition()
        .duration(duration)
        .attr("transform", function (d) {
          return d?.data?.width === "100%"
            ? `translate(${d?.y + 165}, -20)`
            : `translate(${d?.y + (isChild ? 145 : 165)}, ${
                d?.x - height / 2
              })`;
        });

      // Remove any exiting nodes
      var nodeExit = node
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + source?.y + "," + source?.x + ")";
        })
        .remove();

      // On exit reduce the node circles size to 0
      nodeExit.select("circle").attr("r", 1e-6);

      // On exit reduce the opacity of text labels
      nodeExit.select("text").style("fill-opacity", 1e-6);

      // ****************** links section ***************************

      // Update the links...
      var link: any = svg.selectAll("path.link").data(links, function (d: any) {
        return d.id;
      });

      // Enter any new links at the parent's previous position.
      var linkEnter = link
        .enter()
        .insert("path", "g")
        .attr("class", function (d) {
          if (Object.keys(d?.data)?.length > 0) {
            return "link";
          }
          return "emptyNode";
        })
        .attr("d", function (d) {
          var o = { x: source?.x0, y: source?.y0 };
          return diagonal(o, o);
        })
        .attr("transform", function (d) {
          return `translate(155, -${source?.x0 - 35})`;
        });

      // UPDATE
      var linkUpdate = linkEnter.merge(link);

      // Transition back to the parent element position
      linkUpdate
        .transition()
        .duration(duration)
        .attr("d", function (d) {
          return diagonal(d, d.parent);
        });

      // Remove any exiting links
      var linkExit = link
        .exit()
        .transition()
        .duration(duration)
        .attr("d", function (d) {
          var o = { x: source?.x, y: source?.y };
          return diagonal(o, o);
        })
        .remove();

      // Store the old positions for transition.
      nodes.forEach(function (d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      // Creates a curved (diagonal) path from parent to the child nodes
      function diagonal(s, d) {
        const path: any = `M ${s.y - 16} ${s.x}
            C ${(s.y + d.y) / 2 - 8} ${s.x},
              ${(s.y + d.y) / 2 - 8} ${d.x},
              ${d.y} ${d.x}`;
        return path;
      }

      // Toggle children on click.
      function click(event, d) {
        const elem: any = document.getElementById(`icon-${d?.data?.name}`);
        if (d.children) {
          elem.innerText = "+";
          d._children = d.children;
          d.children = null;
        } else {
          elem.innerText = "-";
          d.children = d._children;
          d._children = null;
          if (setNewNode) {
            setNewNode();
          }
        }
        update(d);
        noOfClick = 0;
      }
      d3.selectAll(".emptyNode").remove();
    }
    // setNewNode();
  };

  useEffect(() => {
    if (Object.keys(graphData)?.length > 0) {
      setTreeData(graphData);
    }
  }, [graphData]);

  useEffect(() => {
    createGraph();
  }, [treeData]);

  const styles = {
    collapsibleGraph: {
      height: "100%",
      width: "100%",
    },
  };

  return <Box className={className} sx={styles.collapsibleGraph}></Box>;
};
