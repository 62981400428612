import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";

const DatasourcesTooltip = ({ node }) => {
  const theme = useTheme();
  const styles = {
    tooltip: {
      width: "150px",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      borderRadius: "4px",
      padding: theme.spacing(1),
    },
    flex: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1),
    },
  };
  return (
    <Box sx={styles.tooltip}>
      <Typography variant="caption" color={theme.palette.surfaceMain.main}>
        {node?.label}
      </Typography>
      <Box sx={styles.flex}>
        <Typography variant="body1" color={theme.palette.surfaceMain.main}>
          {node?.value}
        </Typography>
        <Typography variant="caption" color={theme.palette.surfaceMain.main}>
          Documents
        </Typography>
      </Box>
    </Box>
  );
};

export default DatasourcesTooltip;
