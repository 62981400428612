import { useTheme } from "@mui/material";
import Box from "./Box";
import NoData from "./NoData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "./Table";
import Loader from "./Loader";

interface CustomTableProps {
  tableCols: any[];
  tableData: any[];
  tableHeight?: string;
  onRowClick?: (params: any) => void;
  isLoading?: boolean;
}

const CustomTable = ({
  tableCols,
  tableData,
  tableHeight,
  onRowClick = () => {},
  isLoading = false,
}: CustomTableProps) => {
  return (
    <Box>
      <TableContainer style={{ maxHeight: tableHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableCols.map((col) => (
                <TableCell key={col.id}>{col.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow noHover={true}>
                <TableCell
                  colSpan={tableCols?.length || 0}
                  borderBottom={false}
                >
                  <Loader />
                </TableCell>
              </TableRow>
            ) : tableData?.length > 0 ? (
              tableData.map((row) => (
                <TableRow key={row.id} onClick={() => onRowClick(row)}>
                  {tableCols.map((col) => (
                    <TableCell key={col.id} borderBottom={false}>
                      {col?.render ? col.render(row) : row[col.id] || "-"}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow noHover={true}>
                <TableCell
                  colSpan={tableCols?.length || 0}
                  borderBottom={false}
                >
                  <NoData customText="No Data Found" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomTable;
