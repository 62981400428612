import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "../../common/Box";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../common/Dialog";
import Divider from "../../common/Divider";
import Typography from "../../common/Typography";
import UsersIcon from "../../../assets/img/users.svg";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import TextButton from "../../common/TextButton";
import Tooltip from "../../common/Tooltip";
import { getFileName } from "../../../utils/commonUtils";

const MultipleContextDetailsDialog = ({ open, onClose, data }) => {
  const [selectedID, setSelectedID] = useState<any>(null);
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();
  const styles = {
    dialog: {},
    dialogTitle: {
      fontSize: "18px",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    dialogDetails: {
      minHeight: "500px",
      height: "500px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    contextBtns: (index) => ({
      cursor: "pointer",
      backgroundColor: "rgba(59, 62, 82, 0.3)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "31px",
      width: "31px",
      borderBottom:
        index === selectedID?.index
          ? `2px solid rgba(33, 144, 247, 1)`
          : "2px solid transparent",
    }),
    visibleContext: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    contextAndFile: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const { context } = data;

  useEffect(() => {
    if (data && data.context) {
      setSelectedID({
        index: 0,
        data: context[0],
      });
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={styles.dialog}
    >
      <DialogTitle handleClose={onClose}>
        <Typography sx={styles.dialogTitle}>Snippets</Typography>
        <Typography color={theme.palette.surface50.main} variant="caption">
          Showing all snippets of context generated for the prompt.
        </Typography>
      </DialogTitle>
      <DialogContent sx={styles.dialogDetails}>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
          }}
        >
          {context?.map((contextItem, index) => (
            <Box
              sx={styles.contextBtns(index)}
              onClick={() => setSelectedID({ index, data: contextItem })}
            >
              <Typography
                sx={{ fontSize: "13px" }}
                color={theme.palette.surface80.main}
              >
                {index + 1}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={styles.contextAndFile}>
          <Box sx={styles.visibleContext}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Context
            </Typography>
            <Typography variant="caption" color={theme.palette.surface80.main}>
              {selectedID?.data?.data}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={styles.visibleContext}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1),
              }}
            >
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Retrieved From
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface80.main}
                sx={{
                  width: "100%",
                  wordWrap: "break-word",
                  display: "flex",
                  gap: theme.spacing(1),
                  alignItems: "center",
                }}
              >
                {getFileName(selectedID?.data?.retrievedFrom)}
                <Tooltip
                  title={isCopied ? "Copied to Clipboard" : "Copy File Path"}
                >
                  <ContentCopyRoundedIcon
                    onClick={() => {
                      setIsCopied(true);
                      navigator.clipboard.writeText(
                        selectedID?.data?.retrievedFrom
                      );
                    }}
                    sx={{
                      fontSize: "16px",
                      cursor: "pointer",
                      color: theme.palette.primaryBlue.main,
                    }}
                  />
                </Tooltip>
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default MultipleContextDetailsDialog;
