import { useEffect } from "react";
import Index from "../../components/settings/index";

const Settings = ({ setSetting, setPath }) => {
  setSetting(false);

  useEffect(() => {
    document.title = "Daxa - Settings";

    setPath(window.location.pathname);
  }, []);

  return (
    <>
      <Index />
    </>
  );
};

export default Settings;
