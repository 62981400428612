import React from "react";
import DetailsComponent from "./DetailsComponent";

const DetailsContainer = ({ setTicket, selectedAccount }) => {
  return (
    <DetailsComponent setTicket={setTicket} selectedAccount={selectedAccount} />
  );
};

export default DetailsContainer;
