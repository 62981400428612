import { useTheme } from "@mui/material";
import CustomPage from "../CustomPage";
import Typography from "../../components/common/Typography";
import Box from "../../components/common/Box";
import Tooltip from "../../components/common/Tooltip";
import LangchainIcon from "../../assets/img/langchainHexagonIcon.svg";
import ColorBadge from "../../components/common/ColorBadge";
import Search from "../../components/common/Search";
import { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable";
import { useDispatch } from "react-redux";
import { fetchActiveUsersSlice } from "../../redux/slices/userSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { AppDispatch } from "../../redux/store";
import { UserListingObj } from "./types";
import { useHistory } from "react-router-dom";

const UserListing = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<Array<UserListingObj>>([]);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const styles = {
    users: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    tableTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    font13: {
      fontSize: "13px",
    },
    userApps: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      paddingY: theme.spacing(1),
    },
    datastore: {
      display: "flex",
      gap: theme.spacing(0.5),
      alignItems: "center",
      paddingY: theme.spacing(0.5),
    },
    alignBaseline: {
      alignItems: "baseline",
    },
    tableBg: {
      backgroundColor: theme.palette.surface10.main,
    },
  };

  const breadcrumbs = [
    {
      name: "Home",
      isLink: true,
      link: "/",
    },
    {
      name: "Active Users",
      isLink: false,
    },
  ];

  const tableCols = [
    {
      id: 1,
      title: "User",
      render: (data) => (
        <Typography sx={styles.font13} color={theme.palette.surface70.main}>
          {data?.name}
        </Typography>
      ),
    },
    {
      id: 2,
      title: "Groups",
      render: (data) => (
        <Typography sx={styles.font13} color={theme.palette.surface70.main}>
          {data?.groups?.length}
        </Typography>
      ),
    },
    {
      id: 3,
      title: "Applications",
      render: (data) => (
        <Box sx={styles.userApps}>
          {data?.applications?.length
            ? data?.applications?.map((app) => (
                <Tooltip
                  title={app}
                  children={<img src={LangchainIcon} height={24} alt="app" />}
                />
              ))
            : "-"}
        </Box>
      ),
    },
    {
      id: 4,
      title: "Sensitive Data in Responses",
      render: (data) => (
        <Box sx={styles.datastore}>
          {data?.sensitiveResponse?.anamolous && (
            <ColorBadge color={theme.palette.critical.main} />
          )}
          <Box
            sx={{
              ...styles.datastore,
              ...styles.alignBaseline,
            }}
          >
            <Typography variant="body2" color={theme.palette.surface80.main}>
              {data?.sensitive_data_responses}
            </Typography>
            {/*<Typography sx={styles.font13} color={theme.palette.surface50.main}>
              {`/ ${data?.sensitiveResponse?.outOf}`}
            </Typography>*/}
          </Box>
          {/*<Typography
            variant="body2"
            color={theme.palette.critical.main}
            sx={{ marginLeft: theme.spacing(0.5) }}
          >
            {data?.sensitiveResponse?.anamolous} Anamolous Prompts
          </Typography>*/}
        </Box>
      ),
    },
    {
      id: 5,
      title: "Sensitive Data in Prompts",
      render: (data) => (
        <Box sx={{ ...styles.datastore, ...styles.alignBaseline }}>
          <Typography variant="body2" color={theme.palette.surface80.main}>
            {data?.sensitive_data_prompts}
          </Typography>
          {/*<Typography sx={styles.font13} color={theme.palette.surface50.main}>
            {`/ ${data?.sensitivePrompts?.outOf}`}
          </Typography>*/}
        </Box>
      ),
    },
    {
      id: 5,
      title: "Retrievals",
      render: (data) => (
        <Box sx={{ ...styles.datastore, ...styles.alignBaseline }}>
          <Typography variant="body2" color={theme.palette.surface80.main}>
            {data?.retrievals}
          </Typography>
        </Box>
      ),
    },
  ];

  const fetchActiveUsers = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchActiveUsersSlice(payload));
    if (response?.payload) {
      const userList = Object.keys(response.payload).map((key) => ({
        name: key,
        ...response.payload[key],
      }));
      setUsers(userList);
    }
    setIsLoading(false);
  };

  const gotoUserDetails = (user) => {
    history.push(`/users/${user?.name}`);
  };

  useEffect(() => {
    fetchActiveUsers();
  }, []);

  return (
    <CustomPage breadcrumbs={breadcrumbs}>
      <Box sx={styles.users}>
        <Box sx={styles.tableTitle}>
          <Typography>Users ({users?.length})</Typography>
          <Search
            placeholder="Search By User Name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onClear={() => setSearchKeyword("")}
            onKeyDown={(e) => {}}
          />
        </Box>
        <Box sx={styles.tableBg}>
          <CustomTable
            tableCols={tableCols}
            tableData={users}
            onRowClick={gotoUserDetails}
          />
        </Box>
      </Box>
    </CustomPage>
  );
};

export default UserListing;
