import { axiosDenaliPrivate } from "../../utils/ApiHandler";

export const getImpactedDocuments = (payload) => {
  return axiosDenaliPrivate.post("/api/v1/ai/documents", payload);
};

export const getDocumentInfo = (payload) => {
  return axiosDenaliPrivate.post("/api/v1/ai/document/info", payload);
};

export const getDocumentDetails = (payload) => {
  return axiosDenaliPrivate.post("/api/v1/ai/document/details", payload);
};

export const getDocumentBySources = (payload) => {
  return axiosDenaliPrivate.post("/api/v1/ai/documents_by_sources", payload);
};

export const getSnippetsByLabels = (payload) => {
  return axiosDenaliPrivate.post("/api/v1/ai/snippets_by_labels", payload);
};
