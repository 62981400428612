import { useTheme } from "@mui/material";
import Box from "./Box";

const ColorBadge = ({ color }) => {
  const theme = useTheme();
  const styles = {
    badge: {
      backgroundColor: color || theme.palette.critical.main,
      borderRadius: "50%",
      height: "12px",
      width: "12px",
    },
  };
  return <Box sx={styles.badge}></Box>;
};

export default ColorBadge;
