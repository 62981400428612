import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import Typography from "../../components/common/Typography";
import Tooltip from "../../components/common/Tooltip";
import { getLoaderIcon } from "../../utils/commonUtils";

const TitlePath = ({ type, subText }) => {
  const theme = useTheme();
  const styles = {
    block: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    nameSubtext: {
      display: "flex",
      flexDirection: "column",
    },
    truncateText: {
      width: "300px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };
  return (
    <Box sx={styles.block}>
      {type && <img src={getLoaderIcon(type)} alt="source" height={15} />}
      <Box sx={styles.nameSubtext}>
        <Typography variant="body2" color={theme.palette.surface80.main}>
          {type}
        </Typography>
        <Tooltip
          title={subText}
          children={
            <div style={styles.truncateText as any}>
              <Typography
                variant="caption"
                color={theme.palette.surface50.main}
                sx={styles.truncateText}
              >
                {subText}
              </Typography>
            </div>
          }
        />
      </Box>
    </Box>
  );
};

export default TitlePath;
