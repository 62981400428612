import Box from "../common/Box";
import Divider from "../common/Divider";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { AccordionDetails, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllPackagesSlice,
  downloadPackageSlice,
} from "../../redux/slices/packageSlice";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Loader from "../common/Loader";
import ViewHashes from "./ViewHashes";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Accordion, AccordionSummary } from "../common/Accordion";
import { getDateTime } from "../../utils/commonUtils";
import { AppDispatch } from "../../redux/store";

const PackageDetails = ({ packageDetails }) => {
  const [openViewHashes, setOpenViewHashes] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const styles = {
    packageDetails: {},
    packageName: {
      display: "flex",
      alignItems: "top",
      gap: theme.spacing(0.5),
    },
    packageNameString: {
      fontSize: "13px",
    },
    docIcon: {
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      background: `${theme.palette.surface20.main}90`,
      height: "fit-content",
      display: "flex",
    },
    fileIcon: {
      color: theme.palette.surface80.main,
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
    packageInfo: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      marginTop: theme.spacing(0.5),
    },
    info: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    actions: {
      display: "flex",
      gap: theme.spacing(1.5),
    },
  };

  const downloadPackage = async (packageDetails) => {
    const res = await dispatch(downloadPackageSlice(packageDetails.sha));
    let fileName = packageDetails.packageName;
    if (res?.payload) {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }
  };

  return (
    <Box>
      <Box sx={styles.packageName}>
        <Box sx={styles.docIcon}>
          <InsertDriveFileOutlinedIcon sx={styles.fileIcon} />
        </Box>
        <Box sx={styles.packageInfo}>
          <Box sx={styles.info}>
            <Typography
              color={theme.palette.surface80.main}
              sx={styles.packageNameString}
            >
              {packageDetails?.packageName}
            </Typography>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {getDateTime(packageDetails?.uploadedOn)}
            </Typography>
          </Box>
          <Box sx={styles.actions}>
            <TextButton
              startIcon={<DownloadRoundedIcon />}
              size="small"
              onClick={() => downloadPackage(packageDetails)}
            >
              Download
            </TextButton>
            <Divider orientation="vertical" flexItem />
            <TextButton
              startIcon={<VisibilityOutlinedIcon />}
              size="small"
              onClick={() => setOpenViewHashes(packageDetails)}
            >
              View Hashes
            </TextButton>
          </Box>
        </Box>
      </Box>
      {openViewHashes && (
        <ViewHashes
          open={openViewHashes}
          packageInfo={openViewHashes}
          handleClose={() => setOpenViewHashes(null)}
        />
      )}
    </Box>
  );
};

const PackageDownload = () => {
  const [isFetchingPackages, setIsFetchingPackages] = useState(false);
  const [showOlderPackages, setShowOlderPackages] = useState(false);
  const [packageList, setPackageList] = useState({ latest: [], previous: [] });
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const styles = {
    flexCol: {
      display: "flex",
      flexDirection: "column",
    },
    packageContainer: {
      gap: theme.spacing(1.25),
    },
    font13: {
      fontSize: "13px",
    },
    packagesList: {
      gap: theme.spacing(2),
    },
    viewHide: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      width: "max-content",
    },
    seeMoreText: {
      color: theme.palette.surface50.main,
      cursor: "pointer",
    },
  };

  const getAllPackages = async () => {
    const res = await dispatch(getAllPackagesSlice());
    if (res?.payload) {
      const latest = res.payload.filter(
        (packageItem) => packageItem.version === "latest"
      );
      const previous = res.payload.filter(
        (packageItem) => packageItem.version === "previous"
      );
      setPackageList({ latest, previous });
    }
    setIsFetchingPackages(false);
  };

  useEffect(() => {
    setIsFetchingPackages(true);
    getAllPackages();
  }, []);

  return (
    <>
      {isFetchingPackages ? (
        <Loader height="200px" />
      ) : (
        <Box sx={{ ...styles.flexCol, ...styles.packageContainer }}>
          <Box sx={{ ...styles.flexCol, ...styles.packagesList }}>
            <Typography
              color={theme.palette.surface80.main}
              variant="subtitle2"
            >
              Download Package
            </Typography>
            <Box sx={{ ...styles.flexCol, ...styles.packagesList }}>
              {packageList.latest.map((packageItem) => (
                <>
                  <PackageDetails packageDetails={packageItem} />
                  <Divider orientation="horizontal" />
                </>
              ))}
            </Box>
          </Box>
          <Box sx={{ ...styles.flexCol, ...styles.packagesList }}>
            {/* <TextButton
              sx={styles.viewHide}
              onClick={() => {
                setShowOlderPackages((prevState) => !prevState);
              }}> */}
            <Accordion>
              <AccordionSummary>
                <Typography variant="caption" sx={styles.seeMoreText}>
                  See Older Versions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ ...styles.flexCol, ...styles.packagesList }}>
                  {packageList.previous.map((packageItem) => (
                    <>
                      <PackageDetails packageDetails={packageItem} />
                      <Divider orientation="horizontal" />
                    </>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
            {/* {showOlderPackages ? (
                <KeyboardArrowUpRoundedIcon sx={styles.seeMoreText} />
              ) : (
                <KeyboardArrowDownRoundedIcon sx={styles.seeMoreText} />
              )} */}
            {/* </TextButton> */}
            {/* {showOlderPackages && ( */}

            {/* )} */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PackageDownload;
