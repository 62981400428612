import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import ColorBadge from "../../common/ColorBadge";
import { TagColorMapping } from "../../constants/constants";
import { capitalizeFirstLetter } from "../../../utils/utils";

const SnippetByLabelsTooltip = ({ node }) => {
  const theme = useTheme();
  const styles = {
    tooltip: {
      width: "150px",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      borderRadius: "4px",
      padding: theme.spacing(1),
    },
    name: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    flex: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1),
    },
    alignItemsCenter: {
      alignItems: "center",
    },
  };
  return (
    <Box sx={styles.tooltip}>
      <Box sx={styles.name}>
        <Typography variant="caption" color={theme.palette.surfaceMain.main}>
          {node?.label}
        </Typography>
        {node?.tag ? (
          <Box sx={{ ...styles.flex, ...styles.alignItemsCenter }}>
            <ColorBadge color={TagColorMapping[node.tag]} />
            <Typography
              variant="tooltip"
              color={theme.palette.surfaceMain.main}
            >
              {capitalizeFirstLetter(node.tag)}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box sx={styles.flex}>
        <Typography variant="body1" color={theme.palette.surfaceMain.main}>
          {node?.value}
        </Typography>
        <Typography variant="caption" color={theme.palette.surfaceMain.main}>
          Snippets
        </Typography>
      </Box>
    </Box>
  );
};

export default SnippetByLabelsTooltip;
