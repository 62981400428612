import { useTheme } from "@mui/material";
import Box from "../../../components/common/Box";
import NoData from "../../../components/common/NoData";
import { Select } from "../../../components/common/Select";
import { Tab, Tabs } from "../../../components/common/Tabs";
import CustomTab from "../../documents/CustomTab";
import Typography from "../../../components/common/Typography";
import { useEffect, useMemo, useState } from "react";
import UserPromptsCard from "./UserPromptsCard";
import PromptDetailsCard from "./PromptDetailsCard";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { getUserRetrievals } from "../../../redux/slices/userSlice";
import { getCustomerId } from "../../../utils/SessionHelper";
import { useParams } from "react-router-dom";
import { UserRetrievalsResponse } from "../types";

const labels = ["topics", "entities"];

const UserRetrievals = () => {
  const [selectedTab, setSelectedTab] = useState("sensitive_data_responses");
  const [userPrompts, setUserPrompts] = useState<UserRetrievalsResponse | null>(
    null
  );
  const [filteredPrompts, setFilteredPrompts] = useState<[] | null>(null);
  const [selectedPrompt, setSelectedPrompt] = useState<number>(0);
  const [selectedAppFilter, setSelectedAppFilter] = useState<string>("all");
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const tabs = useMemo(() => {
    return {
      sensitive_data_responses: {
        label: "Sensitive Data in Responses ",
        color: theme.palette.high.main,
        value: "sensitive_data_responses",
        outOf: 0,
        labelKey: "response",
      },
      sensitive_data_prompts: {
        label: "Sensitive Data in Prompts",
        color: theme.palette.purple.main,
        value: "sensitive_data_prompts",
        outOf: 0,
        labelKey: "prompt",
      },
      total_retrievals: {
        label: "Total Prompts",
        value: "total_retrievals",
        outOf: 0,
        labelKey: "",
      },
    };
  }, [userPrompts]);

  const styles = {
    pageBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    promptsPanel: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
      //  paddingY: theme.spacing(2),
      height: "500px",
      overflow: "hidden",
    },
    promptSelector: {
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(2),
      borderBottom: "1px solid #292D40",
      padding: theme.spacing(2),
    },
    prompts: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      flexGrow: "1",
      overflow: "hidden",
    },
    promptList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      height: "100%",
      overflowY: "auto",
    },
    filters: {
      marginLeft: "auto",
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    font13: {
      fontSize: "13px",
    },
    noBottomBorder: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 0,
      },
    },
    tabs: {
      display: "flex",
      gap: theme.spacing(2),
      "&.MuiTab-root": {
        paddingBottom: "0",
      },
    },
  };

  const fetchUserRetrievals = async () => {
    const payload = {
      customerId: getCustomerId(),
      name: params?.id,
    };
    const response = await dispatch(getUserRetrievals(payload));

    if (response?.payload) {
      const data = response.payload;
      const retrievalData = {
        ...data,
        applications: [
          {
            label: `All Applications (${data.applications.length || 0})`,
            value: "all",
          },
          ...data.applications?.map((app) => ({
            value: app,
            label: app,
          })),
        ],
      };
      setUserPrompts(retrievalData);
      setFilteredPrompts(data?.sensitive_data_responses);
    }
  };

  const getKeyFromObject = (obj) => {
    return obj ? Object.keys(obj) : [];
  };

  const getLabels = (key, data) => {
    let labelChips: { label: string; key: string }[] = [];
    const findLabelsWithin = key ? data[key] : data;

    if (findLabelsWithin) {
      if (key) {
        labels?.map((label) => {
          const retrievedLabels = getKeyFromObject(findLabelsWithin[label]);
          labelChips = [
            ...labelChips,
            ...retrievedLabels.map((label) => ({
              label: label,
              key: key,
            })),
          ];
        });
      } else {
        ["prompt", "response"]?.map((keyItem) => {
          labels?.map((label) => {
            const retrievedLabels = getKeyFromObject(data[keyItem][label]);
            labelChips = [
              ...labelChips,
              ...retrievedLabels.map((label) => ({
                label: label,
                key: keyItem,
              })),
            ];
          });
        });
      }
    }

    return labelChips;
  };

  useEffect(() => {
    if (selectedTab) {
      const filteredPrompt = userPrompts
        ? userPrompts[selectedTab]?.filter((prompt) => {
            if (selectedAppFilter === "all") {
              return true;
            } else {
              return prompt.appName === selectedAppFilter;
            }
          })
        : [];
      setFilteredPrompts(filteredPrompt);
    }
  }, [selectedTab, selectedAppFilter]);

  useEffect(() => {
    setSelectedPrompt(0);
  }, [selectedTab]);

  useEffect(() => {
    fetchUserRetrievals();
  }, []);

  return (
    <Box sx={styles.pageBody}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={styles.tabs}
      >
        {Object.values(tabs).map((tab) => (
          <Tab
            sx={{
              "&.MuiTab-root": {
                paddingBottom: 0,
              },
            }}
            label={
              <CustomTab
                {...tab}
                count={userPrompts ? userPrompts[tab.value]?.length : 0}
                selectedValue={selectedTab}
              />
            }
            value={tab.value}
          />
        ))}
      </Tabs>

      <Box sx={styles.promptsPanel}>
        <Box sx={styles.promptSelector}>
          <Box sx={styles.filters}>
            <Typography color={theme.palette.surface30.main} sx={styles.font13}>
              Show Prompts for:
            </Typography>
            <Select
              options={userPrompts?.applications}
              sx={styles.noBottomBorder}
              value={selectedAppFilter}
              onChange={(event: any) =>
                setSelectedAppFilter(event.target.value)
              }
            />
          </Box>
        </Box>
        {userPrompts ? (
          <Box sx={styles.prompts}>
            <Box sx={styles.promptList}>
              {filteredPrompts?.length ? (
                filteredPrompts?.map((prompt: any, index) => (
                  <UserPromptsCard
                    selected={selectedPrompt === index || false}
                    prompt={prompt}
                    onSelect={() => {
                      setSelectedPrompt(index);
                    }}
                    labels={getLabels(tabs[selectedTab].labelKey, prompt)}
                  />
                ))
              ) : (
                <NoData customText="No Prompts Found" />
              )}
            </Box>
            <PromptDetailsCard
              promptDetails={
                filteredPrompts ? filteredPrompts[selectedPrompt] : null
              }
              onCreateTicket={() => {}}
            />
          </Box>
        ) : (
          <NoData customText="No Prompts Found" />
        )}
      </Box>
    </Box>
  );
};

export default UserRetrievals;
