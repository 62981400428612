import { useTheme } from "@mui/material";
import Box from "../../../components/common/Box";
import Chip from "../../../components/common/Chip";
import Typography from "../../../components/common/Typography";
import { convertUTCToLocal, convertYYYYMMDD } from "../../../utils/commonUtils";
import { MessageTypeColorMapping } from "../../../components/constants/constants";

const UserPromptsCard = ({ prompt, labels, selected, onSelect }) => {
  const theme = useTheme();
  const styles = {
    card: (color) => ({
      position: "relative",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
      borderLeft: `1px solid ${color}`,
      padding: theme.spacing(2),
      cursor: "pointer",
      backgroundColor: selected ? theme.palette.surface0.main : "transparent",
    }),
    chip: (color) => ({
      height: "20px",
      "&.MuiChip-root": {
        width: "fit-content",
        borderRadius: "16px",
        border: `1px solid ${color}`,
        backgroundColor: color,
        fontSize: "10px",
        color: theme.palette.surface100.main,
      },
      "& .MuiChip-label": {
        padding: "2px 8px 2px 8px",
      },
    }),
    font13: {
      fontSize: "13px",
    },
    wrapText: {
      wordBreak: "break-word",
    },
    selectedChip: {
      position: "absolute",
      width: "15px",
      height: "15px",
      right: "-7.5px",
      top: "45%",
      backgroundColor: theme.palette.surface0.main,
      transform: "rotate(45deg)",
    },
    chips: {
      display: "flex",
      gap: theme.spacing(1),
      flexWrap: "wrap",
    },
  };
  return (
    <Box sx={styles.card} onClick={onSelect}>
      <Typography color={theme.palette.surface50.main} sx={styles.font13}>
        {convertYYYYMMDD(convertUTCToLocal(prompt?.time))}
      </Typography>
      <Typography
        color={theme.palette.surface80.main}
        sx={{ ...styles.font13, ...styles.wrapText }}
      >
        {prompt?.prompt?.data}
      </Typography>
      <Box sx={styles.chips}>
        {labels?.map((label) => (
          <Chip
            label={label?.label}
            sx={styles.chip(MessageTypeColorMapping[label?.key])}
          />
        ))}
      </Box>
      {/*{selected && <Box sx={styles.selectedChip}></Box>}*/}
    </Box>
  );
};

export default UserPromptsCard;
