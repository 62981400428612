import { useTheme } from "@mui/material";
import Box from "./Box";

export const CardWrapper = ({ children, sx }, props) => {
  const theme = useTheme();
  const styles = {
    commonBox: {
      backgroundColor: theme.palette.surface10?.main,
      padding: theme.spacing(2.5),
      borderRadius: 1,
      width: "100%",
    },
  };

  return <Box sx={{ ...sx, ...styles?.commonBox }}>{children}</Box>;
};
