import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { downloadPackage, getAllPackages } from "../apis/packageAPI";

const initialState = {};

export const getAllPackagesSlice = createAsyncThunk(
  "package/getAll",
  async () => {
    const response = await getAllPackages();
    return response.data;
  }
);

export const downloadPackageSlice = createAsyncThunk(
  "package/download",
  async (packageId: string) => {
    const response = await downloadPackage(packageId);
    return response.data;
  }
);

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default packageSlice.reducer;
