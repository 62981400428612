import { axiosDenaliPrivate } from "../../utils/ApiHandler";

/**
 * Fetch App Details
 * @param params
 * @returns
 */
export const fetchAppData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/details", params);
};

export const fetchAppOverview = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/overview", params);
};

export const fetchAppRetrievalsDetails = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/retrieval/details", params);
};

export const fetchAppRetrievalOverview = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/retrieval/overview", params);
};

/**
 * Fetch list of applications
 * @param params customerId, count of items to fetch
 * @returns
 */
export const fetchAppsList = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/application/", params);
};

export const deleteApp = async (params) => {
  return axiosDenaliPrivate.delete("/api/v1/ai/app", { data: params });
};

export const fetchAppRetreivalFindings = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/retrieval/findings", params);
};

export const setAppDescription = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/description", params);
};

export const setAppPolicy = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/policy", params);
};

export const fetchAppLoaderDetails = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/app/safe_loader/detail", params);
};
