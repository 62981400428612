import React, { useEffect, useState, useRef } from "react";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { useDispatch, useSelector } from "react-redux";
import scanningInheriting from "../../../assets/img/icons/scanning-inheriting.svg";
import scanningOverriding from "../../../assets/img/icons/scanning-overriding.svg";
import Aws from "../../../assets/img/integrations/aws.svg";
import Azure from "../../../assets/img/integrations/azure.svg";
import Gcp from "../../../assets/img/integrations/google.svg";
import loaderWhite from "../../../assets/img/half-circle-white.svg";
import Moment from "react-moment";
import Button from "../../common/Button";
import CalendarIcon from "../../../assets/img/icons/calendar-check-fill.svg";
import Awsicon from "../../../assets/img/aws-account_card.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/img/edit.svg";
import { ReactComponent as ViewIcon } from "../../../assets/img/view.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/close-icon.svg";
import Box from "../../common/Box";
import NoData from "../../common/NoData";

import {
  launchStackV2Async,
  listAccountsAsync,
  accountScanningStatus,
  verifyLaunchStackAsync,
  verifyUpdateStackAsync,
  revertUpdateStackAsync,
} from "../../../redux/slices/accountSlice";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import {
  ACCOUNT_TYPES,
  FREQUENCY,
  LOADER_CONFIG,
} from "../../../utils/Constants";
import { getCustomerId } from "../../../utils/SessionHelper";
import AddAccountModal from "../../modal/accounts-modal/AddAccountModal";
import DeleteAccountModal from "../../modal/accounts-modal/DeleteAccountModal";
import UpdateAccountModal from "../../modal/accounts-modal/UpdateAccountModal";

import "./account.scss";
import ViewAccountModal from "../../modal/accounts-modal/ViewAccountModal";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import infoFilled from "../../../assets/img/icons/ep-info-filled.svg";
import Typography from "../../common/Typography";
import { useTheme } from "@mui/material";
import { scanTypeAI } from "../../constants/constants";

const Cards = () => {
  const [isModal, setIsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);

  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const lastInterval = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const accounts = useSelector((state) => state.account.accounts);

  const [accountStatus, setAccountStatus] = useState({});
  const loading = useSelector((state) => state.globalOperation.loading);

  const statusMsgMapping = {
    creating_stack: "creation",
    updating_stack: "update",
  };

  const styles = {
    blockUI: {
      height: "100%",
    },
    accountsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      height: "100%",
    },
    accountsGrid: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(1),
    },
    accountRow: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
    },
    accountsHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
    accountsBody: {
      display: "flex",
      flex: 1,
    },
    noData: {
      flex: 1,
    },
  };

  const updateLaunchStack = async () => {
    dispatch(showLoading());
    const resp = await dispatch(
      launchStackV2Async({
        id: selectedAccount.id,
      })
    );
    if (resp && resp.payload) {
      window.open(resp.payload.data);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        `Failed to launch stack for account ${selectedAccount.id}. Please check the Account Id.`
      );
    }
    dispatch(hideLoading());
  };

  const refreshAccounts = async () => {
    dispatch(showLoading());
    setAccountList([]);
    await dispatch(listAccountsAsync());
    setIsModal(false);
    setAccountsLoaded(true);
    setDeleteModal(false);
    setUpdateModal(false);
    dispatch(hideLoading());
  };

  const getAccountTypeImage = (type) => {
    switch (type) {
      case ACCOUNT_TYPES.AWS:
        return Awsicon;
      case ACCOUNT_TYPES.GCP:
        return Gcp;
      case ACCOUNT_TYPES.AZURE:
        return Azure;
      default:
        return Aws;
    }
    return;
  };

  const getScanningFreq = (frequency) => {
    switch (frequency) {
      case FREQUENCY.DEFAULT:
      case FREQUENCY.CUSTOM.FRQ_12_HR:
      case FREQUENCY.CUSTOM.FRQ_24_HR:
        return scanningInheriting;
      case FREQUENCY.CUSTOM.FRQ_2_DAYS:
      case FREQUENCY.CUSTOM.FRQ_5_DAYS:
        return scanningOverriding;
      default:
        return scanningInheriting;
    }
    return;
  };

  const deleteAccount = (account) => {
    setSelectedAccount(account);
    setDeleteModal(true);
  };

  const updateAccount = (account) => {
    setSelectedAccount(account);
    setUpdateModal(true);
  };

  const viewAccount = (account) => {
    setSelectedAccount(account);
    setViewModal(true);
  };

  const handleScanning = async (id) => {
    const resp = await dispatch(
      accountScanningStatus({
        customerId: getCustomerId(),
        cloudAccountId: id,
      })
    );

    if (resp && resp.payload && !resp.payload.err) {
      setAccountStatus((prevState) => ({
        ...prevState,
        [id]: {
          first_acct_risk_complete: resp.payload.first_acct_risk_complete,
          first_classification_complete:
            resp.payload.first_classification_complete,
        },
      }));
    }
  };

  useEffect(async () => {
    if (!accounts.length) return;
    for (let account of accounts) {
      handleScanning(account.id);
    }
  }, [accounts]);

  const makePolling = async (id) => {
    const resp = await dispatch(
      verifyLaunchStackAsync({
        id: id,
      })
    );
    if (resp && resp.payload) {
      // refreshAccounts();
    }
  };

  const makeUpdatePolling = async (id) => {
    const resp = await dispatch(
      verifyUpdateStackAsync({
        id: id,
      })
    );
    if (resp && resp.payload) {
      //refreshAccounts();
      setEditDisabled(false);
    }
  };

  const revertUpdateStack = async (id) => {
    const resp = await dispatch(
      revertUpdateStackAsync({
        id: id,
        revert: true,
      })
    );
    if (resp && resp.payload) {
      //refreshAccounts();
      setEditDisabled(false);
    }
  };
  useEffect(() => {
    //const intervalCall = setInterval(async () => {
    //  for (let i of accounts) {
    //    if (i.status === "creating_stack") {
    //      await makePolling(i.id);
    //      dispatch(listAccountsAsync());
    //    }
    //    if (i.status === "updating_stack") {
    //      makeUpdatePolling(i.id);
    //    }
    //    if (i.status === "24_hour_passed") {
    //      await dispatch(listAccountsAsync());
    //    }
    //    if (i.status === "active") {
    //      handleScanning(i.id);
    //    }
    //  }
    //}, 30000);
    //return () => {
    //  // clean up
    //  clearInterval(intervalCall);
    //};
  }, [accounts]);

  useEffect(() => {
    if (accounts.length === 0) {
      refreshAccounts();
    }
    return () => {
      // clean up
      setAccountList([]);
      clearInterval(lastInterval?.current);
    };
  }, []);

  const getScanningStatusMsg = (scanType, id, status) => {
    if (scanType === scanTypeAI && status === "active") {
      return "Connected";
    } else {
      if (status === "active" && accountStatus[id]) {
        if (!accountStatus[id].first_classification_complete) {
          return "Discovering & Analyzing data store contents...";
        } else if (!accountStatus[id].first_acct_risk_complete) {
          return "Identifying issues & attack paths...";
        } else {
          return "Scanning complete. No issues found";
        }
      }
    }
  };

  return (
    <BlockUi
      tag="div"
      blocking={loading}
      loader={
        <Loader active type={LOADER_CONFIG.type} color={LOADER_CONFIG.color} />
      }
      style={styles.blockUI}
    >
      <Box className="account-container" sx={styles.accountsContainer}>
        <Box sx={styles.accountsHeader}>
          <Typography color={theme.palette.surface100.main} variant="body2">
            Accounts
          </Typography>
          <Button
            variant="contained"
            type="button"
            onClick={() => setIsModal(true)}
          >
            <img
              alt=""
              src="static/img/add-account.svg"
              className="add-account-svg mr-2"
            />
            Add New Account
          </Button>
        </Box>
        <Box className="account" sx={styles.accountsBody}>
          {accounts?.length > 0 ? (
            <Box sx={styles.accountsGrid}>
              <Box sx={styles.accountRow}>
                {accounts.map((account, index) => (
                  <div key={account._id} className="account-card p-0">
                    <div className="">
                      <div className="account-details iq-card iq-card-inner p-2">
                        <div className="m-0 row">
                          <div className="col-2 pt-1 text-center p-0 check ">
                            {account.status === "creating_stack" ? (
                              <img src={loaderWhite} width="24px" />
                            ) : account.status === "24_hour_passed" ? (
                              <img src={loaderWhite} width="24px" />
                            ) : (
                              <img
                                src="static/img/mdi-check-circle.svg"
                                title="Onboarding successful"
                              />
                            )}
                          </div>
                          <div className="col-10 card-heading">
                            <div className="row">
                              <div className="col-6">
                                <h6 className="card-title">
                                  {account?.id || ""}
                                </h6>
                              </div>
                              <div className="col-6 d-flex justify-content-end align-items-center">
                                <ViewIcon
                                  title="View"
                                  onClick={() => viewAccount(account)}
                                  className="card-action-images"
                                />

                                {account.status === "creating_stack" ||
                                account.status === "updating_stack" ? (
                                  <CloseIcon
                                    title="Revert"
                                    onClick={() =>
                                      revertUpdateStack(account.id)
                                    }
                                    className="card-action-images"
                                  />
                                ) : (
                                  <EditIcon
                                    title="Edit"
                                    onClick={
                                      editDisabled
                                        ? ""
                                        : () => updateAccount(account)
                                    }
                                    className={
                                      editDisabled
                                        ? "card-action-images card-action-images-disabled"
                                        : "card-action-images"
                                    }
                                  />
                                )}

                                <DeleteIcon
                                  title="Delete"
                                  onClick={() => deleteAccount(account)}
                                  className={"card-action-images"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="d-flex mx-3 mb-2 justify-content-between info">
                            <div>
                              <img src={CalendarIcon} className="mr-1"></img>
                              <span className="mt-4">
                                <Moment format="YYYY-MM-DD">
                                  {account?.metadata?.createdAt}
                                </Moment>
                              </span>
                              {account.status === "24_hour_passed" ? (
                                <img
                                  title={`Ensure AWS Cloudformation Stack ${
                                    statusMsgMapping[account.status]
                                      ? statusMsgMapping[account.status]
                                      : "creation"
                                  } is successfully completed`}
                                  src={infoFilled}
                                  className="pointer ml-2"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <ul>
                            <li className="d-flex justify-content-start align-items-center">
                              <i className="ri-checkbox-blank-circle-fill mr-2 account-icon"></i>
                              {account.status !== "active"
                                ? `Waiting for stack ${
                                    statusMsgMapping[account.status]
                                      ? statusMsgMapping[account.status]
                                      : "creation"
                                  } to complete`
                                : ""}

                              {getScanningStatusMsg(
                                account?.scanType,
                                account.id,
                                account.status
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="footer mx-1">
                          <div className="text-center">
                            <img
                              className={"footer-icons my-2"}
                              src={getAccountTypeImage(account.type)}
                            />
                          </div>

                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Box>
            </Box>
          ) : (
            <Box sx={styles.noData}>
              <NoData customText="No accounts available." />
            </Box>
          )}
        </Box>
        {isModal && (
          <AddAccountModal
            handleModal={() => {
              setIsModal(false);
            }}
            refreshAccounts={refreshAccounts}
          />
        )}

        {deleteModal && selectedAccount && (
          <DeleteAccountModal
            handleModal={() => {
              setDeleteModal(false);
            }}
            refreshAccounts={refreshAccounts}
            account={selectedAccount}
          />
        )}
        {updateModal && selectedAccount && (
          <UpdateAccountModal
            handleModal={() => {
              setUpdateModal(false);
            }}
            refreshAccounts={refreshAccounts}
            account={selectedAccount}
            updateLaunchStack={updateLaunchStack}
            setEditDisabled={setEditDisabled}
            selectedAccount={selectedAccount}
          />
        )}

        {viewModal && selectedAccount && (
          <ViewAccountModal
            handleModal={() => {
              setViewModal(false);
            }}
            refreshAccounts={refreshAccounts}
            account={selectedAccount}
          />
        )}
      </Box>
    </BlockUi>
  );
};

export default Cards;
