import { useTheme } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import MUIButton from "@mui/material/Button";

const Button = (props: ButtonProps) => {
  const theme = useTheme();
  const styles = {
    muiButton: {
      "&.MuiButton-root": {
        textTransform: "none",
        width: "fit-content",
      },
      "&.MuiButton-containedPrimary": {
        backgroundColor: theme?.palette?.primaryBlue?.main || "#2195FF",
        color: theme?.palette?.surface100?.main || "#FFFFFF",
      },
      "&.MuiButton-containedPrimary:hover": {
        backgroundColor: "#0074E0",
      },
      "&.MuiButton-containedPrimary:disabled": {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        color: "rgba(0, 0, 0, 0.26)",
        boxShadow: "none",
        cursor: "not-allowed",
      },
      "&.MuiButton-outlined": {
        border: `1px solid ${theme?.palette?.primaryBlue?.main || "#2195FF"}`,
        color: theme?.palette?.primaryBlue?.main || "#2195FF",
      },
      "&.MuiButton-outlined:hover": {
        backgroundColor: "rgba(33, 149, 255, 0.1)",
        color: theme?.palette?.primaryBlue?.main || "#2195FF",
      },
      "&.MuiButton-outlinedError": {
        color: theme.palette.critical.main,
        border: `1px solid ${theme?.palette?.critical?.main}`,
      },
      "&.MuiButton-outlinedError:hover": {
        backgroundColor: "rgba(255, 101, 91, 0.1)",
        color: theme.palette.critical.main,
        border: `1px solid ${theme?.palette?.critical?.main}`,
      },
    },
  };
  return <MUIButton sx={styles.muiButton} {...props} />;
};

export default Button;
