import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import AttackIcon from "../../assets/img/icons/attack-icon.svg";
import HackerIcon from "../../assets/img/icons/hacker-icon.svg";
import LivenessIcon from "../../assets/img/icons/liveness-icon.svg";
import LockIcon from "../../assets/img/icons/lock-icon.svg";
import AcceptRisk from "../../assets/img/icons/risk-accept.svg";
import Archive from "../../assets/img/v1-icons/archive.svg";
import TicketUnavailableIcon from "../../assets/img/v1-icons/create-ticket.svg";
import NotifyIcon from "../../assets/img/v1-icons/notify.svg";
import TicketIcon from "../../assets/img/v1-icons/view-ticket.svg";
import { getCustomerId } from "../../utils/SessionHelper";
import { getScoreColor } from "../../utils/styles";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import "../datastore/datastore.scss";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import {
  acceptPolicyViolationAsync,
  archivePolicyAsync,
  policyNotifyAsync,
} from "../../redux/slices/policySlice";
import { deleteTicketAsync } from "../../redux/slices/ticketSlice";
import { dateFormatter } from "../../utils/dataHandler";
import DatastoreTicketModal from "../modal/datastore-ticket-modal/DatastoreTicketModal";
import DeleteConfirmationModal from "../modal/shared/DeleteConfirmationModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { useTheme } from "@mui/material";
import TextButton from "../common/TextButton";
import { StatusIndicator } from "../common/StatusIndicator";

const DatastoreTable = ({
  currentPosts,
  refreshDatastores,
  policyViolationId,
  severity,
  setLoading,
  page = "datastore-details",
  sortData,
  ticketDetail,
  tablePadding = "px-3",
  sortType,
  sortField,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const [ticketModal, setTicketModal] = useState(false);
  const [ticketModalInfo, setTicketModalInfo] = useState(false);
  const [isPolicyViolationAccepted, setIsPolicyViolationAccepted] = useState(
    false
  );
  const [isPolicyArchive, setIsPolicyArchive] = useState(false);
  const [acceptedRiskPolicy, setAcceptedRiskPolicy] = useState();
  const [datastoreID, setDatastoreID] = useState("");
  const dispatch = useDispatch();
  const policy = JSON.parse(sessionStorage.getItem("policy"));
  const viewDetails = (item) => {
    sessionStorage.setItem("selected_bar", JSON.stringify(item));
    const path = item.type === "llm" ? "llm" : "datastore-details";
    if (policyViolationId) {
      history.push(
        `/${path}/${item.datastoreId?.replaceAll(
          "/",
          "_"
        )}/${item.assetId?.replaceAll("/", "_")}/${policyViolationId}`
      );
    } else {
      history.push(
        `/${path}/${item.datastoreId?.replaceAll(
          "/",
          "_"
        )}/${item.assetId?.replaceAll("/", "_")}`
      );
    }
  };

  const getSensitiveAlias = (name) => {
    const names = name.split("_");
    return names.length > 2
      ? `${names[0].charAt(0)}${names[1].charAt(0)}${names[2].charAt(0)}`
      : names.length > 1
      ? `${names[0].charAt(0)}${names[1].charAt(0)}`
      : `${names[0].charAt(0)}`;
  };

  const formatedSensitiveInfo = (info) => {
    const names = [];
    const labels = [];
    if (info && info?.length > 0) {
      for (let i of info) {
        if (names.length < 3) {
          names.push({
            name: getSensitiveAlias(i.name).toUpperCase(),
            count: i.count,
          });
          labels.push(getSensitiveAlias(i.name).toUpperCase());
        }
      }
      const result = { label: labels.join(", "), values: names };
      return result;
    }
    return [];
  };

  const getCount = (info) => {
    console.log(info);
  };

  const deleteTicket = async (item) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policyViolationId ? policyViolationId : null,
      datastoreId: item.datastoreId,
    };

    let resp = await dispatch(deleteTicketAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      setLoading(false);
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      refreshDatastores();
    }
  };

  const notifyPolicy = async (item) => {
    setLoading(true);
    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policy?.id ? policy?.id : null,
      datastoreId: item.datastoreId,
    };
    let resp = await dispatch(policyNotifyAsync(payload));
    if (resp && resp.payload && !resp.payload.err) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.success.message);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const archivePolicy = async (item) => {
    setLoading(true);
    setIsPolicyArchive(false);
    const payload = {
      customerId: getCustomerId(),
      policyId: policy?.policyRule ? policy?.policyRule : null,
      datastoreId: [datastoreID],
    };
    let resp = await dispatch(archivePolicyAsync(payload));
    if (resp || resp === null) {
      setLoading(false);
      refreshDatastores();
    }
  };

  const acceptPolicyViolation = async () => {
    setLoading(true);
    setIsPolicyViolationAccepted(false);

    const payload = {
      customerId: getCustomerId(),
      policyViolationId: policyViolationId ? policyViolationId : null,
      datastoreId: acceptedRiskPolicy.datastoreId,
    };

    let resp = await dispatch(acceptPolicyViolationAsync(payload));
    if (resp) {
      setLoading(false);
      refreshDatastores();
    }
  };

  const styles = {
    sortIcon: {
      fontSize: theme.typography.body2,
      color: theme.palette.surface50.main,
    },
  };

  return (
    <div className="row">
      <div className="col col-xl-12">
        {/* <div className="table-responsive"> */}
        <TableContainer>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  scope="col"
                  onClick={() => sortData("cdDatastoreRiskScore")}
                >
                  Data Risk
                  {sortType === "asc" &&
                  sortField === "cdDatastoreRiskScore" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell scope="col" onClick={() => sortData("name.keyword")}>
                  Name
                  {sortType === "asc" && sortField === "name.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell
                  scope="col"
                  onClick={() => sortData("metadata.cloudAccountId.keyword")}
                >
                  Account Id
                  {sortType === "asc" &&
                  sortField === "metadata.cloudAccountId.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell
                  scope="col"
                  onClick={() => sortData("metadata.cloudAccountId.keyword")}
                >
                  Category
                  {sortType === "asc" &&
                  sortField === "metadata.cloudAccountId.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell scope="col" onClick={() => sortData("type.keyword")}>
                  Type
                  {sortType === "asc" && sortField === "type.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell
                  scope="col"
                  onClick={() => sortData("metadata.createdAt.keyword")}
                >
                  Discovered On
                  {sortType === "asc" &&
                  sortField === "metadata.createdAt.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell scope="col" onClick={() => sortData("size.keyword")}>
                  Size
                  {sortType === "asc" && sortField === "size.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                <TableCell
                  scope="col"
                  onClick={() => sortData("mountLocation.keyword")}
                >
                  Sensitive
                  {sortType === "asc" &&
                  sortField === "mountLocation.keyword" ? (
                    <ArrowUpwardRoundedIcon sx={styles.sortIcon} />
                  ) : (
                    <ArrowDownwardRoundedIcon sx={styles.sortIcon} />
                  )}
                </TableCell>
                {policyViolationId && (
                  <TableCell scope="col" style={{ minWidth: "100px" }}>
                    Actions
                  </TableCell>
                )}

                {page === "datastore-details" && (
                  <TableCell scope="col"></TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {currentPosts.map(function (item, idx) {
                const riskScore = getScoreColor(item.cdDatastoreRiskScore);
                const riskValue =
                  riskScore === "score-elevated"
                    ? "crtitical"
                    : riskScore === "score-low"
                    ? "low"
                    : riskScore === "score-moderate"
                    ? "moderate"
                    : "high";
                return (
                  <TableRow
                    key={idx}
                    onClick={() => viewDetails(item)}
                    className="vuls-row"
                  >
                    <TableCell>
                      {/* <div
                        className={`${getScoreColor(
                          item.cdDatastoreRiskScore
                        )}`}
                      ></div> */}
                      <StatusIndicator severity={riskValue} />
                    </TableCell>
                    <TableCell>
                      <div className="wrap-with-ellipsis" title={item?.name}>
                        {item?.name}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        className="wrap-with-ellipsis"
                        title={item?.["metadata.cloudAccountId"]}
                      >
                        {item?.["metadata.cloudAccountId"]}
                      </div>
                    </TableCell>
                    <TableCell>{item.datastoreType}</TableCell>

                    <TableCell>{item.type}</TableCell>
                    <TableCell>
                      {item["metadata.createdAt"]
                        ? dateFormatter(item["metadata.createdAt"])
                        : "-"}
                    </TableCell>
                    <TableCell>{item.size}</TableCell>
                    <TableCell>
                      {formatedSensitiveInfo(item.sensitiveInfo)?.label
                        ?.length > 9 ? (
                        <Popup
                          trigger={
                            <a href="#" className="data-store-name">
                              {formatedSensitiveInfo(
                                item.sensitiveInfo
                              ).label.slice(0, 9)}
                            </a>
                          }
                          on={["hover"]}
                          position="bottom center"
                          arrow={true}
                          className="senstitive-tags-popup"
                        >
                          <div className="popup-item">
                            {formatedSensitiveInfo(item.sensitiveInfo)?.label}
                          </div>
                        </Popup>
                      ) : (
                        <a href="#" className="data-store-name">
                          {formatedSensitiveInfo(item.sensitiveInfo)?.label}
                        </a>
                      )}
                    </TableCell>
                    {page === "datastore-details" && (
                      <TableCell>
                        {item?.indirectAttackStatus && (
                          <img
                            alt="Attack Icon"
                            src={AttackIcon}
                            width="25px"
                            title="Indirect Anomaly"
                          />
                        )}
                        {item?.attackstatus && (
                          <img
                            alt="Hacker Icon"
                            src={HackerIcon}
                            width="21px"
                            title="Direct Anomaly"
                            className="mr-1"
                          />
                        )}
                        {item?.livenessInfo && (
                          <img
                            alt="Liveness Icon"
                            src={LivenessIcon}
                            width="20px"
                            className="mr-1"
                            title="Live Traffic"
                          />
                        )}
                        {item?.sensitiveScore >= 80 && (
                          <img
                            alt="Lock Icon"
                            src={LockIcon}
                            width="20px"
                            title="Highly Sensitive"
                          />
                        )}
                      </TableCell>
                    )}
                    {policy?.actionType === "ticket/accept" &&
                      policyViolationId &&
                      item.tickets === 0 && (
                        <TableCell
                          onClick={(e) => e.stopPropagation()}
                          className={
                            !item.ticketIntegration && "cursor-default"
                          }
                        >
                          <img
                            alt="Create ticket icon"
                            title="Create Ticket"
                            src={TicketUnavailableIcon}
                            className={
                              item.ticketIntegration
                                ? "ticket-icon mr-3  cursor-pointer"
                                : "ticket-icon-disable mr-3 "
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (item.ticketIntegration) {
                                setTicketModal(true);
                                setTicketModalInfo({
                                  datastoreId: item.datastoreId,
                                  policyViolationId: policyViolationId,
                                });
                              }
                            }}
                          />
                          <img
                            alt="Accept risk icon"
                            src={AcceptRisk}
                            title="Accept Policy Violation"
                            width="25px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPolicyViolationAccepted(true);
                              setAcceptedRiskPolicy(item);
                            }}
                          />
                        </TableCell>
                      )}
                    {policy?.actionType === "ticket/accept" &&
                      policyViolationId &&
                      item.tickets > 0 && (
                        <TableCell onClick={(e) => e.stopPropagation()}>
                          <img
                            alt="Ticket details icon"
                            title="Ticket Details"
                            src={TicketIcon}
                            className="ticket-icon mr-1 mr-3  cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(item?.url ? item?.url : "#");
                            }}
                          />
                          <img
                            alt="Accept risk icon"
                            src={AcceptRisk}
                            title="Accept Policy Violation"
                            width="25px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPolicyViolationAccepted(true);
                              setAcceptedRiskPolicy(item);
                            }}
                          />

                          {/* <img
                          title="Delete Ticket"
                          src={DeleteTicketIcon}
                          className="ticket-icon ml-1 mb-1 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();

                            deleteTicket(item);
                          }}
                        /> */}
                        </TableCell>
                      )}
                    {policyViolationId &&
                      policy?.actionType === "notify/archive" && (
                        <TableCell onClick={(e) => e.stopPropagation()}>
                          <img
                            alt="Notify icon"
                            title="Notify"
                            src={NotifyIcon}
                            className="ticket-icon mr-1 mr-3  cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              notifyPolicy(item);
                            }}
                          />
                          <img
                            alt="Archive icon"
                            title="Archive"
                            src={Archive}
                            width="25px"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDatastoreID(item.datastoreId);
                              setIsPolicyArchive(true);
                              // archivePolicy(item);
                              // setIsPolicyViolationAccepted(true);
                              // setAcceptedRiskPolicy(item);
                            }}
                          />
                        </TableCell>
                      )}
                  </TableRow>
                );
              })}
              {ticketModal && (
                <DatastoreTicketModal
                  type="datastore"
                  refreshDatastores={refreshDatastores}
                  datastoreId={ticketModalInfo.datastoreId}
                  policyViolationId={ticketModalInfo.policyViolationId}
                  handleModal={() => {
                    setTicketModal(false);
                  }}
                />
              )}
              {isPolicyArchive && (
                <DeleteConfirmationModal
                  handleModal={() => setIsPolicyArchive(false)}
                  deleteHandle={archivePolicy}
                  modalData={{
                    title: "Archive Policy",
                    message: "Are you sure, you want to archive this policy ?",
                    type: "archive",
                  }}
                />
              )}
              {isPolicyViolationAccepted && (
                <DeleteConfirmationModal
                  handleModal={() => setIsPolicyViolationAccepted(false)}
                  deleteHandle={acceptPolicyViolation}
                  modalData={{
                    title: "Accept Policy",
                    message:
                      "Are you sure, you want to accept this policy violation ?",
                    type: "accept",
                  }}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </div> */}
      </div>
    </div>
  );
};

export default memo(DatastoreTable);
