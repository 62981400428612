import { useTheme } from "@mui/material";
import type { CircularProgressProps } from "@mui/material/CircularProgress";
import MUICircularProgress from "@mui/material/CircularProgress";
import Box from "./Box";

export interface LoaderProps extends CircularProgressProps {
  height?: string;
  width?: string;
}

const Loader = ({ height = "100%", width = "100%", ...props }: LoaderProps) => {
  const theme = useTheme();
  const styles = {
    loaderContainer: {
      width,
      height,
      minHeight: height,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    loader: {
      color: theme.palette.primaryBlue.main,
    },
  };
  return (
    <Box sx={styles.loaderContainer}>
      <MUICircularProgress sx={styles.loader} {...props} />
    </Box>
  );
};

export default Loader;
