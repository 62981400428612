import { InputAdornment, useTheme } from "@mui/material";
import MUITextField, { TextFieldProps } from "@mui/material/TextField";

export const TextField = (props: TextFieldProps) => {
  const theme = useTheme();
  const styles = {
    textField: {
      "& .MuiInputLabel-root": {
        color: theme.palette.surface60.main,
        fontFamily: "Inter",
        "&.Mui-focused": {
          color: theme.palette.primaryBlue.main,
        },
        "&.Mui-error": {
          color: theme.palette.critical.main,
        },
      },
      "& .MuiInputAdornment-root": {
        display: "none",
      },
      "& .MuiInputBase-root": {
        ":after": {
          borderBottom: `2px solid ${theme.palette.primaryBlue.main}`,
        },
        "&.Mui-error:after": {
          borderBottom: `2px solid ${theme.palette.critical.main}`,
        },
        "&.Mui-error:before": {
          borderBottom: `1px solid ${theme.palette.critical.main}`,
        },
      },
      "& .MuiInputBase-input": {
        fontSize: "14px",
      },
      "& .MuiFormHelperText-root": {
        "&.Mui-error": {
          color: theme.palette.critical.main,
        },
      },
      ".MuiInputBase-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.surface40.main}`,
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.primaryBlue.main}`,
          },
        },
      },
      ".MuiFormHelperText-root": {
        marginX: 0,
      },
      ...props.sx,
    },
  };
  return (
    <MUITextField
      {...props}
      sx={styles.textField}
      InputProps={{
        startAdornment: <InputAdornment position="start" />,
      }}
    />
  );
};
