import { Modal, useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import Button from "./Button";

export const DeleteModal = ({
  open,
  onClose,
  title,
  description,
  onDelete,
}) => {
  const theme = useTheme();
  const styles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: theme.palette.surface10.main,
    border: "1px solid rgba(0,0,0,0.1)",
    modalHeader: {
      padding: theme.spacing(2),
      borderBottom: "1px solid #333647",
    },
    modalBody: {
      padding: theme.spacing(2),
    },
    modalFooter: {
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "end",
      gap: theme.spacing(2),
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles}>
        <Box sx={styles.modalHeader}>
          <Typography color={theme.palette.surface100.main} variant="body2">
            {title}
          </Typography>
        </Box>
        <Box sx={styles.modalBody}>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Box sx={styles.modalFooter}>
          <Button variant="contained" type="submit" onClick={onDelete}>
            Delete
          </Button>
          <Button variant="outlined" type="button" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
