import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";
import GroupsIcon from "../../assets/img/users.svg";

const UserGroupsListing = ({ groups, title }) => {
  const theme = useTheme();
  const styles = {
    group: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    groupTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    groupCount: {
      fontFamily: "Manrope, sans-serif",
      fontSize: "24px",
    },
    groupList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    row: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
  };
  return (
    <Box sx={styles.group}>
      <Box sx={styles.groupTitle}>
        <Typography sx={styles.groupCount} color={theme.palette.surface80.main}>
          {groups?.length || 0}
        </Typography>
        <Typography variant="body2" color={theme.palette.surface80.main}>
          {title}
        </Typography>
      </Box>
      <Box sx={styles.groupList}>
        {groups?.map((group) => (
          <Box sx={styles.row}>
            <img src={GroupsIcon} width="16px" />
            <Typography
              variant="subtitle2"
              color={theme.palette.surface70.main}
            >
              {group}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserGroupsListing;
