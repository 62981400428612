import { useEffect } from "react";

import * as d3 from "d3";

import { useTheme } from "@mui/material";
import {
  getFileName,
  getFirstNValue,
  getJoinedString,
} from "../../../utils/commonUtils";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import Divider from "../../common/Divider";
import NoData from "../../common/NoData";

export interface TreeMapData {
  selectedNode: any;
  setSelectedNode: any;
  data: Array<{ name: string; parent: string; value: string }>;
  maxTwoValue: any;
}

const AppTreeMap = ({
  selectedNode,
  setSelectedNode,
  data,
  maxTwoValue,
}: TreeMapData) => {
  const theme = useTheme();
  const styles = {
    graphContainer: {
      width: "100%",
      height: "240px",
    },
    graphFooterContainer: {
      paddingTop: theme.spacing(1),
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    divider: {
      height: "13px",
    },
    minMax: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    colorIndicator: {
      height: "4px",
      width: "300px",
      backgroundImage: "linear-gradient(to right, #8C5DFA , #421F96)",
    },
    graphFooter: {},
  };

  var root = d3
    .stratify()
    .id(function (d: any) {
      return d.id;
    })
    .parentId(function (d: any) {
      return d.parent;
    })(data);

  root.sum(function (d: any) {
    return +d.value;
  });

  const values = data?.map((d) => (d?.value ? Number(d?.value) : NaN));
  const minValue = d3.min(values);
  const [min, max] = d3.extent(values);

  const colorScale = d3
    .scaleLinear()
    .domain([min, max] as any)
    .range(["#421F96", "#8C5DFA"] as any);

  const drawTreeMap = () => {
    const element = d3.selectAll(".treeMapContainer");
    element.selectAll("svg").remove();
    var margin = { top: 10, right: 0, bottom: 0, left: 0 },
      height = 200 - margin.top - margin.bottom;
    const parentWidth =
      document.getElementById("treemap-container")?.clientWidth || 1000;
    const width = parentWidth - margin.left - margin.right;

    var svg = d3
      .select("#treemap-container")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    d3.selectAll(".treemap-tooltip").remove();

    var tooltip = d3
      .select("#treemap-container")
      .append("div")
      .style("opacity", 0)
      .attr("class", "treemap-tooltip")
      .style("border-radius", "5px")
      .style("color", "white")
      .style("position", "absolute");

    d3.treemap().size([width, height]).padding(1)(root);

    const adjustTooltipX = (x) => {
      const tooltipMargin = 100;
      const boundingCoords = document
        .querySelector(".treeMapContainer")
        ?.getBoundingClientRect();

      const tooltipSpacing = tooltipMargin ? tooltipMargin - 10 : 0;
      const xCoord = boundingCoords?.width
        ? boundingCoords?.width - x < 250
          ? x - (!tooltipMargin ? 230 : 220 + tooltipSpacing)
          : x + 50
        : x;
      return xCoord;
    };

    var showTooltip = function (event, d, applyStyle = true) {
      if (applyStyle) {
        d3.select(`#rect-${d.id}`).attr("rx", 6).attr("ry", 6);
        d3.select(`#rect-${d.id}`).style("stroke", "white");
      }
      const xPos = adjustTooltipX(event.clientX);
      const yPos = event.clientY;

      tooltip.transition().duration(200);
      tooltip
        .style("opacity", 1)
        .style("display", "block")
        .html(
          /*html*/ `<div class="treemap-tooltip">
            <div class="treemap-header">
            <div class="treemap-tooltip-file">${d.data.name}</div>
            <div style="font-size:19px">${d.data.value}</div>
            <div class="treemap-tooltip-content">Retrieval Count</div>
            </div>
        <div>`
        )
        .style("left", xPos + "px")
        .style("top", yPos + "px");
    };

    var hideTooltip = function (event, d) {
      if (selectedNode !== d.id) {
        d3.select(`#rect-${d.id}`).attr("rx", 0).attr("ry", 0);
        d3.select(`#rect-${d.id}`).style("stroke", "none");
      }
      tooltip
        .transition()
        .duration(50)
        .style("opacity", 0)
        .style("display", "none");
    };

    var moveTooltip = function (event, d) {
      const xPos = adjustTooltipX(event.clientX);
      const yPos = event.clientY;
      tooltip
        .style("left", xPos + "px")
        .style("top", yPos + "px")
        .style("display", "block");
    };

    const groups = svg
      .selectAll("g")
      .data(root.leaves())
      .enter()
      .append("g")
      .on("mouseenter", showTooltip)
      .on("mouseleave", hideTooltip)
      .on("mousemove", moveTooltip)
      .on("click", (event, d) => {
        if (d.id === selectedNode) {
          setSelectedNode("");
        } else {
          setSelectedNode(d.id);
        }
      });

    groups
      .append("rect")
      .attr("id", (d: any) => `rect-${d.data.id}`)
      .attr("class", (d: any) =>
        Number(d.data.value) >= Number(maxTwoValue)
          ? "rect-text"
          : "rect-no-text"
      )
      .attr("x", function (d: any) {
        return d.x0;
      })
      .attr("y", function (d: any) {
        return d.y0;
      })
      .attr("width", function (d: any) {
        return d.x1 - d.x0;
      })
      .attr("height", function (d: any) {
        return d.y1 - d.y0;
      })
      .style("stroke", "black")
      .style("fill", (d: any) => colorScale(d.data.value));

    groups
      .append("foreignObject")
      .attr("x", function (d: any) {
        return d.x0 + 10;
      }) // +15 to adjust position (more right)
      .attr("y", function (d: any) {
        return d.y0 + 10;
      }) // +20 to adjust position (lower)
      .attr("width", (d: any) => d.x1 - d.x0 - 15)
      .attr("height", (d: any) => d.y1 - d.y0 - 20)
      .attr("style", "cursor:pointer")
      .append("xhtml:p")
      .style("fill", "black")
      .html((d: any) => {
        const width = d.x1 - d.x0 - 5;
        const height = d.y1 - d.y0;
        return /*html*/ `<div class="treemap-rect">
            <div class="treemap-rect-header">
              ${
                d.data.violation
                  ? '<div class="treemap-error-badge"></div>'
                  : ""
              }
              ${
                width > 100
                  ? `<div class="treemap-rect-label" style="font-size:12px">${getFileName(
                      d.data.name
                    )}</div>`
                  : ""
              }
            </div>
            ${
              height > 87 && width > 100
                ? `<div class="treemap-rect-value" style="font-size:32px;font-weight:500">${`${
                    d.data.value <= 9 ? "0" : ""
                  }${d.data.value}`}</div>`
                : ""
            }
          </div>`;
      });
  };

  useEffect(() => {
    if (data?.length > 0) {
      drawTreeMap();
    }
  }, [data, selectedNode]);

  return (
    <>
      {data?.length > 1 ? (
        <Box sx={styles.graphContainer}>
          <div id="treemap-container" className="treeMapContainer"></div>
          <Box sx={styles.graphFooterContainer}>
            <Typography
              variant="caption"
              color={theme.palette.surface40.main}
              sx={styles.graphFooter}
            >
              Rectangle size & color represents the number of retrievals
            </Typography>
            <Divider orientation="vertical" sx={styles.divider} />
            <Box sx={styles.minMax}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Max
              </Typography>
              <Box sx={styles.colorIndicator}></Box>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Min
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <NoData customText="No data available" height="240px" />
      )}
    </>
  );
};

export default AppTreeMap;
