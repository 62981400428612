import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import integrationIcon from "../../../assets/img/icons/settings/integration.svg";
import { addOktaIntegrationAsync } from "../../../redux/slices/integrationSlice";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import "./IntegrationModal.scss";
import { getUserId } from "../../../utils/SessionHelper";
import Button from "../../common/Button";

// Functional component for Add Integration.
const OktaIntegrationModal = ({ handleModal, refreshIntegrations }) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [connectionFailed, setConnectionFailed] = useState(false);
  const [domains, setDomains] = useState([]);

  // Formik
  const formik = useFormik({
    initialValues: {
      ProviderName: "",
      MetadataFile: "",
      key: "",
      value: "",
      AllowedDomains: "",
    },
    validationSchema: Yup.object().shape({
      ProviderName: Yup.string().required("Please enter integration name."),
      MetadataFile: Yup.string().required("Please enter Metadata File Url."),
    }),
    onSubmit: (values) => {
      addIntegration({ ...values });
    },
  });

  const handleCancel = () => {
    handleModal(false);
  };

  const addIntegration = async (values) => {
    setShowLoader(true);
    setConnectionFailed(false);
    const resp = await dispatch(
      addOktaIntegrationAsync({
        ProviderName: values.ProviderName,
        MetadataFile: values.MetadataFile,
        key: ["email"],
        value: ["email"],
        userId: getUserId(),
      })
    );

    if (resp && resp.payload && resp.payload.msg) {
      Toaster(TOASTER_TYPES.SUCCESS, resp.payload.msg);
      setShowLoader(false);
      refreshIntegrations();
      handleModal(false);
    } else {
      Toaster(TOASTER_TYPES.ERROR, resp.payload.err.message);
      setShowLoader(false);
      setConnectionFailed(true);
    }
  };

  const handleDomainEnter = (event) => {
    if (
      event.key === "Enter" &&
      event.target.value &&
      !domains.includes(event.target.value)
    ) {
      setDomains([...domains, event.target.value]);
      event.target.value = "";
    }
  };

  const deleteDomain = (index) => {
    domains.splice(index, 1);
    setDomains([...domains]);
  };

  return (
    <div id="integration-modal">
      <div
        className="modal fade show"
        id="integrationModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="integrationModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-content">
              <div className="modal-header d-flex justify-content-start align-items-center">
                <img src={integrationIcon} className="mr-3" />
                <span>Add Okta Integration</span>
              </div>
              <div className="modal-body">
                {showLoader && (
                  <img
                    src="static/img/loader-circle.svg"
                    className="modal-loading-img"
                  />
                )}
                <label className="label">
                  Name <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="ProviderName"
                      placeholder="Integration Name"
                      name="ProviderName"
                      maxLength={50}
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("ProviderName", e.target.value);
                      }}
                      value={formik.values.ProviderName}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.ProviderName &&
                      formik.touched.ProviderName && (
                        <div className="error-message">
                          {formik.errors.ProviderName}
                        </div>
                      )}
                  </div>
                </div>

                <label className="label">
                  Metadata File Url <span className="mandatory-icon">*</span>
                </label>
                <div className="row py-2">
                  <div className="col col-lg-12">
                    <input
                      type="text"
                      id="MetadataFile"
                      placeholder="Metadata File Url"
                      name="MetadataFile"
                      className="form-control"
                      onChange={(e) => {
                        formik.setFieldValue("MetadataFile", e.target.value);
                      }}
                      value={formik.values.MetadataFile}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.MetadataFile &&
                      formik.touched.MetadataFile && (
                        <div className="error-message">
                          {formik.errors.MetadataFile}
                        </div>
                      )}
                  </div>
                </div>

                {/* <div className="row mt-2">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Key</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-6">
                    <input
                      type="text"
                      id="key"
                      placeholder="Key"
                      name="key"
                      maxLength={50}
                      className="modal-input-style px-2"
                      onChange={(e) => {
                        formik.setFieldValue("key", e.target.value);
                      }}
                      value={formik.values.userName}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.key && formik.touched.key && (
                      <div className="error-message">{formik.errors.key}</div>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col col-md-4 col-lg-3 d-flex justify-content-start align-items-center">
                    <b className="form-field-name ">Value</b>
                    <span className="mandatory-icon">*</span>
                  </div>
                  <div className="col col-lg-6">
                    <input
                      type="text"
                      id="value"
                      placeholder="Value"
                      name="value"
                      maxLength={50}
                      className="modal-input-style px-2"
                      onChange={(e) => {
                        formik.setFieldValue("value", e.target.value);
                      }}
                      value={formik.values.accountId}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.value && formik.touched.value && (
                      <div className="error-message">{formik.errors.value}</div>
                    )}
                  </div>
                </div> */}

                {connectionFailed && (
                  <div className="row mt-2">
                    <div className="col-9 offset-md-3 pl-0 text-danger">
                      Connection failed, please check the details and try again.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Add Integration
                </Button>
                <span className="separator mx-3"></span>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OktaIntegrationModal;
