import MUIMenu from "@mui/material/Menu";
import type { MenuProps } from "@mui/material/Menu";
import MUIMenuItem from "@mui/material/MenuItem";
import type { MenuItemProps } from "@mui/material/MenuItem";
import { useTheme } from "@mui/material";

export const Menu = (args: MenuProps) => {
  const theme = useTheme();
  const styles = {
    menu: {
      "& .MuiList-root": {
        backgroundColor: theme.palette.surface10.main,
      },
      "& .MuiPaper-root": {
        marginTop: theme.spacing(0.5),
        border: `1px solid ${theme.palette.surface20.main}`,
      },
    },
  };
  return <MUIMenu sx={styles.menu} {...args} />;
};

export const MenuItem = (args: MenuItemProps) => {
  return <MUIMenuItem {...args} />;
};
