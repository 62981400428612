import {
  axiosDenaliPrivate,
  axiosPrivate,
  axiosAILabDenaliPrivate,
} from "../../utils/ApiHandler";

/**
 * Provides the list of all datastore stats.
 * @returns -- list of all datastore stats.
 */
export const fetchDatastoreStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastorestats`, params);
};

/**
 * Provides the list of all datastore stats.
 * @returns -- list of all datastore stats.
 */
export const fetchIssueUberScore = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/issues/uberscore/get`, params);
};

/**
 * Provides the list of all issues stats.
 * @returns -- list of all issues stats.
 */
export const fetchIssuesStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/issues/stats`, params);
};

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const fetchAssetsStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/asset/stats`, params);
};

/**
 * Provides the list of all identities stats.
 * @returns -- list of all identities stats.
 */
export const fetchIdentitiesStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/identitystats`, params);
};
/**
 * Provides data-riskplot result.
 * @returns -- Datariskplot list
 */
export const fetchRiskPlot = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/dashboardplot`, params);
};

/**
 * Provides first account classification and risk complete status.
 * @returns -- Object with the status of classification and risk complete status.
 */
export const fetchFirstAccountStatStatus = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/get`, params);
};

/**
 * @returns -- Object with the status of classification and risk complete status.
 */
export const fetchDashboardStats = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/stats/inventory`, params);
};

/**
 * Provides the list of all data flows.
 * @returns -- list of all data flows.
 */
export const fetchDataFlows = (params) => {
  return axiosDenaliPrivate.post(`/api/v2/datastores/flows`, params);
};

/**
 * Provides the list of all achievements .
 * @returns -- list of all achievements.
 */
export const fetchAchievements = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/achievements`, params);
};

/**
 * Provides the trends data .
 * @returns -- trends data.
 */
export const fetchTrends = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/trends`, params);
};

/**
 * Provides the list of all data new flows.
 * @returns -- list of all data new flows.
 */
export const fetchDataNewFlows = (params) => {
  return axiosDenaliPrivate.post(`/api/v2/datastores/flows/new`, params);
};

/**
 * manage dashboard view.
 */
export const manageDashboardView = (params) => {
  return axiosPrivate.post(`/user/manageDashboardView`, params);
};

/**
 * fetch datastore regions.
 */
export const fetchDatastoreRegions = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/customer/datastore/regions`, params);
};

/**
 * fetch datastore regions.
 */
export const fetchAllRegionsData = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/datastore/sensitiveinfo`, params);
};

/**
 * fetch datastore regions.
 */
export const fetchAIRegions = (params) => {
  return axiosDenaliPrivate.post(`/api/vi/ai/models/regions`, params);
};

/**
 * fetch datastore regions.
 */
export const fetchFrameworkRegions = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/ai/apps/region`, params);
};

/**
 * fetch cloud issues.
 */
export const fetchCloudIssues = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/data/cloudissues`, params);
};

/**
 * fetch policy violations filters.
 */
export const fetchPolicyViolationFilters = (params) => {
  return axiosDenaliPrivate.post(
    `/api/v1/data/policyviolations/predefined`,
    params
  );
};

/**
 * fetch policy violations filters for ai dashboard.
 */
export const fetcAIPolicyViolationFilters = (params) => {
  return axiosDenaliPrivate.post(
    `/api/v1/ai/policyviolations/predefined`,
    params
  );
};

/**
 * fetch policy violations.
 */
export const fetchPolicyViolations = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/data/policyviolations`, params);
};

/**
 * fetch policy violations.
 */
export const fetchLLMPolicyViolations = (params) => {
  return axiosDenaliPrivate.post(`/api/v1/ai/policyviolations`, params);
};

/**
 * fetch llm listing
 */
export const fetchLLMListing = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/models", params);
};

/**
 * fetch training data
 */
export const fetchTrainingData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/trainingdata", params);
};

/**
 * fetch top datasource files data
 */
export const fetchDatasourceFiles = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/docs", params);
};

/**
 * fetch training data
 */
export const fetchSensitiveData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/trainingdatastats", params);
};

/**
 * fetch framework data
 */
export const fetchFrameworksData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps", params);
};

/**
 * fetch framework data
 */
export const fetchAITrainingData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/trainingdsinfo", params);
};

/**
 * fetch framework data
 */

export const fetchFrameworkData = (params) => {
  return axiosAILabDenaliPrivate.post("/api/v1/ai/apps", params);
};

/**
 * Fetch Findings Overview data for Dashboard Rings
 * @param {*} customerId
 * @returns
 */
export const fetchFindingOverview = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/overview", params);
};

/**
 * Fetch findings data for bar chart
 * @param {*} customerId
 * @returns
 */
export const fetchFindings = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/findings/", params);
};

export const fetchUserAccessData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/users/restricted/", params);
};

export const fetchDocAccessData = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/documents/accessed/", params);
};
