import { useTheme } from "@mui/material";
import MUITab, { TabProps } from "@mui/material/Tab";
import MUITabs, { TabsProps } from "@mui/material/Tabs";
import Box from "./Box";

export const Tabs = (props: TabsProps) => {
  const theme = useTheme();
  return (
    <MUITabs
      {...props}
      sx={{
        "&.MuiTabs-root": {
          minHeight: "13px",
          flexShrink: 0,
        },
      }}
      TabIndicatorProps={{
        sx: {
          height: "1px",
          backgroundColor: theme.palette.primaryBlue.main,
        },
      }}
    />
  );
};

interface CustomTabProps extends TabProps {
  sx?: any;
}

export const Tab = (props: CustomTabProps) => {
  const theme = useTheme();
  const styles = {
    muiTab: {
      fontSize: "13px",
      color: theme.palette.surface70.main,
      textTransform: "none",
      "&.Mui-selected": {
        color: theme.palette.surface80.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.MuiButtonBase-root": {
        padding: 0,
        minHeight: "21px",
        paddingY: "8px",
        marginRight: theme.spacing(2),
      },
      "&.MuiTab-root": {
        minWidth: "fit-content",
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
      ...props.sx,
    },
  };
  return <MUITab {...props} sx={styles.muiTab} />;
};

export const TabPanel = ({
  children,
  value,
  index,
  sx,
  ...props
}: {
  children: JSX.Element | string;
  value: string;
  index: string;
  sx?: any;
}) => {
  const styles = {
    tabPanel: {
      width: "100%",
      height: "100%",
    },
  };

  return (
    <Box
      role="tabpanel"
      sx={{ ...styles.tabPanel, ...sx }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && children}
    </Box>
  );
};
