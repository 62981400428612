import { useEffect, useState } from "react";
import Box from "../../common/Box";
import MessageDetails from "../MessageDetails";
import SensitiveMessageTable from "../SensitiveMessageTable";
import Loader from "../../common/Loader";
import { useTheme } from "@mui/material";

const SensitiveMessagesDetails = ({ isLoading, messageData }) => {
  const [selectedSensitiveLabel, setSelectedSensitiveLabel] = useState({
    label: "",
    type: "",
  });
  const [messages, setMessages] = useState([]);
  const theme = useTheme();

  const styles = {
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    summaryContent: {
      display: "grid",
      gridTemplateColumns: "30% auto",
      columnGap: theme.spacing(3),
    },
  };

  const setInitialLabel = () => {
    const data = Object.keys(messageData);
    for (let i = 0; i < data.length; i++) {
      const type = data[i];
      const childKeys = Object.keys(messageData[type]);

      // If the parent has non-empty children, return the first one
      if (childKeys.length > 0) {
        return { type, label: childKeys[0] };
      }
    }
    return null;
  };

  useEffect(() => {
    if (selectedSensitiveLabel?.label === "") {
      const label = setInitialLabel();
      if (label) setSelectedSensitiveLabel(label);
    }
    if (Object.keys(messageData)?.length > 0 && selectedSensitiveLabel?.label) {
      const res =
        messageData[selectedSensitiveLabel?.type][selectedSensitiveLabel?.label]
          ?.retrievals;
      setMessages(res);
    }
  }, [selectedSensitiveLabel, messageData]);

  return (
    <>
      {isLoading ? (
        <Box sx={styles.loader}>
          <Loader height="250px" />
        </Box>
      ) : (
        <Box sx={styles.summaryContent}>
          <SensitiveMessageTable
            retrievals={messageData}
            onRowClick={(label, type) => {
              setSelectedSensitiveLabel({ label, type });
            }}
            selectedSensitiveLabel={selectedSensitiveLabel}
          />
          <MessageDetails
            selectedSensitiveLabel={selectedSensitiveLabel}
            messagesList={messages}
            frameworkData={{}}
          />
        </Box>
      )}
    </>
  );
};

export default SensitiveMessagesDetails;
