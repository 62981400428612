import "./UpdateAccountModal.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import {
  updateStackAsync,
  updateAccountGCPAsync,
} from "../../../redux/slices/accountSlice";
import { TOASTER_TYPES, Toaster } from "../../../utils/toaster";
import {
  showLoading,
  hideLoading,
} from "../../../redux/slices/globalOperationSlice";
import { ACCOUNT_TYPES, LOADER_CONFIG } from "../../../utils/Constants";
import Button from "../../common/Button";
import { getUserId } from "../../../utils/SessionHelper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { FormControl, InputLabel } from "../../common/FormControl";
import { TextField } from "../../common/TextField";
import { MenuItem, Select } from "../../common/Select";
import { FormHelperText, styled, useTheme } from "@mui/material";

// Functional component for Add Account.
const UpdateAccountModal = ({
  handleModal,
  refreshAccounts,
  account,
  setEditDisabled,
  updateLaunchStack,
  selectedAccount,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [stackId, setStackId] = useState(null);
  const [gcpDetails, setGcpDetails] = useState({
    accountId: "",
    apiKey: "",
    scanScope: "",
  });
  const [emptyValue, setEmptyValue] = useState({
    accountIdMsg: "",
    apiKeyMsg: "",
    scanScopeMsg: "",
  });
  const [fileName, setFileName] = useState("");

  const DATA_SCANNING_FREQUENCY = [
    { label: "Daily", value: "rate(1 day)" },
    { label: "Weekly", value: "rate(7 days)" },
    { label: "Monthly", value: "rate(30 days)" },
  ];

  const [dataScanFrequency, setDataScanFrequency] = useState();
  const loading = useSelector((state) => state.globalOperation.loading);

  const styles = {
    gcpForm: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    accountId: {
      display: "flex",
      width: "100%",
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    textField: {
      "& .MuiFormLabel-root": {
        fontSize: theme.typography.caption,
      },
      "& .MuiInputBase-input": {
        fontSize: theme.typography.body2,
      },
    },
    modalFooter: {
      display: "flex",
      marginLeft: "auto",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    fileUpload: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(1),
      gap: theme.spacing(1),
      //  alignItems: "left",
    },
    fileName: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  };

  const updateStack = async () => {
    dispatch(showLoading());
    const resp = await dispatch(
      updateStackAsync({
        id: account.id,
        stackId: stackId,
        dataScanFrequency: dataScanFrequency,
      })
    );
    dispatch(hideLoading());
    if (resp?.payload?.update && !resp?.payload?.err) {
      setEditDisabled(true);
      window.open(resp.payload?.update);
      refreshAccounts(); // TODO: Refresh accounts page if need be.
      handleModal(false);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload.err?.message || "Failed to update account."
      );
    }
  };

  const handleLaunchStack = () => {
    updateStack();
  };

  const handleCancel = () => {
    handleModal(false);
  };

  const versions = [
    { value: "0.0.1", label: "0.0.1" },
    { value: "0.0.2", label: "0.0.2" },
  ];

  useEffect(() => {
    if (selectedAccount?.id) {
      setGcpDetails({
        accountId: selectedAccount?.id,
        scanScope: selectedAccount?.scanType,
      });
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (
      account?.dataScanFrequency === "Monthly" ||
      account?.dataScanFrequency === "rate(30 days)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[2].value);
    } else if (
      account?.dataScanFrequency === "Weekly" ||
      account?.dataScanFrequency === "rate(7 days)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[1].value);
    } else if (
      account?.dataScanFrequency === "Daily" ||
      account?.dataScanFrequency === "rate(1 day)"
    ) {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[0].value);
    } else {
      setDataScanFrequency(DATA_SCANNING_FREQUENCY[0].value);
    }
  }, [account]);

  const updateGCPAccount = async () => {
    if (
      gcpDetails?.accountId &&
      gcpDetails?.serviceAccountKey &&
      gcpDetails?.serviceAccountKey !== ""
    ) {
      setEmptyValue({ accountIdMsg: "", apiKeyMsg: "", scanScopeMsg: "" });
      let payload = {
        id: gcpDetails?.accountId,
        userId: getUserId(),
        type: selectedAccount?.type,
        scanScope: gcpDetails?.scanScope || selectedAccount?.scanScope,
        serviceAccountKey: gcpDetails.serviceAccountKey,
      };

      const resp = await dispatch(updateAccountGCPAsync(payload));
      dispatch(hideLoading());
      if (resp) {
        setEditDisabled(true);
        refreshAccounts(); // TODO: Refresh accounts page if need be.
        Toaster(TOASTER_TYPES.SUCCESS, "Account updated successfully.");
        handleModal(false);
      } else {
        Toaster(
          TOASTER_TYPES.ERROR,
          resp.payload.err?.message || "Failed to update account."
        );
      }
    } else {
      setEmptyValue({ ...emptyValue, apiKeyMsg: "Please select a JSON File" });
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const ScanScopeOptions = [
    {
      label: "AI-only",
      value: "ai-scan",
    },
    {
      label: "Full Cloud",
      value: "full-scan",
    },
  ];

  return (
    <div id="update-account">
      <div
        className="modal fade show"
        id="updateModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateModalTitle"
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          {/* Global loader for any api calls inside update account */}
          <BlockUi
            tag="div"
            blocking={loading}
            loader={
              <Loader
                active
                type={LOADER_CONFIG.type}
                color={LOADER_CONFIG.color}
              />
            }
          >
            <div className="modal-content">
              <div className="modal-header pl-0 d-flex justify-content-start align-items-center">
                {/* <img src={updateAccountIcon} className="mr-3" /> */}
                <span>Update Account Stack</span>
              </div>
              {selectedAccount?.type === ACCOUNT_TYPES.AWS ? (
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-3 pl-0"> Account ID:</div>
                    <div className="col-md-5 modal-text">{account.id}</div>
                    <div className="col-md-4 modal-text">
                      {(account.status &&
                        account.status === "creating_stack") ||
                      account.status === "24_hour_passed" ? (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={updateLaunchStack}
                        >
                          Launch create-stack
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3 pl-0"> External ID:</div>
                    <div className="col-md-8 modal-text">
                      {account.externalId}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-3 pl-0"> AWS Role ARN:</div>
                    <div className="col-md-8 modal-text">
                      {account.awsRoleARN}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-3 pl-0"> Stack Id:</div>
                    <div className="col-md-9 pr-0">
                      <input
                        type="text"
                        className="form-control"
                        value={stackId}
                        onChange={(e) => setStackId(e.target.value)}
                        disabled={account.status !== "active"}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-3 pl-0">
                      {" "}
                      Data Scanning Frequency:
                    </div>
                    <div className="col-md-9 pr-0">
                      <select
                        className="form-control"
                        value={dataScanFrequency}
                        onChange={(event) => {
                          setDataScanFrequency(event.target.value);
                        }}
                      >
                        <option disabled>Select Data Scanning Frequency</option>
                        {DATA_SCANNING_FREQUENCY.map((frequency, index) => (
                          <option key={index} value={frequency.value}>
                            {frequency.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row modal-message mt-4 pt-4">
                    <div className="col-md-12 pl-0">
                      Please click on update stack button to update the stack.
                    </div>
                  </div>
                </div>
              ) : selectedAccount?.type === ACCOUNT_TYPES.GCP ? (
                <Box sx={styles.gcpForm}>
                  <Box sx={styles.accountId}>
                    <Typography variant="caption">Account ID:</Typography>
                    <Typography variant="caption">
                      {selectedAccount?.id}
                    </Typography>
                  </Box>
                  <FormControl variant="standard">
                    {/*<TextField
                      error={emptyValue.apiKeyMsg !== ""}
                      helperText={emptyValue.apiKeyMsg}
                      label="API Key"
                      value={gcpDetails?.apiKey}
                      variant="standard"
                      sx={styles.textField}
                      onChange={(event) =>
                        setGcpDetails({
                          ...gcpDetails,
                          apiKey: event.target.value,
                        })
                      }
                    />*/}
                    <Box sx={styles.fileUpload}>
                      <Typography variant="caption">
                        Choose JSON File
                      </Typography>
                      <Box sx={styles.fileName}>
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<CloudUploadIcon />}
                          component="label"
                        >
                          Select File
                          <VisuallyHiddenInput
                            type="file"
                            accept=".json"
                            onChange={async ({ target }) => {
                              const toBase64 = async (file) =>
                                new Promise((resolve, reject) => {
                                  const reader = new FileReader();
                                  reader.readAsDataURL(file);
                                  reader.onload = () => resolve(reader.result);
                                  reader.onerror = (error) => reject(error);
                                });
                              setEmptyValue({ ...emptyValue, apiKeyMsg: "" });
                              setFileName(target.files[0].name);
                              const file = await toBase64(target.files[0]);
                              setGcpDetails({
                                ...gcpDetails,
                                serviceAccountKey: file.split(",")[1],
                              });
                            }}
                          />
                        </Button>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {fileName || "No File Selected"}
                        </Typography>
                      </Box>
                      <Typography
                        variant="caption"
                        color={theme.palette.critical.main}
                      >
                        {emptyValue?.apiKeyMsg}
                      </Typography>
                    </Box>
                  </FormControl>

                  <Select
                    label="Scan Scope"
                    fullWidth={true}
                    variant="standard"
                    value={gcpDetails?.scanScope}
                    options={ScanScopeOptions}
                    onChange={(event) =>
                      setGcpDetails({
                        ...gcpDetails,
                        scanScope: event.target.value,
                      })
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              ) : (
                ""
              )}
              <div className="modal-footer pb-4 pt-0">
                {selectedAccount?.type === ACCOUNT_TYPES.AWS ? (
                  <Button
                    variant="contained"
                    type="button"
                    onClick={handleLaunchStack}
                    disabled={
                      loading || account.status !== "active" || !stackId
                    }
                  >
                    Update Stack
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    type="button"
                    onClick={updateGCPAccount}
                  >
                    Update Stack
                  </Button>
                )}
                <span className="separator mx-3"></span>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => handleCancel()}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </BlockUi>
        </div>
      </div>
    </div>
  );
};

export default UpdateAccountModal;
