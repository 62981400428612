import { useEffect, useMemo, useState } from "react";
import CardWithAction from "./CardWithAction";
import CustomTable from "../common/CustomTable";
import Box from "../common/Box";
import Typography from "../common/Typography";
import { useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import Bar from "../common/Bar";
import { useDispatch } from "react-redux";
import { fetchActiveUsersSlice } from "../../redux/slices/userSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { AppDispatch } from "../../redux/store";
import { getPropertyTotalCount } from "../../utils/commonUtils";

export const SHOW_USERS_COUNT = 5;

interface User {
  name: string;
  retrievals: number;
  applications: any[];
  sensitive_data_prompts: number;
  sensitive_data_responses: number;
}

const UsersCard = () => {
  const [userData, setUserData] = useState<Array<User>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const styles = {
    userGroups: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    chip: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    flex: {
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1.25),
      width: "250px",
    },
  };

  const gotoUserDetails = (user) => {
    history.push(`/users/${user?.name}`);
  };

  const tableCols = [
    {
      id: 1,
      title: "Name",
      render: (data) => {
        return (
          <Box sx={styles.userGroups}>
            <Box sx={styles.chip}>
              <Typography variant="body2" color={theme.palette.surface60.main}>
                {data?.name}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 2,
      title: "Retrievals",
      render: (data) => (
        <Box sx={styles.flex}>
          <Typography variant="h6" isNumber={true}>
            {data?.retrievals}
          </Typography>
          <Bar
            isLastBar={false}
            xLabel={""}
            barBackColor={theme.palette.surface20.main}
            barFaceColor={theme.palette.lightMagenta.main}
            rectWidth={
              (data?.retrievals /
                getPropertyTotalCount(Object.values(userData), "retrievals")) *
              100
            }
            index={data?.retrievals}
            totalValue={4}
            barLabel={""}
            barValue={data?.retrievals}
            type={""}
          />
        </Box>
      ),
    },
    {
      id: 3,
      title: "Applications",
      render: (data) => (
        <Typography variant="h6" isNumber={true}>
          {data?.applications?.length}
        </Typography>
      ),
    },
  ];

  const cardData = useMemo(
    () => ({
      users: {
        title: "Most Active Users",
        children: (
          <CustomTable
            tableCols={tableCols}
            tableData={userData}
            tableHeight="320px"
            onRowClick={gotoUserDetails}
          />
        ),
      },
    }),
    [userData]
  );

  const gotoAllUsers = () => {
    history.push("/users");
  };

  const fetchActiveUsers = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      count: SHOW_USERS_COUNT,
    };
    const response = await dispatch(fetchActiveUsersSlice(payload));
    if (response?.payload) {
      const userList = Object.keys(response.payload).map((key) => ({
        name: key,
        ...response.payload[key],
      }));
      setUserData(userList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchActiveUsers();
  }, []);

  return (
    <CardWithAction
      cardData={cardData}
      buttonTitle="View All"
      onClick={gotoAllUsers}
      isLoading={isLoading}
    />
  );
};

export default UsersCard;
