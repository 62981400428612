import MUIFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import MUIFormControl, { FormControlProps } from "@mui/material/FormControl";
import MUIInputLabel, { InputLabelProps } from "@mui/material/InputLabel";

export const FormControl = (props: FormControlProps) => {
  return <MUIFormControl {...props} />;
};

export const InputLabel = (props: InputLabelProps) => {
  return <MUIInputLabel {...props} />;
};

export const FormControlLabel = (props: FormControlLabelProps) => {
  return <MUIFormControlLabel {...props} />;
};
