import { useTheme } from "@mui/material";
import Button from "../common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import Typography from "../common/Typography";

const DeleteTopic = ({ open, onClose, onDelete, topicName }) => {
  const theme = useTheme();
  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
      paddingRight: theme.spacing(15),
    },
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle handleClose={onClose}>Delete Topic</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          It will permanently delete the policy "{topicName}"
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTopic;
