import { useEffect, useState } from "react";
import Box from "../../common/Box";
import { useTheme } from "@mui/material";
import SensitiveMessageTable from "../SensitiveMessageTable";
import SnippetDetails from "./SnippetDetails";

export const DataSourceMessageDetails = ({
  messageData,
  frameworkData,
  isMessageLoading,
}) => {
  const [selectedSensitiveLabel, setSelectedSensitiveLabel] = useState({
    label: "",
    type: "",
  });
  const [messages, setMessages] = useState([]);
  const theme = useTheme();

  const styles = {
    summaryContent: {
      display: "grid",
      gridTemplateColumns: "30% auto",
      columnGap: theme.spacing(3),
      alignItems: "flex-start",
      height: "100%",
    },
  };

  const setInitialLabel = () => {
    const data = Object.keys(messageData);
    for (let i = 0; i < data.length; i++) {
      const type = data[i];
      const childKeys = Object.keys(messageData[type]);

      // If the parent has non-empty children, return the first one
      if (childKeys.length > 0) {
        return { type, label: childKeys[0] };
      }
    }
    return null;
  };

  useEffect(() => {
    if (messageData && Object.keys(messageData).length > 0) {
      if (selectedSensitiveLabel?.label) {
        const res =
          messageData[selectedSensitiveLabel?.type][
            selectedSensitiveLabel?.label
          ]?.findings;
        setMessages(res);
      } else {
        const label = setInitialLabel();
        if (label) setSelectedSensitiveLabel(label);
      }
    }
  }, [selectedSensitiveLabel, messageData]);

  return (
    <Box sx={styles.summaryContent}>
      <SensitiveMessageTable
        retrievals={messageData}
        onRowClick={(label, type) => {
          setSelectedSensitiveLabel({ label, type });
        }}
        selectedSensitiveLabel={selectedSensitiveLabel}
      />
      <SnippetDetails
        isMessageLoading={isMessageLoading}
        sensitiveData={messageData}
        messagesList={messages}
        selectedSensitiveLabel={selectedSensitiveLabel}
        frameworkData={frameworkData}
      />
    </Box>
  );
};
