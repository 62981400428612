import { useTheme } from "@mui/material";
import { useState } from "react";
import Box from "../common/Box";
import Button from "../common/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../common/Dialog";
import { TextField } from "../common/TextField";
import Typography from "../common/Typography";

const GenerateAPIKey = ({ open, onClose, onAdd }) => {
  const [name, setName] = useState("");
  const theme = useTheme();
  const styles = {
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(2),
    },
    description: {
      paddingRight: theme.spacing(15),
    },
    font12: {
      fontSize: "12px",
    },
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle handleClose={onClose}>Generate New API Key</DialogTitle>
      <Box sx={styles.dialogContent}>
        <Box sx={styles.description}>
          <Typography color={theme.palette.surface50.main} sx={styles.font12}>
            Do not share API Keys with others, or expose it in the <br />
            browser or code.
          </Typography>
        </Box>
        <TextField
          label="Name"
          variant="standard"
          fullWidth
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onAdd({ name });
            setName("");
          }}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateAPIKey;
