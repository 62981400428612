import { useTheme } from "@mui/material";
import Box from "../../../components/common/Box";
import Typography from "../../../components/common/Typography";
import NoData from "../../../components/common/NoData";

const LabelCard = ({ isSelected, label, isHarmful, count, onChange }) => {
  const theme = useTheme();
  const styles = {
    label: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    card: {
      cursor: "pointer",
      display: "flex",
      gap: theme.spacing(0.75),
      flexDirection: "column",
      padding: theme.spacing(2),
      backgroundColor: isSelected ? theme.palette.surface0.main : "transparent",
      borderLeft: isSelected
        ? `1px solid ${theme.palette.primaryBlue.main}`
        : "1px solid transparent",
    },
  };
  return (
    <Box sx={styles.card} onClick={onChange}>
      <Box sx={styles.label}>
        <Typography variant="body2" color={theme.palette.surface80.main}>
          {label}
        </Typography>
      </Box>
      <Typography variant="caption" color={theme.palette.surface30.main}>
        {count} Snippets
      </Typography>
    </Box>
  );
};

const LabelWithSnippetCount = ({
  onChange,
  labels,
  selected,
}: {
  onChange: (label: string) => void;
  labels: { label: string; value: any[] }[];
  selected: string;
}) => {
  const theme = useTheme();
  const styles = {
    list: {
      height: "100%",
      overflowY: "auto",
    },
  };

  return (
    <Box sx={styles.list}>
      {labels && labels?.length ? (
        labels.map((label) => (
          <LabelCard
            label={label?.label}
            count={label?.value}
            isHarmful={false}
            isSelected={selected === label?.label}
            onChange={() => onChange(label?.label)}
          />
        ))
      ) : (
        <NoData customText="No Labels" />
      )}
    </Box>
  );
};

export default LabelWithSnippetCount;
