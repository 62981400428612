import MUIBreadcrumbs, { BreadcrumbsProps } from "@mui/material/Breadcrumbs";
import MUILink, { LinkProps } from "@mui/material/Link";

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  return <MUIBreadcrumbs {...props} />;
};

export const Link = (props: LinkProps) => {
  return <MUILink {...props} />;
};
