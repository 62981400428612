import { useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Divider from "../common/Divider";
import DonutChart from "../common/DonutChart";
import LegendWithColor from "../common/LegendWithColor";
import Loader from "../common/Loader";
import MetricBlock from "../common/MetricBlock";
import Typography from "../common/Typography";
import ImpactedDataCard from "./ImpactedDonutCard";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { fetchFindingOverviewSlice } from "../../redux/slices/dashboardSlice";
import HeroStat from "./HeroStat";
import { ToggleButtonGroup } from "../common/ToggleButton";
import { AppDispatch } from "../../redux/store";

const ImpactedDataStoresPanel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [toggleView, setToggleView] = useState("Overview");
  const [findingOverview, setFindingOverview] = useState<any>([]);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const styles = {
    donutCardContainer: {
      position: "relative",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    donuts: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      alignItems: "center",
      paddingX: theme.spacing(6),
    },
    allDataImpactedText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
      maxWidth: "5rem",
    },
    sensitiveDataText: {
      color: theme.palette.surface70.main,
      fontWeight: theme.typography.fontWeightRegular,
      maxWidth: "9.375rem",
    },
    highlightDataText: {
      color: theme.palette.surface80.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    legendContainer: {
      position: "absolute",
      bottom: 0,
      right: 0,
      display: "flex",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      alignItems: "center",
      zIndex: 20,
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: theme.palette.surface40.main,
      marginY: 0,
    },
    legendGroup: {
      display: "flex",
      gap: theme.spacing(3),
    },
    totalDataStore: {
      opacity: 0.5,
    },
    font13: {
      fontSize: "13px",
    },
    statGrid: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    toggle: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1.25),
      borderLeft: `1px solid ${theme.palette.surface20.main}`,
      padding: theme.spacing(2),
      justifyContent: "center",
    },
    toggleButton: {
      background: theme.palette.surface5.main,
    },
  };

  const fetchFindingOverview = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
    };
    const response = await dispatch(fetchFindingOverviewSlice(payload));
    if (response.payload) {
      const metrics = response.payload;
      setFindingOverview(metrics);
    }
    setIsLoading(false);
  };

  const stats = useMemo(() => {
    if (findingOverview) {
      return [
        {
          label: "Documents",
          outOf: findingOverview?.documents?.total,
          value: findingOverview?.documents?.with_findings,
          suffix: " with Findings",
          variant: "AutoColor",
        },
        {
          label: "Applications",
          outOf: findingOverview?.applications?.total,
          value: findingOverview?.applications?.with_findings,
        },
        {
          label: "Users",
          value: findingOverview?.users?.anamolous_users,
          outOf: findingOverview?.users?.total,
          suffix: "Anamolous",
        },
      ];
    } else return [];
  }, [findingOverview]);

  const toggleButtons = [
    {
      id: "overview",
      label: "Overview",
    },
    {
      id: "policy_violations",
      label: "Policy Violations",
    },
  ];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setToggleView(newValue);
    }
  };

  useEffect(() => {
    fetchFindingOverview();
  }, []);

  return (
    <Box sx={styles.donutCardContainer}>
      {isLoading ? (
        <Loader height="100px" />
      ) : (
        <Box sx={styles.statGrid}>
          {stats.map((stat) => (
            <HeroStat {...stat} />
          ))}
          {/*<Box sx={styles.toggle}>
            <Typography variant="body2" color={theme.palette.surface60.main}>
              Show Data As
            </Typography>{" "}
            <ToggleButtonGroup
              exclusive
              options={toggleButtons}
              value={toggleView}
              onChange={handleChange}
              sx={styles.toggleButton}
              defaultValue={toggleView}
            />
          </Box>*/}
        </Box>
      )}
    </Box>
  );
};

export default ImpactedDataStoresPanel;
