import { useTheme } from "@mui/material";
import Box from "./Box";

interface LogoWrapperProps {
  src: string;
  alt: string;
  height?: string;
  bgColor?: string;
}

const LogoWrapper = ({ src, height, alt, bgColor }: LogoWrapperProps) => {
  const theme = useTheme();
  const styles = {
    bg: {
      display: "flex",
      width: "32px",
      height: "32px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: bgColor || "#2E3351",
      borderRadius: "50%",
    },
  };
  return (
    <Box sx={styles.bg}>
      <img src={src} height={height || "14px"} alt={alt} />
    </Box>
  );
};

export default LogoWrapper;
