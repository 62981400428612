import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDataSourceSnippets,
  fetchPolicyViolations,
  suggestClassificationLabelForDoc,
  suggestClassificationLabelForPrompt,
} from "../apis/frameworkPageAPI";

const initialState = {
  policyViolations: [],
  datasource: [],
};

export const fetchPolicyViolationsSlice = createAsyncThunk(
  "framework/policyViolations",
  async (params) => {
    const response = await fetchPolicyViolations(params);
    return response.data;
  }
);

export const fetchDataSourceSnippetsSlice = createAsyncThunk(
  "framework/datasource",
  async (params) => {
    const response = await fetchDataSourceSnippets(params);
    return response.data;
  }
);

export const suggestClassificationLabelForPromptSlice = createAsyncThunk(
  "framework/suggestPromptLabel",
  async (params) => {
    const response = await suggestClassificationLabelForPrompt(params);
    return response.data;
  }
);

export const suggestClassificationLabelForDocSlice = createAsyncThunk(
  "framework/suggestDocLabel",
  async (params) => {
    const response = await suggestClassificationLabelForDoc(params);
    return response.data;
  }
);

// LLM slice for configuring reducers and actions.
export const frameworkPageSlice = createSlice({
  name: "framework",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolicyViolationsSlice.fulfilled, (state, action) => {
        state.policyViolations = action.payload;
      })
      .addCase(fetchPolicyViolationsSlice.rejected, (state) => {
        state.policyViolations = [];
      })
      .addCase(fetchDataSourceSnippetsSlice.fulfilled, (state, action) => {
        state.datasource = action.payload;
      })
      .addCase(fetchDataSourceSnippetsSlice.rejected, (state) => {
        state.datasource = [];
      });
  },
});

export default frameworkPageSlice.reducer;
