import moment from "moment";
import LangchainIcon from "../assets/img/langchainIcon.svg";
import RDSIcon from "../assets/img/rds.svg";
import PDFIcon from "../assets/img/pdf.svg";
import PostgresIcon from "../assets/img/postgres.svg";
import GPTIcon from "../assets/img/gptIcon.svg";
import S3Icon from "../assets/img/AWS_S3.svg";
import VectorDBIcon from "../assets/img/chromaIcon.svg";
import HuggingIcon from "../assets/img/hugging.png";
import GCPIcon from "../assets/img/gcp.svg";
import UnstructuredIcon from "../assets/img/unstructured_logo.png";
import ExcelIcon from "../assets/img/xls-icon.svg";
import CSVIcon from "../assets/img/csv-icon.svg";
import JSONIcon from "../assets/img/json-icon.svg";
import PandaIcon from "../assets/img/pandas.svg";
import AWSIcon from "../assets/img/awsIcon.svg";
import ChromaIcon from "../assets/img/chromaIcon.svg";
import QdrantIcon from "../assets/img/qdrant-icon.png";
import GoogleDriveIcon from "../assets/img/google-drive-logo.png";
import OllamaIcon from "../assets/img/ollama-logo.png";
import SharepointIcon from "../assets/img/sharepoint.png";
import TextIcon from "../assets/img/text-icon.svg";
import DirectoryIcon from "../assets/img/directory-icon.svg";
import FileIcon from "../assets/img/file-icon.svg";
import SnowflakeIcon from "../assets/img/snowflake.svg";
import DatavoloIcon from "../assets/img/datavolo.png";
import DocIcon from "../assets/img/docx-icon.svg";
import TxtIcon from "../assets/img/txt-icon.svg";

const commonUtils = {};
export default commonUtils;

/**
 *
 * @param date1
 * @param date2
 * @returns returns difference between two dates in days
 */
export const getDifferenceInDays = (date1: Date, date2: Date) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30; // Approximation
  const msPerYear = msPerDay * 365; // Approximation

  const elapsed = Math.abs(date2.getTime() - date1.getTime());

  if (elapsed < msPerMinute) {
    return "Just now";
  } else if (elapsed < msPerHour) {
    const minutes = Math.round(elapsed / msPerMinute);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerDay) {
    const hours = Math.round(elapsed / msPerHour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerWeek) {
    const days = Math.round(elapsed / msPerDay);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerMonth) {
    const weeks = Math.round(elapsed / msPerWeek);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (elapsed < msPerYear) {
    const months = Math.round(elapsed / msPerMonth);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    const years = Math.round(elapsed / msPerYear);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
};

/**
 *
 * @param number
 * @returns number with 0 prefix if less than 10
 */
export const prefixNumber = (number: number) => {
  return number > 0 && number < 10 ? `0${number}` : number;
};

/**
 *
 * @param object
 * @returns checks weather object contains any value or not
 */
export const isContainsValue = (data: object) => {
  if (data && Object.values(data)?.length > 0) {
    return true;
  }
  return false;
};

/**
 *
 * @param object
 * @returns checks weather string contains word vulnerable and returns string without vulnerable
 */
export const getFormattedTitle = (title: string) => {
  if (title.toLocaleLowerCase().includes("vulnerable")) {
    return title.replace("Vulnerable ", "");
  }
  return title;
};

/**
 *
 * @param num
 * @returns number abbreviation
 */
export const getNumberAbbreviation = (num) => {
  if (num) {
    if (num > 9999) {
      if (num < 1e6) return +(num / 1e3).toFixed(1) + "K";
      if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M";
    }
    return num?.toString();
  }
  return "-";
};

/**
 *
 * @param str
 * @returns remove all the special characters
 */
export const removeSpecialCharacters = (str) => {
  return str && str !== "" ? str.replace(/[^a-zA-Z0-9 ]/g, "") : "";
};

/**
 * object property name to formatted string
 * @param title
 * @returns formatted string
 */
export const formatTitle = (title) => {
  let formatedTitle = title.replaceAll("_", " ");
  let arr = formatedTitle.split(" ");

  for (var index = 0; index < arr.length; index++) {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
  }
  return arr.join(" ");
};

/**
 * convert bytes to GB
 * @param bytes
 * @param decimals
 * @returns
 */
export const formatBytes = (bytes, decimals = 3) => {
  if (!bytes) return "-";
  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  // Handle extremely small byte values
  const sizeValue = bytes / Math.pow(1024, Math.max(i, 0));

  // Display up to 2 decimal places but trim unnecessary zeros
  return `${parseFloat(sizeValue.toFixed(decimals))} ${sizes[Math.max(i, 0)]}`;
};

export const isToday = (date) => {
  const today = moment().format("YYYY-MM-DD");
  const dateInQuestion = moment(date).format("YYYY-MM-DD");
  return today === dateInQuestion;
};

export const getSelectedAccountIds = (selectedAccounts) => {
  let accountIds: Array<string> = [];
  if (selectedAccounts?.length > 0) {
    selectedAccounts.map((account: { value: string }) => {
      accountIds.push(account.value);
    });
  }
  return accountIds;
};

export const getMMMDDYYYY = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

export const getDateTime = (date) => {
  return date ? moment(date).format("MMM DD, YYYY, hh:mm A") : "";
};

/**
 * object property name to formatted string
 * @param type
 * @returns icon based on type
 */

export const getImageForNode = (type) => {
  switch (type) {
    case "langchain": {
      return {
        width: 50,
        height: 50,
        imgSrc: LangchainIcon,
      };
    }
    case "rds": {
      return {
        width: 40,
        height: 40,
        imgSrc: RDSIcon,
      };
    }
    case "S3FileLoader":
    case "S3DirLoader":
    case "s3": {
      return {
        width: 40,
        height: 40,
        imgSrc: S3Icon,
      };
    }
    case "llm": {
      return {
        width: 40,
        height: 40,
        imgSrc: HuggingIcon,
      };
    }
    case "CSVLoader":
    case "pdf": {
      return {
        width: 40,
        height: 40,
        imgSrc: PDFIcon,
      };
    }
    case 'PGVector':
    case "postgres": {
      return {
        width: 40,
        height: 40,
        imgSrc: PostgresIcon,
      };
    }
    case "vectorDB": {
      return {
        width: 40,
        height: 40,
        imgSrc: VectorDBIcon,
      };
    }
    case "openai": {
      return {
        width: 40,
        height: 40,
        imgSrc: GPTIcon,
      };
    }
    case "Chroma": {
      return {
        width: 40,
        height: 40,
        imgSrc: ChromaIcon,
      };
    }
    case "Qdrant": {
      return {
        width: 40,
        height: 40,
        imgSrc: QdrantIcon,
      };
    }
    case "GoogleDriveLoader": {
      return {
        width: 40,
        height: 40,
        imgSrc: GoogleDriveIcon,
      };
    }
    case "Ollama": {
      return {
        width: 40,
        height: 40,
        imgSrc: OllamaIcon,
      };
    }
    default:
      return {
        width: 40,
        height: 40,
        imgSrc: PDFIcon,
      };
  }
};

export const getLoaderIcon = (loaderName) => {
  switch (true) {
    case loaderName?.toLocaleLowerCase().includes("pg"): 
    case loaderName?.toLocaleLowerCase().includes("postgres"): {
      return PostgresIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("s3"): {
      return S3Icon;
    }
    case loaderName?.toLocaleLowerCase().includes("textract"): {
      return AWSIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("google"): {
      return GoogleDriveIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("gcs"):
    case loaderName?.toLocaleLowerCase().includes("gcp"): {
      return GCPIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("unstructured"): {
      return UnstructuredIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("datavolo"): {
      return DatavoloIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("excel"): {
      return ExcelIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("panda"): {
      return PandaIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("langchain"): {
      return LangchainIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("openai"): {
      return GPTIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("qdrant"): {
      return QdrantIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("sharepoint"): {
      return SharepointIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("ollama"): {
      return OllamaIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("snowflake"): {
      return SnowflakeIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("text"): {
      return TextIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("txt"): {
      return TxtIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("directory"): {
      return DirectoryIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("file"): {
      return FileIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("pdf"): {
      return PDFIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("json"): {
      return JSONIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("csv"): {
      return CSVIcon;
    }
    case loaderName?.toLocaleLowerCase().includes("doc"): {
      return DocIcon;
    }
    default: {
      return "";
    }
  }
};

export const getMaxValue = (propName, array) => {
  return array?.length > 0
    ? Math.max(...array?.map((arrItem) => arrItem[propName]))
    : 1;
};

export const getFirstNValue = (array, n = 2) => {
  if (array && array.length) {
    const newArr = [...array];
    return newArr?.length > n
      ? `${newArr?.splice(0, n)?.join(", ")} +${newArr?.length}`
      : newArr?.join(", ");
  }
  return "-";
};

export const getSumOfValues = (array: number[]) => {
  let count = 0;
  if (array) {
    count = Object.values(array)?.reduce(
      (acc: number, curr: any) => acc + curr,
      0
    );
  }
  return count;
};

export const convertUTCToLocal = (date) => {
  if (date) {
    return moment.utc(date).local();
  }
  return "";
};

export const getTruncatedString = (str, length, showMore) => {
  if (showMore) {
    return str.substring(0, str.length);
  }
  return str?.length > length ? str.substring(0, length) + "..." : str;
};

export const getDoubleDigitNumber = (num) => {
  if (num && num > 0 && num < 10) return `0${num}`;
  else return num;
};

export const getShortenedString = (str) => {
  if (str) {
    if (str.length > 35) {
      return str.slice(0, 15) + "...." + str.slice(-20);
    } else return str;
  }
  return "";
};

export const getAllValuesSum = (obj, prop) => {
  let sum: any = 0;
  if (obj && Object.values(obj)?.length > 0) {
    sum =
      Object.values(obj).reduce(
        (a: any, b: any) => a + (prop ? b[prop] : b),
        0
      ) || 0;
  }
  return sum;
};

export const getJoinedString = (arr, delimiter = ",") => {
  if (arr && arr.length) {
    return arr.join(delimiter);
  }
  return "";
};

export const generateClassForBarGraph = (string) => {
  const newStr = string.includes(" ") ? string.split(" ")[0] : string;
  return `${newStr.replace(/[^\w\s]/gi, "")}`;
};

export const getFileName = (name) => {
  if (name) {
    return name.split("/")?.at(-1);
  }
  return "";
};

export const convertYYYYMMDD = (date, time = true) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const getPropertyTotalCount = (array, prop) => {
  return array?.length > 0
    ? array?.reduce((acc, curr) => acc + curr[prop], 0)
    : 0;
};

export const getFileExtension = (fileName) => {
  if (!fileName) return "file";

  if (fileName.toLowerCase().includes("csv")) return "csv";

  if (fileName.toLowerCase().includes("json")) return "json";

  if (fileName.toLowerCase().includes("pdf")) return "pdf";

  if (fileName.toLowerCase().includes("docx")) return "docx";

  if (fileName.toLowerCase().includes("txt")) return "txt";

  return "file";
};
