import moment from "moment";
import React, { useEffect, useState } from "react";
import { Graph } from "react-d3-graph";
import { useHistory } from "react-router-dom";
import closeIcon from "../../../../assets/img/icons/close-icon-grey.svg";
import { config } from "./TrainingDataGraphLinearConfig";
import "../TrainingDataGraphView.scss";
import { getNodeIconByType } from "../../d3-flow-chart/flowChartHelper";
import Box from "../../../common/Box";
import GraphSelectedDataCard from "../GraphSelectedDataCard";

const TrainingDataGraphLineageView = ({
  llmInfo,
  width,
  datastoreId,
  trainingData,
  modelName,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const history = useHistory();

  // const TrainingData = [
  //   {
  //     FileName: "training.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training.json",
  //     Source: "Configured",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "4.16 GB",
  //     type: "s3",
  //     training_date: "2023-08-01T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_2.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_2.json",
  //     Source: "Configured",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "2.45 GB",
  //     type: "s3",
  //     training_date: "2023-05-15T04:32:36.491000",
  //   },
  //   {
  //     FileName: "training_3.json",
  //     Location: "s3://sagemaker-us-east-1-253326764176/training_3.json",
  //     Source: "Configured",
  //     sensitive_labels: "SSN, PII, ADDRESS",
  //     size: "6.34 GB",
  //     type: "s3",
  //     training_date: "2023-06-10T04:32:36.491000",
  //   },
  // ];

  const handleRefChange = (ref) => {
    if (ref) ref.resetNodesPositions();
  };

  const nodeClicked = (data) => {
    setSelectedNode(data);
  };

  const getGraphData = (data) => {
    if (data) {
      data.nodes.map((node) => {
        node.height = "2rem";
        node.width = "2rem";
        node.data = {};
        node.data.image = {
          width: node.is_primary ? 50 : 40,
          height: node.is_primary ? 50 : 40,
          src: getNodeIconByType(node.type),
          margin: "10px auto 0 auto",
        };
        node.data.handleClick = nodeClicked;
        node.selected = false;
      });
    }

    data.links = data.links
      .filter((x) => data.nodes.find((y) => y.id === x.source))
      ?.filter((x) => data.nodes.find((y) => y.id === x.target));

    return data;
  };

  const getNodeLinkColor = (node) => {
    switch (node.source) {
      case "Configured":
        return "#3d61ff";
      case "Access Path":
        return "#fecf1c";
      case "Live Access":
        return "#FC4C02";
      default:
        return "#3d61ff";
    }
  };

  const getDataSources = () => {
    let startId = 1;
    const data = { nodes: [], links: [] };
    let datastoreNode = {
      id: startId++,
      datastore_node: true,
      is_primary: true,
      name: llmInfo[0]?.modelName,
      source: "",
      x: 130,
      y: 200,
      heading: modelName,
      datastoreId: datastoreId,
      tooltip_content: {
        name: modelName || llmInfo[0]?.modelName,
        framework: llmInfo?.Framework,
        owner: llmInfo?.Owner,
        baseFamily: llmInfo?.["Base Family"],
      },
    };

    data.nodes.push(datastoreNode);

    let modelPosition = datastoreNode.x;
    // Generating Model nodes
    for (let index = 0; index < trainingData.length; index++) {
      let modelNode = {
        id: startId++,
        datastore_node: true,
        is_primary: true,
        name: llmInfo[index]?.modelName,
        source: "",
        x: modelPosition + 150,
        y: 200,
        heading: modelName,
        datastoreId: datastoreId,
        tooltip_content: {
          name: modelName || llmInfo[0]?.modelName,
          framework: llmInfo?.Framework,
          owner: llmInfo?.Owner,
          baseFamily: llmInfo?.["Base Family"],
        },
      };
      modelPosition = modelNode.x;
      data.nodes.push(modelNode);

      let link = {
        id: new Date().toISOString(),
        source: data.nodes[index].id,
        target: modelNode.id,
        fontColor: getNodeLinkColor(modelNode),
        fontSize: "12",
        strokeWidth: 3,
        color: "#AC5AFF",
        textAnchor: "middle",
      };
      data.links.push(link);
    }

    for (let index = 0; index < trainingData.length; index++) {
      let trainingNode = {
        id: startId++,
        datastore_node: false,
        is_primary: false,
        name: trainingData[index].sourceName,
        datastoreId: datastoreId,
        source: trainingData[index].Source,
        type: "S3Bucket",
        x: data.nodes[index + 1].x,
        y: data.nodes[index + 1].y - 100,
        tooltip_content: {
          // name: trainingData[index].FileName,
          type: trainingData[index].type,
          location: trainingData[index].Location,
          training_date: moment(
            moment.utc(trainingData[index]?.training_date).toDate()
          )
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
          source: trainingData[index].Source,
          sensitive_data: trainingData[index].sensitive_labels,
          size: trainingData[index].size,
        },
      };
      data.nodes.push(trainingNode);

      let link = {
        id: new Date().toISOString(),
        source: trainingNode.id,
        target: data.nodes[index + 1].id,
        fontColor: getNodeLinkColor(trainingNode),
        fontSize: "12",
        strokeWidth: 3,
        color: getNodeLinkColor(trainingNode),
        textAnchor: "middle",
      };
      data.links.push(link);
    }
    return data;
  };

  const formatTitle = (title) => {
    let formatedTitle = title.replace("_", " ");
    const arr = formatedTitle.split(" ");
    for (var index = 0; index < arr.length; index++) {
      arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
    }
    return arr.join(" ");
  };

  const getRightPanel = () => {
    if (selectedNode && selectedNode !== "") {
      return (
        <GraphSelectedDataCard
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
        />
      );
    }
  };
  useEffect(() => {
    getRightPanel();
  }, [selectedNode]);

  useEffect(() => {
    setSelectedNode(null);
    if (trainingData) {
      let sources = getDataSources();
      const graphDataset = getGraphData(sources);
      setGraphData({ nodes: graphDataset.nodes, links: graphDataset.links });
    } else {
      setGraphData({ nodes: [], links: [] });
    }
  }, [width, trainingData, modelName]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <div className="linear-graph">
        {graphData && (
          <Graph
            id="access-graph-id"
            data={graphData}
            config={config}
            ref={handleRefChange}
            width="200px"
          />
        )}
      </div>
      {getRightPanel()}
    </Box>
  );
};

export default TrainingDataGraphLineageView;
