import React, { useEffect, useState } from "react";
import RiskCards from "../../cards/risk-cards";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";

const Cards = ({
  severity,
  filterIssues,
  selectedAccount,
  setSelectedAccount,
  inputText,
  setInputText,
  assets,
  identities,
}) => {
  const [accountList, setAccountList] = useState([
    { value: "all", label: "All" },
  ]);
  const accounts = useSelector((state) => state.account.accounts);
  const theme = useTheme();

  const styles = {
    cardsContainer: {
      marginBottom: theme.spacing(2),
    },
    breadcrumb: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setAccountList([
        { value: "all", label: "All" },
        ...accounts.map((account) => {
          return { value: account.id, label: account.id };
        }),
      ]);
    }
  }, [accounts]);

  return (
    <Box sx={styles.cardsContainer}>
      <div className="row align-items-center">
        <Typography
          color={theme.palette.surface100.main}
          variant="body2"
          sx={styles.breadcrumb}
        >
          {"Assets > "}
          <span className="text-info">Asset Overview</span>
        </Typography>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <RiskCards
            severity={severity}
            filterIssues={filterIssues}
            accountId={selectedAccount ? selectedAccount.value : undefined}
            assets={assets}
            identities={identities}
          />
        </div>
      </div>
    </Box>
  );
};

export default Cards;
