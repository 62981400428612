import { useTheme } from "@mui/material";
import Box from "../components/common/Box";
import { Breadcrumbs, Link } from "../components/common/Breadcrumbs";
import Typography from "../components/common/Typography";

const CustomPage = ({ breadcrumbs, children }) => {
  const theme = useTheme();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    activeLink: { color: theme.palette.surface80.main },
    blueLink: {
      color: theme.palette.primaryBlue.main,
    },
    breadCrumbLink: {
      fontSize: theme.typography.caption,
    },
  };

  return (
    <Box sx={styles.page}>
      <Breadcrumbs sx={styles.breadCrumbLink}>
        {breadcrumbs?.map((breadcrumb) => {
          return breadcrumb?.isLink ? (
            <Link
              underline="hover"
              color="inherit"
              href={breadcrumb?.link}
              sx={styles.blueLink}
            >
              {breadcrumb?.name}
            </Link>
          ) : (
            <Typography variant="caption" sx={styles.activeLink}>
              {breadcrumb?.name}
            </Typography>
          );
        })}
      </Breadcrumbs>
      {children}
    </Box>
  );
};

export default CustomPage;
