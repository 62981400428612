import { axiosPrivate } from "../../utils/ApiHandler";

export const getAllPackages = () => {
  return axiosPrivate.get("/api/v1/packageVersions");
};

export const downloadPackage = (packageID) => {
  return axiosPrivate.get(`/api/v1/downloadFile/${packageID}`, {
    responseType: "blob",
  });
};
