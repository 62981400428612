import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "../../../common/Box";
import { APPLICATION_STR, DATA_LOADER_STR } from "../../../constants/constants";
import {
  AppOverview,
  LoaderAppOverview,
} from "../../../dashboard-widgets/APIResponseType";
import AppDetails from "./AppDetails";
import DataLoaderOverview from "./DataLoaderOverview";
import SafeRetrievalDetails from "./SafeRetrievalDetails";
import { fetchAppRetreivalsSlice } from "../../../../redux/slices/appSlice";

const LoaderOverviewPanel = ({ getCardData, page }) => {
  const [cardData, setCardData] = useState<LoaderAppOverview | null>(null);
  const theme = useTheme();
  const styles = {
    overviewContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  };

  const fetchAPIDetails = async () => {
    const response = await getCardData();
    setCardData(response);
  };

  useEffect(() => {
    fetchAPIDetails();
  }, []);

  return (
    <Box sx={styles.overviewContainer}>
      {page === DATA_LOADER_STR ? (
        <DataLoaderOverview overview={cardData?.overview} />
      ) : (
        <AppDetails overview={cardData?.overview} />
      )}
      <SafeRetrievalDetails
        data={cardData?.data}
        isDataLoader={page === DATA_LOADER_STR}
      />
    </Box>
  );
};

export default LoaderOverviewPanel;
