import { useTheme } from "@mui/material";
import { getFirstNValue, getPropertyTotalCount } from "../../utils/commonUtils";
import Box from "../common/Box";
import CardWithAction from "./CardWithAction";
import Typography from "../common/Typography";
import { useEffect, useMemo, useState } from "react";
import { fetchAppsListSlice } from "../../redux/slices/appSlice";
import { getCustomerId } from "../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import Tooltip from "../common/Tooltip";
import { useHistory } from "react-router-dom";
import {
  ALL_APPLICATIONS_PAGE,
  APPLICATION_DETAILS,
} from "../../routes/constants";
import { ListAppAPI } from "./APIResponseType";
import LangchainIcon from "../../assets/img/langchainIcon.svg";
import Bar from "../common/Bar";
import CustomTable from "../common/CustomTable";
import LogoWrapper from "../common/LogoWrapper";
import { AppDispatch } from "../../redux/store";

const APP_RETRIEVAL_COUNT = 5;

const ApplicationsCard = () => {
  const [applications, setApplications] = useState<ListAppAPI[]>([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  let totalRetrievals = 0;

  const theme = useTheme();
  const styles = {
    noDataCell: {
      "&.MuiTableCell-root": {
        borderBottom: "none",
      },
    },
    appNameCell: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    appVersion: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    barCell: {
      width: "120px",
      display: "flex",
      alignItems: "baseline",
      gap: theme.spacing(1.25),
    },
    truncateText: {
      width: "12rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  };

  const redirectToAppDetailsPage = (app) => {
    history.push(APPLICATION_DETAILS + app?.id);
  };

  const redirectToAllAppsPage = (tab) => {
    history.push(ALL_APPLICATIONS_PAGE);
  };

  const tableCols = [
    {
      id: 1,
      title: "Name",
      render: (data) => (
        <Box sx={styles.appNameCell}>
          <LogoWrapper src={LangchainIcon} alt="Langchain" height="12px" />
          <Box sx={styles.appVersion}>
            <Tooltip title={data?.name}>
              <div>
                <Typography
                  variant="body2"
                  color={theme.palette.surface60.main}
                  sx={styles.truncateText}
                >
                  {data?.name}
                </Typography>
              </div>
            </Tooltip>
            <Typography variant="caption" color={theme.palette.surface60.main}>
              {data?.version}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      id: 2,
      title: "Retrievals",
      render: (data) => (
        <Box sx={styles.barCell}>
          <Typography variant="h6" isNumber={true}>
            {data?.retrievals}
          </Typography>
          <Bar
            isLastBar={false}
            xLabel={""}
            barBackColor={theme.palette.surface20.main}
            barFaceColor={theme.palette.lightMagenta.main}
            rectWidth={
              ((data?.retrievals || 0) /
                getPropertyTotalCount(applications, "retrievals")) *
              100
            }
            index={data?.name}
            totalValue={data?.retrievals || 0}
            barLabel={""}
            barValue={data?.retrievals || 0}
            type={""}
          />
        </Box>
      ),
    },
    {
      id: 3,
      title: "Active Users",
      render: (data) => (
        <Typography variant="h6" isNumber={true}>
          {data?.active_users}
        </Typography>
      ),
    },
    {
      id: 4,
      title: "Labels",
      render: (data) => (
        <Tooltip title={data?.labels?.join(", ")}>
          <div>
            <Typography
              variant="body2"
              color={theme.palette.surface60.main}
              sx={styles.truncateText}
            >
              {data?.labels ? getFirstNValue(data?.labels, 2) : "-"}
            </Typography>
          </div>
        </Tooltip>
      ),
    },
  ];

  const cardData = useMemo(
    () => ({
      applications: {
        title: "Applications",
        children: (
          <CustomTable
            tableCols={tableCols}
            tableData={applications}
            tableHeight="400px"
            onRowClick={redirectToAppDetailsPage}
          />
        ),
      },
    }),
    [applications]
  );

  const fetchAppList = async () => {
    setIsFetchingData(true);
    const payload = {
      customerId: getCustomerId(),
      count: APP_RETRIEVAL_COUNT,
    };
    const response = await dispatch(fetchAppsListSlice(payload));
    if (response?.payload) {
      setApplications(response.payload);
    } else {
      setApplications([]);
    }
    setIsFetchingData(false);
  };

  useEffect(() => {
    fetchAppList();
  }, []);

  return (
    <Box>
      <CardWithAction
        cardData={cardData}
        buttonTitle="View All"
        onClick={redirectToAllAppsPage}
      />
    </Box>
  );
};

export default ApplicationsCard;
