import { IconButton, useTheme } from "@mui/material";

import { useEffect, useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import StarIcon from "../../../assets/img/starIcon.svg";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import Chip from "../../common/Chip";
import Checkbox from "../../common/Checkbox";
import { useSelector } from "react-redux";

const SemanticGuardrailConfig = ({
  selectedLabels,
  setPolicyData,
  isAIAssist,
}) => {
  const [selectedTab, setSelectedTab] = useState("Entity");
  const [topics, setTopics] = useState([]);
  const [entities, setEntities] = useState([]);

  const entitiesList = useSelector((state: any) => state.policy.entities);
  const topicsList = useSelector((state: any) => state.policy.topics);

  const theme = useTheme();
  const styles = {
    card: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      borderTop: `1px solid ${theme.palette.surface20.main}`,
    },
    label: (label) => ({
      backgroundColor:
        selectedTab === label ? theme.palette.surface0.main : "transparent",
      padding: theme.spacing(2),
      cursour: "pointer",
    }),
    width_50: {
      width: "50%",
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "20px", width: "fit-content" },
    },
    labelName: {
      display: "flex",
      gap: theme.spacing(1.25),
      alignItems: "start",
    },
    aiChip: {
      backgroundColor: "transparent",
      color: theme.palette.surface60.main,
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
  };

  const labels = [
    {
      label: "Entity",
      value: "entites",
    },
    {
      label: "Topic",
      value: "topics",
    },
  ];

  const checkIfChecked = (group) => {
    if (selectedTab === "Entity") {
      return selectedLabels?.entities?.includes(group);
    } else {
      return selectedLabels?.topics?.includes(group);
    }
  };

  const getSelectedLabels = () => {
    if (selectedTab === "Entity") {
      return selectedLabels?.entities?.length;
    } else {
      return selectedLabels?.topics?.length;
    }
  };

  const labelList: { group: string; labels: [] }[] =
    selectedTab === "Entity" ? entities : topics;

  const selectedLabelList =
    selectedTab === "Entity"
      ? selectedLabels?.entities
      : selectedLabels?.topics;

  const getCountOfSelectedLabels = (label) => {
    if (label === "Entity") {
      return selectedLabels?.entities?.length || 0;
    } else {
      return selectedLabels?.topics?.length || 0;
    }
  };

  useEffect(() => {
    if (entitiesList?.length) {
      const list = entitiesList.map((entity) => ({
        id: entity?.id,
        group: entity?.name,
        labels: entity?.entities,
      }));

      setEntities(list);
    }
  }, [entitiesList]);

  useEffect(() => {
    if (topicsList?.length) {
      const list = topicsList.map((topic) => ({
        id: topic?.id,
        group: topic?.name,
        labels: [],
      }));

      setTopics(list);
    }
  }, [topicsList]);

  return (
    <Box sx={styles.card}>
      <Box>
        {labels.map((label) => (
          <Box
            sx={styles.label(label?.label)}
            onClick={() => setSelectedTab(label?.label)}
          >
            <Typography variant="body2" color={theme.palette.surface90.main}>
              {label?.label} Groups
            </Typography>
            <Typography variant="caption" color={theme.palette.surface60.main}>
              {getCountOfSelectedLabels(label?.label)} Selected
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.surface5.main,
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.surface20.main}`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color={theme.palette.surface100.main}>
            {selectedTab} Groups
          </Typography>
          {isAIAssist && (
            <Chip
              sx={styles.aiChip}
              label="AI Assisted"
              avatar={<img src={StarIcon} />}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "50%",
              borderRight: `1px solid ${theme.palette.surface20.main}`,
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.surface20.main}`,
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                Available ({labelList?.length || 0})
              </Typography>
            </Box>

            <Box
              sx={{
                padding: theme.spacing(2),
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(3),
                height: "400px",
                overflowY: "auto",
              }}
            >
              {labelList?.map((entity: any) => (
                <Box sx={styles.labelName}>
                  <Checkbox
                    label={entity?.id}
                    checked={checkIfChecked(entity?.id)}
                    sx={styles.checkbox}
                    onClick={() => {
                      if (selectedTab === "Entity") {
                        if (selectedLabels?.entities?.includes(entity?.id)) {
                          setPolicyData((prevState) => ({
                            ...prevState,
                            semanticGuardrail: {
                              ...prevState?.semanticGuardrail,
                              entities: selectedLabels?.entities?.filter(
                                (item) => item !== entity?.id
                              ),
                            },
                          }));
                        } else {
                          setPolicyData((prevState) => ({
                            ...prevState,
                            semanticGuardrail: {
                              ...prevState?.semanticGuardrail,
                              entities: [
                                ...selectedLabels?.entities,
                                entity?.id,
                              ],
                            },
                          }));
                        }
                      } else {
                        if (selectedLabels?.topics?.includes(entity?.id)) {
                          setPolicyData((prevState) => ({
                            ...prevState,
                            semanticGuardrail: {
                              ...prevState?.semanticGuardrail,
                              topics: selectedLabels?.topics?.filter(
                                (item) => item !== entity?.id
                              ),
                            },
                          }));
                        } else {
                          setPolicyData((prevState) => ({
                            ...prevState,
                            semanticGuardrail: {
                              ...prevState?.semanticGuardrail,
                              topics: [...selectedLabels?.topics, entity?.id],
                            },
                          }));
                        }
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={theme.palette.surface80.main}
                    >
                      {entity?.group}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(0.5),
                      }}
                    >
                      {entity?.labels?.map((label) => (
                        <Typography
                          variant="caption"
                          color={theme.palette.surface50.main}
                        >
                          {label}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.surface20.main}`,
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                Selected ({getSelectedLabels()})
              </Typography>
            </Box>

            <Box
              sx={{
                padding: theme.spacing(2),
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(3),
                height: "400px",
                overflowY: "auto",
              }}
            >
              {selectedLabelList?.map((entity) => (
                <Box
                  sx={{ ...styles.labelName, justifyContent: "space-between" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={theme.palette.surface80.main}
                    >
                      {
                        labelList.find((label: any) => label?.id === entity)
                          ?.group
                      }
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(0.5),
                      }}
                    >
                      {labelList
                        ?.find((label: any) => label?.id === entity)
                        ?.labels?.map((label) => (
                          <Typography
                            variant="caption"
                            color={theme.palette.surface50.main}
                          >
                            {label}
                          </Typography>
                        ))}
                    </Box>
                  </Box>
                  <IconButton
                    sx={{
                      width: "20px",
                      padding: "0 !important",
                      height: "16px",
                    }}
                    onClick={() => {
                      if (selectedTab === "Entity") {
                        setPolicyData((prevState) => ({
                          ...prevState,
                          semanticGuardrail: {
                            ...prevState.semanticGuardrail,
                            entities: selectedLabels?.entities?.filter(
                              (item) => item !== entity
                            ),
                          },
                        }));
                      } else {
                        setPolicyData((prevState) => ({
                          ...prevState,
                          semanticGuardrail: {
                            ...prevState.semanticGuardrail,
                            topics: selectedLabels?.topics?.filter(
                              (item) => item !== entity
                            ),
                          },
                        }));
                      }
                    }}
                  >
                    <CloseRoundedIcon
                      sx={{
                        width: "16px",
                        color: theme.palette.primaryBlue.main,
                      }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SemanticGuardrailConfig;
