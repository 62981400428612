import { useTheme } from "@mui/material";
import MUIChip, { ChipProps } from "@mui/material/Chip";

const Chip = (props: ChipProps) => {
  const theme = useTheme();
  const styles = {
    chip: {
      height: "26px",
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightMedium,
      "&.MuiChip-colorPrimary": {
        backgroundColor: theme.palette.primaryBlue.main,
        borderColor: theme.palette.primaryBlue.main,
      },
    },
  };
  return <MUIChip sx={styles.chip} {...props} />;
};

export default Chip;
