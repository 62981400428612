import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addDescriptionForFramework,
  deleteFramework,
  getAllPromptsSummary,
  getMessageDetails,
} from "../apis/llmAPI";

const initialState = {
  messageDetails: [],
  frameworks: [],
  get: [],
};

export const getAllPromptsSummarySlice = createAsyncThunk(
  "llm/get",
  async (params) => {
    const response = await getAllPromptsSummary(params);
    return response.data;
  }
);

export const getMessageDetailsSlice = createAsyncThunk(
  "llm/messageDetails",
  async (params) => {
    const response = await getMessageDetails(params);
    return response.data;
  }
);

export const deleteFrameworkSlice = createAsyncThunk(
  "llm/frameworks",
  async (params) => {
    try {
      const response = await deleteFramework(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

export const addDescriptionForFrameworkSlice = createAsyncThunk(
  "llm/frameworks/description",
  async (params) => {
    try {
      const response = await addDescriptionForFramework(params);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return err.response.data;
    }
  }
);

// LLM slice for configuring reducers and actions.
export const llmSlice = createSlice({
  name: "llm",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {},
});

export default llmSlice.reducer;
