import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Divider from "../../common/Divider";
import TextButton from "../../common/TextButton";
import JiraIcon from "../../icons/JiraIcon";
import SlackIcon from "../../icons/SlackIcon";

const JiraSlackActionButtons = () => {
  const theme = useTheme();
  const styles = {
    cardActions: {
      display: "flex",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
  };
  return (
    <Box sx={styles.cardActions}>
      <TextButton disabled={true} startIcon={<JiraIcon disabled={true} />}>
        Open Jira Ticket
      </TextButton>
      <Divider orientation="vertical" flexItem />
      <TextButton disabled={true} startIcon={<SlackIcon disabled={true} />}>
        Notify on Slack
      </TextButton>
    </Box>
  );
};

export default JiraSlackActionButtons;
