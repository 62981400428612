import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { listSensitiveData } from "../../../redux/slices/dataStoreSlice.js";

import { datastoreIcons } from "../../../utils/styles";
import { getCustomerId } from "../../../utils/SessionHelper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../common/Table";
import Typography from "../../common/Typography";
import { useTheme } from "@mui/material";
import Box from "../../common/Box";

const SensitiveData = ({ selectedAccount, setSensitiveFilter, region }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [data, setData] = useState([]);

  const getDatastoreStats = async () => {
    const resp = await dispatch(
      listSensitiveData({
        customerId: getCustomerId(),
        cloudAccountId:
          selectedAccount.value === "all" ? null : selectedAccount.value,
        region: region ? region : undefined,
      })
    );
    if (resp && resp.payload) {
      setData(resp.payload);
    }
  };

  useEffect(() => {
    getDatastoreStats();
  }, [selectedAccount]);

  const styles = {
    card: {
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.surface10.main,
    },
    cardHeader: {
      padding: theme.spacing(2),
    },
  };

  const tags = {
    "PII-SSN": "Social Security Numbers",
    "PII-CC": "Credit Cards",
    "PII-MN": "Mobile Numbers",
    "PII-PHONE": "Mobile Numbers",
    "PII-ADDRESS": "Street Addresses",
    "PII-IP": "IP Addresses",
    "PII-EMAILS": "Emails",
    "PII-EMAIL": "Email",
    "PII-DL": "Drivers Licenses",
    "PII-NAMES": "Names",
    "PII-NAME": "Name",
    "PII-AADHAR": "Aadhar Card Numbers",
    "PII-EUA": "EU Street Addresses",
    "PII-NIH": "NIH Numbers",
  };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.cardHeader}>
        <Typography color={theme.palette.surface60.main} variant="caption">
          Sensitive Data
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <table className={`mb-0 pb-3 table table-border-less table-hover`}>
            {/*<thead>
                  <tr className="vuls-row">
                    <th scope="col">Tag</th>
                    <th scope="col">Count</th>
                    <th scope="col" className="table-header">
                      Datastores
                    </th>
                  </tr>
                </thead>*/}
            <TableHead>
              <TableRow>
                <TableCell>Tag</TableCell>
                <TableCell>Count</TableCell>
                <TableCell align="right">Datastores</TableCell>
              </TableRow>
            </TableHead>

            {data && data.length ? (
              <TableBody>
                {data.map(function (item, idx) {
                  return (
                    <TableRow
                      key={idx}
                      onClick={() => setSensitiveFilter(item.tag)}
                    >
                      <TableCell>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {tags[item.tag]}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {item.count}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <span className="mr-4">{item.datastores}</span>
                        <img src={datastoreIcons["generic"]} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              ""
            )}
          </table>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SensitiveData;
