import React, { useEffect, useState } from "react";
// import "../datastore/datastore.scss";
import RiskCards from "../../components/cards/risk-cards";
import Select from "react-select";
import { useSelector } from "react-redux";
import SourceCategory from "../../components/vulnerabilities/source-category/SourceCategory";
import { useTheme } from "@mui/material";
import Box from "../common/Box";
import Typography from "../common/Typography";

const Cards = ({
  severity,
  filterIssues,
  selectedAccount,
  cloudEnvClass,
  setSelectedAccount,
  inputText,
  setInputText,
}) => {
  const [accountList, setAccountList] = useState([
    { value: "all", label: "All" },
  ]);
  const accounts = useSelector((state) => state.account.accounts);
  const theme = useTheme();

  const styles = {
    cardContainer: {
      marginBottom: theme.spacing(2),
    },
    breadcrumb: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setAccountList([
        { value: "all", label: "All" },
        ...accounts.map((account) => {
          return { value: account.id, label: account.id };
        }),
      ]);
    }
  }, [accounts]);

  return (
    <Box sx={styles.cardContainer}>
      <div className="row align-items-center">
        <Typography
          color={theme.palette.surface100.main}
          variant="body2"
          sx={styles.breadcrumb}
        >
          {cloudEnvClass ? "Source Category > " : "Issues > "}
          <span className="text-info">Overview</span>
        </Typography>
        {/* <div className="col-12 col-md-12 col-xl-8 mb-2">
          <div className="row justify-content-xl-end">
            <div className="col-auto">
              <b className="page-heading align-middle fs-14">
                ACCOUNT FILTER :
              </b>
            </div>
            <div className="col-auto">
              <Select
                options={accountList}
                classNamePrefix="accounts-dropdown"
                placeholder="Select Account"
                defaultValue={accountList.length > 0 && accountList[0]}
                value={
                  accountList.find(
                    (element) => element.value === selectedAccount.value
                  )
                    ? selectedAccount
                    : accountList[0]
                }
                onChange={(value) => setSelectedAccount(value)}
                isSearchable={false}
              />
            </div>
     
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-sm-12">
          {cloudEnvClass ? (
            <SourceCategory />
          ) : (
            <RiskCards
              severity={severity}
              filterIssues={filterIssues}
              vunerability={true}
              accountId={selectedAccount ? selectedAccount.value : undefined}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default Cards;
