import MUIPopover, { PopoverProps } from "@mui/material/Popover";
import Typography from "./Typography";
import { useTheme } from "@mui/material";

interface CustomPopoverProps extends PopoverProps {
  handleClose: any;
  anchorOrigin?: any;
}

export const Popover = ({
  open,
  anchorEl,
  handleClose,
  children,
  sx,
  ...props
}: CustomPopoverProps) => {
  const theme = useTheme();
  const styles = {
    popover: {
      ".MuiPaper-root": {
        background: theme?.palette?.surface80?.main || "#E6E7ED",
        padding: "12px 16px 20px 16px",
        marginTop: "8px",
      },
      ...sx,
    },
  };

  return (
    <MUIPopover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={
        props?.anchorOrigin || {
          vertical: "bottom",
          horizontal: "left",
        }
      }
      sx={styles.popover}
    >
      {children}
    </MUIPopover>
  );
};
