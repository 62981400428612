import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";
import Tooltip from "./Tooltip";

const ExcessItemsTooltip = ({ items }) => {
  const theme = useTheme();
  const styles = {
    table: {
      padding: theme.spacing(1),
      display: "grid",
      gridTemplateColumns: "70% 30%",
      rowGap: theme.spacing(1),
    },
  };

  return (
    <Box sx={styles.table}>
      {items?.length
        ? items?.map((label) => (
            <>
              <Typography variant="body2" color={theme.palette.surface20.main}>
                {label?.name}
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
                sx={{
                  justifySelf: "end",
                }}
              >
                {label?.count}
              </Typography>
            </>
          ))
        : null}
    </Box>
  );
};

const LabelAndValue = ({ labels }) => {
  const theme = useTheme();
  const styles = {
    card: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    table: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      rowGap: theme.spacing(1),
    },
    tooltipContainer: { width: "fit-content" },
    pointer: {
      cursor: "pointer",
    },
  };
  return (
    <Box sx={styles.card}>
      <Box sx={styles.table}>
        {labels?.slice(0, 4)?.map((label) => (
          <>
            <Typography variant="body2" color={theme.palette.surface80.main}>
              {label?.name}
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.surface80.main}
              sx={{
                justifySelf: "end",
              }}
            >
              {label?.count}
            </Typography>
          </>
        ))}
      </Box>
      {labels?.length > 4 ? (
        <Box sx={styles.tooltipContainer}>
          <Tooltip title={<ExcessItemsTooltip items={labels?.slice(4)} />}>
            <div>
              <Typography
                variant="body2"
                color={theme.palette.primaryBlue.main}
                sx={styles.pointer}
              >
                + {labels?.length - 4} more
              </Typography>
            </div>
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  );
};

export default LabelAndValue;
