import { IconButton, useTheme } from "@mui/material";
import Box from "./Box";
import { Dialog, DialogActions } from "./Dialog";
import Typography from "./Typography";
import Button from "./Button";
import CloseRounded from "@mui/icons-material/CloseRounded";

const DiscardChanges = ({ open, onClose, onSubmit }) => {
  const theme = useTheme();
  const styles = {
    dialogHeader: {
      display: "flex",
      padding: theme.spacing(3),
      alignItems: "center",
      justifyContent: "space-between",
    },
    dialogContent: {
      paddingX: theme.spacing(3),
    },
    closeIcon: {
      color: theme.palette.surface100.main,
      fontSize: theme.spacing(3),
      width: theme.spacing(3),
    },
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <Box sx={styles.dialogHeader}>
        <Typography variant="subtitle1" color={theme.palette.surface100.main}>
          Discard Changes
        </Typography>
        <IconButton onClick={onClose}>
          <CloseRounded sx={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.dialogContent}>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          You will lose any changes you have made
        </Typography>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardChanges;
