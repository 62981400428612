import { getUserId } from "../../utils/SessionHelper";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
} from "../common/Table";
import Box from "../common/Box";
import Typography from "../common/Typography";
import { useTheme } from "@mui/material";
import NoData from "../common/NoData";
import TextButton from "../common/TextButton";
import { Delete } from "@mui/icons-material";

const UserTable = ({
  users,
  setCreateUserModal,
  deleteUser,
  updateRole,
  updateStatus,
}) => {
  const theme = useTheme();
  const styles = {
    tableContainer: {
      flex: 1,
    },
    tableCard: {
      width: "100%",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: theme.spacing(0.5),
      display: "flex",
    },
  };

  const loggedInUser = localStorage.getItem("userId");
  const userIsAdmin = localStorage.getItem("role") === "ADMIN";

  return (
    <Box sx={styles.tableCard}>
      <TableContainer sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.length > 0 &&
              users.map((user, index) => {
                const isLoggedInUser = loggedInUser === user?._id;
                return (
                  <TableRow key={index} noHover={true}>
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {user.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {user.first_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface60.main}
                      >
                        {user.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <select
                        className="form-control user-dropdown"
                        value={user.status}
                        disabled={isLoggedInUser || !userIsAdmin}
                        onChange={(e) =>
                          updateStatus(user._id, e.target.value, user.email)
                        }
                      >
                        <option value={"IN-ACTIVE"}>PENDING</option>
                        <option value={"ACTIVE"}>ACTIVE</option>
                      </select>
                    </TableCell>
                    <TableCell>
                      <select
                        className="form-control user-dropdown"
                        value={user.role}
                        disabled={isLoggedInUser || !userIsAdmin}
                        onChange={(e) => updateRole(user._id, e.target.value)}
                      >
                        <option value={"ADMIN"}>ADMIN</option>
                        <option value={"OPERATOR"}>OPERATOR</option>
                      </select>
                    </TableCell>
                    <TableCell align="center">
                      {user._id === getUserId() ? "" : () => user._id}
                      <TextButton
                        startIcon={<Delete />}
                        disabled={isLoggedInUser || !userIsAdmin}
                        onClick={
                          user._id === getUserId()
                            ? () => {}
                            : () => deleteUser(user._id)
                        }
                      ></TextButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {users?.length === 0 && <NoData customText="No users found" />}
      </TableContainer>
    </Box>
  );
};

export default UserTable;
