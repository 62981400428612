import {
  Stepper as MUIStepper,
  StepperProps,
  Step as MUIStep,
  StepProps,
  StepLabel as MUIStepLabel,
  StepContent as MUIStepContent,
  StepContentProps,
  StepLabelProps,
} from "@mui/material";

export const Stepper = (props: StepperProps) => {
  return <MUIStepper {...props} />;
};

export const Step = (props: StepProps) => {
  return <MUIStep {...props} />;
};

export const StepContent = (props: StepContentProps) => {
  return <MUIStepContent {...props} />;
};

export const StepLabel = (props: StepLabelProps) => {
  return <MUIStepLabel {...props} />;
};
