import { axiosDenaliPrivate } from "../../utils/ApiHandler";

export const getActiveUsers = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/users/", params);
};

export const getUserGroups = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/user-groups", params);
};

export const getActiveUsersByRetrievals = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/user_groups/", params);
};

export const getUserInfo = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/user/info", params);
};

export const getUserDetails = async (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/user/details", params);
};
