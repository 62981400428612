import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccountAsync,
  generateExternalIdAsync,
  getRegionListAsync,
} from "../../../redux/slices/accountSlice";
import {
  hideLoading,
  showLoading,
} from "../../../redux/slices/globalOperationSlice";
import {
  ACCOUNT_TYPES,
  FREQUENCY,
  WIZARD_STEPS,
} from "../../../utils/Constants";
import { getCustomerRef, getUserId } from "../../../utils/SessionHelper";
import { Toaster, TOASTER_TYPES } from "../../../utils/toaster";
import AccountDetailStep from "./AccountDetailStep";
import VerifyAccountStep from "./AccountVerifyStep";
import "./AddAccountModal.scss";
import SelectAccountStep from "./SelectAccountStep";

/**
 * This function provides the header for current step.
 * @param {number} currentStep - Current step number
 * @returns - the header for current step.
 */
const getCurrentStepHeading = (currentStep) => {
  switch (currentStep) {
    case WIZARD_STEPS.SELECT_ACCOUNT:
      return <b>Add Account</b>;
    case WIZARD_STEPS.ACCOUNT_DETAILS:
      return <b>Add AWS Account</b>;
    case WIZARD_STEPS.VERIFY_ACCOUNT:
      return <b>Add AWS Account</b>;
    default:
      return null;
  }
};

/**
 * This function provides the title for current step.
 * @param {number} currentStep - Current step number
 * @returns - the title for current step.
 */
const getCurrentStepTitle = (currentStep) => {
  switch (currentStep) {
    case WIZARD_STEPS.SELECT_ACCOUNT:
      return <b>Select the type of account to add</b>;
    case WIZARD_STEPS.ACCOUNT_DETAILS:
      return <b>Enter Account Details</b>;
    case WIZARD_STEPS.VERIFY_ACCOUNT:
      return <b>Verify Connection</b>;
    default:
      return null;
  }
};

// Functional component for Add Account.
const AddAccountModal = ({ handleModal, refreshAccounts }) => {
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(ACCOUNT_TYPES.AWS);
  const [currentStep, setCurrentStep] = useState(WIZARD_STEPS.SELECT_ACCOUNT);
  const [externalId, setExternalId] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [accountDetails, setAccountDetails] = useState({
    accountId: "",
    accountDescription: "",
    externalId: "",
    awsRoleARN: "",
    scanFrequency: "",
    dataScanFrequency: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const loading = useSelector((state) => state.globalOperation.loading);

  useEffect(() => {
    if (selectedAccount === ACCOUNT_TYPES.GCP) {
      setAccountDetails({
        accountId: "",
        accessKey: "",
        scanScope: "",
      });
    }
  }, [selectedAccount]);

  const addAccount = async (accountDetails) => {
    const payload = {
      id: accountDetails.accountId,
      scanFrequency: accountDetails.scanFrequency,
      dataScanFrequency: accountDetails.dataScanFrequency,
      region: accountDetails.selectedRegion,
      externalId: accountDetails.externalId,
      accountDescription: accountDetails.accountDescription,
      userId: getUserId(),
      type: selectedAccount,
      aiType: accountDetails?.aiType,
    };

    dispatch(showLoading());
    const resp = await dispatch(addAccountAsync(payload));
    dispatch(hideLoading());
    if (resp && resp.payload && !resp.payload.err) {
      handleModal(false);
      refreshAccounts();
      Toaster(TOASTER_TYPES.SUCCESS, "Account added successfully.");
      window.open(resp.payload.data);
    } else {
      Toaster(
        TOASTER_TYPES.ERROR,
        resp.payload?.err?.message ||
          "Please enter a valid Account Id and try again"
      );
    }
  };

  const generateExtId = async () => {
    const resp = await dispatch(generateExternalIdAsync());
    if (resp && resp.payload?.ExternalId) {
      setExternalId(resp.payload?.ExternalId);
    }
  };
  const getRegionList = async () => {
    const resp = await dispatch(getRegionListAsync());
    var respList = resp.payload?.msg;
    var updatedRegionList = [];
    respList.map((val) => {
      updatedRegionList.push({ label: val, value: val });
    });
    if (resp && resp.payload?.msg) {
      setRegionList(updatedRegionList);
    }
  };

  useEffect(() => {
    generateExtId();
    getRegionList();
  }, []);

  return (
    <div id="add-account">
      <div
        className="modal fade show"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        style={{
          display: "block",
          paddingRight: "8px",
          background: "rgba(0, 0, 0, 0.5)",
        }}
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content p-4">
            <div className="modal-header ">
              <div className="header-text">Add New Account</div>
              <div className="row m-0 stepper mt-4">
                <div
                  className={
                    currentStep === WIZARD_STEPS.SELECT_ACCOUNT
                      ? "col-md-6 p-0 pl-3 col-lg-6 step"
                      : "col-md-6 p-0 pl-3 col-lg-6 step-disable"
                  }
                >
                  <div className="step-number mb-3">1</div>
                  <div className="step-title mb-2">
                    Select the type of account{" "}
                  </div>
                </div>
                <div
                  className={
                    currentStep === WIZARD_STEPS.ACCOUNT_DETAILS
                      ? "col-md-6 p-0 pl-3 col-lg-6 step"
                      : "col-md-6 p-0 pl-3 col-lg-6 step-disable"
                  }
                >
                  <div
                    className={
                      currentStep === WIZARD_STEPS.ACCOUNT_DETAILS
                        ? "step-number mb-3"
                        : "step-number-disable mb-3"
                    }
                  >
                    2
                  </div>
                  <div
                    className={
                      currentStep === WIZARD_STEPS.ACCOUNT_DETAILS
                        ? "step-title"
                        : "step-title-disable"
                    }
                  >
                    Enter account details{" "}
                  </div>
                </div>
              </div>
            </div>
            {currentStep === WIZARD_STEPS.SELECT_ACCOUNT ? (
              <SelectAccountStep
                selected={selectedAccount}
                handleStepOneModal={() => {
                  setSelectedAccount("");
                  handleModal(false);
                }}
                nextStep={() => {
                  setCurrentStep(currentStep + 1);
                }}
                handleSelected={(val) => setSelectedAccount(val)}
              />
            ) : (
              ""
            )}

            {currentStep === WIZARD_STEPS.ACCOUNT_DETAILS ? (
              <AccountDetailStep
                accountDetails={accountDetails}
                selectedAccount={selectedAccount}
                externalId={externalId}
                regionList={regionList}
                refreshAccounts={refreshAccounts}
                addAccount={addAccount}
                handleStepOneModal={() => {
                  setSelectedAccount("");
                  handleModal(false);
                }}
                backStep={() => {
                  setCurrentStep(currentStep - 1);
                }}
                loading={loading}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccountModal;
