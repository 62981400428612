import { useTheme } from "@mui/material";
import Box from "../../../components/common/Box";
import CustomPage from "../../CustomPage";
import { useEffect, useState } from "react";
import DocumentInfo from "./DocumentInfo";
import DocumentSnippets from "./DocumentSnippets";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchImpactedDocumentsSlice } from "../../../redux/slices/documentSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { getCustomerId } from "../../../utils/SessionHelper";

const DocumentDetails = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const docs = useSelector((state: any) => state.document.documents);
  const docName = useSelector((state: any) =>
    state.document.documents.find((doc: any) => doc.id.includes(params.doc))
  )?.name;

  const breadcrumbs = [
    {
      isLink: true,
      name: "Home",
      link: "/",
    },
    {
      isLink: true,
      name: "Documents",
      link: "/documents",
    },
    {
      isLink: false,
      name: "Document Details",
    },
  ];

  const fetchDocsList = async () => {
    await dispatch(
      fetchImpactedDocumentsSlice({ customerId: getCustomerId() })
    );
  };

  useEffect(() => {
    if (!docs || !docs?.length) {
      fetchDocsList();
    }
  }, []);

  return (
    <CustomPage breadcrumbs={breadcrumbs}>
      <>
        <DocumentInfo name={docName} />
        <DocumentSnippets name={docName} />
      </>
    </CustomPage>
  );
};

export default DocumentDetails;
