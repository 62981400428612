import { useTheme } from "@mui/material";
import MUITable, { TableProps } from "@mui/material/Table";
import MUITableBody, { TableBodyProps } from "@mui/material/TableBody";
import MUITableCell, { TableCellProps } from "@mui/material/TableCell";
import MUITableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import MUITableHead, { TableHeadProps } from "@mui/material/TableHead";
import MUITableRow, { TableRowProps } from "@mui/material/TableRow";

interface TableContainerCustomProps extends TableContainerProps {
  sx?: {};
}

export const Table = (props: TableProps) => {
  return <MUITable {...props} stickyHeader />;
};
export const TableContainer = (props: TableContainerCustomProps) => {
  const styles = {
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflow: "auto",
    },
  };
  return (
    <MUITableContainer
      {...props}
      sx={{ ...styles.tableContainer, ...props?.sx }}
    />
  );
};

export const TableBody = (props: TableBodyProps) => {
  const theme = useTheme();
  const styles = {
    tableBody: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: theme.palette.surface0.main,
      },
      ...props?.sx,
    },
  };
  return <MUITableBody sx={styles.tableBody} {...props} />;
};

export const TableHead = (props, { sx }: TableHeadProps) => {
  const theme = useTheme();
  const styles = {
    tableHead: {
      zIndex: 1,
      position: "relative",
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface10.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    ...sx,
  };
  return <MUITableHead sx={styles.tableHead} {...props} />;
};

interface TableRowProp extends TableRowProps {
  selected?: boolean;
  noHover?: boolean;
}

export const TableRow = ({ selected, noHover, ...props }: TableRowProp) => {
  const theme = useTheme();
  const styles = {
    tableRow: {
      "&.MuiTableRow-root": {
        backgroundColor: selected ? theme.palette.surface0.main : null,
        verticalAlign: "top",
      },
      "&.MuiTableRow-root:hover": {
        backgroundColor: noHover ? "transparent !important" : null,
        cursor: noHover ? "default" : "pointer",
      },
      "&.MuiTableRow-root .MuiTableCell-root:first-child": {
        borderLeft: `1px solid ${
          selected ? theme.palette.primaryBlue.main : "transparent"
        }`,
      },
      ...props.sx,
    },
  };

  return <MUITableRow sx={styles.tableRow} {...props} />;
};

interface TDProps extends TableCellProps {
  colspan?: string;
  borderBottom?: boolean;
}

export const TableCell = ({ sx, borderBottom = true, ...props }: TDProps) => {
  const theme = useTheme();

  const styles = {
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      verticalAlign: "middle",
      minHeight: theme.spacing(6.5),
      "&.MuiTableCell-root": {
        paddingX: theme.spacing(2),
        border: "none",
        borderBottom: !borderBottom
          ? ""
          : `1px solid ${theme.palette.surface20.main}`,
      },
      ...sx,
    },
  };
  return <MUITableCell sx={styles.tableCell} {...props} />;
};
