import { useTheme } from "@mui/material";
import Box from "../../common/Box";
import Typography from "../../common/Typography";
import { CardWrapper } from "../CardWrapper";
import Chip from "../../common/Chip";
import Tooltip from "../../common/Tooltip";

export const InstanceDetailsCard = ({
  instanceDetails = {},
  isManaged,
  isFineTuned,
}: {
  instanceDetails: any;
  isManaged: boolean;
  isFineTuned: Boolean;
}) => {
  const theme = useTheme();
  const styles = {
    instanceDetailsCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
      width: "100%",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    instanceDetailsCardHeading: {
      fontSize: "13px",
    },
    instanceDetailsCardHeader: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
    instanceDetailsCardDescription: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightLight,
      opacity: "0.5",
    },
    instanceDetailsCardBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    instanceDetail: {
      display: "flex",
    },
    instanceDetailKey: {
      color: theme.palette.surface60.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
    },
    instanceDetailValue: {
      color: theme.palette.surface80.main,
      fontSize: "12px",
      fontWeight: theme.typography.fontWeightRegular,
      width: "50%",
      "word-wrap": "break-word",
    },
    truncateText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "noWrap",
      width: "50%",
    },
  };

  return (
    <CardWrapper>
      <Box sx={styles.instanceDetailsCard}>
        <Box sx={styles.cardHeader}>
          <Box sx={styles.instanceDetailsCardHeader}>
            <Typography variant="body2" sx={styles.instanceDetailsCardHeading}>
              Instance Details
            </Typography>
            <Typography sx={styles.instanceDetailsCardDescription}>
              Post - Trained after downloading
            </Typography>
          </Box>
          <Chip
            label={isFineTuned ? "Under Fine-tuning" : "Deployed"}
            color="primary"
          />
        </Box>
        <Box sx={styles.instanceDetailsCardBody}>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Account</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.account || "-"}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Region</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.region || "-"}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Runtime</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.runtime || "-"}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>
              {isManaged ? "Domain" : "Host"}
            </Typography>
            <Typography sx={styles.instanceDetailValue}>
              {isManaged ? instanceDetails?.sagemaker : instanceDetails?.host}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Size</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.size || "-"}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Last Used</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.last_used || "-"}
            </Typography>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Endpoint URL</Typography>
            <Box sx={styles.truncateText}>
              <Tooltip title={instanceDetails?.endpoint_url}>
                <div>
                  <Typography component="div" sx={styles.instanceDetailValue}>
                    {instanceDetails?.endpoint_url || "-"}
                  </Typography>
                </div>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={styles.instanceDetail}>
            <Typography sx={styles.instanceDetailKey}>Modal Hash</Typography>
            <Typography sx={styles.instanceDetailValue}>
              {instanceDetails?.model_hash}
            </Typography>
          </Box>
          {!isManaged && (
            <Box sx={styles.instanceDetail}>
              <Typography sx={styles.instanceDetailKey}>Clone</Typography>
              <Typography sx={styles.instanceDetailValue}>
                {instanceDetails?.PostTrained?.duplicate_volume?.length > 0
                  ? instanceDetails?.PostTrained?.duplicate_volume.join(" ")
                  : "-"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </CardWrapper>
  );
};
