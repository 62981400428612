import { axiosDenaliPrivate } from "../../utils/ApiHandler";

export const getAllPromptsSummary = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/prompts/summary", params);
};

export const getMessageDetails = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/prompts/detail", params);
};

/**
 * Delete the framework with the specified framework id.
 * @param {object}  -- framework id to be deleted
 */
export const deleteFramework = (params) => {
  return axiosDenaliPrivate.post("/api/v1/ai/apps/delete", params);
};

/**
 * Add the description with the specified framework id.
 * @param {object} params -- payload params
 * @returns framework description added.
 */
export const addDescriptionForFramework = (params) => {
  return axiosDenaliPrivate.put("/api/v1/ai/apps/description", params);
};
