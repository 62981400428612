import externalCloud from "../../../assets/img/new-theme/attack-path-icons/external-cloud.svg";
import awsPrinciple from "../../../assets/img/new-theme/attack-path-icons/ico-aws-principal-circle-solid.svg";
import s3Bucket from "../../../assets/img/new-theme/attack-path-icons/ico-aws-s-3-bucket-circle-solid.svg";
import rdsIcon from "../../../assets/img/new-theme/attack-path-icons/ico-rds-circle.svg";
import ec2Instance from "../../../assets/img/new-theme/attack-path-icons/aws-ec-2-image-circle-solid.svg";
import GenericDB from "../../../assets/img/new-theme/attack-path-icons/GenericDB.svg";
import NeptuneDb from "../../../assets/img/new-theme/attack-path-icons/NeptuneDB.svg";
import DocDB from "../../../assets/img/new-theme/attack-path-icons/DocDB.svg";
import ElasticCache from "../../../assets/img/new-theme/attack-path-icons/ElasticCache.svg";
import ec2Subnet from "../../../assets/img/new-theme/attack-path-icons/ico-aws-EC2Subnet-circle.svg";
import LoadBalancer from "../../../assets/img/new-theme/attack-path-icons/load-balancer.svg";
import AWSGroup from "../../../assets/img/new-theme/attack-path-icons/AWSGroup.svg";
import AWSLambda from "../../../assets/img/new-theme/attack-path-icons/AWSLambda.svg";
import AWSApiGateway from "../../../assets/img/new-theme/attack-path-icons/ico-aws-apigateway-circle-solid.svg";
import AWSRole from "../../../assets/img/new-theme/attack-path-icons/ico-aws-policy-circle.svg";
import AWSUser from "../../../assets/img/new-theme/attack-path-icons/ico-aws-user-circle-solid.svg";
import AWSInternetGateway from "../../../assets/img/new-theme/attack-path-icons/ico-aws-internet-gateway-circle-solid.svg";
import AWSVpc from "../../../assets/img/new-theme/attack-path-icons/ico-aws-vpc-circle-solid.svg";
import AWSRedShift from "../../../assets/img/new-theme/attack-path-icons/ico-aws-red-shift-circle-solid.svg";
import Langchain from "../../../assets/img/v1-icons/langchain.svg";
import vectorDB from "../../../assets/img/v1-icons/vector-db.svg";

export const getNodeIconByType = (nodeType) => {
  switch (nodeType) {
    case "RDSInstance":
      return rdsIcon;
    case "EC2Instance":
    case "AssumedRole":
      return ec2Instance;
    case "LoadBalancer":
    case "LoadBalancerV2":
      return LoadBalancer;
    case "Cloud":
    case "CD_External":
      return externalCloud;
    case "S3Bucket":
      return s3Bucket;
    case "AWSPrincipal":
      return awsPrinciple;
    case "CD_DataStore":
      return rdsIcon;
    case "docdb":
      return DocDB;
    case "neptune":
      return NeptuneDb;
    case "elasticache":
      return ElasticCache;
    case "EC2Subnet":
      return ec2Subnet;
    case "DBSubnetGroup":
      return ec2Subnet;
    case "AWSGroup":
      return AWSGroup;
    case "AWSLambda":
      return AWSLambda;
    case "AWSRole":
      return AWSRole;
    case "APIGatewayRestAPI":
      return AWSApiGateway;
    case "AWSUser":
      return AWSUser;
    case "AWSInternetGateway":
      return AWSInternetGateway;
    case "AWSVpc":
      return AWSVpc;
    case "RedshiftCluster":
      return AWSRedShift;
    case "IAMUser":
      return AWSUser;
    case "llmRound":
      return AWSUser;
    case "vectorDB":
      return vectorDB;
    case "langchain":
      return Langchain;

    default:
      return GenericDB;
  }
};
