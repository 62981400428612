import { useEffect, useState } from "react";
import Box from "../../../components/common/Box";
import LegendWithColor from "../../../components/common/LegendWithColor";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { IconButton, useTheme } from "@mui/material";
import Typography from "../../../components/common/Typography";
import TextButton from "../../../components/common/TextButton";
import Divider from "../../../components/common/Divider";
import LangchainApp from "../../../assets/img/langchainHexagonIcon.svg";
import SpectrumGraph from "../../../components/common/SpectrumGraph";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch } from "react-redux";
import { getCustomerId } from "../../../utils/SessionHelper";
import { getUserInfoSlice } from "../../../redux/slices/userSlice";
import { AppDispatch } from "../../../redux/store";
import { useParams } from "react-router-dom";
import { UserInfoResponse } from "../types";
import {
  LabelColorMapping,
  LabelColorsList,
} from "../../../components/constants/constants";
import NoData from "../../../components/common/NoData";
import UserGroupsListing from "../../../components/custom/UserGroupsListing";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader";

const UserInfo = () => {
  const [userDetails, setUserDetails] = useState<UserInfoResponse | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [userRetrievalFindings, setUserRetrievalFindings] = useState<
    { label: string; value: any; color: any }[] | null
  >(null);
  const dispatch = useDispatch<AppDispatch>();
  const userRetrievals = useSelector(
    (state: any) => state.user.selectedUserRetrievals
  );
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  const theme = useTheme();
  const styles = {
    userHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    iconWrapper: {
      width: "fit-content",
      border: `1px solid ${theme.palette.surface40.main}`,
      borderRadius: "1px",
      padding: "2px",
    },
    userIcon: {
      height: "40px",
      width: "40px",
    },
    userContent: {
      width: "100%",
      display: "flex",
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(2),
      marginLeft: "auto",
      alignItems: "center",
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      justifyContent: "center",
    },
    font20: {
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightMedium,
    },
    font13: {
      fontSize: "13px",
    },
    userDetails: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      backgroundColor: "rgba(19, 22, 41, 0.6)",
      borderRadius: "4px",
      padding: theme.spacing(2),
    },
    retrievalDetails: {
      display: "grid",
      gridTemplateColumns: "1fr 1px 1fr 1px 1fr",
      justifyItems: "center",
      gridGap: theme.spacing(2),
      width: "100%",
    },
    group: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    groupTitle: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    row: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    groupCount: {
      fontFamily: "Manrope, sans-serif",
      fontSize: "24px",
    },
    retrievalContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.5),
    },
    groupContent: {},
    pageBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    iconButton: {
      height: "fit-content",
      width: "fit-content",
    },
    menuIcon: {
      fontSize: theme.spacing(2.5),
      color: theme.palette.primaryBlue.main,
    },
    divider: {
      backgroundColor: theme.palette.surface20.main,
    },
  };

  const fetchUserInfoDetails = async () => {
    setIsLoading(true);
    const payload = {
      customerId: getCustomerId(),
      name: params.id,
    };
    const response = await dispatch(getUserInfoSlice(payload));
    if (response?.payload) {
      setUserDetails({
        ...response?.payload,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userRetrievals) {
      const data = Object.keys(userRetrievals["retrieval_findings"])?.map(
        (findings, index) => ({
          label: findings,
          value: userRetrievals?.retrieval_findings[findings],
          color: LabelColorsList[index],
        })
      );
      setUserRetrievalFindings(data);
    }
  }, [userRetrievals]);

  useEffect(() => {
    fetchUserInfoDetails();
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Box sx={styles.userDetails}>
      {isLoading ? (
        <Loader height="200px" />
      ) : userDetails ? (
        <>
          <Box sx={styles.userHeader}>
            <Box sx={styles.iconWrapper}>
              <AccountBoxIcon sx={styles.userIcon} />
            </Box>
            <Box sx={styles.userContent}>
              <Box sx={styles.userInfo}>
                <Typography sx={styles.font20}>{userDetails?.name}</Typography>
                <Typography
                  variant="caption"
                  color={theme.palette.surface50.main}
                >
                  Role
                </Typography>
              </Box>
              <Box sx={styles.actions}>
                <TextButton startIcon={<RefreshIcon />} onClick={refreshPage}>
                  Refresh
                </TextButton>
                {/*<IconButton
                  onClick={(e) => setMenuAnchor(e.currentTarget)}
                  sx={styles.iconButton}
                >
                  <MoreVertIcon sx={styles.menuIcon} />
                </IconButton>*/}
              </Box>
            </Box>
          </Box>
          <Box sx={styles.retrievalDetails}>
            <UserGroupsListing groups={userDetails?.groups} title="Groups" />
            <Divider orientation="vertical" flexItem sx={styles.divider} />
            <Box sx={styles.group}>
              <Box sx={styles.groupTitle}>
                <Typography
                  sx={styles.groupCount}
                  color={theme.palette.surface80.main}
                >
                  {userDetails?.applications?.length || 0}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.surface80.main}
                >
                  Applications
                </Typography>
              </Box>
              <Box sx={styles.groupContent}>
                {userDetails?.applications?.map((group) => (
                  <Box sx={styles.row} key={group}>
                    <img src={LangchainApp} width="30px" />
                    <Typography
                      variant="subtitle2"
                      color={theme.palette.surface70.main}
                    >
                      {group}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem sx={styles.divider} />
            <Box sx={styles.group}>
              <Box sx={styles.groupTitle}>
                <Typography
                  sx={styles.groupCount}
                  color={theme.palette.surface80.main}
                >
                  {userDetails?.retrievals?.total || 0}
                </Typography>
                <Typography
                  variant="body2"
                  color={theme.palette.surface80.main}
                >
                  Retrievals
                </Typography>
              </Box>
              <Box sx={styles.retrievalContent}>
                <SpectrumGraph
                  height={18}
                  data={userRetrievals ? userRetrievalFindings : null}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: theme.spacing(1),
                    flexWrap: "wrap",
                    width: "90%",
                  }}
                >
                  {userRetrievalFindings?.map((retrieval) => (
                    <LegendWithColor
                      key={retrieval?.label}
                      color={retrieval?.color}
                      label={`${retrieval?.label} (${retrieval?.value})`}
                      textColor={theme.palette.surface70.main}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <NoData customText="No User Details" />
      )}
    </Box>
  );
};

export default UserInfo;
