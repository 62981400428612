import axios from "axios";
import * as sessionHandler from "./SessionHelper";
import * as urlFormatter from "./UrlFormatter";
import { DENATLI_URL } from "./Constants";
/**
 * We are using get for GET method, save for POST method, update for PUT method, remove for DELETE method.
 * As delete is keyword we are using method name as remove.
 */
export const axiosPublic = axios.create({
  baseURL: urlFormatter.BaseURL(),
});
export const axiosPrivate = axios.create({
  baseURL: urlFormatter.BaseURL(),
});
export const axiosDenaliPrivate = axios.create({
  baseURL: DENATLI_URL,
});

export const axiosAILabDenaliPrivate = axios.create({
  baseURL: "https://denali-ailab.dev2.cnox.io/",
});

// Interceptor to to check if token is expired.
axiosPrivate.interceptors.request.use(
  async (config) => {
    let currentDate = new Date();
    let authTokenExpiry = sessionHandler.getAuthTokenExpiry();
    let customerId = sessionHandler.getCustomerId();
    // Checking the token expiry
    if (currentDate.getTime() < authTokenExpiry) {
      if (config?.headers) {
        config.headers["Authorization"] = sessionHandler.getAuthToken();
        config.headers["clouddefense-customer-id"] = customerId;
      }
    } else {
      // Getting the new access token
      let response = await axiosPublic.post("user/getTokenFromRefreshToken", {
        refreshToken: sessionHandler.getRefreshToken(),
      });
      if (response && response.data && response.data.data) {
        sessionHandler.setAuthToken(response?.data?.data?.IdToken);
        if (config?.headers) {
          config.headers["Authorization"] = sessionHandler.getAuthToken();
          config.headers["clouddefense-customer-id"] = customerId;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      handleError();
    }
    return Promise.reject(error);
  }
);

// Interceptor to to check if token is expired.
axiosDenaliPrivate.interceptors.request.use(
  async (config) => {
    let currentDate = new Date();
    let authTokenExpiry = sessionHandler.getAuthTokenExpiry();
    let customerId = sessionHandler.getCustomerId();
    // Checking the token expiry
    if (currentDate.getTime() < authTokenExpiry) {
      if (config?.headers) {
        config.headers["Authorization"] =
          "Bearer " + sessionHandler.getAuthToken();
        config.headers["clouddefense-customer-id"] = customerId;
      }
    } else {
      // Getting the new access token
      let response = await axiosPublic.post("user/getTokenFromRefreshToken", {
        refreshToken: sessionHandler.getRefreshToken(),
      });
      if (response && response.data && response.data.data) {
        sessionHandler.setAuthToken(response?.data?.data?.IdToken);
        if (config?.headers) {
          config.headers["Authorization"] =
            "Bearer " + sessionHandler.getAuthToken();
          config.headers["clouddefense-customer-id"] = customerId;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosDenaliPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      handleError();
    }
    return Promise.reject(error);
  }
);

// Interceptor to to check if token is expired.
axiosAILabDenaliPrivate.interceptors.request.use(
  async (config) => {
    let currentDate = new Date();
    let authTokenExpiry = sessionHandler.getAuthTokenExpiry();
    let customerId = sessionHandler.getCustomerId();
    // Checking the token expiry
    if (currentDate.getTime() < authTokenExpiry) {
      if (config?.headers) {
        config.headers["Authorization"] =
          "Bearer " + sessionHandler.getAuthToken();
        config.headers["clouddefense-customer-id"] = customerId;
      }
    } else {
      // Getting the new access token
      let response = await axiosPublic.post("user/getTokenFromRefreshToken", {
        refreshToken: sessionHandler.getRefreshToken(),
      });
      if (response && response.data && response.data.data) {
        sessionHandler.setAuthToken(response?.data?.data?.IdToken);
        if (config?.headers) {
          config.headers["Authorization"] =
            "Bearer " + sessionHandler.getAuthToken();
          config.headers["clouddefense-customer-id"] = customerId;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosAILabDenaliPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      handleError();
    }
    return Promise.reject(error);
  }
);
var handleError = function () {
  localStorage.clear();
  window.location.href = "/";
};
