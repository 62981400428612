import { useTheme } from "@mui/material";
import Button from "../../common/Button";
import { Dialog, DialogActions, DialogTitle } from "../../common/Dialog";
import Typography from "../../common/Typography";
import Box from "../../common/Box";
import { DeleteApplicationDialogProp } from "../../../types/type";

export const DeleteApplicationDialog = ({
  appName,
  isOpen,
  handleClose,
  onDelete,
}: DeleteApplicationDialogProp) => {
  const theme = useTheme();

  const styles = {
    dialogContent: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(15),
    },
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle handleClose={handleClose}>Delete Application?</DialogTitle>
      <Box sx={styles.dialogContent}>
        <Typography variant="caption" color={theme.palette.surface50.main}>
          It will permanently delete the application '{appName}'
        </Typography>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={() => onDelete()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
