import { useTheme } from "@mui/material";
import { getSumOfValues } from "../../utils/commonUtils";
import Bar from "../common/Bar";
import Box from "../common/Box";
import Loader from "../common/Loader";
import NoData from "../common/NoData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";

const PageTable = ({
  tableCols,
  tableKeys,
  tableData,
  isLoading,
  pageTitle,
}) => {
  const theme = useTheme();
  const styles = {
    page: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    pageHeader: {
      display: "flex",
      alignItems: "center",
    },
    tableContainer: {
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
    },
    noHover: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: "none",
      },
    },
  };

  return (
    <Box sx={styles.page}>
      <Box sx={styles.pageHeader}>
        <Typography>
          {pageTitle} ({tableKeys ? tableKeys?.length : 0})
        </Typography>
      </Box>
      <Box sx={styles.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              {tableCols.map((col) => (
                <TableCell key={col.id}>{col.value}</TableCell>
              ))}
            </TableHead>
            <TableBody sx={styles.noHover}>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Loader height="150px" />
                  </TableCell>
                </TableRow>
              ) : tableKeys?.length ? (
                tableKeys.map((finding) => (
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.surface80.main}
                      >
                        {finding}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" width="20%">
                      <Box
                        sx={{
                          display: "flex",
                          gap: theme.spacing(2),
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.surface80.main}
                        >
                          {tableData[finding]}
                        </Typography>
                        <Bar
                          isLastBar={false}
                          xLabel={""}
                          barBackColor={theme.palette.surface20.main}
                          barFaceColor={
                            tableCols[1]?.color ||
                            theme.palette.lightMagenta.main
                          }
                          rectWidth={
                            (tableData[finding] / getSumOfValues(tableData)) *
                            100
                          }
                          index={tableData[finding]}
                          totalValue={4}
                          barLabel={""}
                          barValue={tableData[finding]}
                          type={""}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={tableCols.length}>
                    <NoData customText="No data found" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PageTable;
