import { IconButton, useTheme } from "@mui/material";
import Box from "../../common/Box";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "../../common/Typography";
import Tooltip from "../../common/Tooltip";

export const LangchainPopover = ({
  handleClose,
  popoverData,
  dataLabels,
}: {
  handleClose: any;
  popoverData: any;
  dataLabels: any;
}) => {
  const theme = useTheme();
  const styles = {
    popover: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
    },
    popoverHeader: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    popoverHeaderBody: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    popoverHeaderBodyText: {
      fontSize: "10px",
    },
    dividerStyles: {
      height: "13px",
      alignSelf: "center",
      backgroundColor: "#999DA6",
    },
    closeIcon: {
      color: theme?.palette?.surface20?.main,
    },
    popoverBody: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      columnGap: theme.spacing(9.5),
      rowGap: theme.spacing(2),
    },
    iconButton: {
      padding: 0,
    },
    lableWithValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    textEllipsis: {
      width: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };

  const labelAndValue = (label, field) => {
    const value =
      (popoverData?.packageInfo[field] !== null &&
      typeof popoverData?.packageInfo[field] === "object"
        ? popoverData?.packageInfo[field][0]?.name
        : popoverData?.packageInfo[field]) || "-";
    return (
      <Box sx={styles.lableWithValue}>
        <Typography color="#63698B" sx={styles.popoverHeaderBodyText}>
          {label}
        </Typography>
        <Tooltip title={value}>
          <div>
            <Typography
              variant="subtitle2"
              color="#080B1E"
              sx={styles.textEllipsis}>
              {value}
            </Typography>
          </div>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box sx={styles.popover}>
      <Box sx={styles.popoverHeader}>
        <Box>
          <Box sx={styles.popoverHeaderBody}>
            <Typography variant="subtitle2" color="#63698B">
              {popoverData?.name}
            </Typography>
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.dividerStyles}
            />
            <TextButton>View Details</TextButton> */}
          </Box>
          <Box sx={styles.popoverHeaderBody}>
            <Typography color="#63698B" sx={styles.popoverHeaderBodyText}>
              {popoverData?.version}
            </Typography>
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={styles.dividerStyles}
            />
            <Typography color="#63698B" sx={styles.popoverHeaderBodyText}>
              5 GB
            </Typography> */}
          </Box>
        </Box>
        <IconButton onClick={handleClose} sx={styles.iconButton}>
          <CloseIcon fontSize="small" sx={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box sx={styles.popoverBody}>
        {dataLabels?.map((item: any) => labelAndValue(item?.label, item.field))}
      </Box>
    </Box>
  );
};
