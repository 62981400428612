import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addTopic, deleteTopic, fetchTopics } from "../apis/topicAPI";

const initialState = {
  topics: [],
};

export const fetchTopicSlice = createAsyncThunk(
  "topic/fetchTopics",
  async (params: any) => {
    const response = await fetchTopics(params);
    return response.data;
  }
);

export const addTopicSlice = createAsyncThunk(
  "topic/addTopic",
  async (params: any) => {
    const response = await addTopic(params);
    return response.data;
  }
);

export const deleteTopicSlice = createAsyncThunk(
  "topic/deleteTopic",
  async (params: any) => {
    const response = await deleteTopic(params);
    return response.data;
  }
);

export const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default topicSlice.reducer;
