import { useTheme } from "@mui/material";
import Box from "../../../../common/Box";
import TextButton from "../../../../common/TextButton";
import Typography from "../../../../common/Typography";

const FindingRow = ({ name, value, isDataLoader, onClick }) => {
  const theme = useTheme();
  const styles = {
    accordionContent: {
      display: "grid",
      gridTemplateColumns: "70% 30%",
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    font13: {
      fontSize: "13px",
    },
    error: (isError) => ({
      height: "6px",
      width: "6px",
      borderRadius: "50%",
      marginLeft: "50%",
      alignSelf: "center",
      backgroundColor: isError ? theme.palette.critical.main : "transparent",
    }),
    snippetValue: {
      marginLeft: "auto",
      cursor: "pointer",
      ":hover": {
        color: theme.palette.primaryBlue.main,
      },
    },
  };
  return (
    <Box sx={styles.accordionContent}>
      {/*<Box sx={styles.error(true)}></Box>*/}
      <Typography color={theme.palette.surface80.main} sx={styles.font13}>
        {name}
      </Typography>
      <Typography
        color={theme.palette.surface50.main}
        variant="caption"
        sx={styles.snippetValue}
      >
        <TextButton onClick={onClick}>{value} Snippets</TextButton>
      </Typography>
    </Box>
  );
};

export default FindingRow;
