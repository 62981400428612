import React, { useEffect, useState } from "react";
import Box from "../../common/Box";
import TextButton from "../../common/TextButton";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Divider, useTheme } from "@mui/material";
import Typography from "../../common/Typography";
import S3Icon from "../../../assets/img/AWS_S3.svg";
import MarkIcon from "../../../assets/img/markDown.svg";
import { Select } from "../../common/Select";
import Search from "../../common/Search";
import { fetchDataSourceSnippetsSlice } from "../../../redux/slices/frameworkPageSlice";
import { DataSourceMessageDetails } from "./DataSourceMessageDetails";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../common/Accordion";
import { getCustomerId, getID } from "../../../utils/SessionHelper";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDateTime, getLoaderIcon } from "../../../utils/commonUtils";
import { fetchAppLoaderDetailsSlice } from "../../../redux/slices/appSlice";
import { AppDispatch } from "../../../redux/store";

interface Loader {
  summary: {
    source: string;
    source_type: string;
    last_modified: string;
  };
  topic_findings: {};
  entity_findings: {};
}

export const DataSourceTab = ({ frameworkData }) => {
  const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
  const [datasourceDetails, setDataSourceDetails] = useState<any>(null);

  const [selectedLoader, setSelectedLoader] = useState<Loader | null>(null);
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const objectId = params.id;

  const styles = {
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
    },
    summaryCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    fontWeight700: {
      fontWeight: 700,
    },
    fontLight: {
      fontSize: "10px",
      marginLeft: 1,
    },
    dFlex: {
      display: "flex",
    },
    alignEnd: {
      alignItems: "end",
    },
    bottom4: {
      marginBottom: 0.5,
    },
    gap4: {
      gap: 1,
    },
    summaryTab: (selected: boolean) => ({
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      padding: "8px 16px",
      background: theme.palette.surface10.main,
      border: `1px solid ${
        selected ? theme.palette.primaryBlue.main : theme.palette.surface20.main
      }`,
      borderRadius: "2px",
      width: "fit-content",
      cursor: "pointer",
    }),
    summaryToggle: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    textButton: {
      width: "fit-content",
    },
    gap56: {
      gap: theme.spacing(7),
    },
    snippetDetails: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      paddingY: theme.spacing(4),
      flex: 1,
    },
    snippetHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    sortByText: {
      fontSize: "13px",
    },
    messageDetailsHeader: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    sortByBox: {
      display: "flex",
      alignItems: "end",
      gap: theme.spacing(1.5),
    },
    gap24: {
      gap: theme.spacing(3),
    },
    accordionSummary: {
      "& .MuiAccordionSummary-expandIconWrapper": {
        svg: {
          color: theme.palette.primaryBlue.main,
        },
      },
    },
  };

  const getLoaderDetails = async () => {
    setIsMessageLoading(true);
    const payload = {
      customerId: getCustomerId(),
      appId: params.id,
    };
    const response = await dispatch(fetchAppLoaderDetailsSlice(payload));
    if (response?.payload?.data) {
      setDataSourceDetails(response?.payload?.data);
    }
    setIsMessageLoading(false);
  };

  useEffect(() => {
    if (datasourceDetails?.length > 0) {
      setSelectedLoader(datasourceDetails[0]);
    }
  }, [datasourceDetails]);

  useEffect(() => {
    getLoaderDetails();
  }, []);

  const snippetsData =
    selectedLoader?.entity_findings || selectedLoader?.topic_findings
      ? {
          entity_findings: selectedLoader?.entity_findings,
          topic_findings: selectedLoader?.topic_findings,
        }
      : null;

  return (
    <Box sx={styles.card}>
      <Box sx={styles.summaryCard}>
        <Box sx={{ ...styles.dFlex, ...styles.gap24 }}>
          {datasourceDetails?.length > 0 ? (
            datasourceDetails?.map((item) => (
              <Box
                sx={styles.summaryTab(
                  item?.summary?.source === selectedLoader?.summary?.source
                )}
                onClick={() => setSelectedLoader(item?.summary?.source)}
              >
                {/*<Box sx={{ ...styles.dFlex, ...styles.alignEnd }}>
                  <Typography
                    color={theme.palette.critical.main}
                    variant="h5"
                    sx={styles.fontWeight700}
                  >
                    {item?.summary?.restrictedCount}
                  </Typography>
                  <Typography
                    color={theme.palette.surface80.main}
                    variant="caption"
                    sx={styles.bottom4}
                  >
                    /{datasourceDetails?.totalCount}
                  </Typography>
                  <Typography
                    color={theme.palette.surface80.main}
                    sx={{ ...styles.fontLight, ...styles.bottom4 }}
                  >
                    Files with restricted data
                  </Typography>
                </Box>*/}
                <Box sx={{ ...styles.dFlex, ...styles.gap4 }}>
                  <img
                    alt=""
                    src={getLoaderIcon(item?.summary?.source)}
                    height="14"
                    width="14"
                  />
                  <Typography
                    color={theme.palette.surface80.main}
                    variant="body2"
                  >
                    {item?.summary?.source_type || "-"}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography color={theme.palette.surface80.main} variant="body2">
              No loader found.
            </Typography>
          )}
        </Box>
        <Box sx={styles.summaryToggle}>
          <Accordion>
            <AccordionSummary sx={styles.accordionSummary}>
              <TextButton sx={styles.textButton}>Summary</TextButton>
            </AccordionSummary>
            <AccordionDetails sx={{ ...styles.dFlex, ...styles.gap56 }}>
              <React.Fragment>
                <Box>
                  <Typography
                    color={theme.palette.surface30.main}
                    variant="caption"
                  >
                    Source
                  </Typography>
                  <Typography variant="body2">
                    {selectedLoader?.summary?.source || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={theme.palette.surface30.main}
                    variant="caption"
                  >
                    Source Type
                  </Typography>
                  <Typography variant="body2">
                    {selectedLoader?.summary?.source_type || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={theme.palette.surface30.main}
                    variant="caption"
                  >
                    Last Modified
                  </Typography>
                  <Typography variant="body2">
                    {getDateTime(selectedLoader?.summary?.last_modified) || "-"}
                  </Typography>
                </Box>
              </React.Fragment>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Divider variant="fullWidth" color={theme.palette.surface20.main} />
      <Box sx={styles.snippetDetails}>
        <Box sx={styles.snippetHeader}>
          <Typography variant="body2">Snippets by Labels</Typography>
        </Box>
        <DataSourceMessageDetails
          isMessageLoading={isMessageLoading}
          frameworkData={frameworkData}
          messageData={snippetsData}
        />
      </Box>
    </Box>
  );
};
