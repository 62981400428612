import { useTheme } from "@mui/material";
import MUIPagination from "@mui/material/Pagination";
import Box from "./Box";

export const Pagination = (props) => {
  const theme = useTheme();
  const styles = {
    paginationContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: theme.spacing(1.5),
    },
  };
  return (
    <Box sx={styles.paginationContainer}>
      <MUIPagination {...props} defaultPage={1} />
    </Box>
  );
};
