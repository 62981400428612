import { Collapse, IconButton, useTheme } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Bar from "../common/Bar";
import Box from "../common/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import Typography from "../common/Typography";
//import WarningIcon from "../../assets/img/warningIcon.svg";
import KeyboardArrowUpRounded from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded";

const CollapsableRow = ({
  row,
  rowData,
  onRowClick,
  selectedSensitiveLabel,
}) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const styles = {
    category: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableCell: {
      fontSize: "13px",
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
    imageWithLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    tableBody: {
      "&.MuiTableBody-root .MuiTableRow-root:hover": {
        backgroundColor: "transparent",
      },
    },
  };

  // Keep row open by default if flag is set to true
  useEffect(() => {
    if (selectedSensitiveLabel?.type === row.value) setOpen(true);
  }, [selectedSensitiveLabel, row]);

  const findingsCount: any = rowData
    ? Object.values(rowData)?.reduce(function (a, b: any) {
        return a + b?.totalCount || b?.length;
      }, 0)
    : 0;

  return (
    <>
      <TableRow>
        <TableCell
          sx={styles.category}
          onClick={() => setOpen((prev) => !prev)}
          width={"60%"}
          borderBottom={false}
        >
          {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {row?.name}
          </Typography>
        </TableCell>
        <TableCell align="right" borderBottom={false}>
          <Typography variant="caption" color={theme.palette.surface60.main}>
            {findingsCount}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          borderBottom={false}
          sx={{
            padding: 0,
            "& .MuiTableCell-root": {
              paddingRight: "0 !important",
            },
          }}
          colSpan={2}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <Table>
              {rowData ? (
                <TableBody sx={styles.tableBody}>
                  {Object.keys(rowData)?.map((rowItem) => {
                    const count =
                      rowData[rowItem]?.totalCount || rowData[rowItem]?.length;
                    return (
                      <TableRow
                        selected={rowItem === selectedSensitiveLabel?.label}
                        onClick={() => onRowClick(rowItem, row.value)}
                      >
                        <TableCell sx={styles.tableCell} borderBottom={false}>
                          <Box sx={styles.imageWithLabel}>
                            <Typography
                              variant="caption"
                              color={theme.palette.surface60.main}
                            >
                              {rowItem}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={styles.tableCell}
                          borderBottom={false}
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.surface60.main}
                          >
                            {count}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : null}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SensitiveMessageTable = ({
  selectedUser,
  retrievals,
  onRowClick,
  selectedSensitiveLabel,
  selectedFilter,
}: {
  selectedUser?: string;
  retrievals: any;
  onRowClick: any;
  selectedSensitiveLabel: any;
  selectedFilter?: string;
}) => {
  const theme = useTheme();
  const styles = {
    tableContainer: {
      //maxHeight: "100%",
      height: "fit-content",
      borderRadius: theme.spacing(0.5),
      maxHeight: "400px",
    },
    barCell: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    tableCell: {
      fontSize: "13px",
      padding: theme.spacing(2),
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
      verticalAlign: "middle",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface0.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(1.25),
        zIndex: 0,
      },
    },
    imageWithLabel: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  };

  const tableRows = [
    {
      name: "Entity",
      value: "entity_findings",
      open: true,
    },
    {
      name: "Topics",
      value: "topic_findings",
    },
    {
      name: "Identity",
      value: "identity",
    },
  ];

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader>
        <TableHead sx={styles.tableHeader}>
          <TableCell>Findings</TableCell>
          <TableCell align="right">Count</TableCell>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => {
            const labelsExist =
              row?.value && retrievals && retrievals[row.value];
            return (
              <CollapsableRow
                row={row}
                rowData={labelsExist ? retrievals[row.value] : null}
                selectedSensitiveLabel={selectedSensitiveLabel}
                onRowClick={onRowClick}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SensitiveMessageTable;
